import React from "react";
import PropTypes from "prop-types";
import { Row, Col, Card, CardHeader, CardBody, Button } from "shards-react";

import RangeDatePicker from "../common/RangeDatePicker";
import Chart from "../../utils/chart";
import { view } from 'react-easy-state';

import formatRupee from '../formatRupee';
import formatDate from '../formatDate'; 
import dashboard_store from '../DashboardStore';
import getArray from '../getArray';
import { AtomSpinner } from 'react-epic-spinners';


function getChartFormattedData(sdata){

    var labels = [];
    var data  = [];

    sdata.forEach(function(stats){
        data.push(stats.data);
        labels.push(formatDate(stats.date));

    })

    

    console.log(data,labels);

    var chart_data = {
                labels: labels,
                datasets: [ 
                {
                  label: "Past 7 days ",
                  fill: "start",
                  data: data,
                backgroundColor: "rgba(0,123,255,0.1)",
                borderColor: "rgba(0,123,255,1)",
                pointBackgroundColor: "#ffffff",
                pointHoverBackgroundColor: "rgb(0,123,255)",
                borderWidth: 1.5,
                pointRadius: 0,
                pointHoverRadius: 1
            },
      
            ]
          }

    return chart_data;

}

export default view(()=>{
        var data = {
            isLoading : dashboard_store.isLoading,
            data      : getChartFormattedData(getArray(dashboard_store.website_data)),
        }

        return <WebsiteStats data={data} />
})


class WebsiteStats extends React.Component {
  constructor(props) {
    super(props);

    this.canvasRef = React.createRef();
    this.renderChart = this.renderChart.bind(this);
  }

  

  componentDidUpdate(prevProps) {
      const oldProps = prevProps;
      const newProps = this.props;
      if(oldProps !== newProps) {
          console.log("Triggered");
          this.renderChart();
      }
  }

  renderChart(){
     const chartOptions = {
      ...{
        responsive: true,
        legend: {
          position: "top"
        },
        elements: {
          line: {
            // A higher value makes the line look skewed at this ratio.
            tension: 0.3
          },
          point: {
            radius: 0
          }
        },
        scales: {
          xAxes: [
            {
              gridLines: false,
              ticks: {
                callback(tick, index) {
                  // Jump every 7 values on the X axis labels to avoid clutter.
                  return index % 1 !== 0 ? "" : tick;
                }
              }
            }
          ],
          yAxes: [
            {
              ticks: {
                suggestedMax: 10,
                callback(tick) {
                  if (tick === 0) {
                    return tick;
                  }
                  // Format the amounts using Ks for thousands.
                  return tick > 999 ? `${(tick / 1000).toFixed(1)}K` : tick;
                }
              }
            }
          ]
        },
        hover: {
          mode: "nearest",
          intersect: false
        },
        tooltips: {
          custom: false,
          mode: "nearest",
          intersect: false
        }
      },
      ...this.props.chartOptions
    };

    const BlogWebsiteStats = new Chart(this.canvasRef.current, {
      type: "LineWithLine",
      data: this.props.data.data,
      options: chartOptions
    });

    // They can still be triggered on hover.
    /*console.log(this.props.data.data.datasets[0].data.length);
    if(this.props.data.data.datasets[0].data.length>0){
        const buoMeta = BlogWebsiteStats.getDatasetMeta(0);
        buoMeta.data[0]._model.radius = 0;
        buoMeta.data[
              this.props.chartData.datasets[0].data.length - 1
        ]._model.radius = 0;
    }*/
    

    // Render the chart.
    BlogWebsiteStats.render();
  }

  render() {
    const { title } = this.props;
    return (
      <Card small className="h-100">
        <CardHeader className="border-bottom">
          <h6 className="m-0">{"Website Visit Stats"}</h6>
        </CardHeader>
        <CardBody className="pt-0">
          
          <canvas
            height="120"
            ref={this.canvasRef}
            style={{ maxWidth: "100% !important" }}
          />
        </CardBody>
      </Card>
    );
  }
}

WebsiteStats.propTypes = {
  /**
   * The component's title.
   */
  title: PropTypes.string,
  /**
   * The chart dataset.
   */
  chartData: PropTypes.object,
  /**
   * The Chart.js options.
   */
  chartOptions: PropTypes.object
};

WebsiteStats.defaultProps = {
  title: "Users Overview",
  chartData: {
    labels: [1,2,3,4,5,6,7],
    datasets: [
      {
        label: "Past 7 days ",
        fill: "start",
        data: [
          500,
          800,
          320,
          180,
          240,
          320,
          230,
          
        ],
        backgroundColor: "rgba(0,123,255,0.1)",
        borderColor: "rgba(0,123,255,1)",
        pointBackgroundColor: "#ffffff",
        pointHoverBackgroundColor: "rgb(0,123,255)",
        borderWidth: 1.5,
        pointRadius: 0,
        pointHoverRadius: 1
      },
      
    ]
  }
};

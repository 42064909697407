import React from "react";
import { Row, Col, Card, CardHeader, CardBody,Button } from "shards-react";
import '../scroll.css';
import replaced_data from './ticketStore';
import { AtomSpinner } from 'react-epic-spinners';
import { view } from 'react-easy-state';
import Message from '../Message';
import getArray from '../getArray';
import formatRupee from '../formatRupee';
import login_data from '../../loginstore';
import { Redirect} from "react-router-dom";



export default view(()=>{
    var data = {
      data : getArray(replaced_data.data),
      isLoading : replaced_data.isLoading,
      gotData : replaced_data.gotData,
      hasError : replaced_data.hasError,
    }
    
    return login_data.isLoggedIn?<ReplacedTicketTable data={data}/>:<Redirect to='/login' />;
    //return <ProfitTable data={data} />
})

class ReplacedTicketTable extends React.Component{
  render(){
    return(
    
      <Row>
      <Col>
        <Card small className="mb-4">
          <CardHeader className="border-bottom">
            <Row>
              <Col md="3" lg="3">
                <h6 className="m-0">Transfer History</h6>
              </Col>
            </Row>
          </CardHeader>       
          
          <CardBody className="p-0 pb-3 scroll_list-group" >
          { (!this.props.data.isLoading  && this.props.data.data.length!==0) && 
            <table className="table mb-0">
              <thead className="bg-light">
                <tr>
                  <th scope="col" className="border-0">
                      #
                  </th>
                  <th scope="col" className="border-0">
                     Ticket
                  </th>
                  <th scope="col" className="border-0">
                      Prev Cust ID
                  </th>
                  <th scope="col" className="border-0">
                      New Cust ID
                  </th>
                  <th scope="col" className="border-0">
                      Prev Cust Name
                  </th>
                  <th scope="col" className="border-0">
                      Replaced Date
                  </th>
                  <th scope="col" className="border-0">
                      Account Balance
                  </th>
                  <th scope="col" className="border-0">
                      Total Amount Paid
                  </th>
                  <th scope="col" className="border-0">
                      Payment Id
                  </th>

                </tr>
              </thead>
              <tbody>

                 {
                          this.props.data.data.map((item,i)=>{
                                return (<Trow key={i} data={item} index={i+1}/>)
                          })
                  }

              
               
              </tbody>
            </table>
          }

            {
                (this.props.data.isLoading) &&
                <AtomSpinner color="blue" style={{alignSelf: 'center',marginLeft: '40%',marginTop: '10%',marginBottom: '10%'}}/>
            }
            {
                (!this.props.data.isLoading && this.props.data.hasError && (!this.props.data.gotData)  ) &&
                <Message message={"Error Loading Data"} icon_name={"warning"} />
            }
            {
                ( !this.props.data.isLoading && (this.props.data.data.length===0) && this.props.data.gotData ) &&
                <Message message={"No Data Avaiable"} icon_name={"sentiment_dissatisfied"} />
            }
            {
              (!this.props.data.isLoading && (!this.props.data.hasError) && !this.props.data.gotData ) && 
              <Message message={"Kindly Select Start and End Date"} icon_name={"call_missed"} />
            }

          </CardBody>
        </Card>
      </Col>
    </Row>
    )
  }
}




class Trow extends React.Component{
 
  render(){
    return(

          <tr>
                  <td>{this.props.index}</td>
                  <td>{this.props.data.group_id+"/"+this.props.data.ticket_no}</td>
                  <td>{this.props.data.custID}</td>
                  <td>{this.props.data.replacedBy}</td>  
                  <td>{this.props.data.name}</td>
                  <td>{this.props.data.replacedDate.toDate().toLocaleDateString()}</td> 
                  <td>{formatRupee(this.props.data.account_balance)}</td>  
                  <td>{formatRupee(this.props.data.total_amount_paid)}</td>
                  <td> 
                      {
                        (this.props.data.payment_id===undefined) &&
                        <Button theme="primary" onClick={()=>{replaced_data.addPaymentId(this.props.data.doc_id)}}>Add</Button>
                      }
                      {
                        (this.props.data.payment_id!==undefined) &&
                        this.props.data.payment_id
                      }
                  </td>

          </tr>

    )
  }
}


import React from "react";
import ReactQuill from "react-quill";
import { Card, CardBody, Form } from "shards-react";
import sms_data from './SendSMSStore';
import {view} from 'react-easy-state'; 
import "react-quill/dist/quill.snow.css";
import "../../assets/quill.css";
import { AtomSpinner } from 'react-epic-spinners';

export default view(()=>{
    var modules = {
      toolbar : false,
    }
    return <Editor isLoading={sms_data.isLoading} modules={modules} />
})

const Editor = (props) => (
  <Card small className="mb-3">
    <CardBody>
      <Form className="add-new-post">
        {
          (!props.isLoading) &&
          <ReactQuill placeholder={"Please Enter  Text Here"} className="add-new-post__editor mb-1" modules={props.modules} onChange={(value)=>sms_data.setMessage(value)}/>
        }
        {
          (props.isLoading) &&
          <center>
            <AtomSpinner color="blue" style={{marginTop:'20%',marginBottom:'20%'}}/>
            
          </center>
        }
        
      </Form>
    </CardBody>
  </Card>
);


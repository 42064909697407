import React from "react";
import {  Modal, ModalBody, ModalHeader,Col,Button,FormSelect,Row,DatePicker} from "shards-react";
import Input from '../common/Input';
import FireFileUpload from '../FireFileUpload';
import SelectGroup from '../SelectGroup';
import surety_data from './suretyStore';


export default class AddSurety extends React.Component{
       constructor(props) {
            super(props);
            this.state = { open: false,date:new Date()};
            this.toggle = this.toggle.bind(this);
            this.handleDateChange = this.handleDateChange.bind(this);
        }

    
        handleDateChange(date){
            this.setState({date:new Date(date)});
            surety_data.setValue('dob',new Date(date));
        }
    

    
      toggle() {
        this.setState({
          open: !this.state.open
        });
      }
    
      render() {
        const { open } = this.state;
        return (
          <div>

            <Modal open={open} toggle={this.toggle}>
              <ModalHeader>
                    <b>Add Surety</b>     
            </ModalHeader>
              <ModalBody flush className="modalScroll">
                <Row>
                    <ChooseGroup size="6" onChange={(evt)=>{surety_data.setValue('group_id',evt.target.value)}}  />
                    <Input size="6" name="Auction No." type="number" onChange={(evt)=>{surety_data.setValue('auction_no',evt.target.value)}} />
                </Row>
                <Row>
                    <SuretyNumber size="12" onChange={(evt)=>{surety_data.setValue('surety_no',evt.target.value)}} />
                </Row>
                <Row>
                        <Input size="6" name="Name" type="text" onChange={(evt)=>{surety_data.setValue('name',evt.target.value)}} />
                        <Col md="6" lg="6" sm="12" xs="12" className="mb-2">
                            <label>Date  of Birth</label>
                            <br />
                            <DatePicker
                                size="sm"
                                selected={this.state.date}
                                onChange={this.handleDateChange}
                                placeholderText="Date"
                                dropdownMode="select"
                                className="text-center"
                            />
                        </Col>
                </Row>
                <Row>
                        <Input size="6" name="Phone" type="number" onChange={(evt)=>{surety_data.setValue('phone',evt.target.value)}} />
                         <Input size="6" name="Company Name" type="text" onChange={(evt)=>{surety_data.setValue('company_name',evt.target.value)}} />
                </Row>
                <Row>
                        <Input size="6" name="Designation" type="text" onChange={(evt)=>{surety_data.setValue('designation',evt.target.value)}} />
                        <Input size="6" name="Salary" type="number" onChange={(evt)=>{surety_data.setValue('salary',evt.target.value)}} />
                </Row>
                <Row>
                        <Input size="12" name="Resedential Address" type="text" onChange={(evt)=>{surety_data.setValue('address',evt.target.value)}} />
                </Row>

                <Row>
                    <Input size="12" name="Office Address" type="text" onChange={(evt)=>{surety_data.setValue('office_address',evt.target.value)}} />
                </Row>
                <Row>
                    <Input size="12" name="Remarks" type="text" onChange={(evt)=>{surety_data.setValue('remarks',evt.target.value)}} />
                </Row>
                <Row>
                    <FileUpload size="4" field_name="File 1" path="Surety/File1"
                                update_url={(url)=>{surety_data.setValue('file1',url)}} cancel_file={()=>{surety_data.setValue('file1',null)}} />
                    <FileUpload size="4" field_name="File 2" path="Surety/File2"
                                update_url={(url)=>{surety_data.setValue('file2',url)}} cancel_file={()=>{surety_data.setValue('file2',null)}} />
                    <FileUpload size="4" field_name="File 3" path="Surety/File3"
                                update_url={(url)=>{surety_data.setValue('file3',url)}} cancel_file={()=>{surety_data.setValue('file3',null)}} />
                </Row>
                <Row>
                    <FileUpload size="4" field_name="File 4" path="Surety/File4"
                                update_url={(url)=>{surety_data.setValue('file4',url)}} cancel_file={()=>{surety_data.setValue('file4',null)}} />
                    <FileUpload size="4" field_name="File 5" path="Surety/File5"
                                update_url={(url)=>{surety_data.setValue('file5',url)}} cancel_file={()=>{surety_data.setValue('file5',null)}} />
                </Row>
                <Col size="8" className={"pt-3"}><Button theme="primary" onClick={()=>{this.toggle();surety_data.addSurity()}}>Add Surety</Button></Col>
              </ModalBody>
            </Modal>
            <Button theme="primary" className="mb-3" onClick={()=>{this.toggle()}}>Add Surety</Button>
          </div>
        );
      }
}


function ChooseGroup(props){
    return(
        <Col md={props.size} className="form-group">
            <label >Group ID</label>
            <SelectGroup onChange={props.onChange} />
        </Col>
    )
}


class FileUpload extends React.Component{
    constructor(props){
        super(props);
        this.state = {url:null,name:null}
        this.update_url = this.update_url.bind(this);
        this.cancel_file = this.cancel_file.bind(this);
    }

    update_url(url,name){
        this.setState({url,name});
        this.props.update_url(url);
    }

    cancel_file(){
        this.setState({url:null})
        this.props.cancel_file();
    }



    render(){
    return(

        <Col md={this.props.size} lg={this.props.size}>
            <label htmlFor="fePassword">{this.props.field_name}</label>
            {(this.state.url===null) &&
                <FireFileUpload path={this.props.path}
                       update_url={this.update_url} />
            }
        {
          (this.state.url!==null) &&
          <div>
              <br />
              <Button theme="danger" onClick={this.cancel_file} >Remove</Button>
              <h6 className="m-0" style={{color:'black',fontSize:14}}>{this.state.name}</h6>
          </div>
        }
     </Col>
    )
    }
}


function SuretyNumber(props){
  return(
  <Col md={props.size} className="form-group">
    <label >{"Surety "}</label>
    <FormSelect onChange ={props.onChange}>
        <option value={"client"}>Client</option>
        <option value={"Surety 1"}>Surety 1</option>
        <option value={"Surety 2"}>Surety 2</option>
        <option value={"Surety 3"}>Surety 3</option>
        <option value={"Surety 4"}>Surety 4</option>
        <option value={"Surety 5"}>Surety 5</option>
        <option value={"Surety 6"}>Surety 6</option>
        <option value={"Surety 7"}>Surety 7</option>
        <option value={"Surety 8"}>Surety 8</option>
        <option value={"Surety 9"}>Surety 9</option>
        <option value={"Surety 10"}>Surety 10</option>
      </FormSelect>
    </Col>
     
  )
}
import React from 'react';
import {view} from 'react-easy-state';
import { Container, Alert} from "shards-react";
import alert_data from './AlertStore';

export default view(()=>{

    return <AlertBox message={alert_data.message} 
				     show={alert_data.showAlert} 
				      type={alert_data.type}
                    />
})


class AlertBox extends React.Component{
    render(){
        return(
            <div>
            {
              this.props.show &&
                    <Container fluid className="px-0">
      			        <Alert className="mb-0" theme={this.props.type}>
        				    <i className="fa fa-info mx-2"></i> {this.props.message}
      			        </Alert>
                    </Container>
            }
            </div>
           
        )
    }
}   
import React from 'react';
import { Container, Row, Col,Card , CardHeader,CardBody, FormCheckbox,FormGroup,FormInput,Button,
		Form,InputGroupAddon,InputGroup,InputGroupText,FormSelect,DatePicker } from "shards-react";
import PageTitle from "../common/PageTitle";
import FireFileUpload from '../FireFileUpload';
import firebase from '../../firebase';
import {toast} from 'react-toastify';
import { AtomSpinner } from 'react-epic-spinners';
import alert from '../AlertStore';
import AlertBox from '../AlertBox';


export default class ModifyGroup extends React.Component{

  constructor(props) {
    super(props);
  
    this.state = {
        group_id: null,
        pso_no  : null,
        pso_date : null,
        deposit_cert_no : null,
        deposit_amount  : null,
        deposit_date  : null,
        deposit_bank : null,
        deposit_rate_of_interest : null,
        cc_no  : null,
        cc_date : null,
        isLoading:false,
        gotData : false,
    };

    this.isNull = this.isNull.bind(this);
    this.onSubmitHandler = this.onSubmitHandler.bind(this);
    this.getData = this.getData.bind(this);
    this.getGroup = this.getGroup.bind(this);
    this.modifyGroup = this.modifyGroup.bind(this);
    this.onUpdateSubmit = this.onUpdateSubmit.bind(this);
  }


  isNull(obj){ 
  
  for (var key in obj) {
      if (obj.hasOwnProperty(key)) {
        var val = obj[key];
        console.log(val)
        if(val===undefined || val === null){
          return true;
        }
      }
  }
  return false;
}

getData = () =>{
  console.log("Group ID ="+this.state.group_id);
  if(this.state.group_id!==null){
          this.getGroup(this.state.group_id);  
  }
}

componentDidMount(){
  var url_string = window.location.href;
  var url = new URL(url_string);
  var c = url.searchParams.get("group_id");
  console.log(c)
  this.setState({group_id:c},this.getData);
}

getGroup = (group_id) =>{

    if(group_id===null || group_id===undefined){
      alert.showMessage("Group ID Not Valid","danger");
      return;
    } 
    var that = this;
    this.setState({isLoading:true});
    
    firebase.firestore().collection('Group').doc(group_id).get().then(function(doc){
      if(!doc.exists){
        alert.showMessage("Group Data doesnt Exist","danger");
      }

      var data = doc.data();
      data['pso_date'] = data.pso_date.toDate().toDateString();
      data['deposit_date'] = data.deposit_date.toDate().toDateString();
      data['cc_date'] = data.cc_date.toDate().toDateString();

      console.log(data);
      that.setState(data);
      that.setState({isLoading:false,gotData:true});

    }).catch(function(err){
        alert.showMessage("Error Fetching Group Data","danger");
        console.log(err);
        that.setState({isLoading:false,gotData:false});
    })

}
  
modifyGroup = () =>{
  console.log(data);
  var data = this.state;
  delete data.isLoading;
  delete data.gotData; 
  var that = this;

  data['pso_date'] = new Date(data.pso_date);
  data['cc_date']  = new Date(data.cc_date);
  data['deposit_date'] = new Date(data.deposit_date);

  this.setState({isLoading:true});

  firebase.firestore().collection('Group').doc(data.group_id).update(data).then(function(){
      that.setState({isLoading:false});
      alert.showMessage("Succesfully updated","success");
      console.log("Update successfull");
      that.getGroup(data.group_id);
  }).catch(function(err){
      that.setState({isLoading:false});
      alert.showMessage("Error updating Group","danger");
      console.log(err);
  })

} 

onUpdateSubmit = (evt) =>{
     evt.preventDefault();
    this.modifyGroup();
}
  
  onSubmitHandler = (evt) =>{
      evt.preventDefault();
      this.getGroup(this.state.group_id);
  }

  render(){

  	

    return(
      <div>
        <AlertBox />
      
    <Container fluid className="main-content-container px-4 pb-4">
        {/* Page Header */}
        <Row noGutters className="page-header py-4">
              <PageTitle sm="4" title="Group" subtitle="Modify" className="text-sm-left" />
        </Row>

        

     	<Col lg="12">
        <Card small className="mb-4">
          <CardHeader className="border-bottom">
              <h6 className="m-0">Modify Group</h6>
              
          </CardHeader>
          <CardBody className="p-2 pb-2" >
        		
         <Col>
          <Form onSubmit={this.onSubmitHandler}>
            <Row form>
              <Col md="4" className="form-group">
                <label htmlFor="feEmailAddress">GroupID</label>
                <FormInput
                  id="feEmailAddress"
                  type="text"
                  placeholder="GroupID"
                  value={this.state.group_id}
                  onChange = {(evt)=>{this.setState({group_id:evt.target.value})}}
                />
              </Col>
              <Col md="4">

                  <Button type="submit" style={{alignSelf: 'center',marginTop: '9%'}}>Get Group</Button>
              </Col>
           </Row>
          </Form>
        </Col>
      

 
          </CardBody>
          </Card>
          </Col>

    {

        (!this.state.isLoading && this.state.gotData) &&

          <Col lg="12">
              <Card small className="mb-4">
                  <CardBody className="p-2 pb-2" >
                              

        <Col>
          <Form onSubmit={this.onUpdateSubmit}>
            <Row form>

                <FormTextInput size="4" field_name="PSO No." value={this.state.pso_no} type="text" onChange={(evt)=>{this.setState({pso_no:evt.target.value})}} />
                <FormTextInput size="4" field_name="PSO Date" value={this.state.pso_date} type="date" onChange={(evt)=>{this.setState({pso_date:evt.target.value})}} />
                <FormTextInput size="4" field_name="Deposit Certificate No." value={this.state.deposit_cert_no} type="text" onChange={(evt)=>{this.setState({deposit_cert_no:evt.target.value})}} />

             </Row>

             <Row form>
                <FormTextInput size="4" field_name="Deposit Date" value={this.state.deposit_date} type="date" onChange={(evt)=>{this.setState({deposit_date:evt.target.value })}} />
                <FormTextInput size="4" field_name="Deposit Amount" value={this.state.deposit_amount} type="text" onChange={(evt)=>{this.setState({deposit_amount:evt.target.value})}} />
                <FormTextInput size="4" field_name="Deposit Bank" value={this.state.deposit_bank} type="text" onChange={(evt)=>{this.setState({deposit_bank:evt.target.value})}} />
             </Row>

             <Row form>
                <FormTextInput size="4" field_name="Deposit Rate of Interest" value={this.state.deposit_rate_of_interest} type="text" onChange={(evt)=>{this.setState({deposit_rate_of_interest:evt.target.value})}} />
                <FormTextInput size="4" field_name="CC No." type="text"  value={this.state.cc_no} onChange={(evt)=>{this.setState({cc_no:evt.target.value})}} />
                <FormTextInput size="4" field_name="CC Date" type="date" value={this.state.cc_date} onChange={(evt)=>{this.setState({cc_date:evt.target.value})}} />
             </Row>


            
            <Button type="submit" style={{marginTop: '3%',alignSelf: 'center',marginBottom: '3%'}}>Update Group</Button>
            
          </Form>
        </Col>
      
            </CardBody>
              </Card>
          </Col>
    }
    
    {
    this.state.isLoading &&

    <Col lg="12" md="12">
        <Card>
            <CardBody>
                  <AtomSpinner 
                            color="blue" 
                            style={{alignSelf: 'center',marginLeft: '40%',marginTop: '10%',marginBottom: '10%'}}/>
            </CardBody>
        </Card>
    </Col>
  }
    </Container>
  </div>

    )
  }
}





//dont use for date
class FormTextInput extends React.Component{
  render(){
    return(
       <Col md={this.props.size} className="form-group">
                  <label>{this.props.field_name}</label>
                  <FormInput
                  {...this.props}
                  type={this.props.type}
                  placeholder={this.props.field_name}
                  onChange = {this.props.onChange}
                />

              </Col>
    )
  }
}


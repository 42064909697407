import React from "react";
import { Container, Row, Col,Card,CardBody,InputGroup,
	Button,
  InputGroupAddon,
  InputGroupText,
  FormSelect,FormInput,} from "shards-react";
import {  view } from 'react-easy-state';
import { AtomSpinner } from 'react-epic-spinners';
import PageTitle from "../common/PageTitle";
import SelectGroup from '../SelectGroup';
import group_names from '../groupNameStore';
import profile_data from '../customerProfileStore';
import Message from '../Message';
import DueTable from './DueTable';
import AuctionTable from './AuctionTable';
import ReceiptTable from './ReceiptTable';
import ChitDetails from './ChitDetails';
import CustomerDetails from './CustomerDetails';
import AllTicketsSelect from './AllTicketsSelect';
import login_data from '../../loginstore';
import { Redirect } from "react-router-dom";
import AlertBox from '../AlertBox';
import GeneralVoucherTable from './GeneralVoucherTable';
import ViewReceipt from "../Receipt/ViewReceipt";


function getArray(proxyObj){
    var arr = [];
    var obj = proxyObj;
      for (var key in obj) {
        if (obj.hasOwnProperty(key)) {
                arr.push(obj[key]);
        }
    }
    return arr;
}



function setCustomerID(cust_id){
    profile_data.setCustomerID(cust_id);
}

function setGroupID(group_id){
  profile_data.setGroupID(group_id);
}

function setTicketNo(ticket_no){
  profile_data.setTicketNo(ticket_no);
}

function setViewByCustomerID(type){
  profile_data.setViewByCustomerID(type);
}

function getProfile(){
  profile_data.getProfile();
}

function set_fetched_url_cust(){
  profile_data.fetched_url_cust = true;
}

function getURLData(){
  var url_string = window.location.href;
  var url = new URL(url_string);
  var id = url.searchParams.get("id");
  return id;
}




export default view(()=>{

    var data = {
        groupNames          : getArray(group_names.names),
        viewByCustomerID    : profile_data.viewByCustomerID,
        fetched_url_cust : profile_data.fetched_url_cust,
        cust_id : profile_data.customerID,
    
        isLoading    : profile_data.isProfileLoading,
        hasError     : profile_data.hasErrorProfileData,
        gotData      : profile_data.gotProfileData,

    }
    console.log(data);
    var cust_id = getURLData();
    console.log("CUST ID=",cust_id);
    if((cust_id!==data.cust_id) && (!data.fetched_url_cust) && (cust_id!==null)){
        set_fetched_url_cust();
        setCustomerID(cust_id);
        setViewByCustomerID("true");
        console.log(profile_data);
        getProfile();
    }
    return (cust_id===null?login_data.isLoggedIn?<ProfileView data={data} />:<Redirect to='/login' />:<ProfileView data={data} />)
})


class ProfileView extends React.Component{

  componentWillUnmount(){
    profile_data.fetched_url_cust = false;
  }

	render(){
		return(
    <div>
      <AlertBox />
			<Container fluid className="main-content-container px-4">
    			<Row noGutters className="page-header py-4">
      				<PageTitle title="Customer Profile" subtitle="Overview" md="12" className="ml-sm-auto mr-sm-auto" />
    			</Row>
    			<Row>
    			<TypeSelector/>
          {
            this.props.data.viewByCustomerID &&
              <CustomerIDInput/>
          }
          {
            !this.props.data.viewByCustomerID &&
            <GroupTicket group_names={this.props.data.groupNames}/>
          }
    			
    			</Row>
          {
            this.props.data.gotData &&
    			 <Row>
      			<CustomerDetails />
      			<ChitDetails />
    			</Row>
        }
        {
          (!this.props.data.gotData && !this.props.data.isLoading) &&
          <Row>
            <Col lg="12" md="12" sm="12">
             <Card>
                <CardBody>
                   <Message message="Please Select a Customer" icon_name="call_missed" />
                </CardBody>
              </Card>
            </Col>
          </Row>
        }
        {
          (this.props.data.gotData && !this.props.data.isLoading && this.props.data.hasError) &&
          <Row>
            <Col lg="12" md="12" sm="12">
             <Card>
                <CardBody>
                   <Message message="Error Loading Data" icon_name="warning" />
                </CardBody>
              </Card>
            </Col>
          </Row>
        }
        {
          this.props.data.isLoading &&
          <Row>
            <Col lg="12" md="12" sm="12">
             <Card>
                <CardBody>
                    <AtomSpinner color="blue" style={{alignSelf: 'center',marginLeft: '40%',marginTop: '15%',marginBottom: '15%'}}/>
                </CardBody>
              </Card>
            </Col>
          </Row>
        }
        { (this.props.data.cust_id!==null) && 
          <Col md="12" lg="12" style={{marginTop: '5%'}}>
            <Col md="4" lg="4">
              <AllTicketsSelect className="p-2"/>
            </Col>
    			      <DueTable  />
      		      <AuctionTable />
    			      <ReceiptTable />
                <GeneralVoucherTable />
          </Col>
        }
        
        <ViewReceipt />
        
  			</Container>
      </div>
		)
	}
}


class GroupTicket extends React.Component{
	render(){
	return(
		<Row>
    <Col sm="12" md="6" lg="6">
		  <SelectGroup onChange={(evt)=>{setGroupID(evt.target.value)}}/>
    </Col>
		<Col lg="6">
			<InputGroup className="mb-3">
    				<FormInput placeholder={"Ticket No"} onChange={(evt)=>{setTicketNo(evt.target.value)}}/>

    				<InputGroupAddon type="prepend">
    						<Button onClick={getProfile}>Get Customer</Button>
    				</InputGroupAddon>
    		</InputGroup>
    	</Col>
    	</Row>
    )
	}
}

class CustomerIDInput extends React.Component{
	render(){
		return(
			<Row>
		<Col lg="12">
			<InputGroup className="mb-3">
    				<FormInput placeholder={"Customer ID"} onChange={(evt)=>{setCustomerID(evt.target.value)}} style={{textTransform:'uppercase'}}/>

    				<InputGroupAddon type="prepend">
    						<Button onClick={getProfile}>Get Customer</Button>
    				</InputGroupAddon>
    		</InputGroup>
    	</Col>
    	</Row>
		)
	}
}

class TypeSelector extends React.Component{
  render(){
    return(
        <Col sm="12" md="6" lg="4">
            <InputGroup className="mb-3">
                
                <FormSelect onChange={(evt)=>{setViewByCustomerID(evt.target.value)}}>
                       <option value={true}>View By CustomerID</option>
                       <option value={false}>View by Ticket No</option>
                  </FormSelect>

            <InputGroupAddon type="prepend">
                    <InputGroupText>View Type</InputGroupText>
            </InputGroupAddon>
         </InputGroup>
        </Col>
    )
  }
}




import { store } from 'react-easy-state';
import is_empty_string  from './isEmptyString';
import firebase from '../firebase';
import alert from './AlertStore';


const auction_data = store({
	isUpcomingLoading : false,
	UpcominghasError : false,
	gotUpcomingData : false,
	Upcomingdata : [],

	group_id : null,
	type : "auctioned",
	isAuctionLoading : false,
	hasErrorAuction : false,
	gotAuctionData : false,
	vewData : [],
	history:{
		"auctioned":{},
		"success":{},
		"failure":{},
	},

	setGroupID(group_id){
		auction_data.group_id = group_id;
		auction_data.getAuctionData();
	},
	setAuctionType(type){
		auction_data.type = type;
		auction_data.getAuctionData();
	},

	getAuctionData(){
		let group_id = auction_data.group_id;
		let type = auction_data.type;
		
		if(is_empty_string(group_id) || is_empty_string(type)){
			return;
		}
		
		if(auction_data.history[type][group_id]!==undefined){
			auction_data.viewData = auction_data.history[type][group_id];
			return;
		}

		auction_data.isAuctionLoading = true;
		firebase.firestore().collection('Auction').where('group_id','==',group_id).where('status','==',type).orderBy('auct_no','desc').onSnapshot(function(snap){

			var temp = [];
			
			snap.forEach(function(doc){
				temp.push(doc.data());
			});
			console.log(temp);


			auction_data.isAuctionLoading = false;
			auction_data.hasErrorAuction = false;
			auction_data.gotAuctionData = true;
			auction_data.viewData = temp;
			auction_data.history[type][group_id] = temp;

		},function(err){
			console.log(err);
			alert.showMessage(err.message,"danger");
			auction_data.isAuctionLoading = false;
			auction_data.hasErrorAuction = true;
			auction_data.gotAuctionData = true;
			auction_data.viewData = [];
		})
	},



	getUpcomingAuction(){
		if(auction_data.gotUpcomingData){
			return;
		}
		auction_data.isUpcomingLoading = true;
		firebase.firestore().collection('Auction').where('status','==','pending').onSnapshot(function(snap) {
        
        		var temp = [];
        		snap.forEach(function(doc){
        			temp.push(doc.data());
        		})

        		auction_data.isUpcomingLoading = false;
        		auction_data.UpcominghasError = false;
        		auction_data.gotUpcomingData = true;
        		auction_data.Upcomingdata = temp;

 		},function(err){
 				auction_data.isUpcomingLoading = false;
        		auction_data.UpcominghasError = true;
        		auction_data.gotUpcomingData = true;
        		auction_data.Upcomingdata = [];
 				console.log(err);
 		});

	},
})

export default auction_data;
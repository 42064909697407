import React from "react";
import { Container, Row, Col,InputGroup,
  InputGroupAddon,
  InputGroupText,
  FormSelect,} from "shards-react";

import PageTitle from "../common/PageTitle";
import '../scroll.css';
//import SelectGroup from '../SelectGroup';
import { view } from 'react-easy-state';
import SelectGroup from '../SelectGroup';
import UpcomingAuction from './UpcomingAuction';
import AuctionedTable from './AuctionedTable';
import SuccessTable from './SuccessTable';
import FailureAuction from './FailureAuction';
import auction_data from '../auctionStore';
import getArray from '../getArray';
import login_data from '../../loginstore';
import { Redirect } from "react-router-dom";
import AlertBox from '../AlertBox';


function setGroup(group_id){
    auction_data.setGroupID(group_id);
}

function setType(type){
  auction_data.setAuctionType(type);
}

export default view(()=>{       var data ={
                                   isLoading : auction_data.isAuctionLoading,
                                   hasError  : auction_data.hasErrorAuction,
                                   gotData   : auction_data.gotAuctionData,
                                   data      : getArray(auction_data.viewData),
                                   type      : auction_data.type,       
                                }

                               
                                return login_data.isLoggedIn?<Auction data={data}/>:<Redirect to='/login' />
                          });


class Auction extends React.Component{
    render(){
      return(
      <div>
        <AlertBox />
            <Container fluid className="main-content-container px-4">
    {/* Page Header */}
    <Row noGutters className="page-header py-4">
      <PageTitle sm="4" title="Auction" subtitle="Overview" className="text-sm-left" />
    </Row>



    {/* Default Light Table */}
    <UpcomingAuction />

    <Row className="pl-3">
      <Col md="12" sm="12" lg="4">
        <SelectGroup onChange = {(evt)=>{setGroup(evt.target.value)}} />
      </Col>
    
        <TypeSelector />
    </Row>
    {
      (this.props.data.type==="auctioned") &&
      <AuctionedTable data={this.props.data}/> 
    }
    {
     (this.props.data.type==="success") &&
      <SuccessTable data={this.props.data}/>  
    }
    {
      (this.props.data.type==="failure") &&
      <FailureAuction data={this.props.data}/> 
    }

  </Container>
</div>
        )
    }
}


class TypeSelector extends React.Component{
  render(){
    return(
        <Col sm="12" md="6" lg="4">
            <InputGroup className="mb-3">
                
                <FormSelect onChange = {(evt)=>{setType(evt.target.value)}}>
                      <option value="auctioned">View Pending Auctions</option>
                       <option value="success">View Succesfull Auctions</option>
                       <option value="failure">View Failed Auctions</option>
                  </FormSelect>

            <InputGroupAddon type="prepend">
                    <InputGroupText>View Type</InputGroupText>
            </InputGroupAddon>
         </InputGroup>
        </Col>
    )
  }
}




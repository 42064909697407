

export default function() {
  return [
    {
      title: "Dashboard",
      to: "/blog-overview",
      htmlBefore: '<i class="material-icons">edit</i>',
      htmlAfter: ""
    },
    {
      title : 'Group Customer',
      to : "/groupcustomer",
      htmlBefore : '<i class="material-icons">person_pin</i>',
      htmlAfter: ""
    },
    {
      title : 'Customer',
      to : "/customer",
      htmlBefore : '<i class="material-icons">supervised_user_circle</i>',
      htmlAfter: ""
    },
    {
      title : 'Customer Profile',
      to : "/custprofile",
      htmlBefore : '<i class="material-icons">account_circle</i>',
      htmlAfter: ""
    },

    {
      title : 'Receipt',
      to : "/receipt",
      htmlBefore : '<i class="material-icons">receipt</i>',
      htmlAfter: ""
    },
    {
      title : 'Pay Installment',
      to : "/payinstallment",
      htmlBefore : '<i class="material-icons">payment</i>',
      htmlAfter: ""
    },

    {
      title : 'Due Management',
      to : "/due",
      htmlBefore : '<i class="material-icons">assignment_late</i>',
      htmlAfter: ""
    },

    {
      title : 'Auction',
      to : "/auction",
      htmlBefore : '<i class="material-icons">multiline_chart</i>',
      htmlAfter: ""
    },
    
    {
      title : 'Installments',
      to : "/installment",
      htmlBefore : '<i class="material-icons">pie_chart</i>',
      htmlAfter: ""
    },
    {
      title : 'Surety',
      to : "/surety",
      htmlBefore : '<i class="material-icons">lock</i>',
      htmlAfter: ""
    },
    {
      title : 'Group',
      to : "/group",
      htmlBefore : '<i class="material-icons">group</i>',
      htmlAfter: ""
    },
    {
      title : 'Journal Voucher',
      to : "/generalvoucher",
      htmlBefore : '<i class="material-icons">chrome_reader_mode</i>',
      htmlAfter: ""
    },
    {
      title : 'Replaced Tickets',
      to : "/replacedtickets",
      htmlBefore : '<i class="material-icons">repeat</i>',
      htmlAfter: ""
    },
    {
      title : 'Daily Summary',
      to : "/dailysummary",
      htmlBefore : '<i class="material-icons">date_range</i>',
      htmlAfter: ""
    },
    {
      title : 'Profit Analysis',
      to : "/profit",
      htmlBefore : '<i class="material-icons">show_chart</i>',
      htmlAfter: ""
    },
    {
      title : 'Bank Deposit',
      to : "/deposit",
      htmlBefore : '<i class="material-icons">account_balance</i>',
      htmlAfter: ""
    },
    {
      title : 'Files',
      to : "/files",
      htmlBefore : '<i class="material-icons">folder</i>',
      htmlAfter: ""
    },
    {
      title : 'SMS',
      to : "/sms",
      htmlBefore : '<i class="material-icons">textsms</i>',
      htmlAfter: ""
    },
    {
      title: "Blog Posts",
      htmlBefore: '<i class="material-icons">vertical_split</i>',
      to: "/blog-posts",
    },

    {
      title : 'Collection Assistants',
      to : "/collectionasst",
      htmlBefore : '<i class="material-icons">transfer_within_a_station</i>',
      htmlAfter: ""
    },
    {
      title : 'Introducers',
      to : "/introducer",
      htmlBefore : '<i class="material-icons">contacts</i>',
      htmlAfter: ""
    },
    
    

    {
      title : 'Login History',
      to : "/loginhistory",
      htmlBefore : '<i class="material-icons">access_time</i>',
      htmlAfter: ""
    },
    
    /*

    {
      title : 'Emails',
      to : "/email",
      htmlBefore : '<i class="material-icons">email</i>',
      htmlAfter: ""
    },
    */

    
   /* {
      title : 'App Posts',
      to : "/app_posts",
      htmlBefore : '<i class="material-icons">vertical_split</i>',
      htmlAfter: ""
    },
    {
      title : 'In-App Messaging',
      to : "/messaging",
      htmlBefore : '<i class="material-icons">send</i>',
      htmlAfter: ""
    },
     
    {
      title : 'Employees',
      to : "/employees",
      htmlBefore : '<i class="material-icons">perm_identity</i>',
      htmlAfter: ""
    },*/
   
    /*{
      title : 'Notifications',
      to : "/notification",
      htmlBefore : '<i class="material-icons">notifications</i>',
      htmlAfter: ""
    },*/
    

    {
      title : 'Settings',
      to : "/settings",
      htmlBefore : '<i class="material-icons">settings</i>',
      htmlAfter: ""
    },
    
    
    /*
    {
      title: "Forms & Components",
      htmlBefore: '<i class="material-icons">view_module</i>',
      to: "/components-overview",
    },
    {
      title: "Tables",
      htmlBefore: '<i class="material-icons">table_chart</i>',
      to: "/tables",
    },
    {
      title: "User Profile",
      htmlBefore: '<i class="material-icons">person</i>',
      to: "/user-profile-lite",
    },
    
    {
      title: "Errors",
      htmlBefore: '<i class="material-icons">error</i>',
      to: "/errors",
    }*/
  ];
}

import { store } from 'react-easy-state';
import firebase from '../firebase';
import formatDate from './formatDate';
import alert from './AlertStore';
const due_data = store({

									isLoading : false,
									hasError  : false,
									gotData   : false,

									data  : [],

									group_id : null,
									threshold : 1,//init
									types : {
										'Equal to 1' : 1,
										'Equal to 2' : 2,
										'Equal to 3' : 3,
										'Equal to 4' : 4,
										'More than 5' : 10000,
										'All Paid' : 0,
									},
									history : {}, 

									setGroupId(group_id){
										console.log(group_id);
										due_data.group_id = group_id;
										due_data.getGroup(group_id);
									},

									setThreshold(val){
										due_data.threshold = due_data.types[val];
										console.log(val , due_data.threshold);
										due_data.filterData();
									},

									getGroup(group_id){	
										
										due_data.isLoading = true;
										due_data.data = [];

										if(due_data.history[group_id]!==undefined){
											console.log("From history");
											due_data.data = due_data.history[group_id];
											due_data.filterData();
											due_data.isLoading = false;
											return;
										}
										
										firebase.firestore().collection('GroupCustomer').where('group_id','==',group_id).onSnapshot(function(snap){

											var temp = [];
											snap.forEach(function(doc){
												temp.push(doc.data());
											})

											due_data.isLoading = false;
											due_data.hasError = false;
											due_data.gotData  = true;
											due_data.data = temp;
											due_data.history[group_id] = temp;
											console.log(temp);
											due_data.filterData();

										},function(err){
											console.log(err);
											due_data.isLoading = false;
											due_data.hasError = true;
											due_data.gotData  = true;
										})

									},

									filterData(){
										var val = due_data.threshold;
										var data = due_data.history[due_data.group_id];
									
										console.log(val,data);
										var not_paid = [];

										if(data===undefined){
											return;
										}
										
										data.forEach(function(doc){
											var temp =[];

											doc.installment_payments_detail.forEach(function(inst){
												if(!inst.paid){
													temp.push(doc);
												}	
											})
											console.log("Length = ",temp.length);
											if(temp.length==val){
												not_paid.push(doc);
											}
											else if(temp.length>=5 && (val>5)){
												not_paid.push(doc);
											}
											
										})

										not_paid.sort(due_data.compare);
										due_data.data = not_paid;


									},

									

									filterCollectionAsst(collection_asst_id){
										console.log(collection_asst_id);
										if(collection_asst_id==="None" || collection_asst_id===null){
											due_data.data = due_data.history[due_data.group_id]===undefined?[]:due_data.history[due_data.group_id];
											return;
										}

										var temp = [];
										var data = due_data.history[due_data.group_id];

										if(data===undefined || data===null){
											return;
										}

										console.log(data);
										data.forEach(function(doc){
											if(doc.caid===collection_asst_id){
												console.log(doc);
												temp.push(doc);
											}
										})

										temp.sort(due_data.compare);
										due_data.data = temp;

									},


									sendDueSMSAlert(cust_data){
										var payload = {
											group_id : cust_data.group_id,
											selected_tickets : [cust_data.ticket_no], //this must be array
											message : "Dear Member, Your Due of Rs.{due} as on "+(formatDate(new Date()))+" for your Group No "+(cust_data.group_id)+" Ticket No "+(cust_data.ticket_no)+" Kindly pay in-time. Thank you, SGRSP Chits, "
										}
										var func_ref = firebase.functions().httpsCallable('SendSMSSelectedGroupTickets');
										alert.showMessage("Kindly Wait...We are sending the SMS to "+cust_data.name,"warning");
										func_ref(payload).then(function(result) {
											// Read result of the Cloud Function.
											var rdata = result.data;
											console.log(rdata);      
											  
											if(rdata.status==="success"){
												  alert.showMessage("SMS sent Succesfully","success");
											}
											else{
												  alert.showMessage(rdata.message,"danger");
											}	
								
											}).catch(function(error) {
													// Getting the Error details.
													//var code = error.code;
													//var message = error.message;
													//var details = error.details;
													// ...
													console.log(error);
													alert.showMessage(error.message,'danger');
											});
									}	,

									compare( a, b ) {
										  if ( a['priced'] > b['priced'] ){
														return -1;
										  }
										  if ( a['priced'] < b['priced'] ){
															return 1;
										  }
										  return 0;
									},







								})

export default  due_data;
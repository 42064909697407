import React from "react";
import {Modal,ModalBody,ModalHeader,Col,ListGroup,Row,ListGroupItem,Button} from 'shards-react';
import formatDate from '../formatDate';
import formatRupee from '../formatRupee';



export default class ViewAuction extends React.Component {
  constructor(props) {
    super(props);
	this.toggle = this.toggle.bind(this);
	this.state = {show:false}
  }
  toggle() {
    this.setState({show:!this.state.show});
  }

  render() {
    const  open  = this.state.show;
    return (
      <div>
        <Modal open={open} toggle={this.toggle}>
         
          <div>
          <ModalHeader>{this.props.data.group_id+" Auction No "+this.props.data.auct_no}</ModalHeader>
          <ModalBody>

              <ListGroup flush className="modalScroll">

                  <Item field={"Group ID"} value={this.props.data.group_id}/>
				  <Item field={"Auction Create Date"} value={formatDate(this.props.data.created_date.toDate())}/>
				  <Item field={"Auction Date"} value={formatDate(this.props.data.auction_date_and_time.toDate())}/>
				  <Item field={"Status "} value={this.props.data.status}/>
				  <Item field={"Bid Amount"} value={formatRupee(this.props.data.bid_amount)}/>
				  <Item field={"Company Dividend"} value={formatRupee(this.props.data.company_dividend)}/>
				  <Item field={"Members Dividend"} value={formatRupee(this.props.data.dividend)}/>
				  <Item field={"Bidder Cust ID"} value={this.props.data.bidder_cust_id}/>
				  <Item field={"Bidder Name"} value={this.props.data.bidder_name}/>
				  <Item field={"Bidder Ticket No."} value={this.props.data.bidder_ticket_no}/>
				  <Item field={"Bidder Phone"} value={this.props.data.phone}/>
				  <Item field={"Bidder Email"} value={this.props.data.email}/>
				  <Item field={"Bidder Ticket No."} value={this.props.data.bidder_ticket_no}/>
				  <Item field={"Amount Paid"} value={formatRupee(this.props.data.amount_paid)}/>
				  <Item field={"Amount Paid Date"} value={formatDate(this.props.data.amount_paid_date.toDate())}/>
				  <Item field={"Payment Mode"} value={this.props.data.payment_mode}/>
				  <Item field={"Payment Reference"} value={this.props.data.payment_ref}/>
                  
              </ListGroup>
              <Col lg="3">
                  <Button onClick={this.toggle}>Close</Button>
              </Col>

          </ModalBody>
          </div>
        </Modal>
		    <Button theme="primary" onClick={this.toggle}>View</Button>
      </div>
    );
  }
}


class Item extends React.Component{
  render(){
    return(
      <ListGroupItem className="px-4">
        <div className="progress-wrapper">
          <Row>
          <Col md="6">
          <strong className=" d-block mb-2" style={{marginLeft: '5%',color:'grey'}}>
            {this.props.field}
          </strong>
          </Col>
          <Col md="6">
          <strong className="d-block mb-2" style={{marginLeft: '2%',color:'blue'}}>
            {this.props.value}
          </strong>
          </Col>
        </Row>

    
        </div>
      </ListGroupItem>
    )
  }
}

import React from "react";
import PropTypes from "prop-types";
import { Container, Row, Col } from "shards-react";

import PageTitle from "../common/PageTitle";
import WebsiteStats from "./WebsiteStats";
import TodayDailySummary from "./TodayDailySummary";
import GroupStats from './GroupStats';
import AlertBox from '../AlertBox';



const Dashboard = () => (
<div>
  <AlertBox />
  <Container fluid className="main-content-container px-4">
    {/* Page Header */}
    <Row noGutters className="page-header py-4">
      <PageTitle title="Overview" subtitle="Dashboard" className="text-sm-left mb-3" />
    </Row>

    {/* Small Stats Blocks */}
    <GroupStats />

    <Row>
      {/* Users Overview */}
      <Col lg="8" md="12" sm="12" className="mb-4">
        <WebsiteStats />
      </Col>

      {/* Users by Device */}
      <Col lg="4" md="6" sm="12" className="mb-4">
        <TodayDailySummary />
      </Col>

         </Row>
  </Container>
</div>
);

export default Dashboard;

import { store } from 'react-easy-state';
import firebase from '../firebase';
import alert from './AlertStore';


const collection_assistant_list = store({ 
			     list : [],
			     isLoading:false,
			     hasData : false,
                             hasError : false,

                             ca_data : {},
                             viewCaData : {
                                aadhar : null,
                                address :null,
                                bank_accnt_no : null,
                                ca_id : null,
                                email : null,
                                father_husband_name : null,
                                ifsc_code : null,
                                name : null,
                                phone : null,
                                status : null,

                        },


                 getList(){
                        

                        if(collection_assistant_list.hasData){
                            return;
                        }

                        collection_assistant_list.isLoading = true;

                        firebase.firestore().collection('CAList').doc('list').onSnapshot(function(doc) {
        
                            if(doc.exists){
                                    console.log("Collection Assistant List data: ", doc.data());
    
                                    collection_assistant_list.list = doc.data().list;
                                    collection_assistant_list.isLoading = false;
                                    collection_assistant_list.hasData = true;
                                    console.log(collection_assistant_list);
                            }
                            else{
                                    collection_assistant_list.hasData = false;
                                    collection_assistant_list.isLoading = false;
                            }
                        },function(err){
                                collection_assistant_list.hasError = true;
                                collection_assistant_list.hasData = false;
                                 console.log(err);
                        });
                 },


                 getCollectionAssistant(id){
                         collection_assistant_list.isLoading = true;
                         if(collection_assistant_list.ca_data[id]!==undefined){
                                collection_assistant_list.viewCaData = collection_assistant_list.ca_data[id];
                                collection_assistant_list.isLoading = false;
                         }

                         firebase.firestore().collection('CollectionAssistant').doc(id).get().then(function(doc){
                                 if(!doc.exists){
                                        collection_assistant_list.isLoading = false;
                                        collection_assistant_list.viewCaData = {};
                                        return;
                                 }
                                 var data = doc.data();
                                 collection_assistant_list.ca_data[id] = data;
                                 collection_assistant_list.viewCaData  = data;
                                 collection_assistant_list.isLoading   = true;
                                 return;
                         })


                 }
	})







export default collection_assistant_list;

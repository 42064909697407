import React from "react";
import { Col, Card, CardHeader, CardBody } from "shards-react";
import '../scroll.css';
import { view } from 'react-easy-state';
import { AtomSpinner } from 'react-epic-spinners';
import auction_data from '../auctionStore';
import Message from '../Message';
import {Link} from 'react-router-dom';


function getArray(proxyObj){
    var arr = [];
    var obj = proxyObj;
      for (var key in obj) {
        if (obj.hasOwnProperty(key)) {
                arr.push(obj[key]);
        }
    }
    return arr;
}


export default view(()=>{
	var data = {
		isLoading : auction_data.isUpcomingLoading,
		data : getArray(auction_data.Upcomingdata),
		gotData : auction_data.gotUpcomingData,
		hasError : auction_data.UpcominghasError,
	};

	return <UpcomingAuction data={data} />
})


class UpcomingAuction extends React.Component{

	componentDidMount(){
		auction_data.getUpcomingAuction();
	}

	render(){

		console.log(this.props.data.data);

		return(
		<Col>
        <Card small className="mb-4">
          <CardHeader className="border-bottom">
              <h6 className="m-0">Upcoming Auctions</h6>
          </CardHeader>

       
          
          <CardBody className="p-0 pb-3 scroll_list-group" >
          {
          	(!this.props.data.isLoading  && (this.props.data.data.length>0) )&&
            <table className="table mb-0">
              <thead className="bg-light">
                <tr>
                  <th scope="col" className="border-0">
                    #
                  </th>
                  <th scope="col" className="border-0">
                    Group No.
                  </th>
                  <th scope="col" className="border-0">
                    Auct No.
                  </th>
                  <th scope="col" className="border-0">
                     Create Date
                  </th>
                  <th scope="col" className="border-0">
                      Auction Date and Time
                  </th>
                  <th scope="col" className="border-0">
                      Change timings
                  </th>
                  <th scope="col" className="border-0">
                      Approve
                  </th>


                </tr>
              </thead>
              <tbody>
                	
                	{
                          this.props.data.data.map((item,i)=>{
                                return (<Trow key={i} data={item} index={i+1}/>)
                          })
                  
                	}
               
              </tbody>
            </table>
        }

        {
            (this.props.data.isLoading) &&
               <AtomSpinner color="blue" style={{alignSelf: 'center',marginLeft: '40%',marginTop: '10%',marginBottom: '10%'}}/>
        }
        
        {
              (!this.props.data.isLoading && this.props.data.hasError && this.props.data.gotData ) &&
              <Message message={"Error Loading Data"} icon_name={"warning"} />
        }
        {
              ( !this.props.data.isLoading && (this.props.data.data.length===0) ) &&
              <Message message={"No Upcoming Auctions"} icon_name={"airline_seat_flat"} />
       	}



          </CardBody>
        </Card>
      </Col>



		)
	}
}


class Trow extends React.Component{
	render(){


    const changelink = "/changeauctiontimings?auct_id="+this.props.data.auct_id+"&prev_timings="+this.props.data.auction_date_and_time.toDate().toUTCString()+"&group_id="+this.props.data.group_id;
    const approvelink = "/approveauction?auct_id="+this.props.data.auct_id+"&prev_timings="+this.props.data.auction_date_and_time.toDate().toUTCString()+"&group_id="+this.props.data.group_id+"&auct_no="+this.props.data.auct_no;


		return(

			<tr>
                  <td>{this.props.index}</td>
                  <td>{this.props.data.group_id}</td>
                  <td>{this.props.data.auct_no}</td>
                  <td>{this.props.data.created_date.toDate().toLocaleDateString()}</td>
                  <td>{new Date(this.props.data.auction_date_and_time.toDate()).toString().substring(0,25)}</td>
                  <td style={{fontSize: 25,color:'red'}}><Link to={changelink} style={{color:'red'}}><i class="material-icons">history</i></Link></td>
                  <td style={{fontSize: 25,color:'blue'}}><Link to={approvelink} style={{color:'blue'}}><i class="material-icons">done_outline</i></Link></td>
            </tr>
		)
	}
}
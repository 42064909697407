import { store } from 'react-easy-state';
import group_data from './groupStore';
import auction_data from './auctionStore';
import firebase from '../firebase';


const dashboard_store = store({

			isLoading 	: false,


			group_data 	: [],
			website_data : [],
			gotWesbsiteData : false,
			
			todays_daily_summary : [],
			gotTodaysDailySummary : false,
			
			highDueCustomerList  : [],
			gotCustList : false,

			upcomingAuctionList  : [],


			getDashboardData(){
				dashboard_store.getGroupData();
				dashboard_store.getDaysWebsiteVisitorCount(7);
				dashboard_store.getTodaysDailySummary();
				dashboard_store.getHighDueCustomerList();
				dashboard_store.getUpcomingAuction();
			},

			getGroupData(){
				if(group_data.hasData){
					dashboard_store.group_data = group_data.data;
				}

				dashboard_store.isLoading = false;

				firebase.firestore().collection('Group').onSnapshot(function (snap){
     					 // Respond to data
     					 var temp = [];
     					 snap.forEach(function(doc){
     					 	temp.push(doc.data());
     					 })

     					 dashboard_store.group_data = temp;
     					 dashboard_store.isLoading  = false;
     					 //set group data
     					 group_data.setGroupData(temp);
    			},function(err){
    				console.error(err);
    				dashboard_store.isLoading = false;
    			});

			},

			getDaysPreviousDate(days){
				var days; // Days you want to subtract
				var date = new Date();
				var last = new Date(date.getTime() - (days * 24 * 60 * 60 * 1000));
				
				return last;
			},

			getDaysWebsiteVisitorCount(days){

				if(dashboard_store.gotWesbsiteData){
					return;
				}

				dashboard_store.isLoading = true;

				firebase.firestore().collection('WebsiteDailyCount')
						.where('date','>=',dashboard_store.getDaysPreviousDate(days))
						.where('date','<=',new Date())
						.orderBy('date','asc')
						.onSnapshot(function (snap){
							var temp = [];


							snap.forEach(function(doc){
								var data  =doc.data();
								temp.push({data:data.count,date:data.date.toDate()});
							});

							console.log(temp);

							dashboard_store.website_data = temp;
							dashboard_store.isLoading    = false;
							dashboard_store.gotWesbsiteData = true;

						},function(err){
							console.error(err);
							dashboard_store.isLoading = false;
							dashboard_store.gotWesbsiteData = true;
						})


			},

			getTodaysDailySummary(){

					dashboard_store.isLoading = true; 
					if(dashboard_store.gotTodaysDailySummary){
						return;
					}
					console.log("Todays date"+dashboard_store.getTodayDateFormatted());
					firebase.firestore().collection('DailySummary')
										.doc(dashboard_store.getTodayDateFormatted())
										.onSnapshot(function (doc){

											if(!doc.exists){
												return;
											}

											var temp = [];
											temp.push(doc.data());
											
											dashboard_store.isLoading = false;
											dashboard_store.todays_daily_summary = temp; 
											dashboard_store.gotTodaysDailySummary = true;
					},function(err){
						console.error(err);
						dashboard_store.isLoading = false;
						dashboard_store.gotTodaysDailySummary = true;
					})

			},

			getTodayDateFormatted(){
				var date = new Date();
				var year = date.getFullYear();
				var month = date.getMonth()+1;
				var day  = date.getDate();
				console.log(year+'-'+month+'-'+day);

				return year+"-"+month+"-"+day;
			},

			getHighDueCustomerList(){

					if(dashboard_store.gotCustList){
						return;
					}

					dashboard_store.isLoading = true;

					firebase.firestore().collection('GroupCustomer')
										.orderBy('account_balanace','desc')
										.limit(5)
										.onSnapShot(function(snap){
											var temp = [];
											snap.forEach(function(doc){
												temp.push(doc.data());
											})
											dashboard_store.isLoading = false;
											dashboard_store.highDueCustomerList = temp;
											dashboard_store.gotCustList = true;
										
										},function(err){
											console.error(err);
											dashboard_store.isLoading = false;
										})

			},

			getUpcomingAuction(){
				if(auction_data.gotUpcomingData){
					dashboard_store.upcomingAuctionList = auction_data.Upcomingdata;
				}

				dashboard_store.isLoading = true;

				firebase.firestore().collection('Auction').where('status','==','pending').onSnapshot(function(snap) {

						var temp = [];

						snap.forEach(function(doc){
							temp.push(doc.data());
						})

						dashboard_store.isLoading = false;
						dashboard_store.upcomingAuctionList = temp;

				},function(err){
					console.error(err);
					auction_data.isLoading = false;
				})

			}
})



export default dashboard_store;


import React from "react";
import {Row, Col, Card, CardHeader, CardBody} from "shards-react";


import PageTitle from "../common/PageTitle";
import '../scroll.css';

import deposit_data from '../depositStore';
import { AtomSpinner } from 'react-epic-spinners';
import { view } from 'react-easy-state';
import Message from '../Message';
import getArray from '../getArray';
import formatDate from '../formatDate';

import login_data from '../../loginstore';
import { Redirect,Link } from "react-router-dom";

export default view(()=>{
    var data = {
      data : getArray(deposit_data.viewData),
      isLoading : deposit_data.isLoading,
      gotData : deposit_data.gotData,
      hasError : deposit_data.hasError,
    }

    return login_data.isLoggedIn?<DepositTable data={data} />:<Redirect to='/login' />;
})



class DepositTable extends React.Component{
  render(){
    return(
    
      <Row>
      <Col>
        <Card small className="mb-4">
          <CardHeader className="border-bottom">
              <h6 className="m-0">Bank Deposit</h6>
          </CardHeader>

       
          
          <CardBody className="p-0 pb-3 scroll_list-group" >
           { (!this.props.data.isLoading  && this.props.data.data.length!==0) && 
            <table className="table mb-0">
              <thead className="bg-light">
                <tr>
                  <th scope="col" className="border-0">
                      #
                  </th>
                  <th scope="col" className="border-0">
                     Amount
                  </th>
                  <th scope="col" className="border-0">
                      Description
                  </th>
                  <th scope="col" className="border-0">
                      Deposit Date
                  </th>
                  <th scope="col" className="border-0">
                      Document Link
                  </th>
                  <th scope="col" className="border-0">
                      Type
                  </th>
                  <th scope="col" className="border-0">
                      Edit
                  </th>
                  <th scope="col" className="border-0">
                      Delete
                  </th>

                </tr>
              </thead>
              <tbody>
                {
                          this.props.data.data.map((item,i)=>{
                                return (<Trow key={i} data={item} index={i+1}/>)
                          })
                  }
                               
              </tbody>
            </table>
          }

            {
                (this.props.data.isLoading) &&
                <AtomSpinner color="blue" style={{alignSelf: 'center',marginLeft: '40%',marginTop: '10%',marginBottom: '10%'}}/>
            }
            {
                (!this.props.data.isLoading && this.props.data.hasError && this.props.data.gotData  ) &&
                <Message message={"Error Loading Data"} icon_name={"warning"} />
            }
            {
                ( !this.props.data.isLoading && (this.props.data.data.length===0) && this.props.data.gotData ) &&
                <Message message={"No Data Avaiable"} icon_name={"sentiment_dissatisfied"} />
            }
            {
              (!this.props.data.isLoading && !this.props.data.gotData ) && 
              <Message message={"Kindly Select Start and End Date"} icon_name={"call_missed"} />
            }
          </CardBody>
        </Card>
      </Col>
    </Row>
    )
  }
}


class Trow extends React.Component{
  render(){
    var date = this.props.data.deposit_date;
    var modify_link = '/modifydeposit?amount='+this.props.data.amount+"&description="+this.props.data.description+"&deposit_date="+date+"&doc_link="+this.props.data.doc_link+"&type="+this.props.data.type+"&id="+this.props.data.id;
    var delete_link ='/removedeposit?id='+this.props.data.id;
    return(
               <tr>
                  <td>{this.props.index}</td>
                  <td>{this.props.data.amount}</td>
                  <td>{this.props.data.description}</td>
                  <td>{this.props.data.deposit_date}</td>
                  <td>{<a target="_blank" href={this.props.data.doc_link}>Link</a>}</td>
                  <td >{this.props.data.type}</td> 
                  <td style={{color:'blue',fontWeight: 'bold' }}><Link to={modify_link} ><i class="material-icons">edit</i></Link></td>
                  <td style={{color:'red',fontWeight: 'bold' }}><Link to={delete_link} style={{color:'red'}}><i class="material-icons">delete</i></Link></td>
                  
                </tr>
      )
  }
}
import React from "react";
import { Container, Row, Col, Card, CardHeader, CardBody,Button,InputGroup,InputGroupAddon,FormSelect,FormInput} from "shards-react";
import PageTitle from "../common/PageTitle";
import '../scroll.css';
import { view } from 'react-easy-state';
import { AtomSpinner } from 'react-epic-spinners';
import RangeDatePicker from "./RangeDatePicker";
import customer_data from '../AllCustomerStore';
import Message from '../Message';
import getArray from '../getArray';
import login_data from '../../loginstore';
import { Redirect,Link } from "react-router-dom";
import AlertBox from '../AlertBox';

function setSearchType(val){
  customer_data.setSearchType(val);
}

function searchData(key){
  customer_data.searchData(key);
}


export default view(()=>{
    var data = {
      isLoading : customer_data.isLoading,
      data      : getArray(customer_data.viewData),
      hasError  : customer_data.hasError,
      gotData : customer_data.gotData,
      notValidDate : customer_data.notValidDate,

    }
    var isLoggedIn = login_data.isLoggedIn;
    console.log(data);
    return isLoggedIn?<AllCustomer data={data} />:<Redirect to="/login" />;
})

class AllCustomer extends React.Component{

    render(){
      return(
        <div>
          <AlertBox />
            <Container fluid className="main-content-container px-4">
            
            {/* Page Header */}
            <Row noGutters className="page-header py-4">
                    <PageTitle sm="4" title="Customer" subtitle="All" className="text-sm-left" />
            </Row>

            <Row>
                <Col sm="4">
                      <RangeDatePicker />
                </Col>
                <SearchBar />
            </Row>

              {/* Default Light Table */}
              <Table data={this.props.data}/>

            </Container>
        </div>
        )
    }
}

class SearchBar extends React.Component{

  render(){
    return(
      <Col sm="12" md="6" lg="4">
        <InputGroup className="mb-3">
                    <FormInput placeholder="Search Customer" onChange={(evt)=>{searchData(evt.target.value)}}/>
                  <InputGroupAddon type="prepend">

                    <FormSelect onChange = {(evt)=>{setSearchType(evt.target.value)}}>
                          <option value="name">Name</option>
                          <option value="cust_id">Customer ID</option>
                          <option value="phone">Phone</option>
                          <option value="email">Email</option>
                          <option value="collection_asst_id">Collection Asst ID</option>
                          <option value="introducer_id">Introducer ID</option>
                      </FormSelect>
                  </InputGroupAddon>
              </InputGroup>
      </Col>
    )
  }
}


class Table extends React.Component{
  render(){
    return(
    
      <Row>
      <Col>
        <Card small className="mb-4">
          <CardHeader className="border-bottom">
              <h6 className="m-0">Customers</h6>
          </CardHeader>

       
          
          <CardBody className="p-0 pb-3 scroll_list-group" >
          {
            (!this.props.data.isLoading && this.props.data.data.length>0) &&
            <table className="table mb-0">
              <thead className="bg-light">
                <tr>
                  <th scope="col" className="border-0">
                    #
                  </th>
                  <th scope="col" className="border-0">
                    CustomerID
                  </th>
                  <th scope="col" className="border-0">
                      Name
                  </th>
                  <th scope="col" className="border-0">
                      Phone
                  </th>
                  <th scope="col" className="border-0">
                      Introducer
                  </th>
                  <th scope="col" className="border-0">
                      Collection Assistant
                  </th>
                  <th scope="col" className="border-0">
                    View
                  </th>
                  <th>
                    Add Ticket
                  </th>
                  <th scope="col" className="border-0">
                    Edit
                  </th>
                 

                </tr>
              </thead>
              <tbody>
                  
                     {
                          this.props.data.data.map((item,i)=>{
                                return (<Trow key={i} data={item} index={i+1}/>)
                          })
                        }
                      
                  
                
              </tbody>
            </table>
          }
          {
            (this.props.data.isLoading) &&
             <AtomSpinner color="blue" style={{alignSelf: 'center',marginLeft: '40%',marginTop: '10%',marginBottom: '10%'}}/>
          }
          {
            (!this.props.data.isLoading && (this.props.data.data.length===0) && (!this.props.data.gotData) && (!this.props.data.notValidDate) ) &&
            <Message message={"Kindly Select Start Date and End Date"} icon_name={"call_missed"} />
          }
          {
            (!this.props.data.isLoading && this.props.data.hasError && this.props.data.gotData && (!this.props.data.notValidDate) ) &&
            <Message message={"Error Loading Data"} icon_name={"warning"} />
          }
          {
            ( !this.props.data.isLoading && (this.props.data.data.length===0) && this.props.data.gotData && (!this.props.data.notValidDate) ) &&
            <Message message={"No Data Avaiable"} icon_name={"sentiment_dissatisfied"} />
          }
          {
            ( !this.props.data.isLoading && (this.props.data.notValidDate) ) &&
            <Message message={"Please Enter Valid Date"} icon_name={"sentiment_very_satisfied"} />
          }
          </CardBody>
        </Card>
      </Col>
    </Row>
    )
  }
}


function opt(str){
  console.log(str);
  if(str===null || str===undefined){
      return str;
  }
  var temp = str.match(/.{1,7}/g); //split every 6th char
  var res = "";
  
  if(temp===null){
    return str;
  }

  temp.forEach(function(s){
      res = res+"\n"+s;
  })
  res = res.substring(1,res.length);
  console.log(res);
  return res;
}


class Trow extends React.Component{

  

  
  render(){
    return(
                <tr>
                  <td>{this.props.index}</td>
                  <td>{this.props.data.cust_id}</td>
                  <td>{this.props.data.name}</td>
                  <td>{this.props.data.phone}</td>
                  <td>{this.props.data.introducer_id}</td>
                  <td>{this.props.data.collection_asst_id}</td>
                   <td>
                      <Link  to={"/custprofile?id="+this.props.data.cust_id} >
                        <Button theme="primary">View</Button>
                      </Link>
                  </td>
                  <td style={{color:'blue'}}>
                                <Link  to={"/addcustomergroup?name="+this.props.data.name+"&customer_id="+this.props.data.cust_id} >
                                      <Button type="submit" size="sm" theme="primary" className="mb-2 mr-1">
                                            Add
                                      </Button>
                                </Link>
                  </td>
                  <td style={{color:'green'}}>
                                  <Link to={"/modifycustomer?name="+this.props.data.cust_id} >
                                      <Button type="submit"  outline size="sm" theme="info" className="mb-2 mr-1">
                                            <i class="material-icons">edit</i>
                                      </Button>
                                  </Link>
                  </td> 
                  
                 
                </tr>
      )
  }
}
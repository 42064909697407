import React from "react";
import {  Modal, ModalBody, ModalHeader,Col,Button,FormSelect,DatePicker} from "shards-react";
import Input from '../common/Input';

import pay_store from './ViewIntStore';

export default class PayIntroducer extends React.Component{
       constructor(props) {
            super(props);
            this.state = { open: false };
            this.toggle = this.toggle.bind(this);
        }

    
      toggle() {
        this.setState({
          open: !this.state.open
        });
      }
    
      render() {
        const { open } = this.state;
        return (
          <div>

            <Modal open={open} toggle={this.toggle}>
              <ModalHeader>
                    <b>Add Payment</b>     
            </ModalHeader>
              <ModalBody>   
                <Input size="8" name="Amount" type="number" onChange={(evt)=>pay_store.setAmount(evt.target.value)}/>
                <Input size="8" name="Comment" type="text" onChange={(evt)=>pay_store.setComment(evt.target.value)}/>
                <SelectPaymentMethod size="8" onChange={(evt)=>pay_store.setPaymentMethod(evt.target.value)}/>
                <Col lg="4" md="4" sm="12">
                <label>Date</label>
                <DateSelect onChange={(value)=>{pay_store.setDate(value)}}/>
                </Col>
                <Col size="8" className={"pt-3"}><Button theme="primary" onClick={()=>{this.toggle();pay_store.addPayment()}}>Add Payment</Button></Col>
              </ModalBody>
            </Modal>
            <Button  pill outline size="sm" className="mb-2" onClick={()=>{this.toggle()}}> <i className="material-icons mr-1">add_box</i>Add Payment</Button>
          </div>
        );
      }
}


class DateSelect extends React.Component{
    constructor(props){
        super(props);
        this.state = {startDate : undefined};
        this.handleDateChange = this.handleDateChange.bind(this);
    }
    handleDateChange(value) {
        this.setState({
          ...this.state,
          ...{ startDate: new Date(value) }
        });
    
        this.props.onChange(new Date(value));
    }
    render(){
    return(
            <DatePicker
                size="sm"
                md={this.props.size}
                lg={this.props.size}
                selected={this.state.startDate}
                onChange={this.handleDateChange}
                placeholderText="Date"
                dropdownMode="select"
                className="text-left"
            />
    )
    }
}


function SelectPaymentMethod(props){
    return(
        <Col md={props.size} className="form-group">
            <label >Payment Method</label>
            <FormSelect onChange ={props.onChange}>
                <option value="cash">Cash</option>
                <option value="NEFT">NEFT</option>
                <option value="cheque">Cheque</option>
            </FormSelect>
    </Col>
    )
}


import {store} from 'react-easy-state';
import firebase from '../../firebase';
import isEmptyString from '../isEmptyString';
import alert from '../AlertStore';


const surety_data = store({
    isLoading : false,

    group_id : undefined,
    auction_no : undefined,
    name : undefined,
    dob : undefined,
    phone : undefined,
    company_name :undefined,
    designation : undefined,
    salary : 0,
    address  : undefined,
    remarks : undefined,

    surety_no : 'client',
    office_address : "",

    file1 : null,
    file2 : null,
    file3 : null,
    file4 : null,
    file5 : null,

    selected_group_id : null,
    selected_auction_no : null,
    data : [],
    view_data : [],

    setValue(field_name,value){
        switch(field_name){
            case 'group_id'     : surety_data.group_id = value;break;
            case 'auction_no'   : surety_data.auction_no = parseInt(value);break;
            case 'name'         : surety_data.name = value;break;
            case 'dob'          : surety_data.dob = new Date(value).toLocaleDateString();break;
            case 'phone'        : surety_data.phone = value;  break;
            case 'company_name' : surety_data.company_name = value;break;
            case 'designation'  : surety_data.designation = value;break;
            case 'salary'       : surety_data.salary  = parseInt(value);break;
            case 'address'      : surety_data.address = value;break;
            case 'remarks'      : surety_data.remarks = value;break;
            case 'file1'        : surety_data.file1 = value;break;
            case 'file2'        : surety_data.file2 = value;break;
            case 'file3'        : surety_data.file3 = value;break;
            case 'file4'        : surety_data.file4 = value;break;
            case 'file5'        : surety_data.file5 = value;break;
            case 'surety_no'    : surety_data.surety_no = value;break;
            case 'office_address' : surety_data.office_address = value;break;
            default             : alert.showMessage("Unkown Error Occurred","danger");break
         }
    },

    delete(id){
        if(id===undefined || id===null){
            alert.showMessage("Invalid ID. Error Deleting Surety ","danger");
            return;
        }
        var answer = window.confirm("Are you Sure?");
        if(!answer){
            return;
        }
        surety_data.isLoading = true;
        return firebase.firestore().collection('Surety').doc(id.toString()).delete().then(function(){
            surety_data.isLoading = false;
            alert.showMessage("Delete Successfull","success");
        })
        .catch(function(err){
            surety_data.isLoading = false;
            console.error(err);
            alert.showMessage(err.message,"danger");
        })
    },

    addSurity(){
        if(surety_data.group_id===null || surety_data.group_id===undefined){
            alert.showMessage("Please Select Group ID ","warning");
            return;
        }
        if(surety_data.auction_no===null || surety_data.auction_no===null){
            alert.showMessage("Please Enter  Auction No ","warning");
            return;
        }
        var data = {
            group_id : surety_data.group_id,
            auct_no : surety_data.auction_no,
            name    : surety_data.name,
            dob     : (new Date(surety_data.dob)).toUTCString(),
            phone   : surety_data.phone,
            company_name : surety_data.company_name,
            designation : surety_data.designation,
            salary    : parseInt(surety_data.salary),
            address   : surety_data.address,
            office_address : surety_data.office_address,
            surety_no : surety_data.surety_no,
            remarks   : surety_data.remarks,
            file1     : surety_data.file1,
            file2     : surety_data.file2,
            file3     : surety_data.file3,
            file4     : surety_data.file4,
            file5     : surety_data.file5
        }

        console.log(data);

        surety_data.isLoading = true;
        var func_ref =  firebase.functions().httpsCallable('addSurety');
        console.log("Waiting for Result");
        return func_ref(data).then(function(result){
            // Read result of the Cloud Function.
            var data = result.data;
            console.log(data);
            if(data.status==="success"){
                  alert.showMessage("Surety Added Successfully","success");
             }
            else{
                  console.error(data.message);
                  alert.showMessage(data.message,"danger");
            }
            surety_data.isLoading = false;
        })
        .catch(function(err){
            surety_data.isLoading = false;
            alert.showMessage(err.message,"danger");
        })
    },

    filter(data_arr){
        var auction_no = surety_data.selected_auction_no;
        console.log("Filter Auct, no =  ",auction_no);
        if(isEmptyString(auction_no)){
            console.log("Filter : Auct no is empty");
            surety_data.view_data = data_arr;
            return;
        }
        var temp = [];
        auction_no = parseInt(auction_no);
        data_arr.forEach(function(data){
            if(data.auct_no===auction_no)
                temp.push(data);
        })
        console.log("Filter: Result ",temp);
       surety_data.view_data = temp;
    },

    getSuretyData(){
        var group_id = surety_data.group_id;

        if(isEmptyString(group_id)){
            alert.showMessage("Please Select a Valid Group ID","danger");
            return;
        }

        surety_data.isLoading = true;
        return firebase.firestore().collection('Surety').where('group_id','==',group_id).onSnapshot(function(snap){
            var temp = [];
            snap.forEach(function(doc){
                temp.push(doc.data());
            })
            console.log(temp);
            surety_data.data = temp;
            surety_data.filter(temp);
            surety_data.isLoading = false;
        },function(err){
            console.error(err);
            surety_data.isLoading = false;
            surety_data.data = [];
            surety_data.view_data = [];
        })
    },

    setViewGroupID(group_id){
        surety_data.group_id = group_id;
        surety_data.getSuretyData();
    },

    setAuctionNo(auct_no){
        surety_data.selected_auction_no = auct_no;
        if(auct_no===undefined){
            surety_data.view_data = surety_data.data;
        }
        if(surety_data.data.length>0){
            surety_data.filter(surety_data.data);
        }
    }

})

export default surety_data;
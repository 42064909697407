import { store } from 'react-easy-state';
import firebase from '../firebase';
import alert from './AlertStore';

const GroupCustomer = store({
							viewData : [],
							currentData : [],
							isLoading : false,
							hasError : false,
							gotData : false,
							group_names : [],
							group_names_data :{},
							searchType : 'name',
							sortType : 'asc',
							sortKey :'ticket_no',	

							checkExists(group){
								var arr = GroupCustomer.group_names;
								for(var i=0;i<arr.length;i++){
									if(group === arr[i]){
										return true;
									}
								}
								return false;
							},

							async getCustomerData(group){
								GroupCustomer.isLoading = true;
								var hasError = false;
								if(GroupCustomer.checkExists(group)){
									console.log("Avaiable Locally");
									GroupCustomer.currentData = GroupCustomer.group_names_data[group];
									GroupCustomer.viewData = GroupCustomer.group_names_data[group];
									GroupCustomer.isLoading = false;
									return GroupCustomer.viewData;
								}
								console.log("Fetching from server");
								await firebase.firestore().collection('GroupCustomer').where('group_id','==',group).orderBy('ticket_no','asc').onSnapshot(function(snap) {
									var data = [];
									snap.forEach(function(doc){
										data.push(doc.data());
									})
									console.log("Setting Variables");
									GroupCustomer.group_names.push(group);
									GroupCustomer.group_names_data[group] = data;
									GroupCustomer.viewData = data;
									GroupCustomer.currentData = data;
									GroupCustomer.isLoading = false;
									GroupCustomer.gotData = true;
									console.log("Complete");

								},function(err){
									alert.showMessage(err.message,'danger')
									GroupCustomer.isLoading = false;
									GroupCustomer.hasError = true;
									GroupCustomer.gotData = true;
									hasError = true;
									console.log(err);
								})
							},
							is_empty_string(x)
							{
   									return ( 
        									(typeof x == 'undefined')
                    									||
        									(x == null) 
                    									||
        									(x == false)  //same as: !x
                    									||
        									(x.length == 0)
                    									||
        									(x == "")
                    									||
        									(x.replace(/\s/g,"") == "")
                    									||
        									(!/[^\s]/.test(x))
                    									||
        									(/^\s*$/.test(x))
  										);
							},

							setSearchkey(val){
								console.log(val);
								GroupCustomer.searchType =val;
							},
							
							compare( a, b ) {
									var searchkey = GroupCustomer.searchType;
  									if ( a[searchkey] < b[searchkey] ){
    										return -1;
  									}
  									if ( a[searchkey] > b[searchkey] ){
    										return 1;
  									}
  									return 0;
							},


							search(name){
								console.log(name);
								GroupCustomer.isLoading = true;
								if(GroupCustomer.is_empty_string(name) || GroupCustomer.currentData.length===0){
									GroupCustomer.viewData = GroupCustomer.currentData;
									GroupCustomer.isLoading = false;
									return;
								}
								var temp = []
								GroupCustomer.currentData.forEach(function(cust_data){
									if(cust_data[GroupCustomer.searchType].toString().includes(name)){
										temp.push(cust_data);
									}
								})
								temp.sort(GroupCustomer.compare);
								GroupCustomer.viewData = temp;
								GroupCustomer.isLoading = false;

							},

							sort_compare( a, b ) {
									var sortKey = GroupCustomer.sortKey;
									var sortType = GroupCustomer.sortType;
  									if ( a[sortKey] < b[sortKey] ){
  										if(sortType === "asc"){
    										return -1;
    									}
    									else{
    										return 1;
    									}	
  									}
  									if ( a[sortKey] > b[sortKey] ){
  										if(sortType === "asc"){
    										return 1;
  										}
  										else{
  											return -1;
  										}
  									}
  									return 0;
							},


							setSortType(type){
								GroupCustomer.sortType = type;
								GroupCustomer.sort_data();
							},
							setSortKey(key){
								GroupCustomer.sortKey = key;
								GroupCustomer.sort_data();
							},

							sort_data(){
								var temp = GroupCustomer.currentData;
								temp.sort(GroupCustomer.sort_compare);
								GroupCustomer.viewData = temp;
							}

						})

export default GroupCustomer;
import React, { Component } from 'react';


import firebase from '../firebase';
import FileUploader from "react-firebase-file-uploader";
import alert from './AlertStore';

export default class FireFileUpload extends Component{

	constructor(props) {
	  super(props);
	
	  this.state = {isUploading:false,progress:0};
	}


	/*FIREBASE STORAGE */
  handleUploadStart = () => this.setState({ isUploading: true, progress: 0 });
  handleProgress = progress => this.setState({ progress });
  
  handleUploadError = error => {
    	this.setState({ isUploading: false });
       alert.showMessage(error.message,'danger');
    	//this.props.update_photo_update_error()
      console.log("From upload error");
    	console.error(error);
  };


  handleUploadSuccess = filename => {
  	var that = this;
    this.setState({ filename: filename, progress: 100, isUploading: false });
      firebase
      .storage()
      .ref(this.props.path)
      .child(filename)
      .getDownloadURL() 
      .then(url => {
      		//..handle url
      		that.props.update_url(url,this.state.filename);
      });
  };
	render(){


		return(

  				<div className="custom-file mb-3" {...this.props}>	

  						<div >
          				<FileUploader 
            				accept="image/*"
            				name="avatar"
            				randomizeFilename
            				storageRef={firebase.storage().ref(this.props.path)}
            				onUploadStart={this.handleUploadStart}
            				onUploadError={this.handleUploadError}
            				onUploadSuccess={this.handleUploadSuccess}
            				onProgress={this.handleProgress}
            				className="custom-file-input"
          				/>
          				<label className="custom-file-label" htmlFor="customFile2">
      						    {this.state.isUploading?"Loading "+this.state.progress:"Choose file..."}
    				   </label>
              </div>
             
    	


          				
				</div>
			)
	}
}





import React from 'react';
import { Container, Row, Col,Card , CardHeader,CardBody, FormCheckbox,FormGroup,FormInput,Button,
		Form,InputGroupAddon,InputGroup,InputGroupText,FormSelect,DatePicker } from "shards-react";
import PageTitle from "../common/PageTitle";
import FireFileUpload from '../FireFileUpload';
import firebase from '../../firebase';
import {toast} from 'react-toastify';
import { AtomSpinner } from 'react-epic-spinners';
import AlertBox from '../AlertBox';


export default class RemoveGroup extends React.Component{

  constructor(props) {
    super(props);
  
    this.state = {
        group_id: null,
        isLoading:false,
    };

    this.RemoveGroup = this.RemoveGroup.bind(this);
    this.onSubmitHandler = this.onSubmitHandler.bind(this);
    this.askDelete = this.askDelete.bind(this);
  }


askDelete = () =>{
  if(this.state.group_id!==null){
      if (window.confirm("Delete the Group "+this.state.group_id+"?")) {
          this.RemoveGroup();
      }
  }
}

componentDidMount(){
  var url_string = window.location.href;
  var url = new URL(url_string);
  var c = url.searchParams.get("group_id");
  this.setState({group_id:c},this.askDelete);
   
}

  RemoveGroup = () =>{
    var that = this;
    if(this.state.group_id===null){
      alert.showMessage("Kindly Enter Group Id");
    }
    var data = {
        group_id : this.state.group_id,
    }

    this.setState({isLoading:true});
    var func_ref = firebase.functions().httpsCallable('RemoveGroup');
    func_ref(data).then(function(result) {
                  // Read result of the Cloud Function.
                  var data = result.data;
                  console.log(data);
                  if(data.status==="success"){
                        console.log("Document succesfully removed!");
                        alert.showMessage("Group Removed Successfully");
                        console.log(that.state);
                   }
                  else{
                        toast(data.error.message, {containerId: 'A'});
                        console.log(data.error.message);
                        alert.showMessage(data.error.message);
                  }
                  that.setState({isLoading:false});

        }).catch(function(error) {
                  // Getting the Error details.
                  //var code = error.code;
                  //var message = error.message;
                  //var details = error.details;
                  // ...
                  console.log(error);
                  that.setState({isLoading:false});
                  alert.showMessage("Unable to Remove Group");
        });

  }

  onSubmitHandler = (evt) =>{
      evt.preventDefault();
      this.RemoveGroup();
  }

  render(){

  	

    return(
    <div>
      <AlertBox />
    <Container fluid className="main-content-container px-4 pb-4">
        {/* Page Header */}
        <Row noGutters className="page-header py-4">
              <PageTitle sm="4" title="Group" subtitle="Remove" className="text-sm-left" />
        </Row>

        

     	<Col lg="12">
        <Card small className="mb-4">
          <CardHeader className="border-bottom">
              <h6 className="m-0">Remove Group</h6>
              
          </CardHeader>
          <CardBody className="p-2 pb-2" >
        { !this.state.isLoading &&		
         <Col>
          <Form onSubmit={this.onSubmitHandler}>
            <Row form>
              <Col md="4" className="form-group">
                <label htmlFor="feEmailAddress">GroupID</label>
                <FormInput
                  id="feEmailAddress"
                  type="text"
                  placeholder="GroupID"
                  onChange = {(evt)=>{this.setState({group_id:evt.target.value})}}
                />
              </Col>
              <Col md="4">

                  <Button type="submit" style={{alignSelf: 'center',marginTop: '9%'}}>Remove Group</Button>
              </Col>
           </Row>
          </Form>
        </Col>
      }
      {
        this.state.isLoading &&
        <Col>
          <AtomSpinner color="blue" style={{alignSelf: 'center',marginLeft: '40%',marginTop: '10%',marginBottom: '10%'}}/>
        </Col>
      }
 
          </CardBody>
          </Card>
          </Col>
    </Container>
  </div>
    )
  }
}



import React from "react";
import { Container, Row, Col,Button } from "shards-react";

import PageTitle from "../common/PageTitle";
import '../scroll.css';
import ProfitTable from './ProfitTable';
import RangeDatePicker from "./RangeDatePicker";
import AlertBox from '../AlertBox';
import {Link} from 'react-router-dom';



export default class ProfitAnalysis extends React.Component{
    render(){
      return(
      <div>
        <AlertBox />
            <Container fluid className="main-content-container px-4">
            
            {/* Page Header */}
            <Row noGutters className="page-header py-4">
                  <PageTitle sm="4" title="Analysis" subtitle="Profit" className="text-sm-left" />
            </Row>


            <Link to="/addprofit">
                  <Button  theme="primary">Add New Profit</Button>
            </Link>

          <Row>
            <Col sm="4" style={{marginTop: '2%',marginBottom:'2%'}}>
                <RangeDatePicker className="mb-3"/>
            </Col>
          </Row>


          {/* Default Light Table */}
          <ProfitTable />

      </Container>
    </div>
        )
    }
}





import { store } from 'react-easy-state';
import firebase from '../firebase';
import alert from './AlertStore';

const installment_data = store({
	isLoading : false,
	hasError : false,
	gotData : false,
	data : [],
	history : {},

	getInstData(group_id){
		
		if(installment_data.history[group_id]!==undefined){
			console.log("Local Fetch");
			installment_data.data = installment_data.history[group_id];
			return;
		}

		installment_data.isLoading = true;
		
		firebase.firestore().collection('Installment').where('group_id','==',group_id).orderBy('inst_no','desc').onSnapshot(function(snap){
				installment_data.data = [];
				snap.forEach(function(doc){
					installment_data.data.push(doc.data());
				})

				installment_data.history[group_id] = installment_data.data;
				installment_data.isLoading = false;
				installment_data.hasError = false;
				installment_data.gotData = true;

		},function(err){
			alert.showMessage(err.message,"danger");
			console.log(err);
			installment_data.isLoading = false;
			installment_data.hasError = true;
			installment_data.gotData = true;
		})
	},
})

export default installment_data;
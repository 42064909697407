import React from "react";
import { Row, Col, Card, CardHeader, CardBody,Button} from "shards-react";

import '../scroll.css';

import receipt_data from '../ReceiptStore';

import { AtomSpinner } from 'react-epic-spinners';
import { view } from 'react-easy-state';
import Message from '../Message';
import getArray from '../getArray';
import formatDate from '../formatDate';
import formatRupee from '../formatRupee';
import login_data from '../../loginstore';
import { Redirect } from "react-router-dom";
import pay_inst from "../payInstallmentStore";



export default view(()=>{
    var data = {
        data : getArray(receipt_data.viewData),
        isLoading : receipt_data.isLoading,
        gotData : receipt_data.gotData,
        hasError : receipt_data.hasError,
        no_of_data : receipt_data.no_of_data,
    };
    return login_data.isLoggedIn?<ReceiptTable data={data}/>:<Redirect to='/login' />;
})





class ReceiptTable extends React.Component{
  render(){

    console.log("Table ",this.props.data.data);

    return(
    
      <Row>
      <Col>
        <Card small className="mb-4">
          <CardHeader className="border-bottom">
          
                <h6 className="m-0">Receipt</h6>
              
          </CardHeader>
          
          <CardBody className="p-0 pb-3 scroll_list-group" >

          { (!this.props.data.isLoading  && this.props.data.no_of_data>0) && 
            <table className="table mb-0">
              <thead className="bg-light">
                <tr>
                  <th scope="col" className="border-0">
                    Receipt No.
                  </th>
                  <th scope="col" className="border-0">
                    Receipt Date.
                  </th>
                  <th scope="col" className="border-0">
                      MR No.
                  </th>
                  <th scope="col" className="border-0">
                      Ticket No.
                  </th>
                  <th scope="col" className="border-0">
                      Name
                  </th>
                  <th scope="col" className="border-0">
                    Grand Total
                  </th>
                  <th scope="col" className="border-0">
                     Other Charges
                  </th>
                  <th scope="col" className="border-0">
                      Paid
                  </th>
                  <th scope="col" className="border-0">
                      Payment Method
                  </th>

                  <th scope="col" className="border-0">
                      Print
                  </th>
                  <th scope="col" className="border-0">
                    View
                  </th>

                </tr>
              </thead>
              <tbody>
                  {
                          this.props.data.data.map((item,i)=>{
                                return (<Trow key={i} data={item} index={i+1}/>)
                          })
                   }
                      
              </tbody>
            </table>
          }

             {
                (this.props.data.isLoading) &&
                <AtomSpinner color="blue" style={{alignSelf: 'center',marginLeft: '40%',marginTop: '10%',marginBottom: '10%'}}/>
            }
            {
                (!this.props.data.isLoading && this.props.data.hasError && this.props.data.gotData  ) &&
                <Message message={"Error Loading Data"} icon_name={"warning"} />
            }
            {
                ( !this.props.data.isLoading && (this.props.data.no_of_data===0) && this.props.data.gotData ) &&
                <Message message={"No Data Avaiable"} icon_name={"sentiment_dissatisfied"} />
            }
            {
              (!this.props.data.isLoading && !this.props.data.gotData ) && 
              <Message message={"Kindly Select Start and End Date"} icon_name={"call_missed"} />
            }
          </CardBody>
        </Card>
      </Col>
    </Row>
    )
  }
}


class Trow extends React.Component{
  render(){

    //var mr_date = (this.props.data.mr_date===null?"NA":this.props.data.mr_date.toDate().toLocaleDateString());
    
    var {
        receipt_id,
        paid_date,
        mr_id,
        cust_id,
        name,
        grand_total,
        other_charges,
        customer_paid,
        excess_paid,
        payment_method,
        valid,
        group_id,
        ticket_no,
        email,
        comments,
        receivedBy,
        phone,
        interest,
        inst_ids,
      } = this.props.data;

    var inst_nos = [];
    var values   = [];

    inst_ids.forEach(function(inst){
        inst_nos.push(inst.id.split('_')[2]);
        values.push(inst.value);
    })
    var link = '/Receipt.html?rid='+receipt_id+
                              "&mrid="+mr_id+
                              "&date="+(new Date().toUTCString()).replace('GMT','')+
                              "&name="+name+
                              "&group_id="+(group_id+"/"+ticket_no)+
                              "&cust_id="+(cust_id)+
                              "&payment_method="+payment_method+
                              "&comments="+comments+
                              "&penalty="+formatRupee(interest)+
                              "&extra_charges="+formatRupee(other_charges)+
                              "&excess_paid="+formatRupee(excess_paid)+
                              "&total="+formatRupee(customer_paid)+
                              "&received_by="+receivedBy+
                              "&inst_no="+inst_nos.toString()+
                              "&amount="+values.toString()+
                              "&paid_date="+(new Date(paid_date.toDate()).toUTCString().replace('GMT',''));



    return(

              <tr style={{color:(valid?"black":'red')}} >
                  <td>{receipt_id}</td>
                  <td>{formatDate(paid_date.toDate())}</td>
                  <td>{mr_id===null?"NA":mr_id}</td>
                  <td>{(group_id+"/"+ticket_no)}</td>
                  <td>{name}</td>
                  <td>{formatRupee(grand_total)}</td>
                  <td>{formatRupee(other_charges)}</td>
                  <td>{formatRupee(customer_paid)}</td>
                  <td>{payment_method}</td>
                  <td>{valid?<a href={link} target="_blank"><Button theme="danger">Print</Button></a>:"Not Avaialable"}</td>
                  <td><Button theme="primary" onClick={()=>{pay_inst.viewReceipt(this.props.data)}}>View</Button></td>
              </tr>

    )
  }
}

class DownloadButton extends React.Component{
  render(){
    return(
      <Col sm="12" md="6" lg="4" {...this.props.style}>
        <Button theme="accent" size="md" >Download</Button>
      </Col>
    )
  }
}

import { store } from 'react-easy-state';
import firebase from '../firebase';
import alert from './AlertStore';





const group_names = store({ 
			     names : [],
			     isLoading:false,
			     hasData : false,
			     
                             getGroupNames(){

                                if(group_names.hasData){
                                        console.log("Has Group data")
                                        return;
                                }
                                console.log("Loading Group Data");

                                group_names.isLoading = true;

                                firebase.firestore().collection('GroupList').doc('list').onSnapshot(function(doc) {
        
                                        if(doc.exists){
                                                console.log("Group List data: ", doc.data());
                                                group_names.names = doc.data().list;
                                                group_names.isLoading = false;
                                                group_names.hasData = true;
                                                console.log(group_names);
                                        }
                                        else{
                                                group_names.hasData = false;
                                                group_names.isLoading = false;
                                                console.log(group_names);
                                        }
                                },function(err){
                                        alert.showMessage(err.message,"danger")
                                        group_names.hasData = false;
                                        console.log(err);
                                });

                             },



		      })





export default group_names;

import React from "react";
import { Row, Col, Card, CardHeader, CardBody,Button,InputGroupText,InputGroup,FormInput,InputGroupAddon,FormSelect} from "shards-react";

import '../scroll.css';


import { AtomSpinner } from 'react-epic-spinners';
import { view } from 'react-easy-state';
import Message from '../Message';
import getArray from '../getArray';
import formatDate from '../formatDate';
import formatRupee from '../formatRupee';
import login_data from '../../loginstore';
import RangeDatePicker from "./DatePicker";
import { Redirect } from "react-router-dom";
import voucher_data from './VoucherStore';



export default view(()=>{
    var data = {
            data : getArray(voucher_data.viewData),
            isLoading : voucher_data.isLoading,
            gotData : voucher_data.gotData,
            hasError : voucher_data.hasError,
            no_of_data : voucher_data.no_of_data,
    };


    console.log(data);

    return login_data.isLoggedIn?<VoucherTable data={data}/>:<Redirect to='/login' />;
})





class VoucherTable extends React.Component{
  render(){

    console.log("Table ",this.props.data.data);

    return(
    <div>
        <Row>
            <Col sm="4">
                <RangeDatePicker />
            </Col>
            
            <SearchBar />
            <Sort />
        </Row>
      <Row>
      <Col>
        <Card small className="mb-4">
          <CardHeader className="border-bottom">
          
                <h6 className="m-0">Voucher</h6>
              
          </CardHeader>
          
          <CardBody className="p-0 pb-3 scroll_list-group" >

          { (!this.props.data.isLoading  && this.props.data.no_of_data>0) && 
            <table className="table mb-0">
              <thead className="bg-light">
                <tr>
                  <th scope="col" className="border-0">
                    Voucher No.
                  </th>
                  <th scope="col" className="border-0">
                    Date
                  </th>
                  <th scope="col" className="border-0">
                      Ticket ID
                  </th>
                  <th scope="col" className="border-0">
                    Name
                  </th>
                  <th scope="col" className="border-0">
                      Amount
                  </th>
                  <th scope="col" className="border-0">
                    Type
                  </th>
                  <th scope="col" className="border-0">
                        Comments
                  </th>

                </tr>
              </thead>
              <tbody>
                        {
                          this.props.data.data.map((item,i)=>{
                                return (<Trow key={i} data={item} index={i+1}/>)
                          })
                        }
                      
              </tbody>
            </table>
          }

        
             {
                (this.props.data.isLoading) &&
                <AtomSpinner color="blue" style={{alignSelf: 'center',marginLeft: '40%',marginTop: '10%',marginBottom: '10%'}}/>
            }
            {
                (!this.props.data.isLoading && this.props.data.hasError && this.props.data.gotData  ) &&
                <Message message={"Error Loading Data"} icon_name={"warning"} />
            }
            {
                ( !this.props.data.isLoading && (this.props.data.no_of_data===0) && (!this.props.data.hasError) && this.props.data.gotData ) &&
                <Message message={"No Data Avaiable"} icon_name={"sentiment_dissatisfied"} />
            }
            {
              (!this.props.data.isLoading && !this.props.data.gotData ) && 
              <Message message={"Kindly Select Start and End Date"} icon_name={"call_missed"} />
            }
        
          </CardBody>
        </Card>
      </Col>
    </Row>
    </div>
    )
  }
}


class Trow extends React.Component{
  render(){

    return(
        
              <tr >
                
                  <td>{this.props.data.id}</td>
                  <td>{formatDate(this.props.data.date.toDate())}</td>
                  <td>{(this.props.data.group_id+"/"+this.props.data.ticket_no)}</td>
                  <td>{this.props.data.name}</td>
                  <td>{formatRupee(this.props.data.amount)}</td>
                  <td style={{color:this.props.data.isDebit?"green":"red"}}>{this.props.data.isDebit?"Debit":"Credit"}</td>
                  <td>{this.props.data.comments}</td>
                
            </tr>

    )
  }
}

class DownloadButton extends React.Component{
  render(){
    return(
      <Col sm="12" md="6" lg="4" {...this.props.style}>
        <Button theme="accent" size="md" >Download</Button>
      </Col>
    )
  }
}


class SearchBar extends React.Component{

    render(){
      return(
        <Col sm="12" md="6" lg="4">
          <InputGroup className="mb-3">
                      <FormInput placeholder="Search Voucher" onChange={(evt)=>{voucher_data.searchData(evt.target.value)}}/>
                    <InputGroupAddon type="prepend">
  
                      <FormSelect onChange = {(evt)=>{voucher_data.setSearchField(evt.target.value)}}>
                            <option value="id">Voucher ID</option>
                            <option value="group_id">Group ID</option>
                            <option value="ticket_no">Ticket No.</option>
                        </FormSelect>
                    </InputGroupAddon>
                </InputGroup>
        </Col>
      )
    }
  }
  

  class Sort extends React.Component{
    render(){
      return (
          <Col sm="12" md="6" lg="4">
              <InputGroup className="mb-3">
                  <InputGroupAddon type="prepend">
                      <InputGroupText>Sort</InputGroupText>
                   </InputGroupAddon>
                    <FormSelect onChange = {(evt)=>{voucher_data.setSortField(evt.target.value)}}>
                          <option value="date">{"Date"}</option>
                          <option value="group_id">{"Group ID"}</option>
                          <option value="ticket_no">{"Ticket No"}</option>
                          <option value="name">{"Name"}</option>
                          <option value="custID">{"Customer ID"}</option>
                          <option value="isDebit">{"Type"}</option>
                    </FormSelect>
                    <FormSelect onChange = {(evt)=>{voucher_data.setSortType(evt.target.value)}}>
                          <option value="desc">{"Descending"}</option>
                          <option value="asc">{"Ascending"}</option>
                    </FormSelect>
       
              
          </InputGroup>
        </Col>
      )
    }
  }
  
  
  


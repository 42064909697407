import React from "react";
import {Card,CardHeader ,CardBody,Button } from "shards-react";
import Message from '../Message';
import {  view } from 'react-easy-state';
import getArray from '../getArray';
import formatDate from '../formatDate';
import { AtomSpinner } from 'react-epic-spinners';
import '../scroll.css';
import {Link} from 'react-router-dom';
import int_data from './ViewIntStore';


export default view(()=>{
   var data = {
     isLoading :  int_data.isCustLoading,
     hasError  :  int_data.isCustHasError,
     gotData   :  int_data.isCustHasData,
     cust_data :  getArray(int_data.introduced_cust_data)
   }

   return <IntCustomer data={data} />
})

 class IntCustomer extends React.Component{
    render(){
      return(
         <Card small className="mb-4 pt-3">
            <CardHeader className="border-bottom">
                <h6 className="m-0">Introducer Customers</h6>
            </CardHeader>
  
            <CardBody className="p-0 pb-3 scroll_list-int-table" >
             
            {// (!this.props.data.isLoading && this.props.data.no_of_data>0) &&
              <table className="table mb-0">
                <thead className="bg-light">
                  <tr>
                    <th scope="col" className="border-0">
                      #
                    </th>
                    <th scope="col" className="border-0">
                      Customer ID
                    </th>
                    <th scope="col" className="border-0">
                      Customer Name
                    </th>
                    <th scope="col" className="border-0">
                      Join Date
                    </th>
                    <th scope="col" className="border-0">
                      View
                    </th>
                  </tr>
                </thead>
                <tbody>
                        { 
                            this.props.data.cust_data.map((item,i)=>{
                                  return (<Trow key={i} data={item} index={i+1}/>)
                            })
                         }
                 
                 </tbody>
               </table>
             }
                      {
                          this.props.data.isLoading &&
                          <AtomSpinner color="blue" style={{alignSelf: 'center',marginLeft: '40%',marginTop: '5%',marginBottom: '5%'}}/>
                      }
                        {
                          (!this.props.data.isLoading && this.props.data.hasError) &&
                          <Message message={"Error Loading Data"} icon_name={"warning"} /> 
                        }
                        {
                          ( (!this.props.data.isLoading) && (!this.props.data.hasError) &&  (this.props.data.cust_data.length===0) ) &&
                            <Message message={"No Customers Introduced..."} icon_name={"sentiment_dissatisfied"} />
                        }
              </CardBody>
            </Card>
  
      )
    }
  }
  
  
  class Trow extends React.Component{
    render(){
  
      return(
             <tr>
                <td>{this.props.index}</td>
                <td>{this.props.data.cust_id}</td>
                <td>{this.props.data.name}</td>
                <td>{formatDate(this.props.data.join_date.toDate())}</td>
                <td><Link to={"/custprofile?id="+this.props.data.cust_id}><Button theme="primary">View</Button></Link></td>
                
            </tr>
  
      )
    }
  }
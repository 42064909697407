
import isEmptyString from '../isEmptyString';
import alert from '../AlertStore';
import firebase from '../../firebase';
import {store} from 'react-easy-state';

const lean_data = store({
    isLoading : false,

    group_id : undefined,
    ticket_no :undefined,
    type : 'mark',

    setType(type){
        lean_data.type = type;
    },

    setGroupId(group_id){
        lean_data.group_id = group_id;
    },

    setTicketNo(ticket_no){
        lean_data.ticket_no = ticket_no;
    },

    markLean(){
        var group_id = lean_data.group_id;
        var ticket   = lean_data.ticket_no;

        if(isEmptyString(group_id) || isEmptyString(ticket)){
            alert.showMessage("Please Enter All Details ",'warning');
            return;
        }
        lean_data.isLoading = true;
        firebase.firestore().collection('GroupCustomer').doc(group_id+":"+ticket).update({isLean:lean_data.type==='mark'?true:false}).then(function(){
                if(lean_data.type==='mark')
                    alert.showMessage("Lean Marked Succesfully","success");
                else
                    alert.showMessage("Lean UnMarked Succesfully","success");
                lean_data.isLoading = false;
        })
        .catch(function(err){
            alert.showMessage(err.message,"danger");
            lean_data.isLoading = false;
        })

    }

})


export default lean_data;
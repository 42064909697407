import React from "react";
import {Modal,ModalBody,ModalHeader,Col,ListGroup,Row,ListGroupItem,Button} from 'shards-react';

import formatDate from '../formatDate';
import formatRupee from '../formatRupee';


export default class ViewGroup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {show:false};
    this.toggle = this.toggle.bind(this);
  }

  

  toggle() {
    this.setState({show:!this.state.show});
  }

  render() {
    const  open  = this.state.show;
    return (
      <div>
        <Modal open={open} toggle={this.toggle}>
          
          <ModalHeader>{"Group "+this.props.data[0].group_id}</ModalHeader>
          <ModalBody>

              <ListGroup flush className="modalScroll">
                  
                  
                  <Item field={"Chit Value"} value={formatRupee(this.props.data[0].chitValue)}/>
                  <Item field={"Installment Value"} value={formatRupee(this.props.data[0].installment_value)}/>
                  <Item field={"Start Date"} value={formatDate(this.props.data[0].start_date.toDate())}/>
                  <Item field={"End Date"} value={formatDate(this.props.data[0].end_date.toDate())}/>
                  <Item field={"CC Date"} value={formatDate(this.props.data[0].cc_date.toDate())}/>
                  <Item field={"CC No"} value={this.props.data[0].cc_no}/>
                  <Item field={"Completed Auctions"} value={this.props.data[0].completed_auctions}/>
                  <Item field={"Remaining Auctions"} value={this.props.data[0].remaining_auctions}/>
                  <Item field={"Customer Chits"} value={this.props.data[0].customer_chits}/>
                  <Item field={"Vacant Chits"} value={this.props.data[0].vacant_chits}/>
                  <Item field={"Deposit Amount"} value={formatRupee(this.props.data[0].deposit_amount)}/>
                  <Item field={"Deposit Bank"} value={this.props.data[0].deposit_bank}/>
                  <Item field={"Deposit Cert No"} value={this.props.data[0].deposit_cert_no}/>
                  <Item field={"Deposit Rate of Interest"} value={this.props.data[0].deposit_rate_of_interest}/>
                  <Item field={"Deposit Date"} value={formatDate(this.props.data[0].deposit_date.toDate())}/>
                  <Item field={"PSO Date"} value={formatDate(this.props.data[0].pso_date.toDate())}/>
                  <Item field={"PSO Date"} value={this.props.data[0].pso_no}/>
                  <Item field={"No. of Prized"} value={this.props.data[0].no_of_priced}/>
                  <Item field={"No. of Non Prized"} value={this.props.data[0].no_of_non_priced}/>
                  <Item field={"No. of Months"} value={this.props.data[0].no_of_months}/>

                  
              </ListGroup>

          </ModalBody>
        </Modal>
        <Button onClick={this.toggle}>View</Button>
      </div>
    );
  }
}


class Item extends React.Component{
  render(){
    return(
      <ListGroupItem className="px-4">
        <div className="progress-wrapper">
          <Row>
          <Col md="6">
          <strong className=" d-block mb-2" style={{marginLeft: '5%',color:'grey'}}>
            {this.props.field}
          </strong>
          </Col>
          <Col md="6">
          <strong className="d-block mb-2" style={{marginLeft: '2%',color:'blue'}}>
            {this.props.value}
          </strong>
          </Col>
        </Row>

    
        </div>
      </ListGroupItem>
    )
  }
}

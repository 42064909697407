import React from 'react';
import { Container, Row, Col,Card , CardHeader,CardBody,FormGroup,FormInput,Button,Form,FormSelect,DatePicker } from "shards-react";
import PageTitle from "../common/PageTitle";
import FireFileUpload from '../FireFileUpload';
import firebase from '../../firebase';
import { AtomSpinner } from 'react-epic-spinners';
import SelectCollectionAsst from '../SelectCollectionAsst';
import SelectIntroducer from '../SelectIntroducer';
import alert from '../AlertStore';
import AlertBox from '../AlertBox';

export default class ModifyCustomer extends React.Component{

  constructor(props) {
    super(props);
  
    this.state = {
        cust_id: null,
        isLoading:false,
        gotData : false,
    };

    this.handleDOBDateChange = this.handleDOBDateChange.bind(this);
    this.isNull = this.isNull.bind(this);
    this.onSubmitHandler = this.onSubmitHandler.bind(this);
    this.getData = this.getData.bind(this);
    this.getCustomer = this.getCustomer.bind(this);
    this.modifyCustomer = this.modifyCustomer.bind(this);
    this.onUpdateSubmit = this.onUpdateSubmit.bind(this);
  }

  handleDOBDateChange(value) {
    this.setState({ dob: new Date(value)});
  }

  isNull(obj){ 
  
  for (var key in obj) {
      if (obj.hasOwnProperty(key)) {
        var val = obj[key];
        console.log(val)
        if(val===undefined || val === null){
          return true;
        }
      }
  }
  return false;
}

getData = () =>{
  console.log("Cust ID ="+this.state.cust_id);
  if(this.state.cust_id!==null){
          this.getCustomer(this.state.cust_id);  
  }
}

componentDidMount(){
  var url_string = window.location.href;
  var url = new URL(url_string);
  var c = url.searchParams.get("name");
  console.log(c)
  this.setState({cust_id:c},this.getData);
}

getCustomer = (cust_id) =>{

    if(cust_id===null || cust_id===undefined){
      alert.showMessage("CustID Not Valid","danger");
    } 
    var that = this;
    this.setState({isLoading:true});
    
    firebase.firestore().collection('Customer').doc(cust_id).get().then(function(doc){
      if(!doc.exists){
        alert.showMessage("Customer Data doesnt Exist","danger");
      }
      var data = doc.data();
      data['dob'] = data.dob.toDate();
      console.log(data);
      that.setState(data);
      that.setState({isLoading:false,gotData:true});

    }).catch(function(err){
        alert.showMessage("Error Fetching Customer Data","danger");
        console.log(err);
        that.setState({isLoading:false,gotData:false});
    })

}
  
modifyCustomer = () =>{
  console.log(data);
  var data = this.state;
  delete data.isLoading;
  delete data.gotData; 
  var that = this;
  this.setState({isLoading:true});

  firebase.firestore().collection('Customer').doc(data.cust_id).update(data).then(function(){
      that.setState({isLoading:false});
      alert.showMessage("Succesfully updated","success");
      console.log("Update successfull");
      that.getCustomer(data.cust_id);
  }).catch(function(err){
      that.setState({isLoading:false});
      alert.showMessage("Error updating customer","danger");
      console.log(err);
  })

} 

onUpdateSubmit = (evt) =>{
     evt.preventDefault();
    this.modifyCustomer();
}
  
  onSubmitHandler = (evt) =>{
      evt.preventDefault();
      this.getCustomer(this.state.cust_id);
  }

  render(){

  	

    return(
    <div>
      <AlertBox />
    <Container fluid className="main-content-container px-4 pb-4">
        {/* Page Header */}
        <Row noGutters className="page-header py-4">
              <PageTitle sm="4" title="Customer" subtitle="Modify" className="text-sm-left" />
        </Row>

        

     	<Col lg="12">
        <Card small className="mb-4">
          <CardHeader className="border-bottom">
              <h6 className="m-0">Modify Customer</h6>
              
          </CardHeader>
          <CardBody className="p-2 pb-2" >
        		
         <Col>
          <Form onSubmit={this.onSubmitHandler}>
            <Row form>
              <Col md="4" className="form-group">
                <label htmlFor="feEmailAddress">CustomerID</label>
                <FormInput
                  id="feEmailAddress"
                  type="text"
                  placeholder="CustomerID"
                  value={this.state.cust_id}
                  onChange = {(evt)=>{this.setState({cust_id:evt.target.value})}}
                />
              </Col>
              <Col md="4">

                  <Button type="submit" style={{alignSelf: 'center',marginTop: '9%'}}>Get Customer</Button>
              </Col>
           </Row>
          </Form>
        </Col>
      

 
          </CardBody>
          </Card>
          </Col>

    {

        (!this.state.isLoading && this.state.gotData) &&

          <Col lg="12">
              <Card small className="mb-4">
                  <CardBody className="p-2 pb-2" >
                              

        <Col>
          <Form onSubmit={this.onUpdateSubmit}>
            <Row form>
              <Col md="4" className="form-group">
                <label htmlFor="feEmailAddress">Name</label>
                <FormInput
                  id="feEmailAddress"
                  type="text"
                  placeholder="Name"
                  defaultValue = {this.state.name}
                  onChange = {(evt)=>{this.setState({name:evt.target.value})}}
                />
              </Col>
              <Col md="4">
                <label htmlFor="fePassword">Father/Husband Name</label>
                <FormInput
                  id="fePassword"
                  type="text"
                  placeholder="Father/Husband Name"
                  defaultValue = {this.state.father_husband_name}
                  onChange = {(evt)=>{this.setState({father_husband_name:evt.target.value})}}
                />
              </Col>
              <Col md="4">
                <FormGroup>
                      <label htmlFor="feInputAddress">DOB</label>
                      <br />
                      <DatePicker placeholderText="DOB"
                                  selected={new Date(this.state.dob)}
                                  onChange={this.handleDOBDateChange}
                                  dropdownMode="select"
                                  className="text-center"
                                  />
                </FormGroup>
              </Col>
           </Row>

           <Row form>
              <Col md="4">
                <label htmlFor="fePassword">Phone</label>
                <FormInput
                  id="fePassword"
                  type="text"
                  placeholder="Phone (comma seperated)"
                  onChange = {(evt)=>{this.setState({phone:evt.target.value})}}
                  defaultValue = {this.state.phone}
                />
              </Col>
              <Col md="4">
                <label htmlFor="fePassword">Email</label>
                <FormInput
                  id="fePassword"
                  type="text"
                  placeholder="Email"
                  onChange = {(evt)=>{this.setState({email:evt.target.value})}}
                  defaultValue = {this.state.email}
                />
              </Col>

              <Col md="4">
                <label htmlFor="fePassword">Nominee Name</label>
                <FormInput
                  id="fePassword"
                  type="text"
                  placeholder="Nominee Name"
                  onChange = {(evt)=>{this.setState({nominee_name:evt.target.value})}}
                  defaultValue = {this.state.nominee_name}
                />
              </Col>
            </Row>

            <Row form style={{marginTop: '2%'}}>


              <Col md="4">
                <label htmlFor="fePassword">Nominee Relationship</label>
                <FormInput
                  id="fePassword"
                  type="text"
                  placeholder="Nominee Relationship"
                  onChange = {(evt)=>{this.setState({nominee_relationship:evt.target.value})}}
                  defaultValue = {this.state.nominee_relationship}
                />
              </Col>

              <Col md="4">
                <label htmlFor="fePassword">Bank Account No</label>
                <FormInput
                  id="fePassword"
                  type="text"
                  placeholder="Bank Account No."
                  onChange = {(evt)=>{this.setState({bank_accnt_no:evt.target.value})}}
                  defaultValue = {this.state.bank_accnt_no}
                />
              </Col>
              <Col md="4">
                <label htmlFor="fePassword">IFSC Code</label>
                <FormInput
                  id="fePassword"
                  type="text"
                  placeholder="IFSC Code"
                  onChange = {(evt)=>{this.setState({ifsc_code:evt.target.value})}}
                  defaultValue = {this.state.ifsc_code}
                />
              </Col>
              

            </Row>

            <Row form style={{marginTop: '2%'}}>
              <Col md="12">
                <label htmlFor="address">Address</label>
                <FormInput 
                    type="text"
                    placeholder="Address"
                    onChange = {(evt)=>{this.setState({address:evt.target.value})}}
                    defaultValue = {this.state.address}
                  />
              </Col>
            </Row>

            <Row form style={{marginTop: '2%'}}>
              <Col md="4">
                    <label htmlFor="fePassword">Aadhar Number</label>
                    <FormInput
                      id="fePassword"
                      type="text"
                      placeholder="Aadhar Number"
                      onChange = {(evt)=>{this.setState({aadhar:evt.target.value})}}
                      defaultValue = {this.state.aadhar}
                    />
              </Col>


              <Col md="4">
                <label htmlFor="fePassword">GST</label>
                <FormInput
                  id="fePassword"
                  type="text"
                  placeholder="GST"
                  onChange = {(evt)=>{this.setState({gst:evt.target.value})}}
                  defaultValue = {this.state.gst}
                />
              </Col>

              <Col md="4">
                <label htmlFor="fePassword">Income(PA)</label>
                <FormInput
                  id="fePassword"
                  type="text"
                  placeholder="Income(PA)"
                  onChange = {(evt)=>{this.setState({income_pa:evt.target.value})}}
                  defaultValue = {this.state.income_pa}
                />
              </Col>
            </Row>

            <Row form style={{marginTop: '2%'}}>
              <Col md="4">
                    <label htmlFor="fePassword">PAN</label>
                    <FormInput
                        id="fePassword"
                        type="text"
                        placeholder="PAN"
                        onChange = {(evt)=>{this.setState({pan:evt.target.value})}}
                        defaultValue = {this.state.pan}
                    />
              </Col>

              <Col md="4">
                    <label htmlFor="fePassword">Introducer</label>
                    <SelectIntroducer  
                        onChange = {(evt)=>{this.setState({introducer_id:evt.target.value})}}
                        defaultValue = {this.state.introducer_id}
                    />
              </Col>

              <Col md="4">
                    <label htmlFor="fePassword">Collection Asst.</label>
                    <SelectCollectionAsst 
                        onChange = {(evt)=>{this.setState({collection_asst_id:evt.target.value})}}
                        defaultValue = {this.state.collection_asst_id}
                    />
              </Col>


            </Row>

            <Row form style={{marginTop: '2%'}}>
                <Col md="4">
                    <label htmlFor="fePassword">Signature</label>
                    { this.state.signature_url===null &&
                        <FireFileUpload path="Customer/Signature" 
                          update_url={(url,name)=>{this.setState({signature_url:url,signature_filename:name})}} />
                    }
                    {
                      this.state.signature_url!==null &&
                      <div>
                          <br />
                          <Button theme="danger" 
                                onClick={()=>{this.setState({signature_url:null})}}>Remove</Button>

                          <h6 className="m-0" style={{color:'black',fontSize:14}}>{this.state.signature_filename}</h6>
                         
                      </div>
                    }
               </Col>

               <Col md="4">
                    <label htmlFor="fePassword">Aadhar</label>
                    { this.state.aadhar_url===null &&
                    <FireFileUpload path="Customer/Aadhar" 
                                    update_url={(url,name)=>{this.setState({aadhar_url:url,aadhar_filename:name})}} />
                    }
                    {
                      this.state.aadhar_url!==null &&
                      <div>
                          <br />
                           <Button theme="danger" onClick={()=>{this.setState({aadhar_url:null})}}>Remove</Button>
                           <h6 className="m-0">{this.state.aadhar_filename}</h6>
                      </div>
                    }
               </Col>

               <Col md="4">
                    <label htmlFor="fePassword">Bank</label>
                    { this.state.bank_url===null &&
                    <FireFileUpload path="Customer/Bank" 
                                    update_url={(url,name)=>{this.setState({bank_url:url,bank_filename:name})}} />
                    }
                    {
                      this.state.bank_url!==null &&
                      <div>
                          <br />
                          <Button theme="danger" onClick={()=>{this.setState({bank_url:null})}} >Remove</Button>
                          <h6 className="m-0" style={{color:'black',fontSize:14}}>{this.state.bank_filename}</h6>
                      </div>
                    }
               </Col>
            </Row>

            

            <Row form style={{marginTop: '2%'}}>
                <Col md="4">
                    <label htmlFor="fePassword">Photo</label>
                    { this.state.photo_url===null &&
                    <FireFileUpload path="Customer/Photo"
                                   update_url={(url,name)=>{this.setState({photo_url:url,photo_filename:name})}} />
                    }
                    {
                      this.state.photo_url!==null &&
                      <div>
                          <br />
                          <Button theme="danger" onClick={()=>{this.setState({photo_url:null})}} >Remove</Button>
                          <h6 className="m-0" style={{color:'black',fontSize:14}}>{this.state.photo_filename}</h6>
                      </div>
                    }
                 </Col>

               <Col md="4">
                    <label htmlFor="fePassword">PAN</label>
                    { this.state.pan_url===null &&
                        <FireFileUpload path="Customer/PAN" 
                                        update_url={(url,name)=>{this.setState({pan_url:url,pan_filename:name})}} />
                    }
                    {
                      this.state.pan_url!==null &&
                      <div>
                        <br />
                        <Button theme="danger" onClick={()=>{this.setState({pan_url:null})}} >Remove</Button>
                          <h6 className="m-0" style={{color:'black',fontSize:14}}>{this.state.pan_filename}</h6>
                      </div>
                    }
               </Col>
            </Row>



            
            <Button type="submit" style={{marginTop: '3%',alignSelf: 'center',marginBottom: '3%'}}>Update Customer</Button>
            
          </Form>
        </Col>
      
            </CardBody>
              </Card>
          </Col>
    }
    
    {
    this.state.isLoading &&

    <Col lg="12" md="12">
        <Card>
            <CardBody>
                  <AtomSpinner 
                            color="blue" 
                            style={{alignSelf: 'center',marginLeft: '40%',marginTop: '10%',marginBottom: '10%'}}/>
            </CardBody>
        </Card>
    </Col>
  }
    </Container>
  </div>

    )
  }
}






import React from 'react';
import { FormInput,Col} from "shards-react";

export default class Input extends React.Component{
    render(){
        return(
              <Col md={this.props.size} className="form-group">
                    <label >{this.props.name}</label>
                    <FormInput
                        type={this.props.type}
                        placeholder={this.props.name}
                        onChange = {this.props.onChange}
                        {...this.props}
                    />
              </Col>
        )
    }
}
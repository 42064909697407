import React from "react";
import {Modal,ModalBody,ModalHeader,Col,ListGroup,Row,ListGroupItem,Button} from 'shards-react';
import formatDate from '../formatDate';
import formatRupee from '../formatRupee';



export default class ViewSurety extends React.Component {
  constructor(props) {
      super(props);
	    this.toggle = this.toggle.bind(this);
	    this.state = {show:false}
    }
  toggle() {
    this.setState({show:!this.state.show});
  }

  render() {
    const  open  = this.state.show;
    return (
      <div>
        <Modal open={open} toggle={this.toggle}>
         
          <div>
          <ModalHeader>{this.props.data.group_id + " Auction No "+this.props.data.auct_no}</ModalHeader>
          <ModalBody>

              <ListGroup flush className="modalScroll">

                  <Item field={"Name"} value={this.props.data.name}/>
				  <Item field={"Surety No"} value={this.props.data.surety_no}/>
				  <Item field={"Phone"} value={this.props.data.phone}/>
				  <Item field={"DOB"} value={formatDate(this.props.data.dob.toDate())}/>
				  <Item field={"Office Address"} value={this.props.data.office_address}/>
				  <Item field={"Address"} value={this.props.data.address}/>
				  <Item field={"Company Name"} value={this.props.data.company_name}/>
				  <Item field={"Designation"} value={this.props.data.designation}/>
				  <Item field={"Salary"} value={formatRupee(this.props.data.salary)}/>
				  <Item field={"Remarks"} value={this.props.data.remarks}/>
				  <Item field={"Updated Date"} value={formatDate(this.props.data.date.toDate())}/>
				  <FileItem field={"File1"} url={this.props.data.file1}/>
                  <FileItem field={"File2"} url={this.props.data.file2}/>
                  <FileItem field={"File3"} url={this.props.data.file3}/>
                  <FileItem field={"File4"} url={this.props.data.file4}/>
                  <FileItem field={"File5"} url={this.props.data.file5}/>
                  
              </ListGroup>
              <Col lg="3">
                  <Button onClick={this.toggle}>Close</Button>
              </Col>

          </ModalBody>
          </div>
        </Modal>
		<Button theme="primary" onClick={this.toggle}>View</Button>
      </div>
    );
  }
}


class Item extends React.Component{
  render(){
    return(
      <ListGroupItem className="px-4">
        <div className="progress-wrapper">
          <Row>
          <Col md="6">
          <strong className=" d-block mb-2" style={{marginLeft: '5%',color:'grey'}}>
            {this.props.field}
          </strong>
          </Col>
          <Col md="6">
          <strong className="d-block mb-2" style={{marginLeft: '2%',color:'blue'}}>
            {this.props.value}
          </strong>
          </Col>
        </Row>

    
        </div>
      </ListGroupItem>
    )
  }
}


class FileItem extends React.Component{
    render(){
      return(
        <ListGroupItem className="px-4">
          <div className="progress-wrapper">
            <Row>
            <Col md="6">
            <strong className=" d-block mb-2" style={{marginLeft: '5%',color:'grey'}}>
              {this.props.field}
            </strong>
            </Col>
            <Col md="6">
            <strong className="d-block mb-2" style={{marginLeft: '2%',color:'blue'}}>
                <a href={this.props.url} target="_blank">Link</a>
            </strong>
            </Col>
          </Row>
  
      
          </div>
        </ListGroupItem>
      )
    }
  }

import React from 'react';
import { Container, Row, Col,Card , CardHeader,CardBody,FormInput,Button,
    Form,FormSelect,DatePicker } from "shards-react";
import PageTitle from "../common/PageTitle";
import FireFileUpload from '../FireFileUpload';
import firebase from '../../firebase';
import { AtomSpinner } from 'react-epic-spinners';
import is_empty_string from '../isEmptyString';
import alert from '../AlertStore';

import AlertBox from '../AlertBox';

export default class AddBankDeposit extends React.Component{
  constructor(props) {
    super(props);
  
    this.state = {loading:false,
            amount:null,
            description :null,
            type:'cash', //initial 
            deposit_date : null,
            doc_link:null,
            doc_filename:null,
            };
    this.modifyDeposit = this.modifyDeposit.bind(this);
    this.resetState = this.resetState.bind(this);
  }

  resetState = () =>{
      this.setState({
          loading:false,
            amount:null,
            description :null,
            type:'cash',
            deposit_date : null,
            doc_link:null,
            doc_filename:null,
            id : null,
        });
  }

  componentDidMount(){
      var url_string = window.location.href;
      var url = new URL(url_string);
      var amount = url.searchParams.get("amount");
      var deposit_date = url.searchParams.get('deposit_date');
      var description = url.searchParams.get('description');
      var doc_link = url.searchParams.get('doc_link');
      var id = url.searchParams.get('id');
      var type  = url.searchParams.get('type');


      this.setState({
            amount,
            description,
            type, //initial 
            deposit_date : new Date(deposit_date),
            doc_link : doc_link==="null"?null:doc_link,
            id,
      });
  }

  modifyDeposit = () =>{
    console.log(this.state);

    if( is_empty_string(this.state.amount) || is_empty_string(this.state.description) || is_empty_string(this.state.type)  || is_empty_string(this.state.deposit_date===null?null:this.state.deposit_date.toLocaleDateString()) ){
        alert.showMessage("Kindly Enter All the Details","warning");
        return;
    }

    var data = {
      amount : parseInt(this.state.amount),
      description:this.state.description,
      type:this.state.type,
      deposit_date: new Date(this.state.deposit_date),
      doc_link : this.state.doc_link,
      id : this.state.id,
    };
    console.log(data);
    //return;
    var that = this;

    this.setState({loading:true});
    var that = this;
    firebase.firestore().collection('Deposit').doc(data.id).update(data).then(function(){

          that.setState({loading:false});
          alert.showMessage("Succesfully Updated","success");
          window.close();

    }).catch(function(err){
        console.error(err);
        that.setState({loading:false});
        alert.showMessage(err.message,"danger");

    })

  }

  render(){
    return(
    <div>
      <AlertBox />
       <Container fluid className="main-content-container px-4 pb-4">
          
            {/* Page Header */}
            <Row noGutters className="page-header py-4">
                  <PageTitle sm="4" title="Deposit" subtitle="Modify" className="text-sm-left" />
            </Row>

            <form onSubmit={()=>{window.close();}} style={{marginBottom: '2%',marginLeft: '2%'}}>
                <Button type="submit" theme="danger">Cancel</Button>
           </form>


          <Col lg="12">
               <Card small className="mb-4">
                    <CardHeader className="border-bottom">
                          <h6 className="m-0">Modify Deposit</h6>
                    </CardHeader>
                    <CardBody className="p-2 pb-2" >
                      {
                        !this.state.loading &&
                        <Col>
                          <Form /*onSubmit={this.onSubmitHandler}*/>
                            <Row form>
                              <FormTextInput size="4" type="number" field_name="Deposit Amount" value={this.state.amount} onChange={(evt)=>{this.setState({amount:evt.target.value})}}/>   
                              <FormTextInput size="8" type="text" field_name="Description" value={this.state.description} onChange={(evt)=>{this.setState({description:evt.target.value})}}/>
                            </Row>
                            <Row form>
                              <Type onChange={(evt)=>{this.setState({type:evt.target.value})}} value={this.state.type}/>
                              <RefDate onChange={(date)=>{this.setState({deposit_date:new Date(date)})}} value={new Date(this.state.deposit_date)}/>
                                <FileUpload path="Deposit/Doc" 
                                              field_name={"Doc Link"}
                                                link={this.state.doc_link}
                                                filename={this.state.doc_filename}
                                                  onChange={(url,name)=>{this.setState({doc_link:url,doc_filename:name})}}
                                                  remove={()=>{this.setState({doc_filename:null,doc_link:null})}} />
                            </Row>
                          

                              <SubmitButton onClick={this.modifyDeposit} />
                              
                          </Form>
                        </Col>
                      }
                      
                        {
                          (this.state.loading) &&
                          <AtomSpinner color="blue" style={{alignSelf: 'center',marginLeft: '40%',marginTop: '10%',marginBottom: '10%'}}/>
                        }
                      
                    </CardBody>
                </Card>
              </Col>


        </Container>
      </div>

    )
  }
}


class SubmitButton extends React.Component{
  render(){
    return(
      <Row>
                            <Col lg="4"></Col>
                                <Col lg="4">
                                  <Button onClick={this.props.onClick} style={{maxWidth: '100%',width:'100%',marginTop: '1%'}} size="md">
                                    {"Modify Deposit"}
                                  </Button>
                                </Col>
                                <Col lg="4"></Col>
                            </Row>

    )
  }
}

//dont use for date
class FormTextInput extends React.Component{
  render(){
    return(
       <Col md={this.props.size} className="form-group">
                  <label>{this.props.field_name}</label>
                  <FormInput
                  {...this.props}
                  type={this.props.type}
                  placeholder={this.props.field_name}
                  onChange = {this.props.onChange}
                />

              </Col>
    )
  }
}


class FileUpload extends React.Component{
  render(){
    return(
        <Col md="4">
                                    <label htmlFor="fePassword">{this.props.field_name}</label>
                                    
                                    { this.props.link===null &&
                                      <FireFileUpload path={this.props.path} 
                                                      update_url={(url,name)=>{this.props.onChange(url,name)}} />
                                    }
                                    {
                                        this.props.link!==null &&
                                        <div>
                                            <br />
                                            <Button theme="danger" onClick={()=>{this.props.remove()}}>Remove</Button>
                                            <h6 className="m-0">{this.props.filename}</h6>
                                        </div>
                                    }
                </Col>
    )
  }
}

class RefDate extends React.Component{
  constructor(props) {
    super(props);
  
    this.state = {date:null};
    this.handleDateChange = this.handleDateChange.bind(this);
  }

  handleDateChange(value){
    this.setState({date:new Date(value)});
    this.props.onChange(new Date(value));
  }

  

  render(){
    return(
        <Col md={"4"} className="form-group">
                    <label>{"Deposit Date"}</label>
                    <br />
                    <DatePicker placeholderText="Ref Date"
                                  {...this.props}
                                  selected={this.state.date}
                                  onChange={this.handleDateChange}
                                  dropdownMode="select"
                                  className="text-center"
                                  />
              </Col>
    )
  }
}

class Type extends React.Component{



  render(){
    return(
         <Col md={"4"} className="form-group">
                      <label htmlFor="feEmailAddress">{"Type"}</label>
                      <FormSelect onChange ={this.props.onChange} {...this.props}>
                          <option value="cash">Cash</option>
                          <option value="NEFT">NEFT</option>
                          <option value="cheque">Cheque</option>
                      </FormSelect>
              </Col>  
    )
  }
}

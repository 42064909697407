import React from "react";
import PropTypes from "prop-types";
import {
  Card,
  CardHeader,
  CardBody,
  ListGroup,
  ListGroupItem,
  FormCheckbox,
} from "shards-react";

import {view} from 'react-easy-state';
import sms_data from './SendSMSStore';
import group_list from '../groupNameStore';

export default view(()=>{

  return <GroupNames isLoading={group_list.isLoading} list={group_list.names}/>
})


const GroupNames = (props) => (
  <Card small className="mb-3">
    <CardHeader className="border-bottom">
      <h6 className="m-0">{"Groups"}</h6>
    </CardHeader>
    <CardBody className="p-0">
      <ListGroup flush>
        <ListGroupItem className="px-3 pb-2">
          
             {
                  props.list.map((item,i)=>{
                      return (<GroupList key={i} group_id={item}/>)
                  })
            }
          
        </ListGroupItem>
      </ListGroup>
    </CardBody>
  </Card>
);


class GroupList extends React.Component{
  constructor(props) {
    super(props);
    this.state = {isChecked:false};
    this.handleCheck = this.handleCheck.bind(this);
  }

  handleCheck = () =>{
    if(this.state.isChecked){
        sms_data.removeGroup();
        this.setState({isChecked:false});
    }
    else{
       var res = sms_data.addGroup(this.props.group_id);
      this.setState({isChecked:res});
      console.log("Checked");
    }
  }
  render(){
    return(
      <FormCheckbox checked={this.state.isChecked} onChange={this.handleCheck} className="mb-1" value={this.props.group_id}>
          {this.props.group_id}
      </FormCheckbox>
    )
  }
}



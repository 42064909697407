import React from "react";
import { Container, Row, Col, Card, CardHeader, CardBody,Button} from "shards-react";

import PageTitle from "../common/PageTitle";
import '../scroll.css';
import { AtomSpinner } from 'react-epic-spinners';
import { view } from 'react-easy-state';
import SelectGroup from '../SelectGroup';
import login_data from '../../loginstore';
import { Redirect,Link } from "react-router-dom";
import AlertBox from '../AlertBox';
import transfer_store from './transferMoneyStore';
import Input from '../common/Input';

export default view(()=>{
    return (login_data.isLoggedIn?<TransferMoney isLoading={transfer_store.isLoading}/>:<Redirect to="/login" />)
})

 class TransferMoney extends React.Component{
    render(){
        return(
            <div>
                <AlertBox />
                <Container fluid className="main-content-container px-4">
                    {/* Page Header */}
                    <Row noGutters className="page-header py-4">
                        <PageTitle sm="4" title="Tansfer" subtitle="Transfer money from one Chit to Another" className="text-sm-left" />
                    </Row>

                    <Link to="/generalvoucher">
                        <Button theme="danger" className="m-4">Back</Button>
                    </Link>

                    
                    <Col lg="12" md="12" xs="12" sm="12" className="mb-4">
                        <Card small className="mb-4">
                            <CardHeader className="border-bottom">
                                <h6 className="m-0">Transfer</h6>
                            </CardHeader>
                            <CardBody>
                            {(!this.props.isLoading) &&
                                <div>
                                <Row>
                                <Col md="6" sm="12" xs="12" lg="6">
                                    <h5>From</h5>
                                    <Col md="12">
                                        <SelectGroup onChange={(evt)=>{transfer_store.setValue('sgroup',evt.target.value)}}/>
                                    </Col>
                                    <Input size="12" placeholder={"Ticket No."} type="number" onChange={(evt)=>{transfer_store.setValue('sticket',evt.target.value)}} />
                                </Col>
                                <Col md="6" sm="12" xs="12" lg="6">
                                    <h5>To</h5>
                                    <Col md="12">
                                        <SelectGroup onChange={(evt)=>{transfer_store.setValue('dgroup',evt.target.value)}}/>
                                    </Col>
                                    <Input size="12" placeholder={"Ticket No."} type="number" onChange={(evt)=>{transfer_store.setValue('dticket',evt.target.value)}} />
                                </Col>
                                </Row>
                                <Row>
                                    <Col md="6" sm="12" xs="12" lg="6">
                                        <h5>Amount to Transfer</h5>
                                        <Input size="8" placeholder={"Amount"} type="number" onChange={(evt)=>{transfer_store.setValue('amount',evt.target.value)}} />
                                    </Col>  
                                </Row>

                                <Col md="6" sm="12" xs="12" lg="6">
                                        <Button theme="accent" className="mt-0" onClick={transfer_store.transferMoney}>Transfer Money</Button>
                                </Col>
                                </div>
                            }
                            {
                                (this.props.isLoading) &&
                                <center>
                                    <AtomSpinner color="blue" className="mt-4 mb-4"/>
                                </center>
                            }
                            </CardBody>
                        </Card>
                    </Col>
                    
                </Container>
            </div>
        )
    }
}



import React from "react";
import {Row, Col, Card, CardHeader, CardBody } from "shards-react";

import '../scroll.css';

import dailysummary_data from '../DailySummaryStore';
import { AtomSpinner } from 'react-epic-spinners';
import { view } from 'react-easy-state';
import Message from '../Message';
import getArray from '../getArray';
import format_rupee from '../formatRupee';
import formatDate from '../formatDate';
import login_data from '../../loginstore';
import { Redirect } from "react-router-dom";


export default view(()=>{
    var data = {
      data : getArray(dailysummary_data.data),
      isLoading : dailysummary_data.isLoading,
      gotData : dailysummary_data.gotData,
      hasError : dailysummary_data.hasError,

      cash_in : dailysummary_data.cash_total,
      neft_in : dailysummary_data.neft_total,
      cheque_in : dailysummary_data.cheque_total,

      cash_deposit : dailysummary_data.cash_deposit_total,
      neft_deposit : dailysummary_data.neft_deposit_total,
      cheque_deposit : dailysummary_data.cheque_deposit_total,

      expenditure : dailysummary_data.expenditure_total,
    }

    return login_data.isLoggedIn?<Table data={data} />:<Redirect to='/login' />;
})



class Table extends React.Component{
  render(){

    const total_in = this.props.data.cash_in+this.props.data.cheque_in+this.props.data.neft_in;
    const total_dep = this.props.data.cash_deposit+this.props.data.cheque_deposit+this.props.data.neft_deposit;
    const total_diff = total_in - total_dep;

    return(
    
      <Row>
      <Col>
        <Card small className="mb-4">
          <CardHeader className="border-bottom">
              <h6 className="m-0">Daily Summary</h6>
          </CardHeader>

       
          
          <CardBody className="p-0 pb-3 scroll_list-group" >
          {
            (!this.props.data.isLoading  && this.props.data.data.length!==0) &&

            <table className="table mb-0">
              <thead className="bg-light">
                <tr>
                  <th scope="col" className="border-0">
                      #
                  </th>
                  <th scope="col" className="border-0">
                      Date
                  </th>
                  <th scope="col" className="border-0">
                      Cash
                  </th>
                  <th scope="col" className="border-0">
                      Cheque
                  </th>
                  <th scope="col" className="border-0">
                      NEFT
                  </th>
                  <th scope="col" className="border-0">
                      Total Receipt
                  </th>
                  <th scope="col" className="border-0">
                      Cash Deposit
                  </th>
                  <th scope="col" className="border-0">
                     Cheque Deposit
                  </th>
                  <th scope="col" className="border-0">
                      NEFT Deposit
                  </th>
                  <th scope="col" className="border-0">
                      Total Deposit
                  </th>
                  <th scope="col" className="border-0">
                      Total Difference
                  </th>
                  <th scope="col" className="border-0">
                      Total Expenditure
                  </th>

                </tr>
              </thead>
              <tbody>
                   {
                          this.props.data.data.map((item,i)=>{
                                return (<Trow key={i} data={item} index={i+1}/>)
                          })
                    }
                   
                   <tr>
                  <th></th>
                  <th>Total</th>
                  <th>{format_rupee(this.props.data.cash_in)}</th>
                  <th>{format_rupee(this.props.data.cheque_in)}</th>
                  <th>{format_rupee(this.props.data.neft_in)}</th>
                  <th style={{color:'green',fontWeight: 'bold' }}>{format_rupee(total_in)}</th>
                  <th>{format_rupee(this.props.data.cash_deposit)}</th>
                  <th>{format_rupee(this.props.data.cheque_deposit)}</th>
                  <th>{format_rupee(this.props.data.neft_deposit)}</th>
                  <th style={{color:'blue',fontWeight: 'bold' }}>{format_rupee(total_dep)}</th>
                  <th style={{color:'red',fontWeight: 'bold' }}>{format_rupee(total_diff)}</th>
                  <th>{format_rupee(this.props.data.expenditure)}</th>
                </tr>      
              </tbody>
              
            </table>
          }

            {
                (this.props.data.isLoading) &&
                <AtomSpinner color="blue" style={{alignSelf: 'center',marginLeft: '40%',marginTop: '10%',marginBottom: '10%'}}/>
            }
            {
                (!this.props.data.isLoading && this.props.data.hasError && this.props.data.gotData  ) &&
                <Message message={"Error Loading Data"} icon_name={"warning"} />
            }
            {
                ( !this.props.data.isLoading && (this.props.data.data.length===0) && this.props.data.gotData ) &&
                <Message message={"No Data Avaiable"} icon_name={"sentiment_dissatisfied"} />
            }
            {
              (!this.props.data.isLoading && !this.props.data.gotData ) && 
              <Message message={"Kindly Select Start and End Date"} icon_name={"call_missed"} />
            }


          </CardBody>
        </Card>
      </Col>
    </Row>
    )
  }
}



class Trow extends React.Component{
  render(){

    const receipt_total = this.props.data.cash_in + this.props.data.cheque_in + this.props.data.NEFT_in;
    const deposit_total = this.props.data.cash_deposit + this.props.data.cheque_deposit + this.props.data.NEFT_deposit;
    const total_diff = receipt_total - deposit_total;

    return(
              <tr>
                  <td>{this.props.index}</td>
                  <td>{formatDate(this.props.data.date.toDate())}</td>
                  <td>{format_rupee(this.props.data.cash_in)}</td>
                  <td>{format_rupee(this.props.data.cheque_in)}</td>
                  <td>{format_rupee(this.props.data.NEFT_in)}</td>
                  <td style={{color:'green',fontWeight: 'bold' }}>{format_rupee(receipt_total)}</td>
                  <td>{format_rupee(this.props.data.cash_deposit)}</td>
                  <td>{format_rupee(this.props.data.cheque_deposit)}</td>
                  <td>{format_rupee(this.props.data.NEFT_deposit)}</td>
                  <td style={{color:'blue',fontWeight: 'bold' }}>{format_rupee(deposit_total)}</td>
                  <td style={{color:'red',fontWeight: 'bold' }}>{format_rupee(total_diff)}</td>
                  <td>{format_rupee(this.props.data.expenditure)}</td>
                </tr>
    )
  }
}
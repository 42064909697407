import React from "react";
import {view} from 'react-easy-state';
import {Modal,ModalBody,ModalHeader,Col,ListGroup,Row,ListGroupItem,Button} from 'shards-react';
import pay_inst from '../payInstallmentStore';
import getArray from '../getArray';
import formatDate from '../formatDate';
import formatRupee from '../formatRupee';

export default view(()=>{
    console.log("SHow=",pay_inst.showReceipt);
    return <ViewReceipt show={pay_inst.showReceipt} data={getArray(pay_inst.showReceiptdata)}/>;
})


class ViewReceipt extends React.Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.getPreviousCarriage = this.getPreviousCarriage.bind(this);
  }

  componentDidMount(){
    console.log("View Receipt Mounted");
  }

  getPreviousCarriage(){
      var val = 0;
      if(this.props.data[0].grand_total>this.props.data[0].customer_paid){
        val = this.props.data[0].grand_total - this.props.data[0].customer_paid;
      }
      return val;
  }

  toggle() {
    pay_inst.closeViewReceipt();
  }

  render() {
    const  open  = this.props.show;
    console.log("RENDER SHoW = ",open);
    return (
      <div>
        <Modal open={open} toggle={this.toggle}>
          {
          (this.props.data.length>0) &&
          <div>
          <ModalHeader>{"Receipt No "+this.props.data[0].receipt_id}</ModalHeader>
          <ModalBody>

              <ListGroup flush className="modalScroll">
                  {
                    (!this.props.data[0].valid)
                    &&
                    <center>
                    <h4 style={{color:'red'}}>Invalid</h4> 
                    </center>
                  }

                  <Item field={"CUST ID"} value={this.props.data[0].cust_id}/>
                  <Item field={"Group Ticket"} value={this.props.data[0].group_id+"/"+this.props.data[0].ticket_no}/>
                  <Item field={"Name"} value={this.props.data[0].name}/>
                  <Item field={"Receipt Date"} value={formatDate(this.props.data[0].paid_date.toDate())}/>
                  <Item field={"Manual Receipt ID"} value={this.props.data[0].mr_id}/>
                  <Item field={"Manual Receipt Date"} value={formatDate(this.props.data[0].mr_date.toDate())}/>
                  <Item field={"Payment Method"} value={this.props.data[0].payment_method}/>
                  <Item field={"Installment Total"} value={formatRupee(this.props.data[0].installment_total-this.props.data[0].interest)}/>
                  <Item field={"Interest"} value={formatRupee(this.props.data[0].interest)}/>
                  <Item field={"Other Charges"} value={formatRupee(this.props.data[0].other_charges)}/>
                  <Item field={"Grand Total"} value={formatRupee(this.props.data[0].grand_total)}/>
                  <Item field={"Previous Carriage"} value={formatRupee(this.getPreviousCarriage())}/>
                  <Item field={"Customer Paid"} value={formatRupee(this.props.data[0].customer_paid)}/>
                  <Item field={"Excess Paid"} value={formatRupee(this.props.data[0].excess_paid)}/>
                  <Item field={"Comments"} value={this.props.data[0].comments}/>
                  <Item field={"Received By"} value={this.props.data[0].receivedBy}/>
                  
              </ListGroup>
              <Col lg="3">
                  <Button onClick={()=>{pay_inst.editReceipt(this.props.data[0].receipt_id,this.props.data[0].comments)}}>Edit</Button>
              </Col>

          </ModalBody>
          </div>
          }
        </Modal>
      </div>
    );
  }
}


class Item extends React.Component{
  render(){
    return(
      <ListGroupItem className="px-4">
        <div className="progress-wrapper">
          <Row>
          <Col md="6">
          <strong className=" d-block mb-2" style={{marginLeft: '5%',color:'grey'}}>
            {this.props.field}
          </strong>
          </Col>
          <Col md="6">
          <strong className="d-block mb-2" style={{marginLeft: '2%',color:'blue'}}>
            {this.props.value}
          </strong>
          </Col>
        </Row>

    
        </div>
      </ListGroupItem>
    )
  }
}

import React from "react";
import { Container, Row, Col,Button,Card,CardBody,InputGroup,InputGroupAddon,InputGroupText,FormSelect} from "shards-react";

import PageTitle from "../common/PageTitle";
import '../scroll.css';
import CsvDownloader from 'react-csv-downloader';

import {Link} from 'react-router-dom';

import deposit_data from '../depositStore';
import {view} from 'react-easy-state';

import RangeDatePicker from "./RangeDatePicker";
import DepositTable from './DepositTable';

import AlertBox from '../AlertBox';
import Message from '../Message';


function setSortKey(value){
  console.log(value);
  deposit_data.sortkey = value;
  deposit_data.filterData();
}

export default view((props)=>{

    return <DailySummary data={deposit_data.data}/>
})

class DailySummary extends React.Component{
    render(){
      return(
      <div>
        <AlertBox />
            <Container fluid className="main-content-container px-4">
    {/* Page Header */}
    <Row noGutters className="page-header py-4">
      <PageTitle sm="4" title="Deposit" subtitle="Bank" className="text-sm-left" />
    </Row>

    

        <Link  to="/adddeposit">
            <Button type="submit" theme="primary">Add New Deposit</Button>
        </Link>

  <Row>
    <Col style={{marginTop: '2%',marginBottom:'2%'}}>
              <RangeDatePicker className="mb-3"/>
     </Col>
     <Col  style={{marginTop: '2%',marginBottom:'2%'}}>
        <Sort style={{marginTop: '2%',marginBottom:'2%'}}/>
     </Col>
     <Col style={{marginTop: '2%',marginBottom:'2%'}}>
        <DownloadButton data={this.props.data}/>
     </Col>
  </Row>


    <DepositTable />
    

  </Container>
</div>
        )
    }
}


class Sort extends React.Component{
  render(){
    return (
        <Col sm="12" md="6" lg="4">
            <InputGroup className="mb-3">
                <InputGroupAddon type="prepend">
                    <InputGroupText>Select</InputGroupText>
                 </InputGroupAddon>
                  <FormSelect onChange = {(evt)=>{setSortKey(evt.target.value)}}>
                        <option value="all">{"All"}</option>
                        <option value="cash">{"Cash"}</option>
                        <option value="NEFT">{"NEFT"}</option>
                        <option value="cheque">{"Cheque"}</option>
                  </FormSelect>
     
            
        </InputGroup>
      </Col>
    )
  }
}


class DownloadButton extends React.Component{
  render(){
    return(
      <CsvDownloader datas={this.props.data} filename="bankdeposit.csv" md="4"> 
              
      <Col sm="12" md="6" lg="4">
        <Button theme="accent" size="md" > Download  <i className="material-icons">cloud_download</i></Button>
      </Col>

      </CsvDownloader>
    )
  }
}

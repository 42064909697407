import {store} from 'react-easy-state';
import firebase from '../../firebase';
import alert from '../AlertStore';

const data = store({
    isLoading : false,
    group_id  : undefined,
    ticket_no : undefined,
    newCustID : undefined,

    transferChit(){
        if(data.group_id===undefined || data.ticket_no===undefined || data.newCustID===undefined){
            alert.showMessage("Kindly Enter all Details","warning");
            return;
        }
        data.isLoading = true;
        var payload = {group_id:data.group_id,ticket_no:data.ticket_no,newCustId:data.newCustID};
        
        var func_ref = firebase.functions().httpsCallable('TransferChit');
        func_ref(payload).then(function(result) {
            // Read result of the Cloud Function.
            var rdata = result.data;
            console.log(rdata);                  
            data.isLoading = false;
              
            if(rdata.status==="success"){
                  alert.showMessage("Transfered Succesfully","success");
            }
            else{
                  alert.showMessage(rdata.message,"danger");
            }	

            }).catch(function(error) {
                    // Getting the Error details.
                    //var code = error.code;
                    //var message = error.message;
                    //var details = error.details;
                    // ...
                    console.log(error);
                    data.isLoading = false;
                    alert.showMessage(error.message,"danger");
            });

    }
})


export default data;
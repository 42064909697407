import { store } from 'react-easy-state';
import firebase from '../firebase';
import is_empty_string from './isEmptyString';
import alert from './AlertStore';

const receipt_data = store({

		isLoading : false,

		start_date : undefined,
		end_date  : undefined,

		viewData : [],
		downloadData : [],
		data : [],
		gotData : false,
		hasError : false,
		no_of_data : 0,

		//search
		searchType : 'receipt_id', //initial


		getDiffDays(date1,date2){

			var oneDay = 24*60*60*1000; // hours*minutes*seconds*milliseconds
			var diff_days = Math.round(Math.abs((date1.getTime() - date2.getTime())/(oneDay)));
			return diff_days;
		},

		getReceiptData(){

			if(receipt_data.start_date===undefined || receipt_data.end_date===undefined ){
				alert.showMessage("Kindly Select start and end Date","warning");
				return;
			}
			var start_date = new Date(receipt_data.start_date);
			var end_date  = new Date(receipt_data.end_date);
			
			if( end_date < start_date ){
				alert.showMessage("Kindly Select End Date Greater than start Date","danger");
				return;
			}
			
			/*if( receipt_data.getDiffDays(start_date,end_date) > 90 ){
				alert.showMessage("Only 90Days time gap is allowed","danger");
				return;
			}*/

			receipt_data.isLoading = true;
			receipt_data.no_of_data =0;
			firebase.firestore().collection('Receipt').where('paid_date','>=',start_date).where('paid_date','<=',end_date).orderBy('paid_date','desc').onSnapshot(function(snap){

					var temp = [];
					var download_data = [];
					snap.forEach(function(doc){
						var receipt = doc.data();
						
						var inst_ids = receipt.inst_ids;
						var inst_string = " ";
						for(var i=0;i<inst_ids.length;i++){
							inst_string = inst_ids[i].id.split('_')[2] + ",";
						}
						receipt.inst_ids = inst_string.substring(0,inst_string.length-1);
						receipt.mr_date = receipt.mr_date.toDate().toLocaleDateString();
						receipt.paid_date = receipt.paid_date.toDate().toLocaleDateString();
						console.log(receipt);
						temp.push(doc.data());
						download_data.push(receipt);
					})
					receipt_data.no_of_data = snap.size;
					receipt_data.data = temp;
					receipt_data.downloadData = download_data;
					receipt_data.viewData = temp;
					receipt_data.isLoading = false;
					receipt_data.gotData = true;
					receipt_data.hasError = false;

			},function(err){
					console.error(err);

					receipt_data.no_of_data = 0;
					receipt_data.isLoading = false;
					receipt_data.gotData = true;
					receipt_data.hasError = true;
					receipt_data.data = [];
					receipt_data.viewData = [];
			})

		},

		searchData(key){
				
				if(is_empty_string(key)){
					receipt_data.viewData = receipt_data.data;
					return;
				}
				var temp = [];
				receipt_data.data.forEach(function(receipt){
					if(!is_empty_string(receipt[receipt_data.searchType].toString())){
						
						if(receipt[receipt_data.searchType].toString().includes(key)){
								temp.push(receipt);
						}
					}
						
				})
				temp.sort(receipt_data.compare);
				receipt_data.viewData = temp;
		},


		compare( a, b ) {
				var searchkey = receipt_data.searchType;
  				if ( a[searchkey] < b[searchkey] ){
    							return -1;
  				}
  				if ( a[searchkey] > b[searchkey] ){
    								return 1;
  				}
  				return 0;
		},

		setSearchType(type){
			receipt_data.searchType = type;
		},


		setStartDate(date){
			receipt_data.start_date = date;
			if(receipt_data.end_date!==undefined){
				receipt_data.getReceiptData();
			}

		},
		setEndDate(date){
			receipt_data.end_date = date;
			if(receipt_data.start_date!==undefined){
					receipt_data.getReceiptData();
			}
		},

})


export default receipt_data;
import React from 'react';
import { Container, Row, Col,Card , CardHeader,CardBody,FormInput,Button,
		Form} from "shards-react";
import PageTitle from "../common/PageTitle";
import firebase from '../../firebase';
import {toast} from 'react-toastify';
import { AtomSpinner } from 'react-epic-spinners';
import AlertBox from '../AlertBox';


export default class RemoveIntroducer extends React.Component{

  constructor(props) {
    super(props);
  
    this.state = {
        int_id: null,
        isLoading:false,
    };

    this.handleDOBDateChange = this.handleDOBDateChange.bind(this);
    this.RemoveIntroducer = this.RemoveIntroducer.bind(this);
    this.isNull = this.isNull.bind(this);
    this.onSubmitHandler = this.onSubmitHandler.bind(this);
  }

  handleDOBDateChange(value) {
    this.setState({ dob: new Date(value)});
  }

  isNull(obj){ 
  
  for (var key in obj) {
      if (obj.hasOwnProperty(key)) {
        var val = obj[key];
        console.log(val)
        if(val===undefined || val === null){
          return true;
        }
      }
  }
  return false;
}

componentDidMount(){
  var url_string = window.location.href;
  var url = new URL(url_string);
  var c = url.searchParams.get("name");
  console.log(c);
  this.setState({int_id:c});
}

  RemoveIntroducer = () =>{
    var that = this;
    if(this.state.cust_id===null){
      alert.showMessage("Kindly Enter CustomerID","danger");
    }
    var data = {
        int_id : this.state.int_id,
    }
    

    this.setState({isLoading:true});
    var func_ref = firebase.functions().httpsCallable('RemoveIntroducer');
    func_ref(data).then(function(result) {
                  // Read result of the Cloud Function.
                  var data = result.data;
                  console.log(data);
                  if(data.status==="success"){
                        console.log("Document succesfully removed!");
                        alert.showMessage("Introducer Removed Successfully","success");
                        console.log(that.state);
                   }
                  else{
                        console.log(data.error.message);
                        alert.showMessage(data.error.message,"danger");
                  }
                  that.setState({isLoading:false});

        }).catch(function(error) {
                  // Getting the Error details.
                  //var code = error.code;
                  //var message = error.message;
                  //var details = error.details;
                  // ...
                  console.log(error);
                  that.setState({isLoading:false});
                  toast("unable to Remove Introducer", {containerId: 'A'});
                  alert.showMessage("Unable to Remove Introducer","danger");
        });

  }

  onSubmitHandler = (evt) =>{
      evt.preventDefault();
      this.RemoveIntroducer();
  }

  render(){

  	

    return(
    <div>
      <AlertBox />
    <Container fluid className="main-content-container px-4 pb-4">
        {/* Page Header */}
        <Row noGutters className="page-header py-4">
              <PageTitle sm="4" title="Introducer" subtitle="Remove" className="text-sm-left" />
        </Row>

        

     	<Col lg="12">
        <Card small className="mb-4">
          <CardHeader className="border-bottom">
              <h6 className="m-0">Remove Introducer</h6>
              
          </CardHeader>
          <CardBody className="p-2 pb-2" >
        { !this.state.isLoading &&		
         <Col>
          <Form onSubmit={this.onSubmitHandler}>
            <Row form>
              <Col md="4" className="form-group">
                <label htmlFor="feEmailAddress">IntroducerID</label>
                <FormInput
                  id="feEmailAddress"
                  type="text"
                  placeholder="IntroducerID"
                  onChange = {(evt)=>{this.setState({int_id:evt.target.value})}}
                />
              </Col>
              <Col md="4">

                  <Button type="submit" style={{alignSelf: 'center',marginTop: '9%'}}>Remove Introducer</Button>
              </Col>
           </Row>
          </Form>
        </Col>
      }
      {
        this.state.isLoading &&
        <Col>
          <AtomSpinner color="blue" style={{alignSelf: 'center',marginLeft: '40%',marginTop: '10%',marginBottom: '10%'}}/>
        </Col>
      }
 
          </CardBody>
          </Card>
          </Col>
    </Container>
  </div>
    )
  }
}


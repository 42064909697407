import React from "react";
import { Row, Col,Card,CardHeader ,ListGroup,ListGroupItem,Button,Progress } from "shards-react";

import profile_data from '../customerProfileStore';
import { view } from 'react-easy-state';
import {Link} from 'react-router-dom';

export default view(()=>{
      var data = {
        data         : profile_data.customerData,
        isLoading    : profile_data.isProfileLoading,
        hasData      : profile_data.hasProfileData,
        hasError     : profile_data.hasErrorProfileData,
        gotData      : profile_data.gotProfileData,     
    };
      
      return <CustomerDetails data={data} />
})


function getProgress(proxyObj){
    var completed=0,total=0,progress=0;
    var obj = proxyObj;
      for (var key in obj) {
        if (obj.hasOwnProperty(key)) {
                total = total+1;
                if(obj[key]!==null){
                    completed = completed + 1;
                }
        }
    }
    progress = (completed/total)*100;
    return progress;
}



class CustomerDetails extends React.Component{
  render(){
    return(

          <Col lg="4">
            { this.props.data.hasData &&
              <CustProfile data={this.props.data.data}/>
            }
            {
              this.props.data.hasData &&
              <ContactDetails data={this.props.data.data}/>
            }
          </Col>
      )
  }
}

class ContactDetails extends React.Component{
	render(){
    console.log("=============================");
    console.log("Customer Details");
    console.log(this.props.data);
     console.log("=============================");
		return(
			<Card small className="mb-4 pt-3">
				 <CardHeader className="border-bottom text-center">
     				<h6 className="m-0">{"Contact Details"}</h6>
     		    </CardHeader>
     		    <ListGroup flush>
     				 
      	<ListGroupItem className="p-4">
      <span>			
        <FieldValue field={"Phone"} value={this.props.data.phone}/>
        <FieldValue field={"Email"} value={this.props.data.email} />
        <FieldValue field={"Address"} value={this.props.data.address} />
     
	 </span>  
      </ListGroupItem>
    </ListGroup>
			</Card>
		)
	}
}


const no_image = require('../../assets/profile_image.png');

class CustProfile extends React.Component{
  render(){

  
    var progress = parseInt(getProgress(this.props.data));



    return(
  
  <Card small className="mb-4 pt-3">
    <CardHeader className="border-bottom text-center">
      <div className="mb-3 mx-auto">
        <img
          className="rounded-circle"
          src={this.props.data.photo_url===null?no_image:this.props.data.photo_url}
          alt={"User"}
          width="110"
          height= '100'
        />
      </div>
      <h4 className="mb-0">{this.props.data.name}</h4>
      <span className="text-muted d-block mb-2">{this.props.data.cust_id}</span>
      <Link to={"/addcustomergroup?name="+this.props.data.name+"&customer_id="+this.props.data.cust_id} >
          <Button pill outline size="sm" className="mb-2">
              <i className="material-icons mr-1">add_box</i> Add Ticket
          </Button>
      </Link>
    </CardHeader>
    <ListGroup flush>
      <ListGroupItem className="px-4">
        <div className="progress-wrapper">
          <strong className="text-muted d-block mb-2">
            {"Profile Completed"}
          </strong>
          <Progress
            className="progress-sm"
            value={progress}
          >
            <span className="progress-value">
              {progress}%
            </span>
          </Progress>
        </div>
      </ListGroupItem>
      <ListGroupItem className="p-4">
        <span>  
        <FieldValue field={"Father/Husband"} value={this.props.data.father_husband_name}/>
        <FieldValue field={"Nominee"} value={this.props.data.nominee_name}/>
        <FieldValue field={"Relationship"} value={this.props.data.nominee_relationship} />
        <FieldValue field={"IntroducerID"} value={this.props.data.introducer_id} />
        <FieldValue field={"Collection Assistant ID"} value={this.props.data.collection_asst_id} />
        <FieldValue field={"Bank Account No"} value={this.props.data.bank_accnt_no} />
        <FieldValue field={"IFSC code"} value={this.props.data.ifsc_code} /> 
        <Files field={"Photo URL"} value={this.props.data.photo_url} />
        <Files field={"Aadhar URL"} value={this.props.data.aadhar_url} />
        <Files field={"Bank URL"} value={this.props.data.bank_url} />
        <Files field={"PAN URL"} value={this.props.data.pan_url} />
        <Files field={"Signature URL"} value={this.props.data.signature_url} />

  </span>
      </ListGroupItem>
    </ListGroup>
  </Card>
    )
  }
}


class Files extends React.Component{
  render(){
    return(
    
          <Row style={{marginTop: '3%'}}>
            <Col>{this.props.field}</Col>
            <Col style={{fontWeight:'bold'}}><a href={this.props.value} target="_blank">Link</a></Col>
          </Row>
    )
  }
}



class FieldValue extends React.Component{
  render(){
    return(
    
          <Row style={{marginTop: '3%'}}>
            <Col>{this.props.field}</Col>
            <Col style={{fontWeight:'bold'}}>{this.props.value}</Col>
          </Row>
    )
  }
}

import React from "react";
import {Card,CardHeader ,CardBody,Button } from "shards-react";
import Message from '../Message';
import { AtomSpinner } from 'react-epic-spinners';
import profile_data from '../customerProfileStore';
import {  view } from 'react-easy-state';
import getArray from '../getArray';
import formatDate from '../formatDate';
import formatRupee from '../formatRupee';

export default view(()=>{
      var data = {
        data       : getArray(profile_data.viewVoucherData),
        isLoading   : profile_data.isVoucherLoading,
        hasError    : profile_data.hasErrorVoucherData,
        no_of_data :   profile_data.viewVoucherData.length,
      };
      console.log(data);

      return <GeneralVoucher data={data} />
})


class GeneralVoucher extends React.Component{
  render(){
    return(
       <Card small className="mb-4 pt-3">
          <CardHeader className="border-bottom">
              <h6 className="m-0">Journal Voucher</h6>
          </CardHeader>

          <CardBody className="p-0 pb-3 scroll_list-group" >
           
          { (!this.props.data.isLoading && this.props.data.no_of_data>0) &&
            <table className="table mb-0">
              <thead className="bg-light">
                <tr>
                  <th scope="col" className="border-0">
                    Voucher ID
                  </th>
                  <th scope="col" className="border-0">
                    date
                  </th>
                  <th scope="col" className="border-0">
                    Ticket No.
                  </th>
                  <th scope="col" className="border-0">
                    Amount
                  </th>
                  <th scope="col" className="border-0">
                    Type
                  </th>
                  <th scope="col" className="border-0">
                    Comment
                  </th>
                </tr>
              </thead>
              <tbody>
                      {
                          this.props.data.data.map((item,i)=>{
                                return (<Trow key={i} data={item} index={i+1}/>)
                          })
                        }
               
               </tbody>
             </table>
           }
                    {
                        this.props.data.isLoading &&
                        <AtomSpinner color="blue" style={{alignSelf: 'center',marginLeft: '40%',marginTop: '5%',marginBottom: '5%'}}/>
                      }
                      {
                        (!this.props.data.isLoading && this.props.data.hasError) &&
                        <Message message={"Error Loading Data"} icon_name={"warning"} /> 
                      }
                      {
                        ( (!this.props.data.isLoading) && (!this.props.data.hasError) && (this.props.data.no_of_data===0) ) &&
                          <Message message={"No Vouchers Added..."} icon_name={"chrome_reader_mode"} />
                      }
           </CardBody>
          </Card>

    )
  }
}


class Trow extends React.Component{
  render(){



    return(
           <tr>
                  <td>{this.props.data.id}</td>
                  <td>{formatDate(this.props.data.date.toDate())}</td>
                  <td>{this.props.data.group_id+"/"+this.props.data.ticket_no}</td>
                  <td>{formatRupee(this.props.data.amount)}</td>
                  <td style={{color:this.props.data.isDebit?"green":"red"}}>{this.props.data.isDebit?"Debit":"Credit"}</td>
                  <td>{this.props.data.comments}</td>
                  
          </tr>

    )
  }
}
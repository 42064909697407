import React from "react";
import { Container, Row, Col } from "shards-react";

import PageTitle from "../components/common/PageTitle";
import Editor from "../components/add-new-post/Editor";
import SidebarActions from "../components/add-new-post/SidebarActions";
import {AtomSpinner} from 'react-epic-spinners';
import post_data from '../components/add-new-post/PostStore';
import {view} from 'react-easy-state';
import AlertBox from '../components/AlertBox';

export default view(()=>{
    return <AddNewPost isLoading={post_data.isLoading} />
})


const AddNewPost = (props) => (
  <div>
    <AlertBox />
  <Container fluid className="main-content-container px-4 pb-4">
    {/* Page Header */}
    <Row noGutters className="page-header py-4">
      <PageTitle sm="4" title="Add New Post" subtitle="Blog Posts" className="text-sm-left" />
    </Row>
  { 
    (!props.isLoading) && 

    <Row>
      {/* Editor */}
      <Col lg="9" md="12">
        <Editor />
      </Col>

      {/* Sidebar Widgets */}
      <Col lg="3" md="12">
        <SidebarActions />
      </Col>
    </Row>
  }
  {
    (props.isLoading) &&
    <div>
    <AtomSpinner color="blue" style={{alignSelf:'center',marginTop:'10%',marginLeft:'40%'}}/>
    <p style={{alignSelf:'center',marginTop:'2%',marginLeft:'38%'}}>Uploading Post....</p>
    </div>
  }
  </Container>
  </div>
);


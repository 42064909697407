import React from "react";
import { Col, 
		InputGroup,
  		InputGroupAddon,
  		FormSelect ,
  		InputGroupText
	} from "shards-react";
import {  view } from 'react-easy-state';
import group_names from './groupNameStore';
import getArray from './getArray';




export default view((props)=>{     
                                  var groupNames = getArray(group_names.names);

                                  return <SelectGroup group_names={groupNames} 
                                                   	  isLoading={group_names.isLoading}
                                                   	  exists = {group_names.hasData}
                                                   	  onChange = {props.onChange}
                                                  
                                          />
                      });

class SelectGroup extends React.Component{

	componentDidMount(){
			console.log("Running GetGRoupNames");
			group_names.getGroupNames();
	}


  render(){

      var arr  = this.props.group_names;
     

    return (
    
      <InputGroup className="mb-3">
                
     <FormSelect onChange ={this.props.onChange}>
        <option value={null}>{this.props.isLoading?"Loading...":(this.props.exists?"Choose a Group":"No Group List")}</option>
        {

               arr.map((item,i)=>{
                         return (<option key={i}>{item}</option>)
                  })
              
        } 
        

      </FormSelect>
     
      <InputGroupAddon type="prepend">
                    <InputGroupText>Group</InputGroupText>
      </InputGroupAddon>
    </InputGroup>
  )
  }
}


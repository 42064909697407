
import React,{Component} from "react";

import {
  Card,
  CardHeader,
  ListGroup,
  ListGroupItem,
  FormSelect,
  Col,
  Row
} from "shards-react";

import SelectGroup from '../SelectGroup';
import { AtomSpinner } from 'react-epic-spinners';
import {view} from 'react-easy-state';
import formatRupee from '../formatRupee';
import send_data from './SendSMSStore';

export default view(()=>{
    var data = {
        cust_data : send_data.group_customer_data,
        isLoading : send_data.isGroupCustomerLoading,
        hasError : send_data.isGroupCustomerHasError,
        selected : send_data.selected_tickets, // to listen for changes 
    }
    console.log("Selected from view = ",data.selected);
    return <ClassStudent data={data} />
})

 class ClassStudent extends Component{ 

  render(){
      console.log("Selected from render ",this.props.data.selected);
    return(
     <Card small className="mb-4 pt-3">
        <CardHeader className="border-bottom text-center">
        		<h4 className="mb-0">{"Group"}</h4>
        			<Col sm="12" className="d-flex mb-2 mb-sm-0" style={{marginTop: '5px'}}>
              			<Col md="12" className="form-group">
            				<SelectGroup onChange={(evt)=>{send_data.selectGroup(evt.target.value)}}/>
            			</Col>
            		</Col>
   		 </CardHeader>
    <ListGroup flush className={"scroll_list-group"}>
    	
          {   
                this.props.data.cust_data.map((item,i)=>{
                            return (<CustomerItem key={i} 
                                                 data={item}
                                                 SelectTicket = {send_data.selectTicket}
                                                 isSelected = {send_data.isCustSelected(item.ticket_no)} 
                                                />
                                  )
                })
            }

          { 
            ((this.props.data.cust_data.length===0) && !this.props.data.isLoading)&&

            <ListGroupItem className="px-4" style={{marginTop: '10%',marginBottom: '10%'}}>
                <div className="progress-wrapper" >
                    <strong className=" d-block mb-2" style={{textAlign:"center",fontSize: 20,color:'blue'}}>
                          {"No Customers Available"}
                    </strong>
                </div>
            </ListGroupItem>
            

          }


         
          {
            
            (this.props.data.isLoading) &&

            <ListGroupItem className="px-4" style={{marginTop: '10%',marginBottom: '10%',marginLeft: '30%'}}>
                <div className="progress-wrapper" >
                    <strong className=" d-block mb-2" style={{textAlign:"center"}}>
                         <AtomSpinner color="blue" /> 
                    </strong>
                </div>
            </ListGroupItem>


          }


      
    </ListGroup>
  </Card>
      )
  }
}




class CustomerItem extends Component{
  render(){
    return(
      <ListGroupItem className="px-4"  style={{backgroundColor:this.props.isSelected?"#4169e1":"white"}} onClick={()=>{this.props.SelectTicket(this.props.data.ticket_no)}}>
        <div className="progress-wrapper" style={{color:this.props.isSelected?"white":"black"}}>
        <Row>
          <div>
            <strong className=" d-block mb-2">
              {this.props.data.name}
            </strong>
            <p className=" mb-1">
              {this.props.data.group_id+"/"+this.props.data.ticket_no+" "+(this.props.data.priced?"Prized":"Non Prized")+" AC="+(formatRupee(this.props.data.account_balance))}
            </p>
          </div>
          </Row>
        </div>
      </ListGroupItem>

    )
  } 
}
import {store} from 'react-easy-state';
import firebase from '../../firebase';
import alert from '../AlertStore';

const send_data = store({
    isLoading : false,
    group_list : [], //for group sms
    message : undefined,

    selectedGroup : undefined, //for inidvidual sms
    group_customer_data : [],
    isGroupCustomerLoading : false,
    isGroupCustomerHasError : false, 
    selected_tickets : [],


    selectedWindow : 'log',


    selectGroup(group_id){
        send_data.selectedGroup = group_id;
        console.log("group id = ",send_data.selectedGroup)
        if(group_id!==null || group_id!==undefined){
            send_data.getCustomers();
        }
    },

    removeTicket(ticket_no){
        var temp = send_data.selected_tickets;
        for(var i=0;i<temp.length;i++){
            if(temp[i]===ticket_no){
              temp.splice(i, 1); // to remove at index
            }
        }
        console.log("Removed Tickets",ticket_no);
        send_data.selected_tickets = undefined; //required to trigger change in view
        send_data.selected_tickets = temp;
    },

    selectTicket(ticket_no){
        if(send_data.isCustSelected(ticket_no)){
            send_data.removeTicket(ticket_no);
            return;
        }
        var temp = send_data.selected_tickets;
        temp.push(ticket_no);
        send_data.selected_tickets = undefined; //required to trigger events in view
        send_data.selected_tickets  = temp;
        console.log("Selected Cust ",send_data.selected_tickets);
    },

    isCustSelected(ticket_no){
        console.log("Checkign ",ticket_no);
        var selected = send_data.selected_tickets;
        for(var i=0;i<selected.length;i++){
            if(selected[i]===ticket_no){
                console.log("True");
                return true;
            }
        }
        console.log("False");
        return false;
    },


    getCustomers(){
        var group_id = send_data.selectedGroup;
        if(group_id===null || group_id===undefined){
            console.log("Group id is ",group_id);
            return;
        }
        send_data.isGroupCustomerLoading = true;

        return firebase.firestore().collection('GroupCustomer').where('group_id','==',group_id).orderBy('ticket_no','asc').get().then(function(snap){
            var temp  = [];
            snap.forEach(function(doc){
                temp.push(doc.data());
            })
            send_data.selected_tickets = [];
            send_data.group_customer_data = temp;
            send_data.isGroupCustomerLoading = false;
            send_data.isGroupCustomerHasError = false;
        })
        .catch(function(err){
            console.error(err);
            send_data.selected_tickets = [];
            send_data.isGroupCustomerHasError = true;
            send_data.isGroupCustomerLoading = false;
        })
    },

    changeWindow(evt){
        if (evt.target.checked) {
            console.log(evt.target.value);
            send_data.selectedWindow = evt.target.value;
        }
        console.log(evt.target.checked);
    },  

    removeGroup(){
        var temp = [];
        send_data.group_list = [];
        console.log("Selected Group List ",send_data.group_list);
    },
    clearSelected(){
        send_data.group_list = [];
    },
    addGroup(group_id){
        if(send_data.group_list.length>0){
            alert.showMessage("Can send SMS to Only One Group at a Time","warning");
            return false;
        }
        send_data.group_list = [group_id];
        console.log("Selected Group List ",send_data.group_list);
        return true;
    },

    setMessage(msg){
        send_data.message = msg.replace(/<\/?[^>]+(>|$)/g, ""); //clean html

    },
    sendMessage(){

        //redirect to cust tickets message from here
        if(send_data.selectedWindow==='cust'){
            send_data.sendSMSSelectedTickets();
            return;
        }
        console.log("Sending Group Message");
        if(send_data.group_list.length===0){
            alert.showMessage("No Group Selected","danger");
            return;
        }
        if(send_data.message===undefined || send_data.message===''){
            alert.showMessage("No Message","danger");
            return;
        }
        send_data.isLoading = true;
        var func_ref = firebase.functions().httpsCallable('sendCustomSMS');
        func_ref({message:send_data.message,group_list:send_data.group_list}).then(function(result) {
            // Read result of the Cloud Function.
            var rdata = result.data;
            console.log(rdata);                  
            send_data.isLoading = false;
            try{
              if(rdata.status==="success"){
                  alert.showMessage("Message Sent Succesfully","success");;
              }
              else{
                  alert.showMessage(rdata.message,"danger");
              }	
            }
            catch(err){
                 console.error(err);
                  alert.showMessage("Unknown Error Occured","danger");
            }
            
        }).catch(function(error) {
            console.log(error);
            send_data.isLoading = false;
            alert.showMessage(error.message,"danger");
        });
    },

    sendSMSSelectedTickets(){
        console.log("Sending MEssage inidividually");
        //redirect to cust tickets message from here
        if(send_data.selected_window==='group'){
            send_data.sendMessage();
            return;
        }
        if(send_data.selected_tickets.length===0){
            alert.showMessage("No Tickets Selected","danger");
            return;
        }
        if(send_data.message===undefined || send_data.message===''){
            alert.showMessage("No Message","danger");
            return;
        }
        send_data.isLoading = true;
        var func_ref = firebase.functions().httpsCallable('SendSMSSelectedGroupTickets');
        func_ref({message:send_data.message,group_id:send_data.selectedGroup,selected_tickets:send_data.selected_tickets}).then(function(result) {
            // Read result of the Cloud Function.
            var rdata = result.data;
            console.log(rdata);                  
            send_data.isLoading = false;
            try{
              if(rdata.status==="success"){
                  alert.showMessage("Message Request Received Succesfully. Will be delivered Shortly","success");;
              }
              else{
                  alert.showMessage(rdata.message,"danger");
              }	
            }
            catch(err){
                 console.error(err);
                  alert.showMessage("Unknown Error Occured","danger");
            }
            
        }).catch(function(error) {
            console.log(error);
            send_data.isLoading = false;
            alert.showMessage(error.message,"danger");
        });
    }

})


export default send_data;
import React from "react";
import { Container, Row, Col,CardBody,Card} from "shards-react";

import {  view } from 'react-easy-state';
import { AtomSpinner } from 'react-epic-spinners';
import PageTitle from "../common/PageTitle";
import Message from '../Message';

import login_data from '../../loginstore';
import { Redirect } from "react-router-dom";
import AlertBox from '../AlertBox';
import IntroducerDetails from './IntroducerDetails';
import IntroducerCustomer from './IntroducerCustomer';
import int_data from './ViewIntStore';
import IntroducerPayments from './IntroducerPayments';

function getURLData(){
    var url_string = window.location.href;
    var url = new URL(url_string);
    var id = url.searchParams.get("id");
    return id;
  }

export default view(()=>{

    var data = {
        isLoading : int_data.isIntDetLoading,
        hasError  : int_data.isIntDetHasError,
        gotData   : int_data.gotIntDetData,
        int_data  : int_data.introducer_data,
    }

    var url_int_id = getURLData();
    if(url_int_id!==undefined && url_int_id!==null){
        if(data.int_data !== undefined){
            if(data.int_data.int_id!==url_int_id){
                console.log("Calling getIntroducer");
                int_data.setIntroducerId(url_int_id);
                int_data.getIntroducerData();
            }
        }
        else{
            console.log("Calling getIntroducer");
            int_data.setIntroducerId(url_int_id);
            int_data.getIntroducerData();
        }
    }

    console.log("From view ",data);
    return (login_data.isLoggedIn?<ViewIntroducer data={data}/>:<Redirect to="/login" />);
})

class ViewIntroducer extends React.Component{
    render(){
        return(
        <div>
            <AlertBox />
			<Container fluid className="main-content-container px-4">
    			<Row noGutters className="page-header py-4">
      				<PageTitle title="Introducer Profile" subtitle="Overview" md="12" className="ml-sm-auto mr-sm-auto" />
    			</Row>
                {
                ((!this.props.data.isLoading) && (!this.props.data.hasError) && (this.props.data.int_data!==undefined)) &&
                <Row>
                    <IntroducerDetails data={this.props.data.int_data}/>
                    <Col>
                        <IntroducerCustomer />
                        <IntroducerPayments />
                    </Col>
    			</Row>
                }
                
                {

                ((this.props.data.isLoading) || (this.props.data.hasError) || (this.props.data.int_data===undefined)) &&
                 <Row>
                    <Col lg="12" md="12" sm="12">
                     <Card>
                        <CardBody>
                            {
                                (this.props.data.isLoading) &&
                                <AtomSpinner color="blue" style={{margin:'15%'}} />
                            }
                            {
                                (this.props.data.hasError && (!this.props.data.isLoading)) &&
                                <Message message="Error Fetching Data..." icon_name="warning" />
                            }
                            {
                                (this.props.data.int_data===undefined && (!this.props.data.isLoading) && (!this.props.data.hasError) && (this.props.data.gotData))  &&
                                <Message message="Customer Not Found" icon_name="warning" />
                            }
                        </CardBody>
                    </Card>
                    </Col>
                </Row>
                }
            </Container>
        </div>
        )
    }
}
import { store } from 'react-easy-state';
import firebase from '../firebase';
import alert from './AlertStore';


const introducer_list = store({ 
			     list : [],
			     isLoading:false,
			     hasData : false,
                             hasError : false,

                getList(){
                        console.log("Function called");
                        
                        if(introducer_list.hasData){
                            console.log("Has Data");
                            return;
                        }

                        introducer_list.isLoading = true;
                        firebase.firestore().collection('IntList').doc('list').onSnapshot(function(doc) {
                            console.log("Firebase return data");
                            if(doc.exists){
                                    console.log("Introducer List data: ", doc.data());
                                    introducer_list.list = doc.data().list;
                                    introducer_list.isLoading = false;
                                    introducer_list.hasData = true;
                                    console.log(introducer_list);
                            }
                            else{
                                    console.log("No IntList");
                                    introducer_list.hasData = false;
                                    introducer_list.isLoading = false;
                            }
                        },function(err){
                                alert.showMessage(err.message,"danger")
                                introducer_list.hasError = true;
                                introducer_list.hasData = false;
                                console.log(err);
                        });
                }
})







export default introducer_list;

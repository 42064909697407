import { store } from 'react-easy-state';
import firebase from '../firebase';
import alert from './AlertStore';



const dailysummary_data = store({
		isLoading : false,
		hasError : false,
		gotData : false,


		start_date : null,
		end_date : null,

		data : [],

		cash_total : 0,
		cheque_total : 0,
		neft_total : 0,
		cash_deposit_total : 0,
		neft_deposit_total : 0,
		cheque_deposit_total : 0,
		expenditure_total : 0,
		
		

		getDiffDays(date1,date2){

			var oneDay = 24*60*60*1000; // hours*minutes*seconds*milliseconds
			var diff_days = Math.round(Math.abs((date1.getTime() - date2.getTime())/(oneDay)));
			return diff_days;
		},

		getData(){

			/*if( dailysummary_data.getDiffDays(dailysummary_data.start_date,dailysummary_data.end_date) > 90 ){ 
				alert.showMessage("Kindly Enter 90 days limit dates",'warning');
				return;
			}*/
			if(dailysummary_data.start_date > dailysummary_data.end_date){
				alert.showMessage("Kindly Enter Start Date Lesser than End Date",'danger');
				return;
			}

			dailysummary_data.resetValues();
			dailysummary_data.isLoading = true;
			firebase.firestore().collection('DailySummary').where('date','>=',new Date(dailysummary_data.start_date)).where('date','<=',new Date(dailysummary_data.end_date)).orderBy('date','desc').onSnapshot(function(snap) {

					var temp = [];
					snap.forEach(function(doc){
						var data = doc.data();
						dailysummary_data.cash_total = dailysummary_data.cash_total +  data.cash_in;
						dailysummary_data.cheque_total = dailysummary_data.cheque_total +  data.cheque_in;
						dailysummary_data.neft_total = dailysummary_data.neft_total +  data.NEFT_in;
						dailysummary_data.cash_deposit_total = dailysummary_data.cash_deposit_total +  data.cash_deposit;
						dailysummary_data.neft_deposit_total = dailysummary_data.neft_deposit_total +  data.NEFT_deposit;
						dailysummary_data.cheque_deposit_total = dailysummary_data.cheque_deposit_total + data.cheque_deposit + 0;
						dailysummary_data.expenditure_total = dailysummary_data.expenditure_total +  data.expenditure;
		
						temp.push(data);
					})
					console.log(temp);
					dailysummary_data.data = temp;
					dailysummary_data.isLoading = false;
					dailysummary_data.hasError = false;
					dailysummary_data.gotData = true;

			},function(err){
				console.log(err);
				dailysummary_data.isLoading =false;
				dailysummary_data.hasError = true;
				dailysummary_data.gotData = true;
			})

		},

		resetValues(){
			dailysummary_data.cash_total = 0;
			dailysummary_data.cheque_total = 0;
			dailysummary_data.neft_total = 0;
			dailysummary_data.cash_deposit_total = 0;
			dailysummary_data.neft_deposit_total = 0;
			dailysummary_data.cheque_deposit_total = 0;
			dailysummary_data.expenditure_total = 0;
		},


		setStartDate(date){
			dailysummary_data.start_date = new Date(date);

			if(dailysummary_data.end_date!==null){
				dailysummary_data.getData();
			}
		},

		setEndDate(date){
			dailysummary_data.end_date = new Date(date);

			if(dailysummary_data.start_date!==null){
				dailysummary_data.getData();
			}
		}

})


export default dailysummary_data;
import React from "react";
import { Container, Row, Col, Card, CardHeader, CardBody} from "shards-react";

import PageTitle from "../common/PageTitle";
import '../scroll.css';
import { AtomSpinner } from 'react-epic-spinners';
import { view } from 'react-easy-state';
import group_names from '../groupNameStore';
import installment_data from '../InstallmentStore';
import SelectGroup from '../SelectGroup';
import Message from '../Message';
import getArray from '../getArray';
import formatDate from '../formatDate';
import formatRupee from '../formatRupee';
import login_data from '../../loginstore';
import { Redirect } from "react-router-dom";
import AlertBox from '../AlertBox';

function getInstData(group_id){
  console.log(group_id);
  installment_data.getInstData(group_id);
}



export default view(()=>{     
                                var groupNames = getArray(group_names.names);
                                var data = {
                                    data : getArray(installment_data.data),
                                    hasError : installment_data.hasError,
                                    gotData : installment_data.gotData,
                                    isLoading : installment_data.isLoading,
                                }
                                return login_data.isLoggedIn?<Installment   group_names={groupNames} data={data}/>:<Redirect to='/login'/>
                          });


class Installment extends React.Component{
    render(){
      return(
      <div>
        <AlertBox />
            <Container fluid className="main-content-container px-4">
    {/* Page Header */}
    <Row noGutters className="page-header py-4">
      <PageTitle sm="4" title="Installment" subtitle="Overview" className="text-sm-left" />
    </Row>

  <Row>
    <Col sm="12" md="6" lg="4">
      <SelectGroup  onChange = {(evt)=>{getInstData(evt.target.value)}}/>
    </Col>
  </Row>

    {/* Default Light Table */}
    <Table data={this.props.data}/>

  </Container>
      </div>
        )
    }
}




class Table extends React.Component{



  render(){
    return(
    
      <Row>
      <Col>
        <Card small className="mb-4">
          <CardHeader className="border-bottom">
              <h6 className="m-0">Installment</h6>
          </CardHeader>

       
          
          <CardBody className="p-0 pb-3 scroll_list-group">
        {
          (!this.props.data.isLoading && (this.props.data.data.length>0) ) &&
            <table className="table mb-0">
              <thead className="bg-light">
                <tr>
                  <th scope="col" className="border-0">
                    #
                  </th>
                  <th scope="col" className="border-0">
                    Group No.
                  </th>
                  <th scope="col" className="border-0">
                    Inst No.
                  </th>
                  <th scope="col" className="border-0">
                     Subscription Value
                  </th>
                  <th scope="col" className="border-0">
                      Dividend
                  </th>
                  <th scope="col" className="border-0">
                      Other Charges
                  </th>
                  <th scope="col" className="border-0">
                      Payable
                  </th>
                  <th scope="col" className="border-0">
                      Create Date
                  </th>
                  <th scope="col" className="border-0">
                      Due Date
                  </th>
                  <th scope="col" className="border-0">
                      Charges Reason
                  </th>

                  <th scope="col" className="border-0">
                    Apply Charges
                  </th>

                </tr>
              </thead>
              <tbody>
               
                 {
                          this.props.data.data.map((item,i)=>{
                                return (<Trow key={i} data={item} index={i+1}/>)
                          })
                  }
                      
               
              </tbody>
            </table>
          }
            {
            (this.props.data.isLoading) &&
               <AtomSpinner color="blue" style={{alignSelf: 'center',marginLeft: '40%',marginTop: '10%',marginBottom: '10%'}}/>
            }
            {
              (!this.props.data.isLoading && (this.props.data.data.length===0) && (!this.props.data.gotData)  ) &&
              <Message message={"Kindly Select a Group"} icon_name={"call_missed"} />
            }
            {
              (!this.props.data.isLoading && this.props.data.hasError && this.props.data.gotData ) &&
              <Message message={"Error Loading Data"} icon_name={"warning"} />
            }
            {
              ( !this.props.data.isLoading && (this.props.data.data.length===0) && this.props.data.gotData ) &&
              <Message message={"No Data Avaiable"} icon_name={"sentiment_dissatisfied"} />
            }
          </CardBody>
        </Card>
      </Col>
    </Row>
    )
  }
}


class Trow extends React.Component{
  render(){
    var extra_charges = this.props.data.extra_charges===null?0:this.props.data.extra_charges;
    var payable = (this.props.data.installment_val - this.props.data.dividend) + extra_charges;
    var edit = (new Date()>new Date(this.props.data.due_date.toDate()))?"Not Avaiable":(<i class="material-icons">edit</i>);
      
    var apply_charges_link = '/applycharges?group_id='+this.props.data.group_id+"&inst_no="+this.props.data.inst_no+"&inst_id="+this.props.data.inst_id;
    
    var apply_comp = <a href={apply_charges_link} target="_blank" style={{color:'green'}}>{edit}</a>;
    var comp = this.props.data.charges_applied?"Not Available":apply_comp;

    return(
              <tr>
                  <td>{this.props.index}</td>
                  <td>{this.props.data.group_id}</td>
                  <td>{this.props.data.inst_no}</td>
                  <td style={{color:'green'}}>{formatRupee(this.props.data.installment_val)}</td>
                  <td style={{color:'red'}}>{formatRupee(this.props.data.dividend)}</td>
                  <td style={{color:'red'}}>{formatRupee(extra_charges)}</td>
                  <td style={{color:'blue'}}>{formatRupee(payable)}</td>
                  <td>{formatDate(this.props.data.createDate.toDate())}</td>
                  <td>{formatDate(this.props.data.due_date.toDate())}</td>
                  <td>{this.props.data.charges_applied?this.props.data.charges_reason:"NA"}</td>
                  <td style={{color:'green'}}>{comp}</td>
                </tr>

    )
  }
}



import React from "react";
import PropTypes from "prop-types";
import {
  Row,
  Col,
  FormSelect,
  Card,
  CardHeader,
  CardBody,
  CardFooter
} from "shards-react";

import Chart from "../../utils/chart";
import { view } from 'react-easy-state';

import formatRupee from '../formatRupee';
import dashboard_store from '../DashboardStore';
import getArray from '../getArray';
import { AtomSpinner } from 'react-epic-spinners';

function formatChartSummaryData(data){
    var temp = [0,0,0];
    
    if(data.length>0){
      temp[0] = data[0].cash_in;
      temp[1] = data[0].NEFT_in;
      temp[2] = data[0].cheque_in;
    }


   var chartdata ={
              datasets: [
              {
                hoverBorderColor: "#ffffff",
                data: temp,
                backgroundColor: [
                    "rgba(0,123,255,0.9)",
                    "rgba(0,123,255,0.5)",
                    "rgba(0,123,255,0.3)"
                ]
              }
            ],
            labels: ["Cash "+formatRupee(temp[0]), "NEFT "+formatRupee(temp[1]), "Cheque "+formatRupee(temp[2])]
          }

    return chartdata;

}



export default view(()=>{

  var data = {
    isLoading : dashboard_store.isLoading ,
    summary   : formatChartSummaryData(getArray(dashboard_store.todays_daily_summary)),
  }


  return <TodayDailySummary data={data}/>;
})

class TodayDailySummary extends React.Component {
  constructor(props) {
    super(props);

    this.canvasRef = React.createRef();
    this.renderChart = this.renderChart.bind(this);
  }


  componentDidUpdate(prevProps) {
      const oldProps = prevProps;
      const newProps = this.props;
      if(oldProps !== newProps) {
          this.renderChart();
      }
}


  renderChart(){

    const chartConfig = {
      type: "pie",
      data: this.props.data.summary,
      options: {
        ...{
          legend: {
            position: "bottom",
            labels: {
              padding: 25,
              boxWidth: 20
            }
          },
          cutoutPercentage: 0,
          tooltips: {
            custom: false,
            mode: "index",
            position: "nearest"
          }
        },
        ...this.props.chartOptions
      }
    };

    new Chart(this.canvasRef.current, chartConfig);
  }

  render() {


    return (
      <Card small className="h-100">
        <CardHeader className="border-bottom">
          <h6 className="m-0">{"Today's Summary"}</h6>
        </CardHeader>
        <CardBody className="d-flex py-0">
        
         <canvas
            height={"300"}
            ref={this.canvasRef}
            className="blog-users-by-device m-auto"
          />
        
        
        {
          this.props.data.isLoading &&
          <AtomSpinner color="blue" style={{alignSelf: 'center',marginLeft: '40%',marginTop: '10%',marginBottom: '10%'}}/>
        }
        </CardBody>
        
      </Card>
    );
  }
}


/*
TodayDailySummary.defaultProps = {
  title: "Users by device",
  chartData: {
    datasets: [
      {
        hoverBorderColor: "#ffffff",
        data: [40000, 10000, 20000],
        backgroundColor: [
          "rgba(0,123,255,0.9)",
          "rgba(0,123,255,0.5)",
          "rgba(0,123,255,0.3)"
        ]
      }
    ],
    labels: ["Cash 40000", "NEFT 10000", "Cheque 20000"]
  }
};
*/


function is_empty_string(x){

            if(x===null || x===undefined || (typeof x === 'undefined')  ){
              return true;
            }

   					return ( 

        					(x === false)  //same as: !x
                    				||
        					(x.length === 0)
                    				||
        					(x === "")
       								||
        					(x.replace(/\s/g,"") === "")
       								||
        					(!/[^\s]/.test(x))
            						||
        					(/^\s*$/.test(x))
  					);
	}

export default is_empty_string;
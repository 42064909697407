import React from "react";
import { Container,Card,CardHeader,CardBody, Row, Col,FormRadio,Button,InputGroup,FormInput,InputGroupAddon,FormSelect } from "shards-react";

import PageTitle from "../common/PageTitle";
import AlertBox from '../AlertBox';
import SelectGroup from '../SelectGroup';
import AddSurety from './AddSurety';
import {view} from 'react-easy-state';
import {AtomSpinner} from 'react-epic-spinners';
import Message from '../Message';
import login_data from '../../loginstore';
import { Redirect } from "react-router-dom";
import formatDate from '../formatDate';
import surety_data from './suretyStore';
import ViewSurety from './ViewSurety';

export default view(()=>{
    var data = {
      isLoading : surety_data.isLoading,
      data      : surety_data.view_data,
    }
    console.log(data);
    console.log("isLoggedin =",login_data.isLoggedIn);
    return login_data.isLoggedIn?<Surety data={data} />:<Redirect to='/login' />;
})

 class Surety extends React.Component{
    render(){
        return(
            <div>
                <AlertBox />
                <Container fluid className="main-content-container px-4 ">
                    {/* Page Header */}
                    <Row noGutters className="page-header py-4">
                        <PageTitle sm="4" title="Surety" subtitle="Add and Manage Surety" className="text-sm-left" />
                    </Row>

                    <AddSurety />

                    <Row>
                        <Col md="6" lg="4" sm="12" xs="12">
                            <SelectGroup className="m-3"  onChange={(evt)=>{surety_data.setViewGroupID(evt.target.value)}}/>
                        </Col>
                        <SearchBar onChange={(evt)=>{surety_data.setAuctionNo(evt.target.value)}}/>
                    </Row>

                    <Row>
        <Col>
          <Card small className="mb-4">
            <CardHeader className="border-bottom">
                <h6 className="m-0">Surety</h6>
            </CardHeader>
            <CardBody className="p-0 pb-3 scroll_list-group">
          {
            ((!this.props.data.isLoading) && (this.props.data.data.length>0) ) &&
              <table className="table mb-0">
                <thead className="bg-light">
                  <tr>
                    <th scope="col" className="border-0">
                         #
                    </th>
                    <th scope="col" className="border-0">
                        Auction No.
                    </th>
                    <th scope="col" className="border-0">
                        Surety No.
                    </th>
                    <th scope="col" className="border-0">
                        Name
                    </th>
                    <th scope="col" className="border-0">
                        DOB
                    </th>
                    <th scope="col" className="border-0">
                        Phone No.
                    </th>
                    <th scope="col" className="border-0">
                       View
                    </th>
                    <th scope="col" className="border-0">
                       Delete
                    </th>
                  </tr>
                </thead>
                <tbody>
                     {
                            this.props.data.data.map((item,i)=>{
                                    return (<TRow key={i} data={item} index={i+1}/>)
                            })
                     }  
                    
                </tbody>
              </table>
            }
              {
              (this.props.data.isLoading) &&
                 <AtomSpinner color="blue" style={{alignSelf: 'center',marginLeft: '40%',marginTop: '10%',marginBottom: '10%'}}/>
              }
              {
                ( !this.props.data.isLoading && (this.props.data.data.length===0) ) &&
                <Message message={"No Data Avaiable"} icon_name={"warning"} />
              }
            </CardBody>
          </Card>
        </Col>
      </Row>
                </Container>
            </div>

        )
    }
}



function TRow(props){
  return(
    <tr>
      <td>{props.index}</td>
      <td>{props.data.auct_no}</td>
      <td>{props.data.surety_no}</td>
      <td>{props.data.name}</td>
      <td>{formatDate(props.data.dob.toDate())}</td>
      <td>{props.data.phone}</td>
      <td><ViewSurety data={props.data} /></td>
      <td><DeleteSurety data_id={props.data.id} /></td>
    </tr>
  )
}


function DeleteSurety(props){
  return(
    <Button theme="danger" onClick={()=>{surety_data.delete(props.data_id)}}>Delete</Button> 
  )
}
class SearchBar extends React.Component{



    render(){
      return(
        <Col sm="12" md="6" lg="4">
          <InputGroup className="mb-3">
                      <FormInput placeholder="Search Surety" onChange={this.props.onChange}/>
                    <InputGroupAddon type="prepend">
  
                      <FormSelect >
                            <option value="auct_no">Auction No.</option>
                        </FormSelect>
                    </InputGroupAddon>
                </InputGroup>
        </Col>
      )
    }
  }
  
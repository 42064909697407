import React from "react";
import { Container, Row, Col,Button,InputGroup,
  InputGroupAddon,
  InputGroupText,
  FormSelect} from "shards-react";

import PageTitle from "../common/PageTitle";
import '../scroll.css';
import { view } from 'react-easy-state';
import SelectGroup from '../SelectGroup';
import CsvDownloader from 'react-csv-downloader';
import due_data from '../DueManagementStore';
import DueTable from './DueTable';
import SelectCollectionAsst from '../SelectCollectionAsst';
import login_data from '../../loginstore';
import { Redirect } from "react-router-dom";
import AlertBox from '../AlertBox';

function setGroup(group){
  due_data.setGroupId(group);
}

function setfilterType(type){
  due_data.setThreshold(type);
}

function filterCA(ca_id){
  ca_id = (ca_id==="Choose Collection Asst"?null:ca_id);
  due_data.filterCollectionAsst(ca_id);
}

function getInstIdsString(inst_data){
   var inst_ids = [];
    inst_data.forEach(function(data){
        if(data.paid===false){
          inst_ids.push(data.name.split('_')[2]);
        }
    })
    return inst_ids.toString();

}

export default view(()=>{
  var data = due_data.data;

  return login_data.isLoggedIn?<Customer data={data} />:<Redirect to='/login' />
})

class Customer extends React.Component{
    render(){
      return(
    <div>
      <AlertBox />
  <Container fluid className="main-content-container px-4">
    {/* Page Header */}
    <Row noGutters className="page-header py-4">
      <PageTitle sm="4" title="Due Management" subtitle="Overview" className="text-sm-left" />
    </Row>

    <Row>
      <Col md="4" sm="6" lg="4">
          <SelectGroup onChange={(evt)=>{setGroup(evt.target.value)}}/>
      </Col>
      <InstFilter onChange={(evt)=>{setfilterType(evt.target.value)}}/>
      <Col lg="3">
        <SelectCollectionAsst onChange={(evt)=>{filterCA(evt.target.value)}} />
      </Col>
      <Col md="2">
          <CsvDownloader datas={this.props.data} filename="duefile.csv" md="4"> 
            <DownloadButton />
          </CsvDownloader>
      </Col> 
  </Row>

    {/* Default Light Table */}
    <DueTable />

  </Container>
</div>
        )
    }
}


class DownloadButton extends React.Component{
  render(){
    return(
      <Col sm="12" md="6" lg="4">
        <Button theme="accent" size="md" > Download  <i className="material-icons">cloud_download</i></Button>
      </Col>
    )
  }
}


class InstFilter extends React.Component{
  render(){
    return (
    <Col sm="12" md="6" lg="3">
      <InputGroup className="mb-3">
                
      <FormSelect {...this.props}>
        <option>{"Equal to 1"}</option>
        <option>{"Equal to 2"}</option>
        <option>{"Equal to 3"}</option>
        <option>{"Equal to 4"}</option>
        <option>{"More than 5"}</option>
        <option>{"All Paid"}</option>
      </FormSelect>

      <InputGroupAddon type="prepend">
                    <InputGroupText>Filter</InputGroupText>
      </InputGroupAddon>
    </InputGroup>
  </Col>
  )
  }
}


class TypeSelector extends React.Component{
  render(){
    return(
        <Col sm="12" md="6" lg="4">
            <InputGroup className="mb-3">
                
                <FormSelect>
                       <option>View All Customers</option>
                       <option>View by Group</option>
                  </FormSelect>

            <InputGroupAddon type="prepend">
                    <InputGroupText>View Type</InputGroupText>
            </InputGroupAddon>
         </InputGroup>
        </Col>
    )
  }
}



import React from "react";
import { Container, Row, Col,FormRadio } from "shards-react";

import PageTitle from "../common/PageTitle";
import Editor from "./Editor";
import Send from "./Send";
import GroupNames from "./GroupNames";
import {view} from 'react-easy-state';
import { Redirect } from "react-router-dom";
import login_data from '../../loginstore';
import AlertBox from '../AlertBox';
import sms_data from './SendSMSStore';
import CustomerList from './CustomerList';
import SMSLogTable from './SMSLogTable';
import {AtomSpinner} from 'react-epic-spinners';

export default view(()=>{
  var data = {
    currentWindow : sms_data.selectedWindow,
    isLoading : sms_data.isLoading
  }
  return login_data.isLoggedIn?<SMS data={data}/>:<Redirect to='/login' />;
  //return <SMS data={data} />
})


class SMS extends React.Component{
  componentWillUnmount(){
    sms_data.clearSelected();
    sms_data.selectedWindow = "log";
  }
 render(){
   return(

   
<div>
  <AlertBox />
  <Container fluid className="main-content-container px-4 pb-4">
    {/* Page Header */}
    <Row noGutters className="page-header py-4">
      <PageTitle sm="4" title="SMS" subtitle="Send SMS to Customers" className="text-sm-left" />
    </Row>

    {

    (!this.props.data.isLoading) &&

    <Row>
        <Col md="4" sm="12" xs="12" lg="4">
          <label>
          <input type="radio" value="group"
                    name="window"
                    onChange={sms_data.changeWindow}
                    >
                    </input>Group SMS
                    </label>
        </Col>
        <Col md="4" sm="12" xs="12" lg="4">
          <label>
          <input type="radio" value="cust" 
                     name="window"
                      onChange={sms_data.changeWindow}>
                        </input>Selected Customer SMS
            </label>
        </Col>
        <Col md="4" sm="12" xs="12" lg="4">
          <label>
          <input type="radio" value="log" 
                    name="window"
                    defaultChecked
                     onChange={sms_data.changeWindow}></input>SMS Log
            </label>
        </Col>
    </Row>
 }

    <br />

      {
        (this.props.data.currentWindow==='group' && (!this.props.data.isLoading)) &&
        <GroupSMS />
      }
      {
        (this.props.data.currentWindow==="cust" && (!this.props.data.isLoading) ) &&
        <CustomerSMS />
      }
      {
        (this.props.data.currentWindow==="log" && (!this.props.data.isLoading) ) &&
        <SMSLogTable />
      }
      {
        (this.props.data.isLoading) &&
        <Loading />
      }
  </Container>
</div>

  )
 }
}


function Loading(){
  return(
    <Row>
      {/* Editor */}
      <Col lg="5" md="12">
      </Col>

      {/* Sidebar Widgets */}
      <Col lg="3" md="12" style={{marginTop:'10%',marginBottom:'15%'}}>
        <AtomSpinner color="blue" />
      </Col>
    </Row>
  )
}

function GroupSMS(){
  return(
    <Row>
      {/* Editor */}
      <Col lg="9" md="12">
      	<Editor />
      </Col>

      {/* Sidebar Widgets */}
      <Col lg="3" md="12">
        <GroupNames />
        <Send />
      </Col>
    </Row>
  )
}


function CustomerSMS(){
  return(
    <Row>
    <Col lg="4" md="12">
      <CustomerList />
    </Col>

    {/* Editor */}
    <Col lg="5" md="12">
      <Editor />
    </Col>

    {/* Sidebar Widgets */}
    <Col lg="3" md="12">
      <Send />
    </Col>
  </Row>
  )
}
import { store } from 'react-easy-state';
import firebase from '../firebase';
import alert from './AlertStore';


const group_data = store({
	data 		: [],
	isLoading 	: false,
	hasData 	: false,
	hasError 	: false,

	getData(){
		if(group_data.hasData && !(group_data.hasError) ){
			return;
		}

		group_data.isLoading = true;
		firebase.firestore().collection('Group').orderBy('start_date','desc').get().then(function(snap){
				var temp = [];
				snap.forEach(function(doc){
					temp.push(doc.data());
				})

				group_data.data 	 = temp;
				group_data.isLoading =  false;
				group_data.hasData   =  true;
				group_data.hasError  =  false;

		}).catch(function(err){
			alert.showMessage(err.message,'danger');
			console.log(err);
			group_data.isLoading =  false;
			group_data.hasData   =  false;
			group_data.hasError  =  true;
		})	
	},


	setGroupData(data){
		group_data.data = data;
		group_data.hasData = true;
	}

})

export default group_data;
import React from "react";
import { Row, Col,Card,CardHeader ,ListGroup,ListGroupItem,Button,Progress } from "shards-react";
import PayIntroducer from './PayIntroducer';


export default class IntroducerDetails extends React.Component{
    render(){
        return(
              
                        <Col lg="4">
                            <IntProfile data={this.props.data}/>
                            <ContactDetails data={this.props.data}/>
                          
                        </Col>
                    )
               
        
    }
}


class ContactDetails extends React.Component{
	render(){
    console.log("=============================");
    console.log("Customer Details");
    console.log(this.props.data);
     console.log("=============================");
		return(
			<Card small className="mb-4 pt-3">
				 <CardHeader className="border-bottom text-center">
     				<h6 className="m-0">{"Contact Details"}</h6>
     		    </CardHeader>
     		    <ListGroup flush>
     				 
      	<ListGroupItem className="p-4">
      <span>			
        <FieldValue field={"Phone"} value={this.props.data.phone}/>
        <FieldValue field={"Email"} value={this.props.data.email} />
        <FieldValue field={"Address"} value={this.props.data.address} />
     
	 </span>  
      </ListGroupItem>
    </ListGroup>
			</Card>
		)
	}
}

const no_image = require('../../assets/profile_image.png');

class IntProfile extends React.Component{
    render(){
      return(
    
    <Card small className="mb-4 pt-3">
      <CardHeader className="border-bottom text-center">
        <div className="mb-3 mx-auto">
          <img
            className="rounded-circle"
            src={this.props.data.photo_url===null?no_image:this.props.data.photo_url}
            alt={"User"}
            width="110"
            height= '100'
          />
        </div>
        <h4 className="mb-0">{this.props.data.name}</h4>
        <span className="text-muted d-block mb-2">{this.props.data.int_id}</span>
        <PayIntroducer />
      </CardHeader>
      <ListGroup flush>
        <ListGroupItem className="p-4">
          <span>  
          <FieldValue field={"Father/Husband"} value={this.props.data.father_husband_name}/>
          <FieldValue field={"DOB"} value={this.props.data.dob.toDate().toLocaleDateString()} />
          <FieldValue field={"Bank Accnt No."} value={this.props.data.bank_accnt_no} />
          <FieldValue field={"IFSC Code"} value={this.props.data.ifsc_code} />
          <FieldValue field={"Aadhar No."} value={this.props.data.aadhar} />
          <Files field={"Photo URL"} value={this.props.data.photo_url} />
          <Files field={"Aadhar URL"} value={this.props.data.aadhar_url} />
          <Files field={"Bank URL"} value={this.props.data.bank_accnt_url} />
          <Files field={"PAN URL"} value={this.props.data.pan_url} />
          <Files field={"Signature URL"} value={this.props.data.signature_url} />
  
    </span>
        </ListGroupItem>
      </ListGroup>
    </Card>
      )
    }
  }
  
  
  class Files extends React.Component{
    render(){
      return(
      
            <Row style={{marginTop: '3%'}}>
              <Col>{this.props.field}</Col>
              <Col style={{fontWeight:'bold'}}><a href={this.props.value} target="_blank">Link</a></Col>
            </Row>
      )
    }
  }
  
  
  
  class FieldValue extends React.Component{
    render(){
      return(
      
            <Row style={{marginTop: '3%'}}>
              <Col>{this.props.field}</Col>
              <Col style={{fontWeight:'bold'}}>{this.props.value}</Col>
            </Row>
      )
    }
  }
  
import React from "react";
import { Container, Row, Col,Button } from "shards-react";

import PageTitle from "../common/PageTitle";
import '../scroll.css';
import RangeDatePicker from "./RangeDatePicker";
import AlertBox from '../AlertBox';
import SelectGroup from '../SelectGroup';
import ReplacedTicketTable from './ReplacedTicketTable';
import replaced_data from './ticketStore';


export default class ProfitAnalysis extends React.Component{
    render(){
      return(
      <div>
        <AlertBox />
            <Container fluid className="main-content-container px-4">
            
            {/* Page Header */}
            <Row noGutters className="page-header py-4">
                  <PageTitle sm="4" title="Chit Transfer" subtitle="History of Chits Transfered and Substituted" className="text-sm-left" />
            </Row>



          <Row>
            <Col sm="4" style={{marginTop: '1%',marginBottom:'1%'}}>
                <SelectGroup onChange={(evt)=>replaced_data.setGroupID(evt.target.value)} className="mb-1"/>
            </Col>
          </Row>


          {/* Default Light Table */}
          <ReplacedTicketTable />

      </Container>
    </div>
        )
    }
}





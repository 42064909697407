import {store} from 'react-easy-state';
import firebase from '../../firebase';
import alert from '../AlertStore';
import is_empty_string from '../isEmptyString';



const voucher_data = store({
    isLoading : false,

	start_date : undefined,
	end_date  : undefined,

	viewData : [],
	data : [],
	gotData : false,
	hasError : false,
	no_of_data : 0,

	//search
    searchField : 'id', //initial -> VoucherID

    //sort
    sortField : 'date',
    sortType : 'desc',
    
    //data for adding voucher
    group_id : undefined,
    ticket_no : undefined,
    amount : 0,
    isDebit : true,
    comments : undefined,



	getDiffDays(date1,date2){

			var oneDay = 24*60*60*1000; // hours*minutes*seconds*milliseconds
			var diff_days = Math.round(Math.abs((date1.getTime() - date2.getTime())/(oneDay)));
			return diff_days;
    },

    setStartDate(date){
        voucher_data.start_date = new Date(date);
        if(voucher_data.end_date!==undefined){
            voucher_data.getVoucherData();
        }
    }, 
    setEndDate(date){
        voucher_data.end_date = new Date(date);
        if(voucher_data.start_date!==undefined){
            voucher_data.getVoucherData();
        }
    },

    


    setGroupID(group_id){
        if(is_empty_string(group_id)){
            alert.showMessage("Please Select a Valid Group","warning");
            return;
        }
        voucher_data.group_id = group_id;
    },
    setTicketNo(ticket_no){
        if(isNaN(ticket_no)){
            alert.showMessage("Please Enter a Valid Ticket No.","warning");
            return;
        }
        voucher_data.ticket_no = ticket_no;
    },

    setAmount(amount){
        if(isNaN(amount) || parseInt(amount)<0){
            alert.showMessage("Please Enter a Valid Amount","warning");
            return;
        }
        voucher_data.amount = parseInt(amount);
    },

    setIsDebit(value){
        if(value==="debit"){
            voucher_data.isDebit = true;
        }
        else{
            voucher_data.isDebit = false;
        }
    },
    setComments(comment){
        voucher_data.comments = comment;
    },
    
    addVoucher(){
        if(is_empty_string(voucher_data.group_id) || is_empty_string(voucher_data.ticket_no)){
            alert.showMessage("Kindly Enter All Details","warning");
            return;
        }
        if(isNaN(voucher_data.amount) || voucher_data.amount<0){
            alert.showMessage("Invalid Amount","danger");
            return;
        }
        voucher_data.isLoading = true;

        var func_ref = firebase.functions().httpsCallable('addGeneralVoucher');
        
        const data = {
            group_id : voucher_data.group_id,
            ticket_no : voucher_data.ticket_no,
            amount : voucher_data.amount,
            isDebit : voucher_data.isDebit,
            comments : voucher_data.comments,
        }

	    func_ref(data).then(function(result) {
                  // Read result of the Cloud Function.
                  var rdata = result.data;
                  console.log(rdata); 

				  try{
					if(rdata.status==="success"){
						alert.showMessage("Voucher Added Succesfully","success");	
					}
					else{
						alert.showMessage(rdata.message,"danger");
					}	
				  }
				  catch(err){
						alert.showMessage("Unknown Error Occured.Kindly View this Profile Before Proceeding further","danger");
                  }
                  
                  voucher_data.isLoading = false;
                  voucher_data.clearData();
				  
        }).catch(function(error) {
                  // Getting the Error details.
                  //var code = error.code;
                  //var message = error.message;
                  //var details = error.details;
                  // ...
                  console.log(error);
				  voucher_data.isLoading = false;
				  alert.showMessage(error.message,"danger");
        });
    },
    clearData(){
        voucher_data.group_id = undefined;
        voucher_data.ticket_no = undefined;
        voucher_data.amount = 0;
        voucher_data.isDebit = true;
        voucher_data.comments = undefined;
    },

    getVoucherData(){
        if(voucher_data.start_date===undefined || voucher_data.end_date===undefined ){
            alert.showMessage("Kindly Select start and end Date","warning");
            return;
        }
        var start_date = new Date(voucher_data.start_date);
        var end_date  = new Date(voucher_data.end_date);
        
        if( end_date < start_date ){
            alert.showMessage("Kindly Select End Date Greater than start Date","danger");
            return;
        }
        
        /*if( voucher_data.getDiffDays(start_date,end_date) > 90 ){
            alert.showMessage("Only 90Days time gap is allowed","danger");
            return;
        }*/

        voucher_data.isLoading = true;
        voucher_data.no_of_data =0;
        firebase.firestore().collection('GeneralVoucher').where('date','>=',start_date).where('date','<=',end_date).orderBy('date','desc').onSnapshot(function(snap){

                var temp = [];
                snap.forEach(function(doc){
                    temp.push(doc.data());
                })
                voucher_data.no_of_data = snap.size;
                voucher_data.data = temp;
                voucher_data.viewData = temp;
                voucher_data.isLoading = false;
                voucher_data.gotData = true;
                voucher_data.hasError = false;

        },function(err){
                console.error(err);

                voucher_data.no_of_data = 0;
                voucher_data.isLoading = false;
                voucher_data.gotData = true;
                voucher_data.hasError = true;
                voucher_data.data = [];
                voucher_data.viewData = [];
        })
    },

    /*Search functions */

    setSearchField(field_name){
        voucher_data.searchField = field_name;
    },  
    
    searchData(key){
				
        if(is_empty_string(key)){
            voucher_data.viewData = voucher_data.data;
            return;
        }
        var temp = [];
        voucher_data.data.forEach(function(receipt){
            if(!is_empty_string(receipt[voucher_data.searchField].toString())){
                
                if(receipt[voucher_data.searchField].toString().includes(key)){
                        temp.push(receipt);
                }
            }
                
        })
        temp.sort(voucher_data.compare);
        voucher_data.viewData = temp;
    },
    compare( a, b ) {
          var searchkey = voucher_data.searchField;
          if ( a[searchkey] < b[searchkey] ){
                        return -1;
          }
          if ( a[searchkey] > b[searchkey] ){
                            return 1;
          }
          return 0;
    },

    /*sort functions */
    sort_data(){
        var temp = voucher_data.viewData;
        temp.sort(voucher_data.sort_compare);
        voucher_data.viewData = temp;
    },

    sort_compare( a, b ) {
        var sortKey = voucher_data.sortField;
        var sortType = voucher_data.sortType;
          if ( a[sortKey] < b[sortKey] ){
              if(sortType === "asc"){
                return -1;
            }
            else{
                return 1;
            }	
          }
          if ( a[sortKey] > b[sortKey] ){
              if(sortType === "asc"){
                return 1;
              }
              else{
                  return -1;
              }
          }
          return 0;
     },
     setSortField(field){
        voucher_data.sortField = field;
        voucher_data.sort_data();
    },

    setSortType(type){
        voucher_data.sortType = type;
        voucher_data.sort_data();
    },

})

export default voucher_data;
import React from "react";
import {Card,CardHeader ,CardBody,Button } from "shards-react";
import Message from '../Message';
import {  view } from 'react-easy-state';
import getArray from '../getArray';
import formatDate from '../formatDate';
import formatRupee from '../formatRupee';
import { AtomSpinner } from 'react-epic-spinners';
import int_data from './ViewIntStore';

export default view(()=>{
   var data = {
     isLoading : int_data.isPaymentLoading,
     hasError  : int_data.isPaymentHasError,
     gotData   : int_data.isPaymentHasData,
     payment_data : getArray(int_data.payment_data),
   }

   return <IntPayments data={data} />
})

 class IntPayments extends React.Component{
    render(){
      return(
         <Card small className="mb-4 pt-3">
            <CardHeader className="border-bottom">
                <h6 className="m-0">Introducer Payments</h6>
            </CardHeader>
  
            <CardBody className="p-0 pb-3 scroll_list-group" >
             
            {(!this.props.data.isLoading && this.props.data.payment_data.length>0) &&
              <table className="table mb-0">
                <thead className="bg-light">
                  <tr>
                    <th scope="col" className="border-0">
                      #
                    </th>
                    <th scope="col" className="border-0">
                      Date
                    </th>
                    <th scope="col" className="border-0">
                      Amount
                    </th>
                    <th scope="col" className="border-0">
                      Payment Method
                    </th>
                    <th scope="col" className="border-0">
                      Comments
                    </th>
                    <th scope="col" className="border-0">
                      Delete
                    </th>
                  </tr>
                </thead>
                <tbody>
                        { 
                            this.props.data.payment_data.map((item,i)=>{
                                  return (<Trow key={i} data={item} index={i+1}/>)
                            })
                         }
                 
                 </tbody>
               </table>
             }
                      {
                          this.props.data.isLoading &&
                          <AtomSpinner color="blue" style={{alignSelf: 'center',marginLeft: '40%',marginTop: '5%',marginBottom: '5%'}}/>
                      }
                        {
                          (!this.props.data.isLoading && this.props.data.hasError) &&
                          <Message message={"Error Loading Data"} icon_name={"warning"} /> 
                        }
                        {
                          ( (!this.props.data.isLoading) && (this.props.data.payment_data.length===0) ) &&
                            <Message message={"No Payments Yet..."} icon_name={"payment"} />
                        }
              </CardBody>
            </Card>
  
      )
    }
  }
  
  
  class Trow extends React.Component{
    render(){  
      return(
             <tr>
                  <td>{this.props.index}</td>
                  <td>{formatDate(this.props.data.date.toDate())}</td>
                  <td>{formatRupee(this.props.data.amount)}</td>
                  <td>{this.props.data.payment_method}</td>
                  <td>{this.props.data.comment}</td> 
                  <td><Button theme="danger" onClick={()=>int_data.deletePayment(this.props.data.id)}>Delete</Button></td> 
            </tr>
  
      )
    }
  }
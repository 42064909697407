import React from "react";
import {  Modal, ModalBody, ModalHeader,Col,Button,FormSelect} from "shards-react";
import Input from '../common/Input';
import voucher_data from './VoucherStore';
import SelectGroup from '../SelectGroup';



export default class AddVoucher extends React.Component{
       constructor(props) {
            super(props);
            this.state = { open: false };
            this.toggle = this.toggle.bind(this);
        }

    
      toggle() {
        this.setState({
          open: !this.state.open
        });
      }
    
      render() {
        const { open } = this.state;
        return (
          <div>

            <Modal open={open} toggle={this.toggle}>
              <ModalHeader>
                    <b>Add Voucher</b>     
            </ModalHeader>
              <ModalBody>
                <ChooseGroup ize="8" onChange={(evt)=>{voucher_data.setGroupID(evt.target.value)}}  />
                <Input size="8" name="Ticket No." type="number" onChange={(evt)=>{voucher_data.setTicketNo(evt.target.value)}} />
                <Input size="8" name="Amount" type="number" onChange={(evt)=>{voucher_data.setAmount(evt.target.value)}} />
                <Input size="8" name="Comments" type="text" onChange={(evt)=>{voucher_data.setComments(evt.target.value)}} />
                <SelectType size="8" onChange={(evt)=>{voucher_data.setIsDebit(evt.target.value)}} />
                <Col size="8" className={"pt-3"}><Button theme="primary" onClick={()=>{this.toggle();voucher_data.addVoucher();}}>Add Voucher</Button></Col>
              </ModalBody>
            </Modal>
            <Button theme="primary" onClick={()=>{this.toggle()}}>Add Voucher</Button>
          </div>
        );
      }
}


function ChooseGroup(props){
    return(
        <Col md={props.size} className="form-group">
            <label >Group ID</label>
            <SelectGroup onChange={props.onChange} />
        </Col>
    )
}
function SelectType(props){
    return(
        <Col md={props.size} className="form-group">
            <label >Voucher Type</label>
            <FormSelect onChange ={props.onChange}>
                <option value="debit">Debit</option>
                <option value="credit">Credit</option>
            </FormSelect>
    </Col>
    )
}


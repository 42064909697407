import { store } from 'react-easy-state';
import firebase from '../firebase';
import formatDate from './formatDate';
import alert from './AlertStore';



const deposit_data = store({
		isLoading : false,
		hasError : false,
		gotData : false,


		start_date : null,
		end_date : null,

		data : [],
		viewData : [],

		sortkey  : 'all',

		filterData(){
			if(deposit_data.sortkey==="all"){
				deposit_data.viewData = deposit_data.data;
				return;
			}
			var temp = [];
			//Get only type which is of sort key
			deposit_data.data.forEach(function(deposit){
				if(deposit.type===deposit_data.sortkey){
					temp.push(deposit);
				}
			})
			deposit_data.viewData = temp;

		},

		getDiffDays(date1,date2){

			var oneDay = 24*60*60*1000; // hours*minutes*seconds*milliseconds
			var diff_days = Math.round(Math.abs((date1.getTime() - date2.getTime())/(oneDay)));
			return diff_days;
		},

		getData(){

			/*if( deposit_data.getDiffDays(deposit_data.start_date,deposit_data.end_date) > 90 ){ 
				alert.showMessage("Kindly Enter 90 days limit dates",'danger');
				return;
			}*/
			if(deposit_data.start_date > deposit_data.end_date){
				alert.showMessage("Kindly Enter Start Date Lesser than End Date","danger");
				return;
			}

			deposit_data.isLoading = true;
			firebase.firestore().collection('Deposit').where('deposit_date','>=',new Date(deposit_data.start_date)).where('deposit_date','<=',new Date(deposit_data.end_date)).orderBy('deposit_date','desc').onSnapshot(function(snap) {

					var temp = [];
					snap.forEach(function(doc){
						var data = doc.data();
						data.deposit_date = formatDate(data.deposit_date.toDate())
						temp.push(data);
					})

					console.log(temp);
					deposit_data.data = temp;
					deposit_data.viewData = temp;
					deposit_data.filterData();
					deposit_data.isLoading = false;
					deposit_data.hasError = false;
					deposit_data.gotData = true;

			},function(err){
				console.log(err);
				deposit_data.isLoading =false;
				deposit_data.hasError = true;
				deposit_data.gotData = true;
			})

		},

		setStartDate(date){
			deposit_data.start_date = new Date(date);

			if(deposit_data.end_date!==null){
				deposit_data.getData();
			}
		},

		setEndDate(date){
			deposit_data.end_date = new Date(date);

			if(deposit_data.start_date!==null){
				deposit_data.getData();
			}
		}

})


export default deposit_data;
import React from "react";
import { Container, Row, Col, Card, CardHeader, CardBody,Button } from "shards-react";

import PageTitle from "../common/PageTitle";
import '../scroll.css';
import { AtomSpinner } from 'react-epic-spinners';
import Message from '../Message';
import { view } from 'react-easy-state';
import group_data from '../groupStore';
import getArray from '../getArray';
import formatDate from '../formatDate';
import formatRupee from '../formatRupee';
import login_data from '../../loginstore';
import { Redirect } from "react-router-dom";
import ViewGroup from './ViewGroup';
import AlertBox from '../AlertBox';

export default view(()=>{
    var data = {
        isLoading : group_data.isLoading,
        hasData   : group_data.hasData,
        hasError  : group_data.hasError,  
        data      : getArray(group_data.data)
    };

    return login_data.isLoggedIn?<Group data={data} />:<Redirect to='/login' />;
})

class Group extends React.Component{

  componentDidMount(){
      group_data.getData();
  }

    render(){
      return(
      <div>
        <AlertBox />
            <Container fluid className="main-content-container px-4">
    {/* Page Header */}
    <Row noGutters className="page-header py-4">
      <PageTitle sm="4" title="Group" subtitle="Overview" className="text-sm-left" />
    </Row>

 

    {/* Default Light Table */}
    <Table data={this.props.data} />

  </Container>
</div>
        )
    }
}





class Table extends React.Component{
  render(){
    return(
    
      <Row>
      <Col>
        <Card small className="mb-4">
          <CardHeader className="border-bottom">
              <h6 className="m-0">Groups</h6>
          </CardHeader>

       
          
          <CardBody className="p-0 pb-3 scroll_list-group" >
        {

          (!this.props.data.isLoading && (this.props.data.data.length>0) ) &&
            <table className="table mb-0">
              <thead className="bg-light">
                <tr>
                  <th scope="col" className="border-0">
                    #
                  </th>
                  <th scope="col" className="border-0">
                    Group
                  </th>
                  <th scope="col" className="border-0">
                    Chit Value
                  </th>
                  <th scope="col" className="border-0">
                     Installment Value
                  </th>
                  <th scope="col" className="border-0">
                     Months
                  </th>
                  <th scope="col" className="border-0">
                      Start Date
                  </th>

                  <th scope="col" className="border-0">
                      End Date
                  </th>
                  <th scope="col" className="border-0">
                      Status
                  </th>
                  <th scope="col" className="border-0">
                    View
                  </th>
                  <th scope="col" className="border-0">
                    Edit
                  </th>

                </tr>
              </thead>
              <tbody>

                {
                          this.props.data.data.map((item,i)=>{
                                return (<Trow key={i} data={item} index={i+1}/>)
                          })
                  
                  }
              
               
              </tbody>
            </table>
          }

            
        

          {
              (this.props.data.isLoading) &&
               <AtomSpinner color="blue" style={{alignSelf: 'center',marginLeft: '40%',marginTop: '10%',marginBottom: '10%'}}/>
          }
        
          {
              (!this.props.data.isLoading && this.props.data.hasError ) &&
              <Message message={"Error Loading Data"} icon_name={"warning"} />
          }
          {
              ( !this.props.data.isLoading && (this.props.data.data.length===0) ) &&
             <Message message={"No Groups Added"} icon_name={"airline_seat_flat"} />
          }


          </CardBody>
        </Card>
      </Col>
    </Row>
    )
  }
}



class Trow extends React.Component{
  render(){

    var modify_link = '/modifygroup?group_id='+this.props.data.group_id;

    return(
                <tr style={{color: (this.props.data.status==="active"?"black":'red')}}>
                  <td>{this.props.index}</td>
                  <td>{this.props.data.group_id}</td>
                  <td>{formatRupee(this.props.data.chitValue)}</td>
                  <td>{formatRupee(this.props.data.installment_value)}</td>
                  <td>{this.props.data.no_of_months}</td>
                  <td>{formatDate(this.props.data.start_date.toDate())}</td>
                  <td>{formatDate(this.props.data.end_date.toDate())}</td>
                  <td>{this.props.data.status}</td> 
                  <td><ViewGroup data={[this.props.data]}/></td>
                  <td style={{color:'green'}}><a href={modify_link} style={{color:'green'}} target="_blank"><i class="material-icons">edit</i></a></td>
                </tr>
    )
  }
}

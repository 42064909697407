import React from "react";
import { Container, Row, Col} from "shards-react";

import PageTitle from "../common/PageTitle";
import '../scroll.css';

import RangeDatePicker from "./RangeDatePicker";
import SummaryTable from './SummaryTable';
import AlertBox from '../AlertBox';



export default class DailySummary extends React.Component{
    render(){
      return(
      <div>
        <AlertBox />
            <Container fluid className="main-content-container px-4">
    {/* Page Header */}
    <Row noGutters className="page-header py-4">
      <PageTitle sm="4" title="Summary" subtitle="Daily" className="text-sm-left" />
    </Row>

  <Row>
    <Col sm="4">
              <RangeDatePicker className="pb-3" />
     </Col>
  </Row>

    {/* Default Light Table */}
    <SummaryTable />

  </Container>
</div>
        )
    }
}






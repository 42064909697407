import {store} from 'react-easy-state';
import firebase from '../../firebase';
import alert from '../AlertStore';

const report_data = store({
    isLoading : false,
    
    sendReport(data){
        report_data.isLoading = true;
        if(data.title===null || data.desc===null){
            alert.showMessage("Kindly Enter All Details","warning");
            return;
        }
        firebase.firestore().collection('Reports').add({date:new Date(),title:data.title,desc:data.desc}).then(function(){
            report_data.isLoading = false;
            alert.showMessage('Report Sent Successfully',"success");
        }).catch(function(err){
            console.error(err);
            report_data.isLoading = false;
            alert.showMessage(err.message,"danger");
        })
    }   
})

export default report_data;
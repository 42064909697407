import React from "react";
import { Container, Row, Col, InputGroup,
  InputGroupAddon,
  FormSelect,FormInput,Button} from "shards-react";

import PageTitle from "../common/PageTitle";
import '../scroll.css';
import {view} from 'react-easy-state';
import CsvDownloader from 'react-csv-downloader';
import RangeDatePicker from "./RangeDatePicker";
import ReceiptTable from './ReceiptTable';
import receipt_data from '../ReceiptStore';
import ViewReceipt from './ViewReceipt';
import AlertBox from '../AlertBox';

function setSearchType(type){
    receipt_data.setSearchType(type);
}

function searchData(key){
  receipt_data.searchData(key);
}



export default view(()=>{
    var temp = receipt_data.downloadData;
    return <Receipt data={temp} />
})

class Receipt extends React.Component{
    render(){
      return(
      <div>
        <AlertBox />
            <Container fluid className="main-content-container px-4">
    {/* Page Header */}
    <Row noGutters className="page-header py-4">
      <PageTitle sm="4" title="Receipt" subtitle="All" className="text-sm-left" />
    </Row>

  <Row>
    <Col sm="4">
              <RangeDatePicker />
     </Col>
      <SearchBar />
     <Col sm="2">
          <CsvDownloader datas={this.props.data} filename="receipt.csv" md="4"> 
              <DownloadButton />
          </CsvDownloader>
     </Col>
  </Row>

    {/* Default Light Table */}
    <ViewReceipt />
    <ReceiptTable />

  </Container>
</div>
        )
    }
}

class SearchBar extends React.Component{

  render(){
    return(
      <Col sm="12" md="6" lg="4">
        <InputGroup className="mb-3">
                    <FormInput placeholder="Search Receipt" onChange={(evt)=>{searchData(evt.target.value)}}/>
                  <InputGroupAddon type="prepend">

                    <FormSelect onChange = {(evt)=>{setSearchType(evt.target.value)}}>
                          <option value="receipt_id">Receipt ID</option>
                          <option value="name">Name</option>
                          <option value="cust_id">Customer ID</option>
                          <option value="ticket_no">Ticket No.</option>
                          <option value="mr_id">MR ID</option>
                      </FormSelect>
                  </InputGroupAddon>
              </InputGroup>
      </Col>
    )
  }
}


class DownloadButton extends React.Component{
  render(){
    return(
      <Col sm="12" md="6" lg="4">
        <Button theme="accent" size="md" > Download  <i className="material-icons">cloud_download</i></Button>
      </Col>
    )
  }
}

import React from "react";
import { Nav,Button } from "shards-react";

import Notifications from "./Notifications";
import UserActions from "./UserActions";
import login_data from '../../../../loginstore';
import {view,store} from 'react-easy-state';

function signOut(){
	login_data.logout();
}

var data = store({
	time : null,
	am_pm : null,
	startTime() {
		var date = new Date();
		var hours = date.getHours();
		var minutes = date.getMinutes();
		var seconds = date.getSeconds();
		var ampm = hours >= 12 ? 'PM' : 'AM';
		hours = hours % 12;
		hours = hours ? hours : 12; // the hour '0' should be '12'
		minutes = minutes < 10 ? '0'+minutes : minutes;
		seconds = seconds < 10 ? '0'+seconds : seconds;
		data.time = hours + ':' + minutes + ":"+ seconds;
		data.am_pm = ampm;
		setTimeout(data.startTime, 500)
	 },
		
	 

}) 

export default view(()=>{
	var isLoggedIn = login_data.isLoggedIn;
	return <Nav1 login={isLoggedIn} time={data.time} ampm={data.am_pm}/>
})

class Nav1 extends React.Component{
	componentDidMount(){
		data.startTime()
	}
	render(){
		return(
		<div>
			<Nav navbar className="flex-row">
   			{
   				/* <Notifications />
    			<UserActions />
			*/}
		
				<a className={"mt-3 mr-1"}>{this.props.time}</a>
				<a className={"mt-3 mr-4"}>{this.props.ampm}</a>
			
			{
				this.props.login &&
				<a className={"mt-3 mr-1"} href="#"><i class="material-icons" >logout</i></a>
			}	
			{
				this.props.login &&
     			<a href="#" className={"mt-3 mr-4"} onClick={signOut}>Logout</a>
     		
		 	}
		 </Nav>
	</div>

  
		)
	}
} 


import React from "react";
import { Row, Col, Card, CardHeader, CardBody,Button } from "shards-react";

import '../scroll.css';
import { view } from 'react-easy-state';
import { AtomSpinner } from 'react-epic-spinners';
import Message from '../Message';
import getArray from '../getArray';
import formatRupee from '../formatRupee';
import {Link } from 'react-router-dom';

import due_data from '../DueManagementStore';

export default view(()=>{
    var data = {
      isLoading : due_data.isLoading,
      data      : getArray(due_data.data),
      hasError  : due_data.hasError,
      gotData : due_data.gotData,

    }
    return <Table data={data} />;
})

class Table extends React.Component{
  render(){

    const {isLoading,data,hasError,gotData} = this.props.data;

    return(
    
      <Row>
      <Col>
        <Card small className="mb-4">
          <CardHeader className="border-bottom">
              <h6 className="m-0">Due Management</h6>
          </CardHeader>

       
          
          <CardBody className="p-0 pb-3 scroll_list-group" >

      
      { (!isLoading && gotData && !hasError && data.length>0)  &&
            <table className="table mb-0">
              <thead className="bg-light">
                <tr>

                <th scope="col" className="border-0">
                    #
                  </th>
                  <th scope="col" className="border-0">
                    Ticket No.
                  </th>
                  <th scope="col" className="border-0">
                    Name
                  </th>
                  <th scope="col" className="border-0">
                    Phone
                  </th>
                  <th scope="col" className="border-0">
                    Account Balance
                  </th>
                  <th scope="col" className="border-0">
                    Inst No's
                  </th>
                  <th scope="col" className="border-0">
                    Stage
                  </th>
                  <th scope="col" className="border-0">
                    Send Alert
                  </th>
                  <th scope="col" className="border-0">
                    Pay
                  </th>

                </tr>
              </thead>
              <tbody>
                
                {

                      data.map((item,i)=>{
                            return (<Trow key={i} data={item} index={i+1}/>)
                      })
              
                } 
               
              </tbody>
            </table>
          }

          {
                isLoading &&
                  <AtomSpinner color="blue" style={{alignSelf: 'center',marginLeft: '40%',marginTop: '10%',marginBottom: '10%'}}/>

            }
            {
            (!isLoading && !gotData  && !hasError  ) &&

              <Message message="Please Select a Group" icon_name={"call_missed"} />
            }
            {
            (!isLoading && gotData && data.length===0 && !hasError ) &&

              <Message message="No Due Payers " icon_name={"sentiment_satisfied_alt"} />
            }
            {

            (!isLoading && hasError && gotData ) &&

              <Message message="Error Fetching Data" icon_name={"warning"} />
            }
          </CardBody>
        </Card>
      </Col>
    </Row>
    )
  }
}

class Trow extends React.Component{
  render(){
    
    var inst_ids = [];
    this.props.data.installment_payments_detail.forEach(function(data){
        if(data.paid===false){
          inst_ids.push(data.name.split('_')[2]);
        }
    })

    return(
          <tr>
                  <td>{this.props.index}</td>
                  <td>{this.props.data.ticket_no}</td>
                  <td>{this.props.data.name}</td>
                  <td>{this.props.data.phone}</td>
                  <td style={{color:'red',fontWeight: 'bold'}}>{formatRupee(this.props.data.account_balance)}</td>
                  <td>{inst_ids.toString()}</td>
                  <td style={{color:this.props.data.priced?"red":"black"}}>{this.props.data.priced?"Prized":"Non Prized"}</td>
                  <td><Button theme="primary" onClick={()=>{due_data.sendDueSMSAlert(this.props.data)}}>Send Alert</Button></td>
                  <td><Link to={"/payinstallment?group_id="+this.props.data.group_id+"&ticket_no="+this.props.data.ticket_no}>
                        <Button theme="primary"><i class="material-icons">payment</i></Button>
                      </Link>
                  </td>
          </tr>

      )
  }
}


import React from "react";
import {  Row, Col,Button,
  FormSelect,FormInput,DatePicker } from "shards-react";
import '../scroll.css';
import {  view } from 'react-easy-state';
import DueInstallment from './DueInstallment';
import pay_inst from '../payInstallmentStore';
import formatRupee from '../formatRupee';

function setOtherCharges(charges){
    pay_inst.setOtherCharges(charges);
}

function setCustomerPaid(amount){
    pay_inst.setCustomerPaid(amount);
}

function setMRID(id){
  pay_inst.setMRID(id);
}

function setMRDate(date){
  pay_inst.setMRDate(date);
}

function setComments(comments){
  pay_inst.setComments(comments);
}

function setReceivedBy(person){
  pay_inst.setReceivedBy(person);
}

function setPaymentMethod(method){
  pay_inst.setPaymentMethod(method);
}




export default view(()=>{

  var chitDetails = pay_inst.chitDetails;
  var data = {
                  grand_total : pay_inst.grand_total,
                  excess_paid : pay_inst.excess_paid,
                  estimated_arrear : Math.abs(pay_inst.estimated_arrear),
                  previous_carriage : Math.abs(pay_inst.previous_carriage),
                  inst_total      : pay_inst.inst_total,
                  selected_date   : pay_inst.mr_date,
            };
  console.log(data);

	return <PaymentDetails chitdata={chitDetails} data={data} />
})



class PaymentDetails extends React.Component{

  constructor(props) {
    super(props);
  
    this.state = {date:undefined};
    this.handleDateChange = this.handleDateChange.bind(this);
  }

  handleDateChange(value){
      setMRDate(new Date(value)); 
  }

	render(){
		return(
				<div>
				              <Row>
                					<FieldValue field="Name  :" value={this.props.chitdata.name} size={"3"}/>
                					<FieldValue field="Phone :" value={this.props.chitdata.phone} size={"3"}/>
                					<FieldValue field="Ticket :" value={this.props.chitdata.group_id+"/"+this.props.chitdata.ticket_no} size={"3"} />
                					<FieldValue field="Stage :" value={this.props.chitdata.priced?"Prized":"Non-Prized"} size="3"/>
                				</Row>
                						<br />
                						<DueInstallment />
                				            
                				      <Row style={{marginTop: '5%'}}>	
                								<InputField field_name="Previous Carriage" value={formatRupee(this.props.data.previous_carriage)} disabled/>
                								<InputField field_name="Estimated Arrear" value={formatRupee(this.props.data.estimated_arrear)}  disabled/>
                								<InputField field_name="Excess Paid" value={formatRupee(this.props.data.excess_paid)} disabled/>
                								<InputField field_name="Installment Total" value={formatRupee(this.props.data.inst_total)} disabled/>
                								<InputField field_name="Grand Total" value={formatRupee(this.props.data.grand_total)} disabled/>
                								<InputField field_name="Other Charges"  onChange={(evt)=>{setOtherCharges(evt.target.value)}}/>
                								<InputField field_name="Customer Paid" onChange={(evt)=>{setCustomerPaid(evt.target.value)}}/>
                								<InputField type={"number"} field_name="MR ID" onChange={(evt)=>{setMRID(evt.target.value)}}/>
                                <MRDate selected={this.props.data.selected_date} onChange={this.handleDateChange}/>
                                <PaymentMethod onChange={(evt)=>{setPaymentMethod(evt.target.value)}}/>
                								<InputField field_name="Comments" onChange={(evt)=>{setComments(evt.target.value)}}/>
                								<InputField field_name="Recieved By" onChange={(evt)=>{setReceivedBy(evt.target.value)}}/>	
                							</Row>
                					<Row>
                						<Col lg="4"></Col>
                						<Col lg="4">
                							<Button onClick={pay_inst.payInstallment} style={{maxWidth: '100%',width:'100%',marginTop: '1%'}} size="md">
                									{"Pay Installment "}
                									<i class="material-icons">payment</i>
                							</Button>
                						</Col>
                						<Col lg="4"></Col>
                					</Row>
                	</div>
		)
	}
}


class PaymentMethod extends React.Component{
  render(){
    return(
          <Col md="4" className="form-group">
                    <label htmlFor="feEmailAddress">{"Payment Method"}</label>
                    <br />
                     <FormSelect {...this.props}>
                        <option value={null}>Select</option>
                        <option value={"cash"}>Cash</option>
                        <option value={"cheque"}>Cheque</option>
                        <option value={"NEFT"}>NEFT</option>
                        <option value={"JV"}>JV</option>
                    </FormSelect>
              </Col>

    )
  }
}

class MRDate extends React.Component{
  render(){
    return(
            <Col md="4" className="form-group">
                    <label htmlFor="feEmailAddress">{"MR Date"}</label>
                    <br />

                    <DatePicker placeholderText="MR Date"
                                  dropdownMode="select"
                                  className="text-center"
                                  {...this.props}
                                  />
              </Col>
      )
  }
}

class InputField extends React.Component{
	render(){
		return(
				<Col md="4" className="form-group">
                		<label htmlFor="feEmailAddress">{this.props.field_name}</label>
                		<FormInput

                			{...this.props}
                  			id="feEmailAddress"
                 	 		placeholder={this.props.field_name}
                  			onChange = {this.props.onChange}
                		/>
              </Col>
		)
	}
}


class FieldValue extends React.Component{
	render(){
		return(
		<Col md={this.props.size} lg={this.props.size} sm="12">
        		<Col className="text-muted">{this.props.field}</Col>
        		<Col style={{fontWeight:'bold'}}>{this.props.value}</Col>
        	
       </Col>
		)
	}
}

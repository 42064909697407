import {store} from 'react-easy-state';
import alert from '../AlertStore';
import firebase from '../../firebase';
import is_empty_string from '../isEmptyString';
const int_data = store({
    isIntDetLoading : false,
    isIntDetHasError : false,
    gotIntDetData : false,

    introducer_id : undefined,
    introducer_data : undefined,


    introduced_cust_data : [],
    isCustLoading : false,
    isCustHasError : false,
    isCustHasData : false,

    isPaymentLoading : false,
    isPaymentHasError : false,
    isPaymentHasData : false,
    payment_data : [], 


    amount : 0,
    comment : null,
    payment_method : 'cash',//initially
    date : new Date(undefined),


    setAmount(amount){
        int_data.amount = amount;
    },

    setComment(comment){
        int_data.comment = comment;
    },

    setPaymentMethod(method){
        int_data.payment_method = method;
    },

    setDate(date){
        int_data.date = new Date(date);
    },


    setIntroducerId(int_id){
        int_data.introducer_id = int_id;
    },


    getIntroducerData(){
        var introducer_id = int_data.introducer_id;

        if(introducer_id===undefined){
            alert.showMessage("Introducer ID Not found","danger");
            return;
        }
        int_data.getIntroducerCustData(introducer_id);
        int_data.getPaymentData(introducer_id);
        int_data.isIntDetLoading = true;
        return firebase.firestore().collection('Introducer').doc(introducer_id).get().then(function(doc){
            if(doc.exists){
                int_data.introducer_data = doc.data();
                int_data.isIntDetHasError = false;
                int_data.gotIntDetData = true;
            }
            else{
                alert.showMessage("Introducer Details Not Found","danger");
                int_data.isIntDetHasError = true;
                int_data.gotIntDetData = false;
                int_data.introducer_data = undefined;
            }

            int_data.isIntDetLoading = false;
            console.log(doc.data());

        })
        .catch(function(err){
            alert.showMessage(err.message,"danger");
            int_data.isIntDetLoading = false;
            int_data.gotIntDetData = false;
            int_data.isIntDetHasError = true;
            int_data.introducer_data = undefined;
        })

    },

    getIntroducerCustData(int_id){
        if(int_id===undefined || int_id===null){
            int_data.isCustHasData = false;
            int_data.isCustHasError = true;
            int_data.introduced_cust_data = [];
            return;
        }

        int_data.isCustLoading = true;
        return firebase.firestore().collection('Customer').where('introducer_id','==',int_id).get().then(function(snap){
            var temp = [];
            snap.forEach(function(doc){
                temp.push(doc.data());
            })
            console.log("Cust Data : ",temp);
            int_data.introduced_cust_data = temp;
            int_data.isCustLoading = false;
            int_data.isCustHasData = true;
            int_data.isCustHasError = false;
        })
        .catch(function(err){
            console.error(err);
            int_data.introduced_cust_data = [];
            int_data.isCustLoading = false;
            int_data.isCustHasData = false;
            int_data.isCustHasError = true;

        })
    },


    getPaymentData(int_id){
        if(int_id===undefined || int_id===null){
            alert.showMessage("Invalid Introducer ID","danger");
            return;
        }
        int_data.isPaymentLoading = true;
        return firebase.firestore().collection('IntroducerPayment').where('int_id','==',int_id).get().then(function(snap){
            var temp = [];
            snap.forEach(function(doc){
                temp.push(doc.data());
            })
            int_data.isPaymentLoading = false;
            int_data.payment_data = temp;
            int_data.isPaymentHasError = false;
            int_data.isPaymentHasData = true;
        }).catch(function(err){
            console.error(err);
            alert.showMessage(err.message,"danger");
            int_data.isPaymentLoading = false;
            int_data.payment_data = [];
            int_data.isPaymentHasError = true;
            int_data.isPaymentHasData = false; 
        })
    },



    deletePayment(id){
        int_data.isPaymentLoading = true;
        return firebase.firestore().collection('IntroducerPayment').doc(id.toString()).delete().then(function(){
            alert.showMessage("Payment Succesfully Deleted","danger");
            int_data.isPaymentLoading = false;
            int_data.getPaymentData(int_data.introducer_id);
        }).catch(function(err){
            int_data.isPaymentLoading = false;
            alert.showMessage(err.message,"danger");
            console.error(err);
        })
    },



    addPayment(){
        
        var data = {
             int_id : int_data.introducer_id,
             amount : parseInt(int_data.amount),
             comment : int_data.comment,
             payment_method : int_data.payment_method,  
             date   : (new Date(int_data.date)).toString(),
        }

        console.log("Payload Data ",data);
        
        if(data.int_id===undefined || data.int_id===null){
            alert.showMessage("Introducer ID Not Found","danger");
            return;
        }
        if(data.amount<0 || isNaN(data.amount)){
            alert.showMessage("Please Enter a Valid Amount","warning");
            return;
        }
        if(data.date===undefined){
            alert.showMessage("Please Select a Date");
            return;
        }

        int_data.isIntDetLoading = true;
        var func_ref = firebase.functions().httpsCallable('payIntroducer');
        func_ref(data).then(function(result) {
            // Read result of the Cloud Function.
            var rdata = result.data;
            console.log(rdata); 

            try{
              if(rdata.status==="success"){
                  alert.showMessage("Payment Added Succesfully","success");
                  int_data.getPaymentData(int_data.introducer_id);	
              }
              else{
                  alert.showMessage(rdata.message,"danger");
              }	
            }
            catch(err){
                  alert.showMessage("Unknown Error Occured.Kindly View this Profile Before Proceeding further","danger");
            }
            
            
            int_data.isIntDetLoading = false;
            int_data.clearPaymentData();
            
         }).catch(function(error) {
            // Getting the Error details.
            //var code = error.code;
            //var message = error.message;
            //var details = error.details;
            // ...
            console.log(error);
            int_data.isIntDetLoading = false;;
            alert.showMessage(error.message,"danger");
        });
        
    },

    clearPaymentData(){
        int_data.amount = 0;
        int_data.date  = new Date(undefined);
        int_data.comment = null;
        int_data.payment_method = 'cash'; //initially

    }
})


export default int_data;
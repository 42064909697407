import React from 'react';
import { Container, Row, Col,Card , CardHeader,CardBody,FormInput,Button,Form} from "shards-react";
import PageTitle from "../common/PageTitle";
import firebase from '../../firebase';
import { AtomSpinner } from 'react-epic-spinners';
import alert from '../AlertStore';
import {Redirect} from 'react-router-dom'; 
import AlertBox from '../AlertBox';

export default class RemoveDeposit extends React.Component{

  constructor(props) {
    super(props);
  
    this.state = {
        id: null,
        isLoading:false,
        deleted : false,
    };

    this.RemoveDeposit = this.RemoveDeposit.bind(this);
    this.onSubmitHandler = this.onSubmitHandler.bind(this);
    
  }



componentDidMount(){
  var url_string = window.location.href;
  var url = new URL(url_string);
  var c = url.searchParams.get("id");
  this.setState({id:c});
   
}

  RemoveDeposit = () =>{
    var that = this;
    if(this.state.id===null){
      alert.showMessage("Kindly Enter DepositID","danger");
    }
  
    this.setState({isLoading:true});
    var that = this;
    firebase.firestore().collection('Deposit').doc(this.state.id).delete().then(function(){
        that.setState({isLoading:false,deleted:true});
        alert.showMessage("Deposit Deleted Succesfully","success");
    }).catch(function(err){
        console.error(err);
        that.setState({isLoading:false});
        alert.showMessage(err.message,"danger");
    })

  }

  onSubmitHandler = (evt) =>{
      evt.preventDefault();
      this.RemoveDeposit();
  }

  render(){

  	if (this.state.deleted === true) {
      return <Redirect to='/deposit' />
    }

    return(
    <div>
      <AlertBox  />
    <Container fluid className="main-content-container px-4 pb-4">
        {/* Page Header */}
        <Row noGutters className="page-header py-4">
              <PageTitle sm="4" title="Deposit" subtitle="Remove" className="text-sm-left" />
        </Row>

        

     	<Col lg="12">
        <Card small className="mb-4">
          <CardHeader className="border-bottom">
              <h6 className="m-0">Remove Deposit</h6>
              
          </CardHeader>
          <CardBody className="p-2 pb-2" >
        { !this.state.isLoading &&		
         <Col>
          <Form onSubmit={this.onSubmitHandler}>
            <Row form>
              <Col md="4" className="form-group">
                <label htmlFor="feEmailAddress">DepositID</label>
                <FormInput
                  id="feEmailAddress"
                  type="text"
                  placeholder="DepositID"
                  value={this.state.id}
                  onChange = {(evt)=>{this.setState({id:evt.target.value})}}
                />
              </Col>
              <Col md="4">

                  <Button type="submit" style={{alignSelf: 'center',marginTop: '9%'}}>Remove Deposit</Button>
              </Col>
           </Row>
          </Form>
        </Col>
      }
      {
        this.state.isLoading &&
        <Col>
          <AtomSpinner color="blue" style={{alignSelf: 'center',marginLeft: '40%',marginTop: '10%',marginBottom: '10%'}}/>
        </Col>
      }
 
          </CardBody>
          </Card>
          </Col>
    </Container>
    </div>
    )
  }
}



import React from "react";
import {
  Row,
  Col,
} from "shards-react";

import { view } from 'react-easy-state';

import SmallStats from "../common/SmallStats";
import dashboard_store from '../DashboardStore';
import getArray from '../getArray';
import login_data from '../../loginstore';
import { Redirect } from "react-router-dom";


function random_rgba() {
    var o = Math.round, r = Math.random, s = 255;
    return 'rgba(' + o(r()*s) + ',' + o(r()*s) + ',' + o(r()*s) + ',' + r().toFixed(1) + ')';
}

function pushData(label,value,arr){
	var modal_data = {
      					label: "Group",
      					value: "8",
      					chartLabels: [null, null, null, null, null, null, null],
      					attrs: { md: "6", sm: "6" },
      					datasets: [
        				{
          					label: "Today",
          					fill: "start",
          					borderWidth: 1.5,
          					backgroundColor: "rgba(0, 184, 216, 0.1)",
          					borderColor: "rgb(0, 184, 216)",
          					data: [1, 2, 1, 3, 5, 4, 7]
        				}
      					]
    			};


    var data = [];
    for(var i=0;i<10;i++){
    	data.push(Math.floor((Math.random() * 5) + 1));
    }

   	modal_data['label'] = label;
   	modal_data['value'] = value;
   	modal_data['datasets'][0]['data'] = data;
   	modal_data['datasets'][0]['backgroundColor'] = random_rgba();
   	arr.push(modal_data);
   	return arr;
}

function getFormattedData(group_data,upcoming_data){
	
    var total_active =  0;
    var vacant_chits = 0;
    var no_of_groups = 0;
   	var total_auctions_left = 0;
   	var no_of_priced  = 0;
   	var no_of_non_priced = 0;
   	var days_left = 9999999;

   	group_data.forEach(function(group){
   		no_of_groups = no_of_groups + 1;
   		total_active = total_active + group.customer_chits;
   		vacant_chits = vacant_chits + group.vacant_chits;
   		no_of_priced = no_of_priced + group.no_of_priced;
   		no_of_non_priced = no_of_non_priced + group.no_of_non_priced;
   		total_auctions_left = total_auctions_left + group.remaining_auctions;
   	})

   	upcoming_data.forEach(function(auction){
   		var oneDay = 24*60*60*1000;
   		var diffDays = Math.round(Math.abs((auction.auction_date_and_time.toDate().getTime() - (new Date()).getTime())/(oneDay)));
   		if(diffDays<days_left){
   			days_left = diffDays;
   		}
   	})

   	var temp = [];
   	pushData('Active Groups',no_of_groups,temp);
	pushData('Active Chits',total_active,temp);
	pushData('Vacant Chits',vacant_chits,temp); 
	pushData('No of Prized',no_of_priced,temp);  	
	//pushData('No of Non Prized',no_of_non_priced,temp);
	pushData('Total Auctions Left',total_auctions_left,temp);
	pushData('Days Left For Next Auction',days_left,temp);

	return temp;


}



export default view(()=>{

	var data = {
		isLoading 		: dashboard_store.isLoading,
		group_data 		: getArray(dashboard_store.group_data),
		upcoming_data   : getArray(dashboard_store.upcomingAuctionList),
		data 			: getFormattedData(dashboard_store.group_data,dashboard_store.upcomingAuctionList),
	}
  var isLoggedIn = login_data.isLoggedIn;
	console.log(data);
	return isLoggedIn?<GroupStats data={data.data} />:<Redirect to="/login" />;
})


class GroupStats extends React.Component{

	componentDidMount(){
		dashboard_store.getGroupData();
		dashboard_store.getUpcomingAuction();
	}

	render(){
		return(
			<Row>
      				{this.props.data.map((stats, idx) => (
        			<Col className="col-lg mb-4" key={idx} {...stats.attrs}>
          					<SmallStats
           	 					id={`small-stats-${idx}`}
            					variation="1"
            					chartData={stats.datasets}
            					chartLabels={stats.chartLabels}
            					label={stats.label}
            					value={stats.value}
            					percentage={stats.percentage}
            					increase={stats.increase}
            					decrease={stats.decrease}
          			/>
        			</Col>
      				))}
    		</Row>
		)
	}
}



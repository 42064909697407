import React from 'react';
import { Container, Row, Col,Card , CardHeader,CardBody, FormCheckbox,FormGroup,FormInput,Button,
		Form,InputGroupAddon,InputGroup,InputGroupText,FormSelect,DatePicker } from "shards-react";
import PageTitle from "../common/PageTitle";
import firebase from '../../firebase';
import {toast} from 'react-toastify';
import { AtomSpinner } from 'react-epic-spinners';
import alert from '../AlertStore';
import AlertBox from '../AlertBox';

export default class AddNewGroup extends React.Component{

  constructor(props) {
    super(props);
  
    this.state = {
      isLoading:false,

      group_id : null,
      pso_no : null,
      pso_date : null,
      deposit_cert_no : null,
      deposit_amount : null,
      deposit_date : null,
      deposit_bank : null,
      deposit_rate_of_interest : null,
      cc_no : null,
      cc_date : null,
      no_of_months : null,
      installment_value : null,
      start_date : null,
      end_date : null,
      first_auction_date_and_time : null,
      chitValue : null,

    };

    this.handlePSODateChange = this.handlePSODateChange.bind(this);
    this.handleDepositDateChange = this.handleDepositDateChange.bind(this);
    this.handleCCDateChange = this.handleCCDateChange.bind(this);
    this.handleStartDateChange = this.handleStartDateChange.bind(this);
    this.handleEndDateChange = this.handleEndDateChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.addGroup = this.addGroup.bind(this);
    this.isNull = this.isNull.bind(this);
  }

  handlePSODateChange(value) {
    this.setState({ pso_date: new Date(value)});
  }
  handleDepositDateChange(value) {
    this.setState({ deposit_date: new Date(value)});
  }
  handleCCDateChange(value) {
    this.setState({ cc_date: new Date(value)});
  }
  handleStartDateChange(value) {
    this.setState({ start_date: new Date(value)});
  }
  handleEndDateChange(value) {
    this.setState({ end_date: new Date(value)});
  }

  isNull(obj){ 
  
      for (var key in obj) {
          if (obj.hasOwnProperty(key)) {
              var val = obj[key];
              console.log(val)
              if(val===undefined || val === null){
                    return true;
              }
          }
      }
      return false;
    }

  handleSubmit=(evt)=>{
    evt.preventDefault();
    this.addGroup();
  }

   addGroup = () =>{
    var that = this;
    var data = this.state;

    if(this.isNull(data)){
      alert.showMessage("Please Fill All Details","warning");
      return;
    }

    //modify the data 
    try{
      data['pso_date'] = new Date(data.pso_date).toUTCString();
      data['deposit_date'] = new Date(data.deposit_date).toUTCString();
      data['start_date'] = new Date(data.start_date).toUTCString();
      data['end_date'] = new Date(data.end_date).toUTCString();
      data['cc_date'] = new Date(data.cc_date).toUTCString();
      data['first_auction_date_and_time'] = new Date(data.first_auction_date_and_time).toUTCString();
    }
    catch(err){
        alert.showMessage("Error Updating Dates","danger");
        console.log(err);
    }
    delete data.isLoading;
    console.log(data);
    

    this.setState({isLoading:true});
    var func_ref = firebase.functions().httpsCallable('CreateGroup');
    func_ref(data).then(function(result) {
                  // Read result of the Cloud Function.
                  var data = result.data;
                  console.log(data);
                  if(data.status==="success"){
                        console.log("Document succesfully added!");
                        alert.showMessage("Group Created Successfully","success");
                        console.log(that.state);
                   }
                  else{
                        toast(data.error.message, {containerId: 'A'});
                        console.log(data.error.message);
                        alert.showMessage(data.error.message,"danger");
                  }
                  that.setState({isLoading:false});

        }).catch(function(error) {
                  // Getting the Error details.
                  //var code = error.code;
                  //var message = error.message;
                  //var details = error.details;
                  // ...
                  console.log(error);
                  that.setState({isLoading:false});
                  toast("unable to add Group", {containerId: 'A'});
                  alert.showMessage("Unable to add Group","danger");
        });
    
  }


  render(){
    return(
    <div>
      <AlertBox />
    
    <Container fluid className="main-content-container px-4 pb-4">
        {/* Page Header */}
        <Row noGutters className="page-header py-4">
              <PageTitle sm="4" title="New Group" subtitle="Add" className="text-sm-left" />
        </Row>

        

     	<Col lg="12">
        <Card small className="mb-4">
          <CardHeader className="border-bottom">
              <h6 className="m-0">Add New Group</h6>
              
          </CardHeader>
          <CardBody className="p-2 pb-2" >
      { !this.state.isLoading &&
         <Col>
          <Form onSubmit={this.handleSubmit}>
            <Row form>
              <Col md="4" className="form-group">
                <label htmlFor="feEmailAddress">Group No.</label>
                <FormInput
                  id="feEmailAddress"
                  type="text"
                  placeholder="Group No."
                  onChange = {(evt)=>{this.setState({group_id:evt.target.value})}}
                />
              </Col>
              <Col md="4">
                <label htmlFor="fePassword">PSO No.</label>
                <FormInput
                  id="fePassword"
                  type="text"
                  placeholder="PSO No."
                  onChange = {(evt)=>{this.setState({pso_no:evt.target.value})}}
                />
              </Col>
              <Col md="4">
             		<FormGroup>
              				<label htmlFor="feInputAddress">PSO Date</label>
              				<br />
              				<DatePicker placeholderText={"PSO Date"}
                                  onChange={this.handlePSODateChange}
                                  selected = {new Date(this.state.pso_date)}/>
            		</FormGroup>
              </Col>
           </Row>

           <Row form>
              <Col md="4">
                <label htmlFor="fePassword">Deposit Certificate No.</label>
                <FormInput
                  id="fePassword"
                  type="text"
                  placeholder="Deposit Certificate No."
                  onChange = {(evt)=>{this.setState({deposit_cert_no:evt.target.value})}}
                />
              </Col>
              <Col md="4">
                <label htmlFor="fePassword">Deposit Amount</label>
                <FormInput
                  id="fePassword"
                  type="text"
                  placeholder="Deposit Amount"
                  onChange = {(evt)=>{this.setState({deposit_amount:evt.target.value})}}
                />
              </Col>

              <Col md="4">
                <label htmlFor="fePassword">Deposit Date</label>
                <br />
              	<DatePicker placeholderText={"Deposit Date"}
                              selected = {new Date(this.state.deposit_date)}
                              onChange={this.handleDepositDateChange}/>
              </Col>
            </Row>

            <Row form style={{marginTop: '2%'}}>


              <Col md="4">
                <label htmlFor="fePassword">Deposit Bank</label>
                <FormInput
                  id="fePassword"
                  type="text"
                  placeholder="Deposit Bank"
                  onChange = {(evt)=>{this.setState({deposit_bank:evt.target.value})}}
                />
              </Col>

              <Col md="4">
                <label htmlFor="fePassword">Deposit Rate of Interest</label>
                <FormInput
                  id="fePassword"
                  type="text"
                  placeholder="Deposit Rate of Interest"
                  onChange = {(evt)=>{this.setState({deposit_rate_of_interest:evt.target.value})}}
                />
              </Col>
              <Col md="4">
                <label htmlFor="fePassword">CC No.</label>
                <FormInput
                  id="fePassword"
                  type="text"
                  placeholder="CC No."
                  onChange = {(evt)=>{this.setState({cc_no:evt.target.value})}}
                />
              </Col>
              

            </Row>

            <Row form style={{marginTop: '2%'}}>
            	<Col md="4">
                		<label htmlFor="fePassword">CC Date</label>
                		<br />
              			<DatePicker placeholderText={"CC Date"}
                                selected = {new Date(this.state.cc_date)}
                                 onChange={this.handleCCDateChange}/>
              </Col>


              <Col md="4">
                <label htmlFor="fePassword">No. of Months</label>
                <FormInput
                  id="fePassword"
                  type="text"
                  placeholder="No. of Months"
                  onChange = {(evt)=>{this.setState({no_of_months:evt.target.value})}}
                />
              </Col>

              <Col md="4">
                <label htmlFor="fePassword">Monthly Subscription</label>
                <FormInput
                  id="fePassword"
                  type="text"
                  placeholder="Monthly Subscription"
                  onChange = {(evt)=>{this.setState({installment_value:evt.target.value})}}
                />
              </Col>
            </Row>

            <Row form style={{marginTop: '2%'}}>
            	<Col md="4">
                		<label htmlFor="fePassword">Start Date</label>
                		<br />
              			<DatePicker placeholderText={"Start Date"}
                              selected = {new Date(this.state.start_date)}
                              onChange={this.handleStartDateChange}/>
              </Col>

              <Col md="4">
                		<label htmlFor="fePassword">End Date</label>
                		<br />
              			<DatePicker placeholderText={"End Date"}
                                selected = {new Date(this.state.end_date) }
                                onChange={this.handleEndDateChange}/>
              </Col>

              <Col md="4">
                		<label htmlFor="fePassword">First Auction Date and Time</label>
                		<br />
                		<FormInput
                 			    id="fePassword"
                  			 type="datetime-local"
                  			placeholder="Monthly Subscription"
                        onChange = {(evt)=>{this.setState({first_auction_date_and_time:evt.target.value})}}
                		/>
              </Col>


            </Row>


            <Row form style={{marginTop: '2%'}}>
            		<Col md="4">
                		<label htmlFor="fePassword">Chit Value</label>
                		<br />
                		<FormInput
                  				id="fePassword"
                  				type="text"
                  				placeholder="Chit Value"
                          onChange = {(evt)=>{this.setState({chitValue:evt.target.value})}}
               			 />
              		</Col>
            </Row>


            
            <Button type="submit" style={{marginTop: '3%',alignSelf: 'center' ,marginBottom: '3%'}}>Create New Group</Button>
            
          </Form>
        </Col>
      }
      {
        this.state.isLoading &&
            <AtomSpinner color="blue" style={{alignSelf: 'center',marginLeft: '40%',marginTop: '10%',marginBottom: '10%'}}/>

      }
 
          </CardBody>
          </Card>
          </Col>
    </Container>
  </div>
    )
  }
}
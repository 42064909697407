import React from "react";
import { Container, Row, Col, Card, CardHeader, CardBody,Button,InputGroup,
  InputGroupAddon,
  InputGroupText,
  FormSelect,FormInput, } from "shards-react";

import { AtomSpinner } from 'react-epic-spinners';

import PageTitle from "../common/PageTitle";
import '../scroll.css';
import { view } from 'react-easy-state';
import GroupCustomer from '../getGroupCustomer';
import SelectGroup from '../SelectGroup';
import Message from '../Message';
import login_data from '../../loginstore';
import { Redirect,Link } from "react-router-dom";
import getArray from '../getArray';
import CsvDownloader from 'react-csv-downloader';
import AlertBox from '../AlertBox';


function getCustData(group){
    GroupCustomer.getCustomerData(group);
}

function searchData(name){
  GroupCustomer.search(name);
}

function setSearchType(val){
  GroupCustomer.setSearchkey(val);
}

function setSortType(type){
    GroupCustomer.setSortType(type);
}
function setSortKey(key){
    GroupCustomer.setSortKey(key);
}

function getDownloadData(data){
    var cont  = [];
    data.forEach(function(doc){
        delete doc.installment_payments_detail;
        delete doc.join_date;
        cont.push(doc);
    })
    return cont;
}


export default view(()=>{     
                                  var ViewArr = getArray(GroupCustomer.viewData);
                                  var downloadData = getDownloadData(ViewArr);
                                  console.log("D DAta " ,downloadData);
                                  return login_data.isLoggedIn?
                                                <Customer 
                                                   viewData = {ViewArr}
                                                   isLoading ={GroupCustomer.isLoading}
                                                   hasError = {GroupCustomer.hasError}
                                                   gotData = {GroupCustomer.gotData}
                                                   downloadData={downloadData}
                                                />
                                                :
                                                <Redirect to='/login' />
                          });


 class Customer extends React.Component{
    render(){
      return(
        <div>
          <AlertBox />
            <Container fluid className="main-content-container px-4">
            {/* Page Header */}
            <Row noGutters className="page-header py-4">
                    <PageTitle sm="4" title="Customer" subtitle="Group" className="text-sm-left" />
            </Row>

            <Row>
              <Col sm="12" md="6" lg="4">
                <SelectGroup onChange={(evt)=>{getCustData(evt.target.value)}} value={"abc"} />
              </Col>
                {
                  (!this.props.isLoading && !this.props.hasError && this.props.gotData ) &&
                    <SearchBar />
                }
                {
                  (!this.props.isLoading && !this.props.hasError && this.props.gotData ) &&   
                        <Sort />
                 
                } 
            </Row>

            {/* Default Light Table */}
            <Table  isLoading = {this.props.isLoading} 
                    data={this.props.viewData} 
                    hasError={this.props.hasError} 
                    gotData={this.props.gotData}
                    ddata = {this.props.downloadData}
            />

            </Container>
        </div>
        )
    }
}






class Table extends React.Component{
  render(){

    var arr = this.props.data;
    var hasError = this.props.hasError;
    var gotData = this.props.gotData;
    var isLoading = this.props.isLoading;

    return(
    
      <Row>
      <Col>
        <Card small className="mb-4">
          <CardHeader className="border-bottom">
          <Row>
              <Col md="3" lg="3">
                <h6 className="m-0">Customers</h6>
              </Col>
              <Col md="6" lg="7">
              </Col>
              <Col md="3" lg="2">
                {
                  this.props.ddata.length>0 &&
                  <CsvDownloader datas={this.props.ddata} filename="groupData.csv" md="4"> 
                    <DownloadButton />
                  </CsvDownloader>
                }
                
              </Col>
            </Row>
          </CardHeader>

       
          
          <CardBody className="p-0 pb-3 scroll_list-group" >
           
           
           { (!isLoading && gotData && !hasError)  &&
            <table className="table mb-0">
              <thead className="bg-light">
                <tr>
                  <th scope="col" className="border-0">
                    #
                  </th>
                  <th scope="col" className="border-0">
                    Group
                  </th>
                  <th scope="col" className="border-0">
                    First Name
                  </th>
                  <th scope="col" className="border-0">
                    CustomerID
                  </th>
                  <th scope="col" className="border-0">
                    Phone
                  </th>
                  <th scope="col" className="border-0">
                    Stage
                  </th>
                  <th scope="col" className="border-0">
                    Status
                  </th>
                  <th scope="col" className="border-0">
                    isLean
                  </th>
                  <th scope="col" className="border-0">
                    View
                  </th>
                  <th scope="col" className="border-0">
                    Edit
                  </th>

                </tr>
              </thead>
              <tbody>

                {

                      arr.map((item,i)=>{
                            return (<Trow key={i} data={item} index={i+1}/>)
                      })
              
                } 
              </tbody>
            </table>
            }

            {
                  isLoading &&
                  <AtomSpinner color="blue" style={{alignSelf: 'center',marginLeft: '40%',marginTop: '10%',marginBottom: '10%'}}/>

            }
            {
            (!isLoading && !gotData  ) &&

              <Message message="Please Select a Group" icon_name={"call_missed"} />
            }
            {

            (!isLoading && hasError && gotData ) &&

              <Message message="Error Fetching Data" icon_name={"warning"} />
            }

          </CardBody>
        </Card>
      </Col>
    </Row>
    )
  }
}


class DownloadButton extends React.Component{
  render(){
    return(
      <Col sm="12" md="6" lg="4" {...this.props.style}>
        <Button theme="accent" size="md" >Download</Button>
      </Col>
    )
  }
}



class Trow extends React.Component{
  render(){
    return(

           <tr>
                  <td>{this.props.index}</td>
                  <td>{this.props.data.group_id+"/"+this.props.data.ticket_no}</td>
                  <td>{this.props.data.name}</td>
                  <td>{this.props.data.custID}</td>
                  <td>{this.props.data.phone}</td>
                  <td>{this.props.data.priced?"Prized":"NonPrized"}</td>
                  <td style={{color:(this.props.data.status==="active"?"green":"red")}}>{this.props.data.status}</td>
                  <td style={{color:this.props.data.isLean?"red":'green'}}>{this.props.data.isLean?"True":"False"}</td>
                  <td>
                      <Link to={"/custprofile?id="+this.props.data.custID} >
                        <Button theme="primary">View</Button>
                      </Link>
                  </td>
                  <td style={{color:'green'}}>
                                  <Link to={"/modifycustomer?name="+this.props.data.custID} >
                                      <Button type="submit"  outline size="sm" theme="info" className="mb-2 mr-1">
                                            <i class="material-icons">edit</i>
                                      </Button>
                                  </Link>
                  </td> 
            </tr>
      )
  }
}


class SearchBar extends React.Component{



  render(){
    return(
      <Col sm="12" md="6" lg="4">
        <InputGroup className="mb-3">
                    <FormInput placeholder="Search Customer" onChange={(evt)=>{searchData(evt.target.value)}}/>
                  <InputGroupAddon type="prepend">

                    <FormSelect onChange = {(evt)=>{setSearchType(evt.target.value)}}>
                          <option value="name">Name</option>
                          <option value="ticket_no">Ticket No.</option>
                          <option value="phone">Phone</option>
                          <option value="email">Email</option>
                          <option value="custID">CustID</option>
                      </FormSelect>
                  </InputGroupAddon>
              </InputGroup>
      </Col>
    )
  }
}


class Sort extends React.Component{
  render(){
    return (
        <Col sm="12" md="6" lg="4">
            <InputGroup className="mb-3">
                <InputGroupAddon type="prepend">
                    <InputGroupText>Sort</InputGroupText>
                 </InputGroupAddon>
                  <FormSelect onChange = {(evt)=>{setSortKey(evt.target.value)}}>
                        <option value="ticket_no">{"TicketNo"}</option>
                        <option value="name">{"Name"}</option>
                        <option value="custID">{"Customer ID"}</option>
                        <option value="priced">{"Stage"}</option>
                  </FormSelect>
                  <FormSelect onChange = {(evt)=>{setSortType(evt.target.value)}}>
                        <option value="asc">{"Ascending"}</option>
                        <option value="desc">{"Descending"}</option>
                  </FormSelect>
     
            
        </InputGroup>
      </Col>
    )
  }
}


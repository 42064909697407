import React from "react";
import { Container, Row, Col, Card, CardHeader, CardBody,Button,InputGroup,
  InputGroupAddon,FormInput,} from "shards-react";

import { AtomSpinner } from 'react-epic-spinners';

import PageTitle from "../common/PageTitle";
import '../scroll.css';
import {  view } from 'react-easy-state';
import SelectGroup from '../SelectGroup';
import Message from '../Message';
import PaymentDetails  from './PaymentDetails';
import pay_inst from '../payInstallmentStore';
import login_data from '../../loginstore';
import { Redirect } from "react-router-dom";
import AlertBox from '../AlertBox';


export default view(()=>{

		var data = {
			isLoading : pay_inst.isChitLoading,
			hasError : pay_inst.ChitHasError,
			hasData  : pay_inst.hasChitData,
			gotData : pay_inst.gotData,
			group_id : pay_inst.group_id,
			ticket_no : pay_inst.ticket_no,
		}

		var url_string = window.location.href;
		var url = new URL(url_string);
		var group_id = url.searchParams.get("group_id");
		var ticket_no = url.searchParams.get("ticket_no");

		if(group_id!==null && group_id!==undefined && ticket_no!==null && ticket_no!==undefined){
			console.log("group ID =",group_id,"Ticket =",ticket_no);
			console.log("Calling From url");
			pay_inst.setGroupID(group_id);
			pay_inst.setTicketNo(ticket_no);
		}	
		 
		console.log(data);

		return login_data.isLoggedIn?<PayInstallment data={data}/>:<Redirect to='/login' />;

})

class PayInstallment extends React.Component{

	componentWillMount(){
		if(this.props.data.group_id!==null && this.props.data.ticket_no!=null)
			pay_inst.getData();
	}

	componentWillUnmount(){
		pay_inst.resetPayInstallment();
	}


	render(){
		return(
			<div>
				<AlertBox />
				<Container fluid className="main-content-container px-4">
				 	{/* Page Header */}
           	 		<Row noGutters className="page-header py-4">
                    	<PageTitle sm="4" title="Pay" subtitle="Installment" className="text-sm-left" />
            		</Row>

            		<Row>
    					<GroupTicket />
    				</Row>

    				<Row>
    					<Col lg="12" md="12" sm="12">
             				<Card>
             				{ //if user id selected
             				  <CardHeader className="border-bottom">
              							<h6 className="m-0">Pay Installment</h6>
          						</CardHeader>
          					}
                				<CardBody>

                					{
                						(!this.props.data.isLoading && (this.props.data.hasData)) &&
                						<PaymentDetails />	
                					}
                					{
                						(!this.props.data.isLoading && (!this.props.data.gotData )) &&
                						<Message message={"Please Select Group and Ticket"} icon_name="call_missed" />
                					}
                					{
              							(this.props.data.isLoading) &&
              	 						<AtomSpinner color="blue" style={{alignSelf: 'center',marginLeft: '40%',marginTop: '10%',marginBottom: '10%'}}/>
            						}
           	 
            						{
              							(!this.props.data.isLoading && this.props.data.hasError && (this.props.data.gotData)) &&
              							<Message message={"Error Loading Data"} icon_name={"warning"} />
            						}
            						{
             							 ( !this.props.data.isLoading && (!this.props.data.hasData) && (this.props.data.gotData) ) &&
             							 <Message message={"No Chit Avaiable"} icon_name={"sentiment_very_satisfied"} />
            						}

                					</CardBody>
                				</Card>
                				
                				
                		</Col>
    				</Row>


				</Container>
			</div>

			)
	}
}








class GroupTicket extends React.Component{
	render(){
	return(
		<Row>
    <Col className="pl-4" sm="12" md="6" lg="6">
		  <SelectGroup onChange={(evt)=>{pay_inst.setGroupID(evt.target.value)}}/>
    </Col>
		<Col lg="6">
			<InputGroup className="mb-3">
    				<FormInput placeholder={"Ticket No"} type="number" onChange={(evt)=>{pay_inst.setTicketNo(evt.target.value)}}/>

    				<InputGroupAddon type="prepend">
    						<Button onClick={pay_inst.getData}>Get Customer</Button>
    				</InputGroupAddon>
    		</InputGroup>
    	</Col>
    	</Row>
    )
	}
}



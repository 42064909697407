import React from "react";
import { Container, Row, Col, Card, CardHeader, CardBody,Button,InputGroup,
  InputGroupAddon,FormInput, } from "shards-react";

import PageTitle from "../common/PageTitle";
import '../scroll.css';
import { AtomSpinner } from 'react-epic-spinners';
import Message from '../Message';
import introducer_list from '../IntroducerStore';
import { view } from 'react-easy-state';
import login_data from '../../loginstore';
import { Redirect,Link  } from "react-router-dom";
import AlertBox from '../AlertBox';


export default view(()=>{
  var data={
      list : introducer_list.list,
      isLoading : introducer_list.isLoading,
      hasData : introducer_list.hasData,
      hasError : introducer_list.hasError,
    }

    return login_data.isLoggedIn?<CollectionAsst data={data} />:<Redirect to='/login' />
})

class CollectionAsst extends React.Component{

    componentDidMount(){
      introducer_list.getList();
    }

    render(){
      return(
      <div>
        <AlertBox />
            <Container fluid className="main-content-container px-4">
            {/* Page Header */}
            <Row noGutters className="page-header py-4">
                  <PageTitle sm="4" title="Introducer" subtitle="View" className="text-sm-left" />
            </Row>

            {/* Default Light Table */}
             <Table data={this.props.data} />

          </Container>
        </div>
        )
    }
}





class Table extends React.Component{
  render(){
    return(
    
      <Row>
      <Col>
        <Card small className="mb-4">
          <CardHeader className="border-bottom">
              <h6 className="m-0">Collection Assistants</h6>
          </CardHeader>

       
          
          <CardBody className="p-0 pb-3 scroll_list-group" >
           
           { (!this.props.data.isLoading && this.props.data.hasData && (this.props.data.list.length>0) ) && 
            <table className="table mb-0">
              <thead className="bg-light">
                <tr>
                  <th scope="col" className="border-0">
                    #
                  </th>
                  <th scope="col" className="border-0">
                   Introducer ID
                  </th>
                  <th scope="col" className="border-0">
                      Name
                  </th>
                  
                  <th scope="col" className="border-0">
                    View
                  </th>
                  <th scope="col" className="border-0">
                    Edit
                  </th>

                </tr>
              </thead>
              <tbody>

                      {
                          this.props.data.list.map((item,i)=>{
                                return (<Trow key={i} data={item} index={i+1}/>)
                          })
                        }
               
               
              </tbody>
            </table>
          }
         {
            (this.props.data.isLoading) &&
             <AtomSpinner color="blue" style={{alignSelf: 'center',marginLeft: '40%',marginTop: '10%',marginBottom: '10%'}}/>
          }
        
          {
            (!this.props.data.isLoading && this.props.data.hasError) &&
            <Message message={"Error Loading Data"} icon_name={"warning"} />
          }
          {
            ( !this.props.data.isLoading && (this.props.data.list.length===0)) &&
            <Message message={"No Introducer Available"} icon_name={"account_box"} />
          }

          </CardBody>
        </Card>
      </Col>
    </Row>
    )
  }
}

class Trow extends React.Component{
  render(){

    var mod_link = '/modifyintroducer?int_id='+this.props.data.int_id;

    return(
         <tr>
                  <td>{this.props.index}</td>
                  <td>{this.props.data.int_id}</td>
                  <td>{this.props.data.name}</td>
                  <td><Link to={"/viewintroducer?id="+this.props.data.int_id}><Button theme="primary">View</Button></Link></td>
                  <td><a href={mod_link}  style={{color:'green'}} target={"_blank"}><i class="material-icons">edit</i></a></td>
          </tr>
    )
  }
}

class SearchBar extends React.Component{
  render(){

    return(
      <Col sm="12" md="6" lg="4">
        <InputGroup className="mb-3">
                    <FormInput placeholder="Search Collection Assistant..."/>
                  <InputGroupAddon type="prepend">
                      <Button theme="primary" addonType="append">Search <i class="material-icons">search</i></Button>
                  </InputGroupAddon>
              </InputGroup>
      </Col>
    )
  }
}

/* eslint jsx-a11y/anchor-is-valid: 0 */

import React from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardFooter,
  Button
} from "shards-react";

import PageTitle from "../components/common/PageTitle";
import {view} from 'react-easy-state';
import post_data from '../components/add-new-post/PostStore';
import {AtomSpinner} from 'react-epic-spinners';
import Message from '../components/Message';
import AlertBox from '../components/AlertBox';
import {Link,Redirect} from 'react-router-dom';

export default view(()=>{
    return <BlogPosts isLoading={post_data.isLoading} data={post_data.post_data}/>
})

class BlogPosts extends React.Component {
  componentDidMount(){
    post_data.getPostsData();
  }
  render() {

    return (
    <div>
      <AlertBox />
      <Container fluid className="main-content-container px-4">
        {/* Page Header */}
        <Row noGutters className="page-header py-4">
          <PageTitle sm="4" title="Blog Posts" subtitle="Components" className="text-sm-left" />
        </Row>

        <Link to="/add-new-post">
          <Button theme="primary" style={{margin:'2%'}}>Add New Post</Button>
        </Link>

        {
        (!this.props.isLoading) &&
        <Row>
          {this.props.data.map((post,idx) =>{
                return <Post data={post} key={idx}/>
          })} 
        </Row>
        }
        {
          (this.props.isLoading) &&
          <AtomSpinner color="blue" style={{alignSelf:'center',marginTop:'10%',marginBottom:'15%',marginLeft:'40%'}}/>
        }
        {
          ((!this.props.isLoading) && this.props.data.length===0) &&
          <Message message={"No Posts"} icon_name={"warning"} />
        }
      </Container>
    </div>
    );
  }
}

function Post(props){
  return(
     <Col lg="3" md="6" sm="12" className="mb-4" >
              <Card small className="card-post h-100">
                <div
                  className="card-post__image"
                  style={{ backgroundImage: `url('${props.data.data.photo_url}')` }}
                />
                <CardBody>
                    <h5 className="card-title">
                    <a href="#" className="text-fiord-blue">
                      {props.data.data.title}
                    </a>
                  </h5>
                  <p className="card-text">{props.data.data.description}</p>
                </CardBody>
                <CardFooter className="text-muted border-top py-3">
                    <Button theme="danger" onClick={()=>{post_data.removePostData(props.data.id)}}>Remove</Button>
                </CardFooter>
              </Card>
            </Col>
  )
}

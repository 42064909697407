import React from "react";
import {Card,CardHeader ,CardBody } from "shards-react";
import Message from '../Message';
import { AtomSpinner } from 'react-epic-spinners';
import profile_data from '../customerProfileStore';
import {view } from 'react-easy-state';
import getArray from '../getArray';
import formatDate from '../formatDate';
import formatRupee from '../formatRupee';

function getTotal(field,data){
    var total = 0;
    data.forEach(function(doc){
        total = total + parseInt(doc[field]);
    })
    return total;
}
  

function GetDiffDays(due_date){
          var date1 = new Date(due_date.toDate());
          var date2 = new Date();
          var timeDiff = date2.getTime() - date1.getTime();
          var diffDays = timeDiff / (1000 * 3600 * 24);

          return parseInt(diffDays);
}  

function getInterestTotal(data){

      var total = 0;
      data.forEach(function(doc){
        let diff_days = GetDiffDays(doc.due_date);
        let interest = diff_days > 0 ? (parseInt(doc.installment_val) * (0.0493150/100) )  * diff_days  :0;
        total = total + Math.floor(interest);
      })

      return total; 

}

export default view(()=>{
      var data = {
        data       : getArray(profile_data.viewDueData),
        isLoading   : profile_data.isInstLoading,
        hasError    : profile_data.hasErrorInstData,
        no_of_data : profile_data.no_of_inst_data,
      };

      return <DueTable data={data} />
})

class DueTable extends React.Component{
	render(){
    var inst_total = getTotal('installment_val',this.props.data.data);
    var dividend = getTotal('dividend',this.props.data.data);
    var other_charges = getTotal('extra_charges',this.props.data.data);
    var interest_total = getInterestTotal(this.props.data.data);
    var total = (inst_total + other_charges + interest_total) - dividend;
		
    return(

        <Card small className="mb-4 pt-0">
          <CardHeader className="border-bottom">
              <h6 className="m-0">Due Installments</h6>
          </CardHeader>

       
          
          <CardBody className="p-0 pb-3 scroll_list-group" >
        {
          (!this.props.data.isLoading && (this.props.data.data.length>0)) &&
            <table className="table mb-0">
              <thead className="bg-light">
                <tr>
                  <th scope="col" className="border-0">
                    #
                  </th>
                  <th scope="col" className="border-0">
                  	Group
                  </th>
                  <th scope="col" className="border-0">
                    Inst No.
                  </th>
                  <th scope="col" className="border-0">
                    Due Date
                  </th>
                  <th scope="col" className="border-0">
                    Inst Val
                  </th>
                  <th scope="col" className="border-0">
                    Dividend
                  </th>
                  <th scope="col" className="border-0">
                    Other Charges
                  </th>
                  <th scope="col" className="border-0">
                    Interest
                  </th>
                  <th scope="col" className="border-0">
                    Total
                  </th>
                </tr>

              </thead>
              <tbody>
                      
                      
                        {
                          this.props.data.data.map((item,i)=>{
                                return (<Trow key={i} data={item} index={i+1}/>)
                          })
                        }
                      

               </tbody>
               <thead className="bg-light">
                <tr>
                  <th scope="col" className="border-0">
                    Total
                  </th>
                  <th scope="col" className="border-0">
                  </th>
                  <th scope="col" className="border-0">
                  </th>
                  <th scope="col" className="border-0">
                  </th>
                  <th scope="col" className="border-0">
                    {formatRupee(inst_total)}
                  </th>
                  <th scope="col" className="border-0">
                    {formatRupee(dividend)}
                  </th>
                  <th scope="col" className="border-0">
                    {formatRupee(other_charges)}
                  </th>
                  
                  <th scope="col" className="border-0">
                    {formatRupee(interest_total)}
                  </th>
                  <th scope="col" className="border-0">
                    {formatRupee(total)}
                  </th>
                </tr>

              </thead>
             </table>
           }

                      {
                        this.props.data.isLoading &&
                        <AtomSpinner color="blue" style={{alignSelf: 'center',marginLeft: '40%',marginTop: '5%',marginBottom: '5%'}}/>
                      }
                      {
                        (!this.props.data.isLoading && this.props.data.hasError) &&
                        <Message message={"Error Loading Data"} icon_name={"warning"} /> 
                      }
                      {
                        ( (!this.props.data.isLoading) && (this.props.data.data.length===0) ) &&
                          <Message message={"No Due Payments..."} icon_name={"done_all"} />
                      }
           </CardBody>
          </Card>

		)
	}
}


class Trow extends React.Component{
  constructor(props) {
    super(props);
    this.state = {interest:0,total:0};
  
    this.get_total = this.get_total.bind(this);
    this.GetDiffDays = this.GetDiffDays.bind(this);
  }

  componentDidMount(){
      this.get_total();
  }

  GetDiffDays = (due_date) => {
          var date1 = new Date(due_date.toDate());
          var date2 = new Date();
          var timeDiff = date2.getTime() - date1.getTime();
          var diffDays = timeDiff / (1000 * 3600 * 24);

          return parseInt(diffDays);

    }  

  get_total=()=>{
      var diff_days = this.GetDiffDays(this.props.data.due_date);
      var interest = diff_days > 0 ? (parseInt(this.props.data.installment_val) * (0.0493150/100) )  * diff_days  :0;
      const total = parseInt(interest) + parseInt(this.props.data.installment_val)+parseInt(this.props.data.extra_charges===null?0:this.props.data.extra_charges)-parseInt(this.props.data.dividend);
      this.setState({total:total,interest:Math.floor(interest)});
  }


  render(){

    var isDue = (new Date()) > (new Date(this.props.data.due_date.toDate()))

    return(
           <tr style={{color:isDue?"red":'black'}}>
                  <td>{this.props.index}</td>
                  <td>{this.props.data.group_id+"/"+this.props.data.ticket_no}</td>
                  <td>{this.props.data.inst_no}</td>
                  <td>{formatDate(this.props.data.due_date.toDate())}</td>
                  <td>{formatRupee(this.props.data.installment_val)}</td>
                  <td>{formatRupee(this.props.data.dividend)}</td>
                  <td>{formatRupee(this.props.data.extra_charges)}</td>
                  <td>{formatRupee(this.state.interest)}</td>
                  <td>{formatRupee(this.state.total)}</td>
          </tr>
    )
  }
}
import {store} from 'react-easy-state';

const alert_data = store({
    showAlert : false,
    message : null,
    type : null,

    showMessage(message,type){
        alert_data.message = message;
        alert_data.showAlert = true;
        alert_data.type = type;
        alert_data.HideAlertAfter();
    },

    HideAlertAfter(){
        setTimeout(function(){ 
            alert_data.message = null;
            alert_data.showAlert = false; 
            alert_data.type = null;
        }, 10000);
    }

})

export default alert_data;
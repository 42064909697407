import React from 'react';
import { Container, Row, Col,Card , CardHeader,CardBody, FormCheckbox,FormGroup,FormInput,Button,
		Form,InputGroupAddon,InputGroup,InputGroupText,FormSelect,DatePicker } from "shards-react";
import PageTitle from "../common/PageTitle";
import FireFileUpload from '../FireFileUpload';
import firebase from '../../firebase';
import {toast} from 'react-toastify';
import { AtomSpinner } from 'react-epic-spinners';
import SelectGroup from '../SelectGroup';
import alert from '../AlertStore';
import AlertBox from '../AlertBox';

export default class AddNewCustomer extends React.Component{

  constructor(props) {
    super(props);
  
    this.state = {
        name  : null,

        group_id : null,
        ticket_no : null,
        
        year : null,
        no : null,
        
        isLoading:false,
    };

    this.AddNewCustomer = this.AddNewCustomer.bind(this);
    this.onSubmitHandler = this.onSubmitHandler.bind(this);
    this.setCustID = this.setCustID.bind(this);
  }

  setCustID(id){
    var temp = id.split('-');
    var year = temp[2];
    var no   = temp[3];
    this.setState({year:year,no:no});
  }

  componentDidMount(){
    var url_string = window.location.href;
    var url = new URL(url_string);
    var cid = url.searchParams.get("customer_id");
    if(cid!==undefined && cid!==null){
      this.setCustID(cid);
    }
    var name = url.searchParams.get("name");
    if(name!==undefined || name!==null)
      this.setState({name:name});
  }

 

  AddNewCustomer = () =>{
    var that = this;
    
    if(this.state.group_id===null){
      alert.showMessage("Kindly Enter Group ID","warning");
      return;
    }

    if(this.state.ticket_no===null){
      alert.showMessage("Kindly Enter Ticket No.","warning");
      return;
    }

    if(this.state.year===null && this.state.no===null){
      alert.showMessage("Kindly Enter Customer ID","warning");
      return;
    }

    var data = {
        cust_id : 'SGRSP-CUST-'+this.state.year+"-"+this.state.no,
        group_id : this.state.group_id,
        ticket_no : this.state.ticket_no,
    }
    console.log(data);
    this.setState({isLoading:true});
    var func_ref = firebase.functions().httpsCallable('addCustomerToGroup');
    func_ref(data).then(function(result) {
                  // Read result of the Cloud Function.
                  var data = result.data;
                  console.log(data);
                  if(data.status==="success"){
                        console.log("Document succesfully Add Newd!");
                        alert.showMessage("Customer Added to Group Successfully","success");
                        console.log(that.state);
                   }
                  else{
                        console.log(data.message);
                        alert.showMessage(data.message,"danger");
                  }
                  that.setState({isLoading:false});

        }).catch(function(error) {
                  // Getting the Error details.
                  //var code = error.code;
                  //var message = error.message;
                  //var details = error.details;
                  // ...
                  console.log(error);
                  that.setState({isLoading:false});
                  alert.showMessage("Unable to Add Customer","danger");
        });

  }

  onSubmitHandler = (evt) =>{
      evt.preventDefault();
      this.AddNewCustomer();
  }


  render(){

  	

    return(
      <div>
        <AlertBox />
    <Container fluid className="main-content-container px-4 pb-4">
        {/* Page Header */}
        <Row noGutters className="page-header py-4">
              <PageTitle sm="4" title="Customer" subtitle="Add New" className="text-sm-left" />
        </Row>

        

     	<Col lg="12">
        <Card small className="mb-4">
          <CardHeader className="border-bottom">
              <h6 className="m-0">Add New Customer</h6>
              
          </CardHeader>
          <CardBody className="p-2 pb-2" >
        { !this.state.isLoading &&		
         <Col>
          <Form onSubmit={this.onSubmitHandler}>
            <Row form>
              <Col md="4" className="form-group">
                <label htmlFor="feEmailAddress">CustomerID</label>

                <InputGroup className="mb-3">
                    <InputGroupAddon type="prepend">
                          <InputGroupText>SGRSP</InputGroupText>
                    </InputGroupAddon>
                    <InputGroupAddon type="prepend">
                          <InputGroupText>CUST</InputGroupText>
                    </InputGroupAddon>
                    <InputGroupAddon type="prepend">
                        <YearsSelect value={this.state.year} onChange={(evt)=>{this.setState({year:evt.target.value})}}/>
                    </InputGroupAddon>

                    <FormInput
                        id="feEmailAddress"
                        type="text"
                        placeholder="CustomerID"
                        onChange = {(evt)=>{this.setState({no:evt.target.value})}}
                        value={this.state.no}
                    />
              </InputGroup>
               {/* <FormInput
                  id="feEmailAddress"
                  type="text"
                  placeholder="CustomerID"
                  onChange = {(evt)=>{this.setState({cust_id:evt.target.value})}}
                />
              */}
              </Col>

            {

              (this.state.name!==null && this.state.name!==undefined) &&

              <Col md="4" className="form-group">
                <label htmlFor="feEmailAddress">Customer Name</label>
                <FormInput
                  id="feEmailAddress"
                  type="text"
                  placeholder="Customer Name"
                  disabled
                  value={this.state.name}
                />
              </Col>

            }

              <Col md="4">

                <label htmlFor="feEmailAddress">Group ID</label>
                <SelectGroup onChange={(evt)=>{this.setState({group_id:evt.target.value})}} />
              </Col>

              <Col md="4" className="form-group">
                <label htmlFor="feEmailAddress">Ticket No.</label>
                <FormInput
                  id="feEmailAddress"
                  type="text"
                  placeholder="Ticket No."
                  onChange = {(evt)=>{this.setState({ticket_no:evt.target.value})}}
                />
              </Col>
            </Row>
            <Row> 
              <Col md="4"></Col>
              <Col md="4">
                  <Button type="submit" style={{alignSelf: 'center',marginTop: '9%'}}>Add New Customer</Button>
              </Col>
              <Col md="4"></Col>
           </Row>
          </Form>
        </Col>
      }
      {
        this.state.isLoading &&
        <Col>
          <AtomSpinner color="blue" style={{alignSelf: 'center',marginLeft: '40%',marginTop: '10%',marginBottom: '10%'}}/>
        </Col>
      }
 
          </CardBody>
          </Card>
          </Col>
    </Container>
  </div>
    )
  }
}




class YearsSelect extends React.Component{
  render(){
    
    var currentYear = new Date().getFullYear(), years = [];
    var startYear = 2019;
    while ( startYear <= currentYear ) {
        years.push(startYear++);
    }   
    

    return(

      <FormSelect onChange ={this.props.onChange}>
        <option value={null}>{"Year"}</option>
        {

               years.map((item,i)=>{
                         return (<option key={i}>{item}</option>)
                  })
              
        } 
        

      </FormSelect>


    )
  }
}
import React from "react";
import { Row, Col, Card, CardHeader, CardBody,Button,FormCheckbox } from "shards-react";
import '../scroll.css';
import profit_data from '../profitStore';
import { AtomSpinner } from 'react-epic-spinners';
import { view } from 'react-easy-state';
import Message from '../Message';
import getArray from '../getArray';
import formatRupee from '../formatRupee';
import CsvDownloader from 'react-csv-downloader';
import login_data from '../../loginstore';
import { Redirect,Link } from "react-router-dom";



export default view(()=>{
    var data = {
      data : getArray(profit_data.data),
      isLoading : profit_data.isLoading,
      gotData : profit_data.gotData,
      hasError : profit_data.hasError,
      profit_total : profit_data.total_profit,
      expenditure_total : profit_data.total_expenditure,
      deleteListCount  : profit_data.deleteList.length,
    }
    
    console.log(data);
    console.log(data.data.length);
    return login_data.isLoggedIn?<ProfitTable data={data}/>:<Redirect to='/login' />;
    //return <ProfitTable data={data} />
})

class ProfitTable extends React.Component{
  render(){
    return(
    
      <Row>
      <Col>
        <Card small className="mb-4">
          <CardHeader className="border-bottom">
            <Row>
              <Col md="3" lg="3">
                <h6 className="m-0">Profit Analysis</h6>
              </Col>
              <Col md="6" lg="7">
              </Col>
              <Col md="3" lg="2">
                {
                  (this.props.data.data.length>0 && this.props.data.deleteListCount===0) &&
                  <CsvDownloader datas={this.props.data.data} filename="profit.csv" md="4"> 
                    <DownloadButton />
                  </CsvDownloader>
                }
                {
                  (this.props.data.deleteListCount>0) &&
                  <Button theme="danger" onClick={profit_data.deleteAllSelected}>Delete</Button>
                }
                
              </Col>
            </Row>
          </CardHeader>

       
          
          <CardBody className="p-0 pb-3 scroll_list-group" >
          { (!this.props.data.isLoading  && this.props.data.data.length!==0) && 
            <table className="table mb-0">
              <thead className="bg-light">
                <tr>
                  <th scope="col" className="border-0">
                      #
                  </th>
                  <th scope="col" className="border-0">
                     Profit
                  </th>
                  <th scope="col" className="border-0">
                      Expenditure
                  </th>
                  <th scope="col" className="border-0">
                      Description
                  </th>
                  <th scope="col" className="border-0">
                      Payment Method
                  </th>
                  <th scope="col" className="border-0">
                      Document1
                  </th>
                  <th scope="col" className="border-0">
                      Document2
                  </th>
                  <th scope="col" className="border-0">
                      Date
                  </th>
                  <th scope="col" className="border-0">
                      Delete
                  </th>

                </tr>
              </thead>
              <tbody>

                 {
                          this.props.data.data.map((item,i)=>{
                                return (<Trow key={i} data={item} index={i+1}/>)
                          })
                  }

              
               
              </tbody>
              <thead>
              <tr>
                  <th scope="col" className="border-0">
                     Total
                  </th>
                  <th scope="col" className="border-0">
                      {formatRupee(this.props.data.profit_total)}
                  </th>
                  <th scope="col" className="border-0" style={{color:'red'}}>
                      {formatRupee(this.props.data.expenditure_total)}
                  </th>
                  <th scope="col" className="border-0" style={{color:'green'}}>
                      {formatRupee(this.props.data.profit_total-this.props.data.expenditure_total)}
                  </th>
                  <th scope="col" className="border-0">
                  </th>
                  <th scope="col" className="border-0">
                  </th>
                  <th scope="col" className="border-0">
                  </th>
                  <th scope="col" className="border-0">
                  </th>
                  <th scope="col" className="border-0">
                  </th>
                  <th scope="col" className="border-0">
                  </th>

                </tr>
                
              </thead>
            </table>
          }

            {
                (this.props.data.isLoading) &&
                <AtomSpinner color="blue" style={{alignSelf: 'center',marginLeft: '40%',marginTop: '10%',marginBottom: '10%'}}/>
            }
            {
                (!this.props.data.isLoading && this.props.data.hasError && this.props.data.gotData  ) &&
                <Message message={"Error Loading Data"} icon_name={"warning"} />
            }
            {
                ( !this.props.data.isLoading && (this.props.data.data.length===0) && this.props.data.gotData ) &&
                <Message message={"No Data Avaiable"} icon_name={"sentiment_dissatisfied"} />
            }
            {
              (!this.props.data.isLoading && !this.props.data.gotData ) && 
              <Message message={"Kindly Select Start and End Date"} icon_name={"call_missed"} />
            }

          </CardBody>
        </Card>
      </Col>
    </Row>
    )
  }
}

class DownloadButton extends React.Component{
  render(){
    return(
      <Col sm="12" md="6" lg="4" {...this.props.style}>
        <Button theme="accent" size="md" >Download</Button>
      </Col>
    )
  }
}


class Trow extends React.Component{
  constructor(props) {
    super(props);
  
    this.state = {isChecked:false};
    this.handleCheck = this.handleCheck.bind(this);
  }

  handleCheck = () =>{
    if(this.state.isChecked){
        this.setState({isChecked:false});
        profit_data.removeFromDeleteList(this.props.data.id);
    }
    else{
      this.setState({isChecked:true});
      profit_data.addToDeleteList(this.props.data.id);
      console.log("Checked");
    }
  }
  render(){
    return(

          <tr style={{backgroundColor:this.state.isChecked?'#d9d9d9':'white',color:this.props.data.type==="expenditure"?"red":'black'}}>
                  <td>{this.props.data.id}</td>
                  <td>{formatRupee(this.props.data.type==="profit"?this.props.data.amount:0)}</td>
                  <td>{formatRupee(this.props.data.type==="expenditure"?this.props.data.amount:0)}</td>
                  <td>{this.props.data.description}</td>
                  <td>{this.props.data.payment_method}</td>
                  <td><a target="_blank" href={this.props.data.doc1_link}>Link</a></td>
                  <td><a target="_blank" href={this.props.data.doc2_link}>Link</a></td>
                  <td>{this.props.data.ref_date}</td> 
                  <td><FormCheckbox checked={this.state.isChecked} onChange={this.handleCheck}></FormCheckbox></td>      
          </tr>

    )
  }
}


import React from "react";
import {Card,CardHeader ,CardBody,Button } from "shards-react";
import Message from '../Message';
import { AtomSpinner } from 'react-epic-spinners';
import profile_data from '../customerProfileStore';
import {  view } from 'react-easy-state';
import view_data from '../payInstallmentStore';
import getArray from '../getArray';
import formatDate from '../formatDate';
import formatRupee from '../formatRupee';

export default view(()=>{
      var data = {
        data       : getArray(profile_data.viewReceiptData),
        isLoading   : profile_data.isReceiptLoading,
        hasError    : profile_data.hasErrorReceiptData,
        no_of_data : profile_data.no_of_receipt_data,
      };
      console.log(data);

      return <Receipt data={data} />
})


class Receipt extends React.Component{
  render(){
    return(
       <Card small className="mb-4 pt-3">
          <CardHeader className="border-bottom">
              <h6 className="m-0">Reciept Details</h6>
          </CardHeader>

          <CardBody className="p-0 pb-3 scroll_list-group" >
           
          { (!this.props.data.isLoading && this.props.data.no_of_data>0) &&
            <table className="table mb-0">
              <thead className="bg-light">
                <tr>
                  <th scope="col" className="border-0">
                    Recpt. No.
                  </th>
                  <th scope="col" className="border-0">
                    Group
                  </th>
                  <th scope="col" className="border-0">
                    Inst No.
                  </th>
                  <th scope="col" className="border-0">
                    MR No.
                  </th>
                  <th scope="col" className="border-0">
                    MR Date
                  </th>
                  <th scope="col" className="border-0">
                    Paid Date
                  </th>
                  <th scope="col" className="border-0">
                    Paid
                  </th>
                  <th scope="col" className="border-0">
                    Excess Paid
                  </th>
                  <th scope="col" className="border-0">
                    Payment Method
                  </th>

                  <th scope="col" className="border-0">
                      View
                  </th>
                </tr>
              </thead>
              <tbody>
                      {
                          this.props.data.data.map((item,i)=>{
                                return (<Trow key={i} data={item} index={i+1}/>)
                          })
                        }
               
               </tbody>
             </table>
           }
                    {
                        this.props.data.isLoading &&
                        <AtomSpinner color="blue" style={{alignSelf: 'center',marginLeft: '40%',marginTop: '5%',marginBottom: '5%'}}/>
                      }
                      {
                        (!this.props.data.isLoading && this.props.data.hasError) &&
                        <Message message={"Error Loading Data"} icon_name={"warning"} /> 
                      }
                      {
                        ( (!this.props.data.isLoading) && (this.props.data.no_of_data===0) ) &&
                          <Message message={"No Payments Yet..."} icon_name={"payment"} />
                      }
           </CardBody>
          </Card>

    )
  }
}


class Trow extends React.Component{
  render(){

    var inst_no = "";
    this.props.data.inst_ids.forEach(function(inst_id){
        var arr = inst_id.id.split('_');
        inst_no = inst_no + arr[2] + ",";
    })


    inst_no = inst_no.length>0?inst_no:"NA";

    return(
           <tr style={{color:(this.props.data.valid?"black":"red")}}>
                  <td>{this.props.data.receipt_id}</td>
                  <td>{this.props.data.group_id+"/"+this.props.data.ticket_no}</td>
                  <td>{inst_no}</td>
                  <td>{this.props.data.mr_id===null?"NA":this.props.data.mr_id}</td>
                  <td>{this.props.data.mr_date===null?"NA":formatDate(this.props.data.mr_date.toDate())}</td>
                  <td>{formatDate(this.props.data.paid_date.toDate())}</td>
                  <td>{formatRupee(this.props.data.customer_paid)}</td>
                  <td>{formatRupee(this.props.data.excess_paid)}</td>
                  <td>{this.props.data.payment_method}</td>
                  <td><Button theme="primary" onClick={()=>{view_data.viewReceipt(this.props.data)}}>View</Button></td>
                  
          </tr>

    )
  }
}
import React from "react";
import { Container, Row,} from "shards-react";
import Table from './Table';
import PageTitle from "../common/PageTitle";
import '../scroll.css';
import {view} from 'react-easy-state';
import { Redirect } from "react-router-dom";
import login_data from '../../loginstore';
import AlertBox from '../AlertBox';

export default view(()=>{
   
    return login_data.isLoggedIn?<Files />:<Redirect to='/login' />;
})


class Files extends React.Component{
    render(){
      return(
      <div>
        <AlertBox />
            <Container fluid className="main-content-container px-4">
    {/* Page Header */}
    <Row noGutters className="page-header py-4">
      <PageTitle sm="4" title="File Manager" subtitle="Dashboard" className="text-sm-left" />
    </Row>

    {/* Default Light Table */}
    <Table />

  </Container>
</div>
        )
    }
}


import React from 'react';
import { Container, Row, Col,Card , CardHeader,CardBody,FormInput,Button,
    Form, } from "shards-react";
import PageTitle from "../common/PageTitle";
import firebase from '../../firebase';
import { AtomSpinner } from 'react-epic-spinners';
import AlertBox from '../AlertBox';
import alert from '../AlertStore';
import {Link} from 'react-router-dom';


export default class ApproveAuction extends React.Component{
  constructor(props) {
    super(props);
  
    this.state = {	
                loading  :false, 
            		group_id : null,
            		prev_timings : null,
            		auct_id  : null,
                auct_no : null,
            		next_timings : null,
                ticket_no : null,
                bid_amount : null,
            };
    this.ApproveAuction = this.ApproveAuction.bind(this);
  }


  componentDidMount(){
      var url_string = window.location.href;
      var url = new URL(url_string);
      
      var group_id = url.searchParams.get("group_id");
      var prev_timings =url.searchParams.get('prev_timings');
      var auct_id = url.searchParams.get('auct_id');
      var auct_no = url.searchParams.get('auct_no');

      this.setState({
            group_id,
            prev_timings,
            auct_id,
            auct_no
      });
  }

  ApproveAuction = () =>{
  		if(this.state.next_timings===null || this.state.next_timings===undefined){
  			alert.showMessage("Kindly Enter New Auction Timings","warning");
  			return;
  		}
      if(this.state.ticket_no===null || this.state.ticket_no === undefined){
        alert.showMessage("Kindly Enter Bidder Ticket No","warning");
        return;
      }
      if(this.state.bid_amount===null || this.state.bid_amount===undefined){
        alert.showMessage("Kindly Enter Bid Amount","warning");
        return;
      }
  		if(this.state.auct_id===null || this.state.auct_id===undefined  || this.state.prev_timings===undefined || this.state.prev_timings===null ){
  			alert.showMessage("Error : A Required Parameter is Missing","warning");
  			return;
  		}

  		var data = {
  			auct_id : this.state.auct_id,
        ticket_no : this.state.ticket_no,
        bid_amount : this.state.bid_amount,
  			next_auction_time : new Date(this.state.next_timings).toUTCString(),
        group_id : this.state.group_id,
        auct_no : this.state.auct_no,
  		}
  		var that = this;

  		this.setState({loading:true});

		var func_ref = firebase.functions().httpsCallable('approveAuction');

		func_ref(data).then(function(result) {
                  // Read result of the Cloud Function.
                  var data = result.data;
                  console.log(data);
                  if(data.status==="success"){
                        console.log("Document succesfully added!");
                        alert.showMessage("Auction Approved Succesfully","success");
                        window.close();
                   }
                  else{
                        console.log(data.message);
                        alert.showMessage("Error:"+data.message,"danger");
                  }
                  that.setState({loading:false});

        }).catch(function(error) {
                  // Getting the Error details.
                  //var code = error.code;
                  //var message = error.message;
                  //var details = error.details;
                  // ...
                  console.log(error);
                  that.setState({loading:false});
                  alert.showMessage("Unable to Approve Auction","danger");
        });



  }

 

  render(){


    return(
      <div>
        <AlertBox />
       <Container fluid className="main-content-container px-4 pb-4">
          
            {/* Page Header */}
            <Row noGutters className="page-header py-4">
                  <PageTitle sm="4" title="Approve" subtitle="Auction" className="text-sm-left" />
            </Row>

            <Link to="/auction"><Button type="button" className={"mb-3 ml-3"} theme="danger">Cancel</Button></Link>
          


          <Col lg="12">
               <Card small className="mb-4">
                    <CardHeader className="border-bottom">
                          <h6 className="m-0">Approve Auction</h6>
                    </CardHeader>
                    <CardBody className="p-2 pb-2" >
                      {
                        !this.state.loading &&
                        <Col>
                          <Form /*onSubmit={this.onSubmitHandler}*/>
                            <Row form>
                              <FormTextInput size="4" type="text" field_name="Group ID" disabled value={this.state.group_id} onChange={()=>{}}/>   
                              <FormTextInput size="2" type="text" field_name="Auct No." disabled value={this.state.auct_no} onChange={()=>{}}/>
                              <FormTextInput size="6" type="text" field_name="Auction Timings" disabled value={this.state.prev_timings} onChange={()=>{}} />
                            </Row>
                            <Row form>
                                <FormTextInput size="4" type="number" field_name="Ticket No." onChange={(evt)=>{this.setState({ticket_no:evt.target.value})}}/> 
                                <FormTextInput size="4" type="number" field_name="Bid Amount" onChange={(evt)=>{this.setState({bid_amount:evt.target.value})}}/> 
                             	  <FormTextInput size="4" type="datetime-local" field_name="Next Auction Timings" onChange={(evt)=>{this.setState({next_timings:new Date(evt.target.value)})}}/>   
                            
                            </Row>
                          

                              <SubmitButton onClick={this.ApproveAuction} />
                              
                          </Form>
                        </Col>
                      }
                      
                        {
                          (this.state.loading) &&
                          <AtomSpinner color="blue" style={{alignSelf: 'center',marginLeft: '40%',marginTop: '10%',marginBottom: '10%'}}/>
                        }
                      
                    </CardBody>
                </Card>
              </Col>


        </Container>
      </div>

    )
  }
}


class SubmitButton extends React.Component{
  render(){
    return(
      <Row>
                            <Col lg="4"></Col>
                                <Col lg="4">
                                  <Button onClick={this.props.onClick} style={{maxWidth: '100%',width:'100%',marginTop: '1%'}} size="md">
                                    {"Approve Auction"}
                                  </Button>
                                </Col>
                                <Col lg="4"></Col>
                            </Row>

    )
  }
}

//dont use for date
class FormTextInput extends React.Component{
  render(){
    return(
       <Col md={this.props.size} className="form-group">
                  <label>{this.props.field_name}</label>
                  <FormInput
                  {...this.props}
                  type={this.props.type}
                  placeholder={this.props.field_name}
                  onChange = {this.props.onChange}
                />

              </Col>
    )
  }
}


import React from 'react';
export default class Message extends React.Component{
    render(){
      return(
         
              <div style={{marginTop: '10%'}} className="text-muted">
                        <div  style={{fontSize:40,textAlign: 'center',color:'blue' }}>
                          {this.props.message}
                        </div>
                        <div style={{margin: '2%',marginLeft: "0%",fontSize: 60,textAlign: 'center',color:'blue'}}>
                             <i class="material-icons">{this.props.icon_name}</i>  
                        </div>                  
                    </div>
              
        )
    }
}
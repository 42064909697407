import React from 'react';
import { Container, Row, Col,Card , CardHeader,CardBody, FormCheckbox,FormGroup,FormInput,Button } from "shards-react";
import PageTitle from "../common/PageTitle";
import { view } from 'react-easy-state';
import login_data from '../../loginstore';
import { Redirect,Link } from "react-router-dom";
import AlertBox from '../AlertBox';

export default view(()=>{

    return login_data.isLoggedIn?<Settings />:<Redirect to='/login' />;
})

class Settings extends React.Component{
  render(){
    return(
    <div>
      <AlertBox />

    <Container fluid className="main-content-container px-4 pb-4">
        {/* Page Header */}
        <Row noGutters className="page-header py-4">
              <PageTitle sm="4" title="Settings" subtitle="Dashboard" className="text-sm-left" />
        </Row>

      <Row>

     


      

      </Row>

      <Row>

       <Col lg="3">
        <Card small className="mb-4">
          <CardHeader className="border-bottom">
              <h6 className="m-0">Group</h6>
          </CardHeader>
          <CardBody className="p-0 pb-0" >
            <ol>
               <li><h6 style={{margin: '2%'}}><Link to="/addnewgroup">Add New Group</Link></h6></li>
               <li><h6 style={{margin: '2%'}}><Link to="/removegroup">Remove Group</Link></h6></li> 
               <li><h6 style={{margin: '2%'}}><Link to="/modifygroup">Modify Group</Link></h6></li> 
               <li><h6 style={{margin: '2%'}}><Link to="/addcustomergroup">Add Customers to Group</Link></h6></li>
              

          </ol>
          </CardBody>
          </Card>
      </Col>

      <Col lg="3">
        <Card small className="mb-4">
          <CardHeader className="border-bottom">
              <h6 className="m-0">Customer</h6>
          </CardHeader>
          <CardBody className="p-0 pb-0" >
            <ol>
               <li><h6 style={{margin: '2%'}}><Link to="/addcustomer">Add Customer</Link></h6></li> 
               <li><h6 style={{margin: '2%'}}><Link to="/marklean">Lean Management</Link></h6></li>
               <li><h6 style={{margin: '2%'}}><Link to="/removecustomer">Remove Customer</Link></h6></li>
               <li><h6 style={{margin: '2%'}}><Link to="/modifycustomer">Modify Customer</Link></h6></li>
               <li><h6 style={{margin: '2%'}}><Link to="/replacecustomer">Remove and Replace Customer Ticket</Link></h6></li> 
               <li><h6 style={{margin: '2%'}}><Link to="/transferchit">Transfer Customer Ticket</Link></h6></li>   
               
          </ol>
          </CardBody>
          </Card>
      </Col>


      <Col lg="3">
        <Card small className="mb-4">
          <CardHeader className="border-bottom">
              <h6 className="m-0">Introducer</h6>
          </CardHeader>
          <CardBody className="p-0 pb-0" >
            <ol>
               <li><h6 style={{margin: '2%'}}><Link to="/addintroducer">Add Introducer</Link></h6></li> 
               <li><h6 style={{margin: '2%'}}><Link to="/removeintroducer">Remove Introducer</Link></h6></li>   
            
          </ol>
          </CardBody>
          </Card>
      </Col>

       <Col lg="3">
        <Card small className="mb-4">
          <CardHeader className="border-bottom">
              <h6 className="m-0">Collection Assistant</h6>
          </CardHeader>
          <CardBody className="p-0 pb-0" >
            <ol>
               <li><h6 style={{margin: '2%'}}><Link to="/addcollectionasst">Add Collection Asst</Link></h6></li> 
               <li><h6 style={{margin: '2%'}}><Link to="#">Remove Collection Asst</Link></h6></li>   
              
          </ol>
          </CardBody>
          </Card>
      </Col>

        <Col lg="3">
          <Card small className="mb-4">
            <CardHeader className="border-bottom">
                <h6 className="m-0">Receipts</h6>
            </CardHeader>
            <CardBody className="p-0 pb-0" >
              <ol>
                 <li><h6 style={{margin: '2%'}}><Link to="/invalidatereceipt">Invalidate Receipt</Link></h6></li>  
              </ol>
           </CardBody>
          </Card>
      </Col>

      <Col lg="3">
          <Card small className="mb-4">
            <CardHeader className="border-bottom">
                <h6 className="m-0">Report</h6>
            </CardHeader>
            <CardBody className="p-0 pb-0" >
              <ol>
                 <li><h6 style={{margin: '2%'}}><Link to="/report">Report Bug</Link></h6></li>  
              </ol>
           </CardBody>
          </Card>
      </Col>

     

      </Row>

    </Container>
  </div>
    )
  }
}
import React from "react";
import {view} from 'react-easy-state';
import {Modal,ModalBody,ModalHeader,Col,ListGroup,Row,ListGroupItem,Button} from 'shards-react';
import ca_data from '../collectionAssistantListStore';
import formatDate from '../formatDate';
import {AtomSpinner} from 'react-epic-spinners';


export default view((props)=>{

    return <ViewCollectionAssistant id={props.id} isLoading={ca_data.isLoading} data={ca_data.viewCaData} />
})

class ViewCollectionAssistant extends React.Component {
  constructor(props) {
    super(props);
    this.state = {show:false};
    this.toggle = this.toggle.bind(this);
  }
  toggle() {
    this.setState({show:!this.state.show});
  }

  render() {
    const  open  = this.state.show;
    return (
      <div>
        <Modal open={open} toggle={this.toggle}>
          
          <ModalHeader>{"Collecton Assistant"}</ModalHeader>
          <ModalBody>
              {
                  (!this.props.isLoading) &&
              <ListGroup flush className="modalScroll">
                  <Item field={"CA ID"} value={this.props.data.ca_id}/>
                  <Item field={"Name"} value={this.props.data.name}/>
                  <Item field={"Email"} value={this.props.data.email}/>
                  <Item field={"Phone"} value={this.props.data.phone}/>
                  <Item field={"Father/Husband Name"} value={this.props.data.father_husband_name}/>
                  <Item field={"Aadhar"} value={this.props.data.aadhar}/>
                  <Item field={"Bank Account No."} value={this.props.data.bank_accnt_no}/>
                  <Item field={"IFSC Code"} value={this.props.data.ifsc_code}/>
                  <Item field={"Status"} value={this.props.data.status}/>
                  <Item field={"Address"} value={this.props.data.address}/>
              </ListGroup>
            }
            {
                (this.props.isLoading) &&
                <AtomSpinner color={"blue"}/>
            }

          </ModalBody>
        </Modal>
        <Button theme="primary" onClick={()=>{ca_data.getCollectionAssistant(this.props.id);this.toggle();}}>View</Button>
      </div>
    );
  }
}


class Item extends React.Component{
  render(){
    return(
      <ListGroupItem className="px-4">
        <div className="progress-wrapper">
          <Row>
          <Col md="6">
          <strong className=" d-block mb-2" style={{marginLeft: '5%',color:'grey'}}>
            {this.props.field}
          </strong>
          </Col>
          <Col md="6">
          <strong className="d-block mb-2" style={{marginLeft: '2%',color:'blue'}}>
            {this.props.value}
          </strong>
          </Col>
        </Row>

    
        </div>
      </ListGroupItem>
    )
  }
}

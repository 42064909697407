import React from 'react';
import { Container, Row,} from "shards-react";

import PageTitle from "../common/PageTitle";
import '../scroll.css';
import {view} from 'react-easy-state';
import { Redirect } from "react-router-dom";
import login_data from '../../loginstore';
import SMSLogTable from './SMSLogTable';
import AlertBox from '../AlertBox';

export default view(()=>{
   
    return login_data.isLoggedIn?<SMSLog />:<Redirect to='/login' />;
    //return <SMSLog />
})


class SMSLog extends React.Component{
    s
    
    render(){
        return(
        <div>
            <AlertBox />
            <Container fluid className="main-content-container px-4">
                {/* Page Header */}
                <Row noGutters className="page-header py-4">
                    <PageTitle sm="4" title="Log" subtitle="SMS" className="text-sm-left" />
                </Row>

                <SMSLogTable />

            </Container>
        </div>
        )
    }
}
import { store } from 'react-easy-state';
import firebase from '../firebase';
import alert from './AlertStore';


const profile_data = store({
						all_tickets_list : [],
						selected_ticket : {group_id:'any',ticket:'any'},

						setSelectedTicket(groupticket){
							if(groupticket==="All"){
								profile_data.selected_ticket.group_id = 'any';
								profile_data.selected_ticket.ticket_no = 'any';
							}
							else{
								var temp = groupticket.split('/');
								profile_data.selected_ticket.group_id = temp[0];
								profile_data.selected_ticket.ticket_no = temp[1];
							}	
							profile_data.filterData();
						},

						filterData(){
							if(profile_data.selected_ticket.group_id==='any'){
								profile_data.viewDueData = profile_data.due_inst_data;
								profile_data.viewAuctData = profile_data.auction_data;
								profile_data.viewReceiptData = profile_data.receipt_data;
								profile_data.viewVoucherData = profile_data.voucher_data;
								console.log("Any = ",profile_data.voucher_data, " view = ",this.viewVoucherData);
								return;
							}

							var group_id = profile_data.selected_ticket.group_id;
							var ticket   = profile_data.selected_ticket.ticket_no;
							var auctData = profile_data.auction_data;
							var duedata  = profile_data.due_inst_data;
							var receipt_data = profile_data.receipt_data;
							var voucher_data = profile_data.voucher_data;

							var temp = [];

							auctData.forEach(function(doc){
								if(doc.group_id===group_id && doc.bidder_ticket_no===ticket.toString()){
									temp.push(doc);
								}
							})
							profile_data.viewAuctData = temp;
							profile_data.no_of_auct_data = temp.length;
							temp = [];
							duedata.forEach(function(doc){
								console.log("Group ID=",doc.group_id," Ticket =",doc.ticket_no);
								if(doc.group_id===group_id && doc.ticket_no.toString()===ticket){
									temp.push(doc);
								}
							})
							profile_data.viewDueData = temp;
							profile_data.no_of_inst_data = temp.length;							
							temp = [];
							receipt_data.forEach(function(doc){
								if(doc.group_id===group_id && doc.ticket_no===parseInt(ticket)){
									temp.push(doc);
								}
							})
							profile_data.viewReceiptData = temp;
							profile_data.no_of_receipt_data = temp.length;
							temp = [];

							voucher_data.forEach(function(doc){
								if(doc.group_id===group_id && doc.ticket_no===parseInt(ticket)){
									temp.push(doc);
								}
							})
							profile_data.viewVoucherData = temp;
							profile_data.no_of_voucher_data = temp.length;
							temp = [];

						},	


						fetched_url_cust:false,

						customerID : null,
						viewByCustomerID : true,

						group_id : null,
						ticket_no : null,
						
						customerData : null,
						isProfileLoading : false,
						hasProfileData :false,
						hasErrorProfileData : false,
						gotProfileData : false,

						isChitsDataLoading : false,
						hasErrorChitsData : false,
						chits_data : [],

						isInstLoading : false,
						hasErrorInstData:false,
						due_inst_data : [],
						viewDueData : [],
						no_of_inst_data : 0,

						isAuctionLoading : false,
						hasErrorAuctionData : false,
						auction_data : [],
						viewAuctData : [],
						no_of_auct_data : 0,

						isReceiptLoading : false,
						hasErrorReceiptData : false,
						receipt_data : [],
						viewReceiptData : [],
						no_of_receipt_data : 0,

						isVoucherLoading : false,
						hasErrorVoucherData : false,
						voucher_data : [],
						viewVoucherData : [],
						no_of_voucher_data : 0,

						async getCustomerData(){
							if(profile_data.customerID === null || profile_data.customerID === undefined || profile_data.is_empty_string(profile_data.customerID) ){
								return;
							}

							profile_data.isProfileLoading = true;
							await firebase.firestore().collection('Customer').doc(profile_data.customerID).get().then(function(doc){
								if(doc.exists){
										profile_data.isProfileLoading = false;
										profile_data.customerData = doc.data();
										profile_data.hasProfileData = true;
								}
								else{
									profile_data.hasProfileData = false;
								}

								profile_data.gotProfileData = true;
								profile_data.hasErrorProfileData = false;

							}).catch(function(err){
								profile_data.hasErrorProfileData = true;
								profile_data.gotProfileData = true;
								profile_data.isProfileLoading = false;
								profile_data.hasProfileData = false;

								console.error(err);
							})
						},

						async getChitsData(){
							if(profile_data.customerID === null || profile_data.customerID === undefined || profile_data.is_empty_string(profile_data.customerID)){
								return;
							}
							profile_data.isChitsDataLoading = true;
							profile_data.all_tickets_list = [];
							profile_data.no_of_tickets = 0;
							await firebase.firestore().collection('GroupCustomer').where('custID','==',profile_data.customerID).get().then(function(snap){
									var temp = [];
									snap.forEach(function(doc){
										var thiscustdata = doc.data();
										temp.push(thiscustdata);
										profile_data.all_tickets_list.push(thiscustdata.group_id+"/"+thiscustdata.ticket_no);
										profile_data.no_of_tickets = profile_data.no_of_tickets + 1;
									})
									profile_data.chits_data = temp;
									profile_data.isChitsDataLoading = false;
									profile_data.hasErrorChitsData = false;
							}).catch(function(err){
									console.error(err);
									profile_data.hasErrorChitsData = true;
									profile_data.isChitsDataLoading = false;
							})

						},

						async getInstData(){

							if(profile_data.hasErrorChitsData || profile_data.chits_data.length===0){
								return;
							}
							profile_data.isInstLoading = true;
							
							var query = firebase.firestore().collection('Installment');
							var inst_arr = [];
							profile_data.chits_data.forEach(function(chit){
								
								for(var i=0;i<chit.installment_payments_detail.length;i++){
									if(chit.installment_payments_detail[i].paid === false){
										inst_arr.push({key:chit.installment_payments_detail[i].name,ticket_no:chit.ticket_no});
									}
								
								}
									
							})

							
							if(inst_arr.length===0){
									profile_data.isInstLoading = false;
									return;
							}

							profile_data.due_inst_data = []; 
							profile_data.no_of_inst_data = 0;
							inst_arr.forEach(function(inst_id){
								
								 query.doc(inst_id.key).get().then(function(doc){
								 		if(doc.exists){
								 			var data = doc.data();
								 			data['ticket_no'] = inst_id.ticket_no;
											profile_data.due_inst_data.push(data);
											profile_data.isInstLoading = false;
											profile_data.hasErrorInstData = false;
											profile_data.no_of_inst_data = profile_data.no_of_inst_data+1;
											profile_data.viewDueData = profile_data.due_inst_data;
								 		}
								 		else{
								 			profile_data.hasErrorInstData = false;
											profile_data.isInstLoading = false;
								 		}
								 			
								}).catch(function(err){
										profile_data.hasErrorInstData = true;
										profile_data.isInstLoading = false;
										console.error(err);
								})
							
							})
							

						},

						async getAuctionData(){
							if(profile_data.customerID === null || profile_data.customerID === undefined || profile_data.is_empty_string(profile_data.customerID) ){
								return;
							}

							profile_data.isAuctionLoading = true;
							profile_data.no_of_auct_data = 0;
							await firebase.firestore().collection('Auction').where('bidder_cust_id','==',profile_data.customerID).get().then(function(snap){

								var temp = [];
								
								snap.forEach(function(doc){
									temp.push(doc.data());
								})

								profile_data.no_of_auct_data = snap.size;
								profile_data.auction_data = temp;
								profile_data.viewAuctData = temp;
								profile_data.isAuctionLoading = false;
								profile_data.hasErrorAuctionData = false;
							}).catch(function(error){
									console.error(error);
									profile_data.isAuctionLoading = false;
									profile_data.hasErrorAuctionData = true;
							})
						},

						async getReceiptData(){

							if(profile_data.customerID === null || profile_data.customerID === undefined || profile_data.is_empty_string(profile_data.customerID) ){
								return;
							}

							profile_data.isReceiptLoading = true;
							profile_data.no_of_receipt_data = 0;
							await firebase.firestore().collection('Receipt').where('cust_id','==',profile_data.customerID).orderBy('receipt_id','desc').get().then(function(snap){
								var temp = [];

								snap.forEach(function(doc){
									temp.push(doc.data());
								})

								profile_data.no_of_receipt_data = snap.size;
								profile_data.receipt_data = temp;
								profile_data.viewReceiptData = temp;
								profile_data.isReceiptLoading = false;
								profile_data.hasErrorReceiptData = false;
							}).catch(function(error){
								profile_data.isReceiptLoading = false;
								profile_data.hasErrorReceiptData = true;
								console.error(error);	
							})
						},

						async getVoucherData(){

							if(profile_data.customerID === null || profile_data.customerID === undefined || profile_data.is_empty_string(profile_data.customerID) ){
								return;
							}

							profile_data.isVoucherLoading = true;
							profile_data.no_of_voucher_data = 0;
							await firebase.firestore().collection('GeneralVoucher').where('custID','==',profile_data.customerID).orderBy('date','desc').get().then(function(snap){
								var temp = [];

								snap.forEach(function(doc){
									temp.push(doc.data());
								})
								console.log("Voucher Size",snap.size);
								console.log("Voucher Data ",temp);
								profile_data.no_of_voucher_data = snap.size;
								profile_data.voucher_data = temp;
								profile_data.viewVoucherData = temp;
								profile_data.isVoucherLoading = false;
								profile_data.hasErrorVoucherData = false;

								
							}).catch(function(error){
								profile_data.isVoucherLoading = false;
								profile_data.hasErrorVoucherData = true;
								console.error(error);	
							})
						},


						async getCustomerByGroupAndTicketNo(code){

							if(code===null || code===undefined){
								return;
							}

							profile_data.isProfileLoading = true;

							await firebase.firestore().collection('GroupCustomer').doc(code).get().then(function(doc){
								if(doc.exists){
									profile_data.customerID = doc.data().custID;
								}
								else{
									alert.showMessage("Ticket No Doesnt Exist",'danger');
									profile_data.customerID = null;
									profile_data.hasProfileData = false;
								}
								profile_data.isProfileLoading = false;
								profile_data.hasProfileData = true;
							}).catch(function(err){
								console.error(err);
								profile_data.isProfileLoading = false;
								profile_data.customerID = null;
								profile_data.hasProfileData = false;
							})

						},

						resetAllData(){
						
							profile_data.customerData = null;
							profile_data.isProfileLoading = false;
							profile_data.hasProfileData = false;
							profile_data.hasErrorProfileData = false;
							profile_data.gotProfileData = false;

							profile_data.isChitsDataLoading = false;
							profile_data.hasErrorChitsData = false;
							profile_data.chits_data = [];

							profile_data.isInstLoading = false;
							profile_data.hasErrorInstData = false;
							profile_data.due_inst_data = [];

							profile_data.isAuctionLoading = false;
							profile_data.hasErrorAuctionData = false;
							profile_data.auction_data = [];

							profile_data.isReceiptLoading = false;
							profile_data.hasErrorReceiptData = false;
							profile_data.receipt_data = [];

							profile_data.isVoucherLoading = false;
							profile_data.hasErrorVoucherData = false;
							profile_data.voucher_data = [];
						},

						getProfile(){
							

							if(!profile_data.viewByCustomerID){

								if(profile_data.is_empty_string(profile_data.group_id) || profile_data.is_empty_string(profile_data.ticket_no) ){
									alert.showMessage("Kindly Enter Group ID and Ticket No","danger");
									return;
								}
								profile_data.resetAllData();
								var code = profile_data.group_id+":"+profile_data.ticket_no;
								profile_data.getCustomerByGroupAndTicketNo(code).then(function(){
									
									profile_data.getCustomerData();
									profile_data.getChitsData().then(function(){
												profile_data.getInstData();
									});
									profile_data.getAuctionData();
									profile_data.getReceiptData();
									profile_data.getVoucherData();
								})


							}
							else{
								
								if(profile_data.customerID === null || profile_data.customerID === undefined || profile_data.is_empty_string(profile_data.customerID) ){
										return;
								}
								profile_data.resetAllData();
								profile_data.getCustomerData();
								profile_data.getChitsData().then(function(){
									profile_data.getInstData();
								});
								profile_data.getAuctionData();
								profile_data.getReceiptData();
								profile_data.getVoucherData();
								
							}
						},

						is_empty_string(x)
							{
   									return ( 
        									(typeof x == 'undefined')
                    									||
        									(x == null) 
                    									||
        									(x == false)  //same as: !x
                    									||
        									(x.length == 0)
                    									||
        									(x == "")
                    									||
        									(x.replace(/\s/g,"") == "")
                    									||
        									(!/[^\s]/.test(x))
                    									||
        									(/^\s*$/.test(x))
  										);
							},

							setCustomerID(cust_id){
								profile_data.customerID = cust_id;
							},
							setGroupID(group_id){
								profile_data.group_id = group_id;
							},
							setTicketNo(ticket_no){
								profile_data.ticket_no = ticket_no;
							},
							setViewByCustomerID(type){
								profile_data.viewByCustomerID = (type==="true");
							}


					})

export default profile_data;
import React from "react";
import { Col, 
		InputGroup,
  		InputGroupAddon,
  		FormSelect ,
  		InputGroupText
	} from "shards-react";
import { store, view } from 'react-easy-state';
import PropTypes from 'prop-types'; 
import profile_data from '../customerProfileStore';
import getArray from '../getArray';

export default view((props)=>{     
                                  var groupTicketNames = getArray(profile_data.all_tickets_list);
                                var no_of_tickets = profile_data.no_of_tickets;
                                console.log(no_of_tickets); 
                                  return <SelectGroup ticket_names={groupTicketNames} 
                                                   	  onChange = {profile_data.setSelectedTicket} 
                                          />
                      });

class SelectGroup extends React.Component{



  render(){

      var arr  = this.props.ticket_names;
     

    return (
    
      <InputGroup className="mb-3">
                
     <FormSelect onChange ={(evt)=>{this.props.onChange(evt.target.value)}}>
        <option value={"All"}>{"All"}</option>
        {

               arr.map((item,i)=>{
                         return (<option key={i}>{item}</option>)
                })
              
        } 
        

      </FormSelect>
     
      <InputGroupAddon type="prepend">
                    <InputGroupText>Group</InputGroupText>
      </InputGroupAddon>
    </InputGroup>
  )
  }
}


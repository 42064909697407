import {store} from 'react-easy-state';
import alert from '../AlertStore';
import firebase from '../../firebase';

const transfer_data = store({
    isLoading : false,

    source_group : undefined,
    dest_group : undefined,

    source_ticket : undefined,
    dest_ticket : undefined,

    amount : 0,

    setValue(field,value){
        switch(field){
            case 'sgroup'  : transfer_data.source_group = value;break;
            case 'sticket' : transfer_data.source_ticket = value;break;
            case 'dgroup' : transfer_data.dest_group = value;break;
            case 'dticket' : transfer_data.dest_ticket = value;break;
            case 'amount' : transfer_data.amount = value;break;
            default : console.log("Error setting field ",field);
        }
    },


    transferMoney(){
        if(isNaN(transfer_data.amount)){
            alert.showMessage("Please Enter a Valid Amount");
            return;
        }

        transfer_data.isLoading = true;

        var payload = {
            source_group_id : transfer_data.source_group,
            source_ticket  : transfer_data.source_ticket,
            dest_group_id : transfer_data.dest_group,
            dest_ticket  : transfer_data.dest_ticket,
            money : parseInt(transfer_data.amount)
        }

        var func_ref = firebase.functions().httpsCallable('TransferMoney');
        return func_ref(payload).then(function(result){
            // Read result of the Cloud Function.
            var data = result.data;
            console.log(data);
            if(data.status==="success"){
                  alert.showMessage("Money Transferred Successfully","success");
                  
             }
            else{
                  console.error(data.message);
                  alert.showMessage(data.message,"danger");
            }
            transfer_data.isLoading = false;
        })
        .catch(function(err){
            console.error(err);
            transfer_data.isLoading = false;
            alert.showMessage(err.message,"danger");
        })

        

    }
})

export default transfer_data;
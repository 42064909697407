import React from "react";
import {Col, Card, CardHeader, CardBody } from "shards-react";

import '../scroll.css';
import { AtomSpinner } from 'react-epic-spinners';
import Message from '../Message';
import getArray from '../getArray';
import ViewAuction from './ViewAuction';
import formatRupee from '../formatRupee';


export default class SuccessTable extends React.Component{
	render(){

    var data = getArray(this.props.data.data);
		console.log(data);

    return(

		<Col>
        <Card small className="mb-4">
          <CardHeader className="border-bottom">
              <h6 className="m-0">Success</h6>
          </CardHeader>

       
          
          <CardBody className="p-0 pb-3 scroll_list-group" >
          {
          	(!this.props.data.isLoading  && (data.length>0) && (!this.props.data.hasError) )&&
            <table className="table mb-0">
              <thead className="bg-light">
                <tr>
                  <th scope="col" className="border-0">
                    Auct No.
                  </th>
                  <th scope="col" className="border-0">
                      Auction Date and Time
                  </th>
                  <th scope="col" className="border-0">
                      Ticket No.
                  </th>
                  <th scope="col" className="border-0">
                      Name
                  </th>
                  <th scope="col" className="border-0">
                      Contact Details
                  </th>
                  <th scope="col" className="border-0">
                      Bid Amount
                  </th>
                  <th scope="col" className="border-0">
                      Amount Paid
                  </th>
                  <th scope="col" className="border-0">
                      Amount Paid Date
                  </th>
                  <th scope="col" className="border-0">
                      View
                  </th>
                  
                </tr>
              </thead>
              <tbody>
                	
                	{
                          data.map((item,i)=>{
                               return (<Trow key={i} data={item} index={i+1}/>)
                          })
                  
                	}
               
              </tbody>
            </table>
        }
        {
        	(!this.props.data.isLoading && (data.length===0) && (!this.props.data.gotData) ) &&
        	<Message message = {"Please Select Group"} icon_name={"call_missed"} /> 
        }

        {
            (this.props.data.isLoading) &&
               <AtomSpinner color="blue" style={{alignSelf: 'center',marginLeft: '40%',marginTop: '10%',marginBottom: '10%'}}/>
        }
        
        {
              (!this.props.data.isLoading && this.props.data.hasError && this.props.data.gotData ) &&
              <Message message={"Error Loading Data"} icon_name={"warning"} />
        }
        {
              ( !this.props.data.isLoading && (data.length===0)  && (this.props.data.gotData)  ) &&
             <Message message={"No Succesfull Auctions"} icon_name={"airline_seat_flat"} />
       	}



          </CardBody>
        </Card>
      </Col>
		)
	}
}

class Trow extends React.Component{
  render(){

    return(

      <tr>
                  <td>{this.props.data.auct_no}</td>
                  <td>{new Date(this.props.data.auction_date_and_time.toDate()).toString().substring(0,25)}</td>
                  <td>{this.props.data.bidder_ticket_no}</td>
                  <td>{this.props.data.bidder_name}</td>
                  <td>{this.props.data.phone}</td>
                  <td>{formatRupee(this.props.data.bid_amount)}</td>
                  <td>{formatRupee(this.props.data.amount_paid)}</td>
                  <td>{this.props.data.amount_paid_date.toDate().toLocaleDateString()}</td>
                  <td><ViewAuction data={this.props.data} /></td>
            </tr>
    )
  }
}
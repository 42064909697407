/* eslint jsx-a11y/anchor-is-valid: 0 */

import React from "react";
import PropTypes from "prop-types";
import {
  Card,
  CardHeader,
  CardBody,
  ListGroup,
  ListGroupItem,
  Button
} from "shards-react";
import sms_data from './SendSMSStore';

const SidebarActions = () => (
  <Card small className="mb-3">
    <CardHeader className="border-bottom">
      <h6 className="m-0">{"Actions"}</h6>
    </CardHeader>

    <CardBody className="p-0">
      <ListGroup flush>
      <ListGroupItem className="d-flex px-3 border-0">
         <h6>Note  </h6>
         <ol>
            <li>{"{name} for Name "}</li>
            <li>{"{due} for Due"}</li>
            <li>{"{ticket} for GroupID/Ticket"}</li>
         </ol>
        </ListGroupItem>
        <ListGroupItem className="d-flex px-3 border-0">
        <center>
         <Button theme="accent" size="md" onClick={()=>{sms_data.sendMessage()}}>
            <i className="material-icons">send</i> Send
          </Button>
        </center> 
        </ListGroupItem>
      </ListGroup>
    </CardBody>
  </Card>
);


export default SidebarActions;

import React from "react";
import { Row, Col, Card, CardHeader, CardBody,Button } from "shards-react";
import FileUploader from '../FireFileUpload';
import file_data from './FileStore';
import Message from '../Message';
import { AtomSpinner } from 'react-epic-spinners';
import {view} from 'react-easy-state';

export default view(()=>{
    var data = {
        currFolderName : file_data.currentFolder,
        files : file_data.files_list,
        folders : file_data.folder_list,
    }
    
    return <Table isLoading={file_data.isLoading} data={data} />;
})

class Table extends React.Component{

    componentDidMount(){
        file_data.listAllItems();
    }


    render(){
      return(
      
        <Row>
        <Col>
          <Card small className="mb-4">
            <CardHeader className="border-bottom">
            <Row>
              <Col lg="3">
                <h6 className="m-0">{this.props.data.currFolderName===''?'root':this.props.data.currFolderName}</h6>
              </Col>
              <Col lg="6">
                <FileUploader 
                    path={'root'+'/'+file_data.currentFolder}
                    update_url={(url,fname)=>file_data.listAllItems()}
                    style={{width: '90%',alignSelf: 'center' }} />
              </Col>
              <Col lg="3">
                <Button theme="primary" style={{marginLeft: '50%'}} onClick={file_data.addNewFolder}>
                    New Folder
                </Button>
              </Col>
            </Row>
            
            </CardHeader>

            
            
            <CardBody className="p-0 pb-3 scroll_list-group" >

            {( (!this.props.isLoading)) && 
              <table className="table mb-0">
                <thead className="bg-light">
                  <tr>
                    <th scope="col" className="border-0" >
                       
                    </th>
                    <th scope="col" className="border-0">
                       Name
                    </th>
                    <th scope="col" className="border-0">
                        Type
                    </th>
                     <th scope="col" className="border-0">
                        Download
                    </th>
                    <th scope="col" className="border-0">
                        Delete
                    </th>
  
                  </tr>
                </thead>
                <tbody>
                    {
                        (this.props.data.currFolderName!=='') &&
                        <tr>
                                <td style={{fontSize: 25}}><i class="material-icons">folder</i></td>
                                <td><a href="#" onClick={()=>file_data.changeFolder('..')}>{".."}</a></td>
                                <td>Back</td>
                                <td>N/A</td>
                                <td style={{color:'blue',fontWeight: 'bold',fontSize: 25}}></td>
                                <td style={{color:'red',fontWeight: 'bold',fontSize: 25}}></td>
                        </tr>
                    }
                    {
                        this.props.data.folders.map((item,i)=>{
                            return (<FolderRow key={i} data={item} index={i+1}/>)
                        })
                    }
                    {
                        this.props.data.files.map((item,i)=>{
                                return (<FileRow key={i} data={item} index={i+1}/>)
                        })
                    }  
                 
                </tbody>
              </table>
            }
            {
                (!this.props.isLoading && (this.props.data.files.length===0 && this.props.data.folders.length===0) && (this.props.data.currFolderName==='') ) &&
                <Message message={"No Files"} icon_name={'hourglass_empty'}/>
             }
             {
                this.props.isLoading &&
                  <AtomSpinner color="blue" style={{alignSelf: 'center',marginLeft: '40%',marginTop: '10%',marginBottom: '10%'}}/>

            }
            
            </CardBody>
            
          </Card>
        </Col>
      </Row>
      )
    }
  }


  class FolderRow extends React.Component{
      render(){
          return(
                <tr>
                    <td style={{fontSize: 25}}><i class="material-icons">folder</i></td>
                    <td><a href="#" onClick={()=>file_data.changeFolder(this.props.data.name)}>{this.props.data.name}</a></td>
                    <td>Folder</td>
                    <td >N/A</td>
                    <td >
                        <Button theme="danger" onClick={()=>file_data.deleteItem(this.props.data.location.path)}>
                            Delete
                        </Button>
                    </td>
                    
                  </tr>

          )
      }
  }
  

  class FileRow extends React.Component{

    constructor(props){
        super(props);
        this.state = {download_url:'#',isLoading:false};
        this.getDownloadUrl = this.getDownloadUrl.bind(this);
    }

    getDownloadUrl(path){
        var that = this;
        this.setState({isLoading:true});
        file_data.getUrl(path).then(function(url){
            that.setState({download_url:url,isLoading:false});
        }).catch(function(err){
            console.error(err);
            that.setState({download_url:'#',isLoading:false});
        })
    }

    componentDidMount(){
        this.getDownloadUrl(this.props.data.location.path);
    }

    render(){
        return(

            <tr>
                    <td style={{fontSize: 25}}><i class="material-icons">insert_drive_file</i></td>
                    <td>{this.props.data.name}</td>
                    <td>File</td>
                    <td style={{color:'voilet',fontWeight: 'bold',fontSize: 25}}>
                        <a  target="_blank" href={this.state.download_url} download>
                                {   (!this.state.isLoading) && 
                                    <Button theme="primary">
                                        View
                                    </Button>
                                    
                                }
                                {
                                    (this.state.isLoading) &&
                                    "Loading..."
                                }
                        </a>
                    </td>
                    <td>
                        <Button theme="danger" onClick={()=>file_data.deleteItem(this.props.data.location.path)}>
                            Delete
                        </Button>
                    </td>
                    
                </tr>

        )
    }
}
import React from "react";
import ReactQuill from "react-quill";
import { Card, CardBody, Form, Col ,Button,FormInput} from "shards-react";

import "react-quill/dist/quill.snow.css";
import "../../assets/quill.css";
import FileUploader from '../FireFileUpload';
import {view} from 'react-easy-state';
import post_data from './PostStore';

export default view(()=>{
	
	var modules = {
		toolbar : false,
	}
	
    return <Editor link={post_data.photo_link}  modules={modules} filename={post_data.filename}  />
})

const Editor = (props) => (
  <Card small className="mb-3">
    <CardBody>
      <Form className="add-new-post">
	 	 <FormInput size="lg" className="mb-3" placeholder="Your Post Title" onChange={(evt)=>{post_data.setTitle(evt.target.value)}}/>
        <FileUpload                           path={"Posts/"+(new Date().toLocaleDateString())} 
                                              link={props.link}
                                              filename={props.filename}
                                              onChange={(url,name)=>{post_data.setPhotoLink(url,name)}}
                                              remove={()=>{post_data.UnsetPhotoLink()}}/>

        <ReactQuill className="add-new-post__editor mb-1" placeholder={"Please Enter  Text Here"} modules={props.modules}  onChange={post_data.setDescription}/>
      </Form>
    </CardBody>
  </Card>
);



class FileUpload extends React.Component{
	render(){
		return(
				<Col md="12">
                    								
                    								{ this.props.link===null &&
                    									<FileUploader path={this.props.path} 
                                    									update_url={(url,name)=>{this.props.onChange(url,name)}} />
                    								}
                    								{
                      									this.props.link!==null &&
                      									<div>
                          									<br />
                           									<Button theme="danger" onClick={()=>{this.props.remove()}}>Remove</Button>
                           									<h6 className="m-0">{this.props.filename}</h6>
                      									</div>
                    								}
               	</Col>
		)
	}
}
import { store } from 'react-easy-state';
import firebase from '../firebase';
import is_empty_string from './isEmptyString';
import alert from './AlertStore';


const customer_data = store({
	start_date : undefined,
	end_date   : undefined,
	notValidDate : false,

	searchType : "name",

	isLoading : false,
	viewData : [],
	currData : [],
	gotData : false,
	hasError: false,

	setStartDate(value){
		customer_data.start_date = new Date(value);
		customer_data.getCustomerData();
	},
	setEndDate(value){
		customer_data.end_date = new Date(value);
		customer_data.getCustomerData();
	},

	setSearchType(val){
		customer_data.searchType = val;
	},

	searchData(key){

		if(is_empty_string(key)){
			customer_data.viewData = customer_data.currData;
			return;
		}
		var temp = [];
		customer_data.currData.forEach(function(cust_data){
						if(cust_data[customer_data.searchType].includes(key)){
								temp.push(cust_data);
						}
		})
		temp.sort(customer_data.compare);
		customer_data.viewData = temp;

	},

	getCustomerData(){
		customer_data.notValidDate = false;
		if(customer_data.start_date===undefined || customer_data.end_date===undefined ){
			return;
		}
		if(customer_data.start_date>customer_data.end_date){
			customer_data.notValidDate = true;
			return;
		}
		customer_data.isLoading = true;
		
		var query = firebase.firestore().collection('Customer')
										.where('join_date','>=',customer_data.start_date)
										.where('join_date','<=',customer_data.end_date);
		query.onSnapshot(function(snap){
			customer_data.viewData = [];
			customer_data.currData = [];
			snap.forEach(function(doc){
				customer_data.viewData.push(doc.data());
			})
			customer_data.isLoading = false;
			customer_data.hasError = false;
			customer_data.gotData = true;
			customer_data.currData = customer_data.viewData; 
		},function(err){
			alert.showMessage(err.message,'danger');
			customer_data.isLoading = false;
			customer_data.hasError = true;
			customer_data.gotData = true;
		})
	},
	compare( a, b ) {
				var searchkey = customer_data.searchType;
  				if ( a[searchkey] < b[searchkey] ){
    							return -1;
  				}
  				if ( a[searchkey] > b[searchkey] ){
    								return 1;
  				}
  				return 0;
		},

})


export default customer_data;


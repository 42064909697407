import React from "react";
import { Row, Col, Card, CardHeader, CardBody,DatePicker} from "shards-react";
import '../scroll.css';
import { AtomSpinner } from 'react-epic-spinners';
import { view } from 'react-easy-state';
import Message from '../Message';
import sms_data from './SmsStore';

export default view(()=>{
    var data = {
        isLoading : sms_data.isLoading,
        data : sms_data.data,
    }
    return <Table data={data} />;
})

class Table extends React.Component{

    constructor(props){
        super(props);
        this.state = {date:new Date()};
        this.handleDateChange = this.handleDateChange.bind(this);
    }

    componentWillMount(){
      sms_data.onDateChange(new Date());
    }

    handleDateChange(date){
        this.setState({date:new Date(date)});
        sms_data.onDateChange(date);
    }

    render(){
      return(
        <div>
        <Row>
            <Col className="mb-2">
                <DatePicker
                    size="sm"
                    selected={this.state.date}
                    onChange={this.handleDateChange}
                    placeholderText="Date"
                    dropdownMode="select"
                    className="text-center"
                />
            </Col>
        </Row>
            
        <Row>
        <Col>
          <Card small className="mb-4">
            <CardHeader className="border-bottom">
                <h6 className="m-0">SMS Log</h6>
            </CardHeader>
            <CardBody className="p-0 pb-3 scroll_list-group">
          {
            (!this.props.data.isLoading && (this.props.data.data.length>0) ) &&
              <table className="table mb-0">
                <thead className="bg-light">
                  <tr>
                    <th scope="col" className="border-0">
                      #
                    </th>
                    <th scope="col" className="border-0">
                        Message
                    </th>
                    <th scope="col" className="border-0">
                        Name
                    </th>
                    <th scope="col" className="border-0">
                       Ticket
                    </th>
                    <th scope="col" className="border-0">
                        Phone No.
                    </th>
                    <th scope="col" className="border-0">
                       Time
                    </th>
                    <th scope="col" className="border-0">
                       Status
                    </th>
                  </tr>
                </thead>
                <tbody>
                     {
                            this.props.data.data.map((item,i)=>{
                                    return (<TRow key={i} data={item} index={i+1}/>)
                            })
                        }  
                    
                </tbody>
              </table>
            }
              {
              (this.props.data.isLoading) &&
                 <AtomSpinner color="blue" style={{alignSelf: 'center',marginLeft: '40%',marginTop: '10%',marginBottom: '10%'}}/>
              }
              {
                ( !this.props.data.isLoading && (this.props.data.data.length===0) ) &&
                <Message message={"No Data Avaiable"} icon_name={"warning"} />
              }
            </CardBody>
          </Card>
        </Col>
      </Row>
      </div>
      )
    }
  }

  function getTime(date){
    var d = new Date(date); 
    const hh = d.getHours(); // => hh
    const mm = d.getMinutes(); // =>  mm
    const ss = d.getSeconds(); // => ss
    return hh+":"+mm+":"+ss;
  }


  class TRow extends React.Component{


      render(){
          return(
              <tr style={((this.props.data.statuscode!=='success') && (this.props.data.statuscode!==200))?{color:'red'}:{color:'black'}}>
                  <td>{this.props.index}</td>
                  <td>{this.props.data.msg}</td>
                  <td>{this.props.data.name}</td>
                  <td>{this.props.data.group_id+"/"+this.props.data.ticket_no}</td>
                  <td>{this.props.data.mobile}</td>
                  <td>{getTime(this.props.data.date.toDate())}</td>
                  <td>{this.props.data.statuscode}</td>
              </tr>
          )
      }
  }
import React from "react";
import {  Col, Card, CardHeader, CardBody} from "shards-react";
import formatRupee from '../formatRupee';
import '../scroll.css';
import { AtomSpinner } from 'react-epic-spinners';
import Message from '../Message';



export default class AuctionedTable extends React.Component{
	render(){
		return(

		<Col>
        <Card small className="mb-4">
          <CardHeader className="border-bottom">
              <h6 className="m-0">Auctioned</h6>
          </CardHeader>

       
          
          <CardBody className="p-0 pb-3 scroll_list-group" >
          {
          	(!this.props.data.isLoading  && (this.props.data.data.length>0) && (!this.props.data.hasError) )&&
            <table className="table mb-0">
              <thead className="bg-light">
                <tr>
                  <th scope="col" className="border-0">
                    Auct No.
                  </th>
                  <th scope="col" className="border-0">
                      Auction Date and Time
                  </th>
                  <th scope="col" className="border-0">
                      Bidder Ticket No.
                  </th>
                  <th scope="col" className="border-0">
                      Bidder Name
                  </th>
                  <th scope="col" className="border-0">
                      Contact Details
                  </th>
                  <th scope="col" className="border-0">
                      Bid Amount
                  </th>
                  <th scope="col" className="border-0">
                      Company Dividend
                  </th>
                  <th scope="col" className="border-0">
                      Dividend
                  </th>
                  <th scope="col" className="border-0">
                      Approve
                  </th>
                  <th scope="col" className="border-0">
                      Call Reauction
                  </th>


                </tr>
              </thead>
              <tbody>
                	
                	{
                          this.props.data.data.map((item,i)=>{
                                return (<Trow key={i} data={item} index={i+1}/>)
                          })
                  
                	}
               
              </tbody>
            </table>
        }
        {
        	(!this.props.data.isLoading && (this.props.data.data.length===0) && (!this.props.data.gotData) ) &&
        	<Message message = {"Please Select Group"} icon_name={"call_missed"} /> 
        }

        {
            (this.props.data.isLoading) &&
               <AtomSpinner color="blue" style={{alignSelf: 'center',marginLeft: '40%',marginTop: '10%',marginBottom: '10%'}}/>
        }
        
        {
              (!this.props.data.isLoading && this.props.data.hasError && this.props.data.gotData ) &&
              <Message message={"Error Loading Data"} icon_name={"warning"} />
        }
        {
              ( !this.props.data.isLoading && (this.props.data.data.length===0)  && (this.props.data.gotData)  ) &&
             <Message message={"No Pending Auctions"} icon_name={"airline_seat_flat"} />
       	}



          </CardBody>
        </Card>
      </Col>
		)
	}
}

class Trow extends React.Component{
	render(){
    var auct_date = new Date(this.props.data.auction_date_and_time.toDate()).toString().substring(0,25);
    var link = "/approveauctionsuccess?group_id="+this.props.data.group_id+"&auct_timings="+auct_date+"&auct_id="+this.props.data.auct_id+"&auct_no="+this.props.data.auct_no+"&cust_id="+this.props.data.bidder_cust_id+"&ticket_no="+this.props.data.bidder_ticket_no+"&phone="+this.props.data.phone; 

		return(

			    <tr>
                  <td>{this.props.data.auct_no}</td>
                  <td>{auct_date}</td>
                  <td>{this.props.data.bidder_ticket_no}</td>
                  <td>{this.props.data.bidder_name}</td>
                  <td>{this.props.data.phone}</td>
                  <td>{formatRupee(this.props.data.bid_amount)}</td>
                  <td>{formatRupee(this.props.data.company_dividend)}</td>
                  <td>{formatRupee(this.props.data.dividend)}</td>
                  <td style={{fontSize: 25,color:'blue'}}><a style={{color:'blue'}} href={link} target="_blank"><i class="material-icons">done_outline</i></a></td>
                  <td style={{color:'green',fontSize: 25}}><i class="material-icons">autorenew</i></td>
            </tr>
		)
	}
}
import { store } from 'react-easy-state';
import firebase from './firebase';
import dashboard_store from './components/DashboardStore';
import loginhist from './components/LoginHistory/LoginHistData';
import sms_data from './components/SMS/SmsStore';
import alert from './components/AlertStore';
import groupNameStore from './components/groupNameStore';

const login_data = store({
	isLoading : false,
	
	isLoggedIn : false,
	username : null,
	password : null,

	authId : null,
	userData : null,

	latitude : null,
	longitude : null,


	getLocation(){
		if(window.navigator.geolocation){
			navigator.geolocation.getCurrentPosition(login_data.showPosition);
		}
		else{
			alert.showMessage("Enable location to login.","danger");
		}
	},

	showPosition(position){
		login_data.latitude = position.coords.latitude;
		login_data.longitude = position.coords.longitude;
	},

	login(){

		login_data.isLoading = true;

		const uname = login_data.username;
		const password = login_data.password;

		if(uname===null || password === null){
			alert.showMessage("Please enter Username and Password","danger");
			login_data.isLoading = false;
			return;
		}
		/*
		if(login_data.latitude===null || login_data.longitude===null){
			alert.showMessage("Kindly Allow Location to login.","danger");
			login_data.isLoading = false;
			return;
		}*/

		var func_ref = firebase.functions().httpsCallable('authenticateAdmin');

		func_ref({uid:uname,password:password,lat:login_data.latitude,long:login_data.longitude}).then(function(result) {
				console.log(result.data);
				if(result.data.status==="failure"){
					alert.showMessage(result.data.message,"danger");
					login_data.isLoggedIn = false;
  					login_data.authId = null;
  					login_data.userData = null;
					login_data.isLoading = false;
					return;
				}

				const token = result.data.token;
				console.log(token);
					firebase.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION).then(function(){
						console.log("Auth persistence session");
						return firebase.auth().signInWithCustomToken(token).then(function(){
							login_data.isLoading = false;
							login_data.invokeDependencies();
						})
						.catch(function(err){
							  login_data.isLoggedIn = false;
							  login_data.authId = null;
							  login_data.userData = null;
							  login_data.isLoading = false;
							  alert.showMessage(err.message,"danger");
						})
				}).catch(function(error) {
  					// Handle Errors here.
  					var errorCode = error.code;
  					var errorMessage = error.message;
  					login_data.isLoggedIn = false;
  					login_data.authId = null;
  					login_data.userData = null;
					login_data.isLoading = false;
					alert.showMessage(errorMessage,"danger");
  					// ...	
				});

		}).catch(function(err){
			console.log(err);
			alert.showMessage(err.message,"danger");
		});

	},

	logout(){
		firebase.auth().signOut().then(function() {
  					// Sign-out successful.
		}).catch(function(error) {
  					// An error happened.
  					console.log(error);
  					alert.showMessage(error.message,"danger");
		});
	},
	invokeDependencies(){
		dashboard_store.getDaysWebsiteVisitorCount(7);
		dashboard_store.getTodaysDailySummary();
		groupNameStore.getGroupNames();
	}




});


login_data.getLocation();


firebase.auth().onAuthStateChanged(function(user) {
  	if (user) {
    		// User is signed in.
    		login_data.isLoggedIn = true;
    		login_data.userData = user;
			login_data.authID = user.uid;
			login_data.invokeDependencies();

  	} else {
    		// No user is signed in.
    		login_data.isLoggedIn = false;
    		login_data.userData = null;
    		login_data.authID = null;
  	}
});


export default login_data;
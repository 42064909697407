import React from "react";
import { Container, Row, Col, Card, CardHeader, CardBody
  } from "shards-react";

import PageTitle from "../common/PageTitle";
import '../scroll.css';
import { AtomSpinner } from 'react-epic-spinners';
import Message from '../Message';
import collection_assistant_list from '../collectionAssistantListStore';
import { view } from 'react-easy-state';
import login_data from '../../loginstore';
import { Redirect } from "react-router-dom";
import ViewCA from './viewCollectionAssistant';
import AlertBox from '../AlertBox';


export default view(()=>{
  var data={
      list : collection_assistant_list.list,
      isLoading : collection_assistant_list.isLoading,
      hasData : collection_assistant_list.hasData,
      hasError : collection_assistant_list.hasError,
    }

    return login_data.isLoggedIn?<CollectionAsst data={data} />:<Redirect to='/login' />;
})

class CollectionAsst extends React.Component{

    componentDidMount(){
      collection_assistant_list.getList();
    }


    render(){
      return(
        <div>
          <AlertBox />
            <Container fluid className="main-content-container px-4">
            {/* Page Header */}
            <Row noGutters className="page-header py-4">
                  <PageTitle sm="4" title="Collection Assistant" subtitle="View" className="text-sm-left" />
            </Row>

            {/* Default Light Table */}
             <Table data={this.props.data} />

          </Container>
        </div>
        )
    }
}





class Table extends React.Component{
  render(){
    return(
    
      <Row>
      <Col>
        <Card small className="mb-4">
          <CardHeader className="border-bottom">
              <h6 className="m-0">Collection Assistants</h6>
          </CardHeader>

       
          
          <CardBody className="p-0 pb-3 scroll_list-group" >
           
           { (!this.props.data.isLoading && this.props.data.hasData && (this.props.data.list.length>0) ) && 
            <table className="table mb-0">
              <thead className="bg-light">
                <tr>
                  <th scope="col" className="border-0">
                    #
                  </th>
                  <th scope="col" className="border-0">
                    CAID
                  </th>
                  <th scope="col" className="border-0">
                      Name
                  </th>
                  
                  <th scope="col" className="border-0">
                    View
                  </th>
                  <th scope="col" className="border-0">
                    Edit
                  </th>

                </tr>
              </thead>
              <tbody>

                      {
                          this.props.data.list.map((item,i)=>{
                                return (<Trow key={i} data={item} index={i+1}/>)
                          })
                        }
               
               
              </tbody>
            </table>
          }
         {
            (this.props.data.isLoading) &&
             <AtomSpinner color="blue" style={{alignSelf: 'center',marginLeft: '40%',marginTop: '10%',marginBottom: '10%'}}/>
          }
        
          {
            (!this.props.data.isLoading && this.props.data.hasError) &&
            <Message message={"Error Loading Data"} icon_name={"warning"} />
          }
          {
            ( !this.props.data.isLoading && (this.props.data.list.length===0)) &&
            <Message message={"No Collection Assistants Available"} icon_name={"account_box"} />
          }

          </CardBody>
        </Card>
      </Col>
    </Row>
    )
  }
}

class Trow extends React.Component{
  render(){

    var modify_link = '/modifycollectionasst?id='+this.props.data.ca_id;

    return(
         <tr>
                  <td>{this.props.index}</td>
                  <td>{this.props.data.ca_id}</td>
                  <td>{this.props.data.name}</td>
                  <td><ViewCA id={this.props.data.ca_id}/></td>
                  <td><a style={{color:'green'}} href={modify_link} target="_blank"><i class="material-icons">edit</i></a></td>
          </tr>
    )
  }
}

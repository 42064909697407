import React from "react";
import { Container, Row,Button} from "shards-react";
import PageTitle from "../common/PageTitle";
import '../scroll.css';
import {view} from 'react-easy-state';
import { Link} from "react-router-dom";
import login_data from '../../loginstore';
import AlertBox from '../AlertBox';
import AddVoucher from './AddVoucher';
import VoucherTable from './VoucherTable';


export default class GeneralVoucher extends React.Component{
    render(){
        return(
            <div>
                <AlertBox  className="mb-0"/>
                    <Container fluid className="main-content-container px-4">
                    {/* Page Header */}
                    <Row noGutters className="page-header py-4">
                            <PageTitle sm="4" title="Journal Voucher" subtitle="Debit and Credit to Account Balance" className="text-sm-left" />
                    </Row>
                    <Row>
                        <AddVoucher />
                        <Link to="/transfermoney">
                            <Button theme="danger" className="ml-4">Transfer Money</Button>
                        </Link>
                    </Row>
                    
                    <br />
                    <VoucherTable />
                    </Container>
            </div>
        )
    }
}




  
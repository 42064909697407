import React from 'react';
import { Container, Row, Col,Card , CardHeader,CardBody,FormInput,Button,
		Form } from "shards-react";
import PageTitle from "../common/PageTitle";
import SelectGroup from "../SelectGroup";
import {view} from 'react-easy-state';
import data from './ReplaceCustomerStore';
import { AtomSpinner } from 'react-epic-spinners';
import AlertBox from '../AlertBox';


export default view(()=>{
    return <ReplaceCustomer isLoading={data.isLoading} />
}) 



class ReplaceCustomer extends React.Component{
    render(){
        return(
        <div>
            <AlertBox></AlertBox>
        <Container fluid className="main-content-container px-4 pb-4">
        {/* Page Header */}
        <Row noGutters className="page-header py-4">
              <PageTitle sm="4" title="Chit" subtitle="Remove and Replace" className="text-sm-left" />
        </Row>

        
        <Col lg="12">
            <Card small className="mb-4">
                <CardHeader className="border-bottom">
                    <h6 className="m-0">Remove and Replace Chit</h6>
                </CardHeader>
                <CardBody>
                    <Col>
                    {   (!this.props.isLoading) &&
                        <Form onSubmit={this.onSubmitHandler}>
                        <Row>
                            <Col lg="4">
                                <label>Group</label>
                                <SelectGroup onChange={(evt)=>{data.group_id = evt.target.value}}/>
                            </Col>
                                <Input field="Ticket No." placeholder="Ticket No." type="number" onChange={(evt)=>{data.ticket_no=evt.target.value}}/>
                                <Input field="New Customer ID" placeholder="New Customer ID" style={{textTransform:'uppercase'}} onChange={(evt)=>{data.newCustID=evt.target.value}}/>
                        </Row>
                        <Row>
                            <Col lg="4"></Col>
                            <Col lg="4"><Button size="md" style={{maxWidth:'100%',width:'100%'}} onClick={()=>{data.replaceCustomer()}}>Replace Customer</Button></Col>
                            <Col lg="4"></Col>
                        </Row>
                        
                        </Form>
                    }
                    {
                        this.props.isLoading &&
                        <center><AtomSpinner color={"blue"} /></center>
                    }
                    </Col>
                </CardBody>
            </Card>
        </Col>
        </Container>
    </div>
        )
    }
}


class Input extends React.Component{
    render(){
        return(
            <Col md="4" lg="4" className="form-group">
                <label htmlFor="feEmailAddress">{this.props.field}</label>
                <FormInput
                  {...this.props}
                />
            </Col>
        )
    }
}
import React from "react";
import { Row, Col,Card,CardHeader ,CardBody } from "shards-react";
import Message from '../Message';
import profile_data from '../customerProfileStore';
import format_rupee from '../formatRupee';
import {view } from 'react-easy-state';
import getArray from '../getArray';




export default view(()=>{
      var data = {
        isLoading  : profile_data.isChitsDataLoading,
        hasError   : profile_data.hasErrorChitsData,
        data       : getArray(profile_data.chits_data),      
    };
      console.log(data);
      console.log(getArray(data.data));

      return <ChitDetails data={data} />
})

class ChitDetails extends React.Component{
	render(){
		return(
			<Col className={"ticketScroll"}>
				{
                          this.props.data.data.map((item,i)=>{
                                return (<Stats key={i} data={item} index={i+1}/>)
                          })
                }
                {
                	(this.props.data.data.length===0) &&
                	<Card>
                    <CardHeader className="border-bottom">
                         <h6 className="m-0">Chit Details</h6>
                    </CardHeader>
                		<CardBody>
                			<Message message={"No Chits"} icon_name={"info"}/>
      					</CardBody>
      				</Card>
      			}		
      		</Col>
           
		)
	}
}


class Stats extends React.Component{
	render(){
		console.log("Priced ",this.props.data.priced);
		return(
			<Card small className="mb-4 pt-3">
				<CardHeader className="border-bottom">
					<Row>
					 	<StatComponent value={format_rupee(this.props.data.account_balance)} field={"Account Balance"} color={this.props.data.account_balance>0?'green':'red'} />
					 	<StatComponent value={format_rupee(this.props.data.total_amount_paid)} field={"Total Paid"} color={'black'}/>
					 	<StatComponent value={format_rupee(this.props.data.future_liabilities)} field={"Future Liabilities"} color={'green'} />
					 	<StatComponent value={this.props.data.priced?"P":"NP"} field={"Stage"} color={'black'}/>
					</Row>
				</CardHeader>
				<CardBody>
          <Row>
					<h6 className="m-2">{this.props.data.group_id+"-"+this.props.data.ticket_no}</h6>
          			{
          				this.props.data.isLean &&
          				<h6 className="m-2" style={{color:'red'}}>Lean</h6>
          			}
          </Row>
				</CardBody>
			</Card>

		)
	}
}

class StatComponent extends React.Component{
	render(){
		return(
			
					  <Col lg="3">
						<h5 style={{textAlign: 'center',color:this.props.color,fontWeight: 'bold'}}>{this.props.value}</h5>
						<p style={{textAlign: 'center'}} className="text-muted">{this.props.field}</p>
					 </Col>
			
		)
	}
}


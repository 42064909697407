import {store} from 'react-easy-state';
import firebase from '../../firebase';
import alert from '../AlertStore';

const post_data = store({
        isLoading : false,

        photo_link : null,
        description : null,
        filename : null,
        title : null,

        post_data : [],

        setTitle(val){
            post_data.title = val;
        },      

        setPhotoLink(url,filename){
            post_data.photo_link = url;
            post_data.filename = filename;
        },

        UnsetPhotoLink(){
            post_data.photo_link = null;
            post_data.filename = null;
        },

        setDescription(text){
            post_data.description =  text.replace(/<\/?[^>]+(>|$)/g, ""); //clean html
        },

        UploadPost(){
            const description = post_data.description;
            const photo_url = post_data.photo_link;
            const photo_filename = post_data.filename;
            const title = post_data.title;
            if(description===null || photo_url===null || photo_filename===null || title===null){
                alert.showMessage("Please enter all Details.","danger");
                return;
            }
            const payload = {
                description,
                photo_url,
                photo_filename,
                title,
                date : new Date(),
            }  
            post_data.isLoading = true;
            firebase.firestore().collection('Post').add(payload).then(function(){
                post_data.isLoading  = false;
                alert.showMessage("Succesfully Posted","success");
            }).catch(function(err){
                post_data.isLoading = false;
                alert.showMessage(err.message,"danger");
            })
        },

        getPostsData(){
            if(post_data.post_data.length>0){
                return;
            }
            post_data.isLoading = true;
            firebase.firestore().collection('Post').onSnapshot(function(snap){
                var temp = [];
                snap.forEach(function(doc){
                    temp.push({id:doc.id,data:doc.data()});
                })
                post_data.post_data = temp;
                post_data.isLoading = false;
            },function(err){
                post_data.isLoading = false;
                alert.showMessage(err.message,"danger");
            })
        },

        removePostData(id){
            post_data.isLoading = true;
            firebase.firestore().collection('Post').doc(id).delete().then(function(){
                post_data.isLoading = false;
                alert.showMessage("Succesfully Deleted","success");
            }).catch(function(err){
                post_data.isLoading = false;
                alert.showMessage(err.message,"danger");
            })
        }
        

})

export default post_data;
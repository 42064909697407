import React from 'react';
import { Container, Row, Col,Card , CardHeader,CardBody,FormInput,Button,Form,DatePicker } from "shards-react";
import PageTitle from "../common/PageTitle";
import ReactQuill from "react-quill";
import { AtomSpinner } from 'react-epic-spinners';
import report_data from './reportStore';
import {view} from 'react-easy-state';

export default view(()=>{

   return <ReportError loading={report_data.isLoadin} />
})


class ReportError extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            title : null,
            desc : null,
        }
        this.onSubmitHandler = this.onSubmitHandler.bind(this);
    }
    onSubmitHandler = (evt) =>{
      evt.preventDefault();
      report_data.sendReport(this.state);
      this.setState({title:null,desc:null});
    }

  render(){


    return(
    <Container fluid className="main-content-container px-4 pb-4">
        {/* Page Header */}
        <Row noGutters className="page-header py-4">
              <PageTitle sm="4" title="Report" subtitle="Send" className="text-sm-left" />
        </Row>

        

     	<Col lg="12">
        <Card small className="mb-4">
          <CardHeader className="border-bottom">
              <h6 className="m-0">Send Report</h6>
              
          </CardHeader>
          <CardBody className="p-2 pb-2 pt-4" >
        { !this.props.isLoading &&		
         <Col>
          <Form onSubmit={this.onSubmitHandler}>
            
              <Col md="12" className="form-group">
                <label htmlFor="feEmailAddress">Subject</label>
                <FormInput
                  id="feEmailAddress"
                  type="text"
                  placeholder="Report Subject"
                  onChange = {(evt)=>{this.setState({title:evt.target.value})}}
                />
              </Col>
              
              <Col md="12" className="form-group">
                
                <label htmlFor="feEmailAddress">Description</label>
                <ReactQuill className="add-new-post__editor mb-1" onChange={(value)=>{this.setState({desc:value})}} placeholder="Description"/>
                
              </Col>

            <Button type="submit" style={{marginTop: '3%',alignSelf: 'center',marginBottom: '3%'}}>Send Report</Button>
            
          </Form>
        </Col>
      }
      {
        this.props.isLoading &&
        <AtomSpinner color="blue" style={{alignSelf: 'center',marginLeft: '40%',marginTop: '10%',marginBottom: '10%'}}/>

      }
 
          </CardBody>
          </Card>
          </Col>
    </Container>
    )
  }
}



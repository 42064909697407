import {store} from 'react-easy-state';
import firebase from '../../firebase';
import alert from '../AlertStore';

const hist_data  = store({
    sdate : undefined,
    edate : undefined,
    isLoading : false,
    data  : [],

    onDateChange(date){
        var d = new Date(date);
        d.setHours(0,0,0,0);
        if(hist_data.sdate===d){
            return; //thats because it is already fetched
        }
        hist_data.sdate = new Date(d);
        d.setHours(23);
        d.setMinutes(59);
        hist_data.edate = new Date(d);
        hist_data.getHistData();
    },

    getHistData(){
        hist_data.isLoading =true;
        hist_data.data = [];
        console.log(hist_data.sdate,hist_data.edate);
        firebase.firestore().collection('LoginHistory')
                            .where('date','>=',new Date(hist_data.sdate))
                            .where('date','<=',new Date(hist_data.edate))
                            .onSnapshot(function(snap){
            var temp = [];
            snap.forEach(function(doc){
                temp.push(doc.data());
            })
            hist_data.isLoading = false;
            hist_data.data      = temp;
            console.log(temp);
        },function(err){
            console.error(err);
            alert.showMessage(err.message,"danger");
            hist_data.isLoading = false;
            hist_data.data      = [];

        })
    }
})




export default hist_data;
import React from 'react';
import { Container, Row, Col,Card , CardHeader,CardBody,FormGroup,FormInput,Button,
    Form,FormSelect,DatePicker } from "shards-react";
import PageTitle from "../common/PageTitle";
import FireFileUpload from '../FireFileUpload';
import firebase from '../../firebase';
import {toast} from 'react-toastify';
import { AtomSpinner } from 'react-epic-spinners';
import alert from '../AlertStore';
import AlertBox from '../AlertBox';


export default class AddCollectionAsst extends React.Component{

  constructor(props) {
    super(props);
  
    this.state = {
        name : null,
        father_husband_name : null,
        dob : new Date(),
        phone : null,
        email : null,
        bank_accnt_no : null,
        ifsc_code : null,
        address : null,
        aadhar : null,
        signature_url : null,
        aadhar_url : null,
        photo_url : null,
        bank_url:null,
        pan_url : null,

        isLoading:false,
    };

    this.handleDOBDateChange = this.handleDOBDateChange.bind(this);
    this.addCustomer = this.addCustomer.bind(this);
    this.isNull = this.isNull.bind(this);
    this.onSubmitHandler = this.onSubmitHandler.bind(this);
  }

  handleDOBDateChange(value) {
    this.setState({ dob: new Date(value)});
  }

  isNull(obj){ 
  
  for (var key in obj) {
      if (obj.hasOwnProperty(key)) {
        var val = obj[key];
        console.log(val)
        if(val===undefined || val === null){
          return true;
        }
      }
  }
  return false;
}

  addCustomer = () =>{
    var that = this;
    var data = this.state;
    data['dob'] = new Date(data.dob).toUTCString();
    delete data.isLoading;
    

    this.setState({isLoading:true});
    var func_ref = firebase.functions().httpsCallable('CreateCA');
    func_ref(data).then(function(result) {
                  // Read result of the Cloud Function.
                  var data = result.data;
                  console.log(data);
                  if(data.status==="success"){
                        console.log("Document succesfully added!");
                        alert.showMessage("Collection Assistant Added Successfully","success");
                        console.log(that.state);
                   }
                  else{
                        toast(data.error.message, {containerId: 'A'});
                        console.log(data.error.message);
                        alert.showMessage(data.error.message,"danger");
                  }
                  that.setState({isLoading:false});

        }).catch(function(error) {
                  // Getting the Error details.
                  //var code = error.code;
                  //var message = error.message;
                  //var details = error.details;
                  // ...
                  console.log(error);
                  that.setState({isLoading:false});
                  toast("unable to add Collection Assistant", {containerId: 'A'});
                  alert.showMessage("Unable to Add Customer","danger");
        });

  }

  onSubmitHandler = (evt) =>{
      evt.preventDefault();
      this.addCustomer();
  }

  render(){

    var url_string = window.location.href;
  var url = new URL(url_string);
  var c = url.searchParams.get("name");
  console.log(c)

    return(
    <div>
      <AlertBox />
    <Container fluid className="main-content-container px-4 pb-4">
        {/* Page Header */}
        <Row noGutters className="page-header py-4">
              <PageTitle sm="4" title="New Collection Assistant" subtitle="Add" className="text-sm-left" />
        </Row>

        <form onSubmit={()=>{window.close();}} style={{marginBottom: '2%',marginLeft: '2%'}}>
            <Button type="submit" theme="danger">Cancel</Button>
        </form>

      <Col lg="12">
        <Card small className="mb-4">
          <CardHeader className="border-bottom">
              <h6 className="m-0">Add New Collection Assistant</h6>
              
          </CardHeader>
          <CardBody className="p-2 pb-2" >
        { !this.state.isLoading &&    
         <Col>
          <Form onSubmit={this.onSubmitHandler}>
            <Row form>
              <Col md="4" className="form-group">
                <label htmlFor="feEmailAddress">Name</label>
                <FormInput
                  id="feEmailAddress"
                  type="text"
                  placeholder="Name"
                  onChange = {(evt)=>{this.setState({name:evt.target.value})}}
                />
              </Col>
              <Col md="4">
                <label htmlFor="fePassword">Father/Husband Name</label>
                <FormInput
                  id="fePassword"
                  type="text"
                  placeholder="Father/Husband Name"
                  onChange = {(evt)=>{this.setState({father_husband_name:evt.target.value})}}
                />
              </Col>
              <Col md="4">
                <FormGroup>
                      <label htmlFor="feInputAddress">DOB</label>
                      <br />
                      <DatePicker placeholderText="DOB"
                                  selected={new Date(this.state.dob)}
                                  onChange={this.handleDOBDateChange}
                                  dropdownMode="select"
                                  className="text-center"
                                  />
                </FormGroup>
              </Col>
           </Row>

           <Row form>
              <Col md="4">
                <label htmlFor="fePassword">Phone</label>
                <FormInput
                  id="fePassword"
                  type="text"
                  placeholder="Phone (comma seperated)"
                  onChange = {(evt)=>{this.setState({phone:evt.target.value})}}
                />
              </Col>
              <Col md="4">
                <label htmlFor="fePassword">Email</label>
                <FormInput
                  id="fePassword"
                  type="text"
                  placeholder="Email"
                  onChange = {(evt)=>{this.setState({email:evt.target.value})}}
                />
              </Col>

               <Col md="4">
                    <label htmlFor="fePassword">Aadhar Number</label>
                    <FormInput
                      id="fePassword"
                      type="text"
                      placeholder="Aadhar Number"
                  onChange = {(evt)=>{this.setState({aadhar:evt.target.value})}}
                    />
              </Col>
            </Row>

            <Row form style={{marginTop: '2%'}}>


              <Col md="4">
                <label htmlFor="fePassword">Bank Account No</label>
                <FormInput
                  id="fePassword"
                  type="text"
                  placeholder="Bank Account No."
                  onChange = {(evt)=>{this.setState({bank_accnt_no:evt.target.value})}}
                />
              </Col>
              <Col md="4">
                <label htmlFor="fePassword">IFSC Code</label>
                <FormInput
                  id="fePassword"
                  type="text"
                  placeholder="IFSC Code"
                  onChange = {(evt)=>{this.setState({ifsc_code:evt.target.value})}}
                />
              </Col>

              <Col md="4">
                    <label htmlFor="fePassword">PAN</label>
                    <FormInput
                        id="fePassword"
                        type="text"
                        placeholder="PAN"
                        onChange = {(evt)=>{this.setState({pan:evt.target.value})}}
                    />
              </Col>
              

            </Row>

            <Row form style={{marginTop: '2%'}}>
              <Col md="12">
                <label htmlFor="address">Address</label>
                <FormInput 
                    type="text"
                    placeholder="Address"
                    onChange = {(evt)=>{this.setState({address:evt.target.value})}}
                  />
              </Col>
            </Row>


            <Row form style={{marginTop: '2%'}}>
                <Col md="4">
                    <label htmlFor="fePassword">Signature</label>
                    { this.state.signature_url===null &&
                        <FireFileUpload path="CA/Signature" 
                          update_url={(url,name)=>{this.setState({signature_url:url,signature_filename:name})}} />
                    }
                    {
                      this.state.signature_url!==null &&
                      <div>
                          <br />
                          <Button theme="danger" 
                                onClick={()=>{this.setState({signature_url:null})}}>Remove</Button>

                          <h6 className="m-0" style={{color:'black',fontSize:14}}>{this.state.signature_filename}</h6>
                         
                      </div>
                    }
               </Col>

               <Col md="4">
                    <label htmlFor="fePassword">Aadhar</label>
                    { this.state.aadhar_url===null &&
                    <FireFileUpload path="CA/Aadhar" 
                                    update_url={(url,name)=>{this.setState({aadhar_url:url,aadhar_filename:name})}} />
                    }
                    {
                      this.state.aadhar_url!==null &&
                      <div>
                          <br />
                           <Button theme="danger" onClick={()=>{this.setState({aadhar_url:null})}}>Remove</Button>
                           <h6 className="m-0">{this.state.aadhar_filename}</h6>
                      </div>
                    }
               </Col>

               <Col md="4">
                    <label htmlFor="fePassword">Bank</label>
                    { this.state.bank_url===null &&
                    <FireFileUpload path="CA/Bank" 
                                    update_url={(url,name)=>{this.setState({bank_url:url,bank_filename:name})}} />
                    }
                    {
                      this.state.bank_url!==null &&
                      <div>
                          <br />
                          <Button theme="danger" onClick={()=>{this.setState({bank_url:null})}} >Remove</Button>
                          <h6 className="m-0" style={{color:'black',fontSize:14}}>{this.state.bank_filename}</h6>
                      </div>
                    }
               </Col>
            </Row>

            

            <Row form style={{marginTop: '2%'}}>
                <Col md="4">
                    <label htmlFor="fePassword">Photo</label>
                    { this.state.photo_url===null &&
                    <FireFileUpload path="CA/Photo"
                                   update_url={(url,name)=>{this.setState({photo_url:url,photo_filename:name})}} />
                    }
                    {
                      this.state.photo_url!==null &&
                      <div>
                          <br />
                          <Button theme="danger" onClick={()=>{this.setState({photo_url:null})}} >Remove</Button>
                          <h6 className="m-0" style={{color:'black',fontSize:14}}>{this.state.photo_filename}</h6>
                      </div>
                    }
                 </Col>

               <Col md="4">
                    <label htmlFor="fePassword">PAN</label>
                    { this.state.pan_url===null &&
                        <FireFileUpload path="CA/PAN" 
                                        update_url={(url,name)=>{this.setState({pan_url:url,pan_filename:name})}} />
                    }
                    {
                      this.state.pan_url!==null &&
                      <div>
                        <br />
                        <Button theme="danger" onClick={()=>{this.setState({pan_url:null})}} >Remove</Button>
                          <h6 className="m-0" style={{color:'black',fontSize:14}}>{this.state.pan_filename}</h6>
                      </div>
                    }
               </Col>
            </Row>



            
            <Button type="submit" style={{marginTop: '3%',alignSelf: 'center',marginBottom: '3%'}}>Create New Collection Assistant</Button>
            
          </Form>
        </Col>
      }
      {
        this.state.isLoading &&
        <AtomSpinner color="blue" style={{alignSelf: 'center',marginLeft: '40%',marginTop: '10%',marginBottom: '10%'}}/>

      }
 
          </CardBody>
          </Card>
          </Col>
    </Container>
  
    </div>
    )
  }
}


class SelectIntroducer extends React.Component{
  render(){
    return (
                
      <FormSelect onChange={this.props.onChange}>
        <option>Choose Introducer</option>
        <option>Vinay (SGRSP-INT-2019-001)</option>
        <option>Mohan (SGRSP-INT-2019-002)</option>
        <option>Vijay (SGRSP-INT-2019-003)</option>
        <option>Sharath (SGRSP-INT-2019-001)</option>
      </FormSelect>
  )
  }
}

class SelectCA extends React.Component{
  render(){
    return (
                
      <FormSelect onChange={this.props.onChange}>
        <option>Choose Collection Assistant.</option>
        <option>Vinay (SGRSP-CA-2019-001)</option>
        <option>Mohan (SGRSP-CA-2019-002)</option>
        <option>Vijay (SGRSP-CA-2019-003)</option>
        <option>Sharath (SGRSP-CA-2019-001)</option>
      </FormSelect>
  )
  }
}


class FileUpload extends React.Component{
  render(){
    return(
        <div className="custom-file mb-3">
          <input type="file" className="custom-file-input" id="customFile2" />
              <label className="custom-file-label" htmlFor="customFile2">
                  Choose file...
              </label>
        </div>
      )
  }
}
import {store} from 'react-easy-state';
import firebase from '../../firebase';
import alert from '../AlertStore';

const replaced_tickets = store({
    isLoading : false,
    gotData : false,
    hasError : false,

    group_id : undefined,

    data : [],

    setGroupID(group_id){
        if(group_id===null || group_id===undefined){
            return;
        }
        replaced_tickets.group_id = group_id;
        replaced_tickets.getData();
    },

    addPaymentId(documentID){
        var payment_id = window.prompt("Please Enter Payment ID");
        if(payment_id===null || payment_id===undefined){
            alert.showMessage("Invalid Payment Id","danger");
            return;
        }

        replaced_tickets.isLoading = true;
        return firebase.firestore().collection('RemovedGroupCustomer').doc(documentID).update({payment_id:payment_id}).then(function(){
                alert.showMessage("Payment Id Added Succesfully");
                replaced_tickets.isLoading = false;
                replaced_tickets.getData();
        })
        .catch(function(err){
            alert.showMessage(err.message,"danger");
            replaced_tickets.isLoading = false;
        })
    },

    getData(){
        var group_id = replaced_tickets.group_id
        if(group_id===undefined || group_id===null){
                alert.showMessage("Invalid Group Id");
                return;
        }
        replaced_tickets.isLoading = true;
        return firebase.firestore().collection('RemovedGroupCustomer').where('group_id','==',group_id).orderBy('replacedDate','desc').get().then(function(snap){
            var temp = [];
            snap.forEach(function(doc){
                var cust_data = doc.data();
                cust_data['doc_id'] = doc.id; // required to add payment id
                temp.push(cust_data);
            })
            console.log(temp);
            replaced_tickets.data = temp;
            replaced_tickets.gotData = true;
            replaced_tickets.hasError = false;
            replaced_tickets.isLoading = false;
            console.log("Replaced =",temp);
        }).catch(function(err){
            console.error(err);
            replaced_tickets.gotData = false;
            replaced_tickets.hasError = true;
            replaced_tickets.isLoading = false;
        })
    }
})

export default replaced_tickets;
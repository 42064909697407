import React from 'react';
import { Container, Row, Col,Card , CardHeader,CardBody,FormInput,Button,
		Form, } from "shards-react";
import PageTitle from "../common/PageTitle";
import firebase from '../../firebase';
import { AtomSpinner } from 'react-epic-spinners';
import is_empty_string from '../isEmptyString';
import alert from '../AlertStore';


export default class InvalidateReceipt extends React.Component{

	constructor(props) {
	  super(props);
	
	  this.state = {receipt_id:null,loading:false};
	  this.onSubmitHandler = this.onSubmitHandler.bind(this);
	}

	onSubmitHandler(evt){
		evt.preventDefault()
		var receipt_id = this.state.receipt_id;
		var that = this;

		if(is_empty_string(receipt_id)){
			alert.showMessage("Please Enter Receipt ID","danger");
			return;
		}

		this.setState({loading:true});
		var func_ref = firebase.functions().httpsCallable('InvalidateReceipt');
		func_ref({receipt_id:receipt_id}).then(function(result) {
                  // Read result of the Cloud Function.
                  var data = result.data;
                  console.log(data);             
				  if(data.status==="success"){
				  	alert.showMessage("Receipt Invalidated Succesfully","success");	
				  }
				  else{
				  	alert.showMessage(data.message,"danger");
				  }
				  that.setState({loading:false});
        }).catch(function(error) {
                  // Getting the Error details.
                  //var code = error.code;
                  //var message = error.message;
                  //var details = error.details;
                  // ...
                  console.log(error);
				  alert.showMessage(error.message,"danger");
				  that.setState({loading:false});
        });

	}


	render(){
		return(
    			<Container fluid className="main-content-container px-4 pb-4">
        		{/* Page Header */}
        		<Row noGutters className="page-header py-4">
              			<PageTitle sm="4" title="Invalidate" subtitle="Receipt" className="text-sm-left" />
        		</Row>

        		<form action="/settings" style={{marginBottom: '2%',marginLeft: '2%'}}>
    				<Button type="submit" theme="danger">Cancel</Button>
		    	</form>

     			<Col lg="12">
        			<Card small className="mb-4">
          				<CardHeader className="border-bottom">
              					<h6 className="m-0">Invalidate Receipt</h6>
          				</CardHeader>
          				
          				<CardBody className="p-2 pb-2" >
        				{!this.state.loading &&
         				<Col>
          					<Form onSubmit={this.onSubmitHandler}>
            					<Row form>
              						<Col md="4" className="form-group">
                						<label htmlFor="feEmailAddress">Receipt ID</label>
                						<FormInput
                  								id="feEmailAddress"
                 	 							type="text"
                  								placeholder="Receipt ID"
                  								onChange = {(evt)=>{this.setState({receipt_id:evt.target.value})}}
                						/>
              						</Col>
              						<Col md="4">
                  							<Button type="submit" style={{alignSelf: 'center',marginTop: '9%'}}>Invalidate Receipt</Button>
              						</Col>
           						</Row>
          					</Form>
        				</Col>
        				}
        				{
        					this.state.loading &&
        					<AtomSpinner color="blue" style={{alignSelf: 'center',marginLeft: '40%',marginTop: '5%',marginBottom: '5%'}}/>
        				}	
 
          				</CardBody>
          			</Card>
          		</Col>
         </Container>

		)
	}
}
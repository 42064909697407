import { store } from 'react-easy-state';
import firebase from '../firebase';
import alert from './AlertStore';


const profit_data = store({
		isLoading : false,
		hasError : false,
		gotData : false,


		start_date : null,
		end_date : null,

		data : [],

		total_profit : 0,
		total_expenditure : 0,

		deleteList : [],

		addToDeleteList(id){
			profit_data.deleteList.push(id);
		},

		removeFromDeleteList(id){
			var temp = profit_data.deleteList;
			var newlist = [];
			for(var i=0;i<temp.length;i++){
				if(temp[i]!==id){
					newlist.push(id);
				}
			}
			profit_data.deleteList = newlist;
		},

		deleteAllSelected(){
			var r = window.confirm("Are you Sure?");
			if (r === false) {
				return;
			};
			var temp = profit_data.deleteList;
			temp.forEach(function(id){
				profit_data.deleteProfit(id);
			})
			profit_data.deleteList = [];
		},

		getDiffDays(date1,date2){

			var oneDay = 24*60*60*1000; // hours*minutes*seconds*milliseconds
			var diff_days = Math.round(Math.abs((date1.getTime() - date2.getTime())/(oneDay)));
			return diff_days;
		},

		getData(){

			/*if( profit_data.getDiffDays(profit_data.start_date,profit_data.end_date) > 90 ){ 
				alert.showMessage("Kindly Enter 90 days limit dates","danger");
				return;
			}*/
			if(profit_data.start_date > profit_data.end_date){
				alert.showMessage("Kindly Enter Start Date Lesser than End Date","warning");
				return;
			}

			profit_data.isLoading = true;
			firebase.firestore().collection('Profit').where('update_date','>=',new Date(profit_data.start_date)).where('update_date','<=',new Date(profit_data.end_date)).orderBy('update_date','desc').onSnapshot(function(snap){

					var temp = [];
					profit_data.total_profit = 0;
					profit_data.total_expenditure = 0;
					snap.forEach(function(doc){
						var data = doc.data();
						data.ref_date = data.ref_date.toDate().toLocaleDateString();
						data.update_date = data.update_date.toDate().toLocaleDateString();
						profit_data.total_profit = profit_data.total_profit + ((data.type==='profit')?Math.abs(data.amount):0);
						profit_data.total_expenditure = profit_data.total_expenditure + ((data.type==='expenditure')?Math.abs(data.amount):0);
						temp.push(data);
					})
					console.log(temp);
					profit_data.data = temp;
					profit_data.isLoading = false;
					profit_data.hasError = false;
					profit_data.gotData = true;

			},function(err){
				console.log(err);
				profit_data.isLoading =false;
				profit_data.hasError = true;
				profit_data.gotData = true;
			})

		},

		setStartDate(date){
			profit_data.start_date = new Date(date);

			if(profit_data.end_date!==null){
				profit_data.getData();
			}
		},

		setEndDate(date){
			profit_data.end_date = new Date(date);

			if(profit_data.start_date!==null){
				profit_data.getData();
			}
		},

		deleteProfit(id){
				
			profit_data.isLoading = true;
			firebase.firestore().collection('Profit').doc(id.toString()).delete().then(function(doc){
				profit_data.isLoading = false;
				alert.showMessage("Delete Succesfull. ID = "+id,"success");
			}).catch(function(err){
				profit_data.isLoading = false;
				alert.showMessage("Delete Unsuccessful : "+err.message,"danger");
			})
		},

		


})


export default profit_data;
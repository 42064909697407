import React from "react";
import { Container, Button,FormInput } from "shards-react";
import { view } from 'react-easy-state';
import login_data from '../../loginstore';
import { AtomSpinner } from 'react-epic-spinners';
import AlertBox from '../AlertBox';
import { Redirect } from "react-router-dom";


export default view(()=>{
    var data = login_data;
    console.log(data);
    var isLoading = data.isLoading;
    var isLoggedIn = data.isLoggedIn;

    console.log("Logged in ",isLoggedIn);
    return (isLoggedIn?<Redirect to="/blog-overview" />:<Login isLoading={isLoading} />)
})

function setUserName(uname){
  login_data.username = uname;
}
function setPassword(password){
  login_data.password = password;
}

function login(){
  login_data.login();
}

class Login extends React.Component{
  render(){
    return(
      <div>
        <AlertBox />
  <Container fluid className="main-content-container px-4 pb-4">
    <div className="error">
      <div className="error__content" style={{backgroundColor: 'white'}}>
      {
        !this.props.isLoading &&
        <div className={"p-4"}>
        <img src="https://sgrspchits.com/img1/sgrsp_logo.png" style={{width: '15%',height: '15%',marginTop:'5%'}}/>
        <h4 style={{marginTop: '5%'}}>SGRSP Chits Pvt Ltd</h4>
        <FormInput type="text" placeholder="Username" style={{margin: '5%'}} onChange={(evt)=>{setUserName(evt.target.value)}}/>
        <FormInput type="password" placeholder="Password" style={{margin: '5%'}} onChange={(evt)=>{setPassword(evt.target.value)}}/>
        <Button size="md" style={{marginBottom: '5%'}} onClick={()=>{login()}}>Login</Button>
        </div>
      }
      {
        this.props.isLoading &&
        <AtomSpinner color="blue" style={{alignSelf: 'center',marginTop: '10%',marginBottom: '10%'}}/>
      }
      </div>
    </div>
  </Container>
</div>

      )
  }
}


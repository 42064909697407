import React from "react";
import { Container, Row, Col, Card, CardHeader, CardBody,Button,FormInput,
 } from "shards-react";

import firebase from '../../firebase';
import PageTitle from "../common/PageTitle";
import '../scroll.css';
import { AtomSpinner } from 'react-epic-spinners';
import AlertBox from '../AlertBox';


export default class ApplyCharges extends React.Component{

	constructor(props) {
	  super(props);
	
	  this.state = {
	  					loading:false,
	  					inst_id:null,
	  					inst_no:null,
	  					charges:null,
	  					charges_reason:null,
	  					group_id : null,
	  				};
	  this.applyCharges = this.applyCharges.bind(this);
	}

	componentDidMount(){
      var url_string = window.location.href;
      var url = new URL(url_string);
      var inst_id = url.searchParams.get("inst_id");
      var inst_no =url.searchParams.get('inst_no');
      var group_id =url.searchParams.get('group_id');

      this.setState({
            inst_no,
            inst_id,
            group_id
      	});
  	}

  	applyCharges = () =>{

  		if(this.state.inst_id===null || this.state.inst_id===undefined || this.state.group_id===null || this.state.group_id===undefined){
  			alert.showMessage("A Required Parameter is Missing","danger");
  			return;
  		}
      	if(this.state.charges===null || this.state.charges_reason === null){
        	alert.showMessage("Kindly Enter Charges and Charges Reason","danger");
        	return;
      	}
     

  		var data = {	
  			inst_id : this.state.inst_id,
  			charges_reason : this.state.charges_reason,
  			charges : this.state.charges,
  			group_id : this.state.group_id,
  		}
  		var that = this;

  		this.setState({loading:true});

		var func_ref = firebase.functions().httpsCallable('ApplyInstCharges');

		func_ref(data).then(function(result) {
                  // Read result of the Cloud Function.
                  var data = result.data;
                  console.log(data);
                  if(data.status==="success"){
                        console.log("Document succesfully added!");
                        alert.showMessage("Charges Applied Succesfully","success");
                        window.close();
                   }
                  else{
                        console.log(data.message);
                        alert.showMessage(data.message,"danger");
                  }
                  that.setState({loading:false});

        }).catch(function(error) {
                  // Getting the Error details.
                  //var code = error.code;
                  //var message = error.message;
                  //var details = error.details;
                  // ...
                  console.log(error);
                  that.setState({loading:false});
                  alert.showMessage("Unable to Approve Charges","danger");
        });



  }




	render(){
		return(
    <div>
      <AlertBox />
			<Container fluid className="main-content-container px-4 pb-4">
          
            		{/* Page Header */}
            		<Row noGutters className="page-header py-4">
                  		<PageTitle sm="4" title="Apply Charges" subtitle="Installment" className="text-sm-left" />
            		</Row>

           	 		<Button type="button" onClick={()=>{window.close();}} className={"mb-3 ml-3"} theme="danger">Cancel</Button>
       					
       				<Col lg="12">
               			<Card small className="mb-4">
                    	<CardHeader className="border-bottom">
                          	<h6 className="m-0">Approve Charges</h6>
                    	</CardHeader>
                    <CardBody className="p-2 pb-2" >
                      {
                        !this.state.loading &&


                      <Col>
                            <Row form>
                              <FormTextInput size="4" type="text" field_name="Inst No." disabled value={this.state.inst_no}/>   
                              <FormTextInput size="2" type="number" field_name="Charges" value={this.state.charges} onChange={(evt)=>{this.setState({charges:evt.target.value})}}/>
                              <FormTextInput size="6" type="text" field_name="Charges Reason" value={this.state.charges_reason} onChange={(evt)=>{this.setState({charges_reason:evt.target.value})}} />
                            </Row>
                          

                              <SubmitButton onClick={this.applyCharges} />
                              
                      </Col>
                   	}

                   	{
        					this.state.loading &&
        					<AtomSpinner color="blue" style={{alignSelf: 'center',marginLeft: '40%',marginTop: '10%',marginBottom: '10%'}}/>

      				}

                    </CardBody>
                 </Card>
                </Col>

       		</Container>
        </div>

		)
	}
}


class SubmitButton extends React.Component{
  render(){
    return(
      <Row>
                            <Col lg="4"></Col>
                                <Col lg="4">
                                  <Button onClick={this.props.onClick} style={{maxWidth: '100%',width:'100%',marginTop: '1%'}} size="md">
                                    {"Apply Charges"}
                                  </Button>
                                </Col>
                                <Col lg="4"></Col>
                            </Row>

    )
  }
}

//dont use for date
class FormTextInput extends React.Component{
  render(){
    return(
       <Col md={this.props.size} className="form-group">
                  <label>{this.props.field_name}</label>
                  <FormInput
                  {...this.props}
                  type={this.props.type}
                  placeholder={this.props.field_name}
                  onChange = {this.props.onChange}
                />

              </Col>
    )
  }
}


import React from "react";
import PropTypes from "prop-types";
import {
  Card,
  CardHeader,
  CardBody,
  ListGroup,
  ListGroupItem,
  FormCheckbox,
} from "shards-react";

const SidebarCategories = ({ title }) => (
  <Card small className="mb-3">
    <CardHeader className="border-bottom">
      <h6 className="m-0">{"Groups"}</h6>
    </CardHeader>
    <CardBody className="p-0">
      <ListGroup flush>
        <ListGroupItem className="px-3 pb-2">
          <FormCheckbox className="mb-1" value="uncategorized" defaultChecked>
            All
          </FormCheckbox>
          <FormCheckbox className="mb-1" value="design" defaultChecked>
            GRS501
          </FormCheckbox>
          <FormCheckbox className="mb-1" value="development">
            GRS502
          </FormCheckbox>
          <FormCheckbox className="mb-1" value="writing">
            GRS503
          </FormCheckbox>
          <FormCheckbox className="mb-1" value="books">
            GRS504
          </FormCheckbox>
        </ListGroupItem>
      </ListGroup>
    </CardBody>
  </Card>
);

SidebarCategories.propTypes = {
  /**
   * The component's title.
   */
  title: PropTypes.string
};

SidebarCategories.defaultProps = {
  title: "Categories"
};

export default SidebarCategories;

import * as firebase from 'firebase';
//import firestore from 'firebase/firestore';
import "firebase/messaging";


   // Your web app's Firebase configuration
  var firebaseConfig = {
    apiKey: "AIzaSyBJz5lo0Pzny80dbdQh8-TBpVVnsEoAR3k",
    authDomain: "sgrspchits-6f59a.firebaseapp.com",
    databaseURL: "https://sgrspchits-6f59a.firebaseio.com",
    projectId: "sgrspchits-6f59a",
    storageBucket: "sgrspchits-6f59a.appspot.com",
    messagingSenderId: "11484766145",
    appId: "1:11484766145:web:f16e16d257eb9d7d"
  };
  // Initialize Firebase
  firebase.initializeApp(firebaseConfig);

export default firebase;
function getArray(proxyObj){
    var arr = [];
    var obj = proxyObj;
      for (var key in obj) {
        if (obj.hasOwnProperty(key)) {
                arr.push(obj[key]);
        }
    }
    return arr;
}


export default getArray;
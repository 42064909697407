import React from "react";
import { Redirect } from "react-router-dom";

// Layout Types
import { DefaultLayout } from "./layouts";

// Route Views
import UserProfileLite from "./views/UserProfileLite";
import AddNewPost from "./views/AddNewPost";
import Errors from "./views/Errors";
import ComponentsOverview from "./views/ComponentsOverview";
import Tables from "./views/Tables";
import BlogPosts from "./views/BlogPosts";
import Customer  from "./components/Customer/Customer";
import AllCustomer from './components/AllCustomer/AllCustomer';
import ProfileView from './components/CustomerProfile/ProfileView';
import CollectionAsst from './components/CollectionAssistant/CollectionAsst';
import Introducer from './components/Introducer/Introducer';
import Installment from './components/Installment/Installment';
import Auction from './components/Auction/Auction';
import Group from './components/Group/Group';
import Receipt from './components/Receipt/Receipt';
import DailySummary from './components/DailySummary/DailySummary';
import ProfitAnalysis from './components/ProfitAnalysis/ProfitAnalysis';
import BankDeposit from './components/BankDeposit/BankDeposit';
import Files from './components/Files/Files';
import SMS from './components/SMS/SMS';
import Email from './components/Email/Email';
import DueManagement from './components/DueManagement/Due';
import Settings from './components/Settings/Settings';
import AddNewGroup from './components/Group/AddNewGroup';
import AddCustomer from './components/AllCustomer/AddCustomer';
import AddCollectionAsst from './components/CollectionAssistant/AddCollectionAsst';
import AddIntroducer from './components/Introducer/AddIntroducer';
import RemoveCustomer from './components/AllCustomer/RemoveCustomer';
import RemoveIntroducer from './components/Introducer/RemoveIntroducer';
import ModifyCustomer from './components/AllCustomer/ModifyCustomer';
import PayInstallment from './components/PayInstallment/PayInstallment';
import InvalidateReceipt from './components/PayInstallment/InvalidateReceipt';
import AddProfit from './components/ProfitAnalysis/AddProfit';
import AddBankDeposit from './components/BankDeposit/AddBankDeposit';
import ModifyDeposit from './components/BankDeposit/ModifyDeposit';
import RemoveDeposit from './components/BankDeposit/RemoveDeposit';
import ChangeAuctionTimings from './components/Auction/ChangeAuctionTimings';
import ApproveAuction from './components/Auction/ApproveAuction';
import MarkSuccessfulAuction from './components/Auction/MarkSuccessfulAuction';
import RemoveGroup from './components/Group/RemoveGroup';
import ModifyGroup from './components/Group/ModifyGroup';
import AddCustomerToGroup from './components/Group/AddCustomerToGroup';
import ModifyIntroducer from './components/Introducer/ModifyIntroducer';
import ModifyCollectionAsst from './components/CollectionAssistant/ModifyCollectionAsst';
import ApplyCharges from './components/Installment/ApplyCharges';
import Dashboard from './components/Dashboard/Dashboard';
import Login from './components/Login/Login';
import Report from './components/Settings/ReportError';
import LoginHistory from './components/LoginHistory/LoginHistory';
import ReplaceCustomer from './components/Customer/ReplaceCustomer';
import TransferChit from './components/Customer/TransferChit';
import SMSLog from './components/SMS/SMSLog';
import GeneralVoucher from './components/GeneralVoucher/GeneralVoucher';
import ReplacedTicket from './components/ReplacedTicket/ReplacedTickets';
import ViewIntroducer from './components/ViewIntroducer/ViewIntroducer';
import MarkLean from './components/Customer/MarkLean';
import ViewAuction from './components/Auction/ViewAuction';
import Surety from './components/Surety/Surety';
import TransferMoney from './components/TransferMoney/TransferMoney';

export default [
  {
    path: "/",
    exact: true,
    layout: DefaultLayout,
    component: () => <Redirect to="/blog-overview" />
  },
  {
    path : '/transfermoney',
    layout : DefaultLayout,
    component : TransferMoney,
  },
  {
    path : '/surety',
    layout : DefaultLayout,
    component : Surety,
  },
  {
    path : '/marklean',
    layout : DefaultLayout,
    component : MarkLean,
  },
  {
    path : '/viewauction',
    layout : DefaultLayout,
    component : ViewAuction,
  },
  {
    path : '/generalvoucher',
    layout : DefaultLayout,
    component : GeneralVoucher,
  },
  {
    path : '/viewintroducer',
    layout : DefaultLayout,
    component : ViewIntroducer,
  },
  {
    path : '/replacedtickets',
    layout : DefaultLayout,
    component : ReplacedTicket,
  },
  {
    path : '/loginhistory',
    layout : DefaultLayout,
    component : LoginHistory,
  },
  {
    path : '/smslog',
    layout : DefaultLayout,
    component : SMSLog,
  },
  {
    path : '/report',
    layout : DefaultLayout,
    component : Report,
  },
  {
    path : '/login',
    layout : DefaultLayout,
    component : Login,
  },
  {
    path : '/groupcustomer',
    layout:DefaultLayout,
    component : Customer
  },
  {
    path : '/customer',
    layout:DefaultLayout,
    component : AllCustomer,
  },
  {
    path : '/replacecustomer',
    layout:DefaultLayout,
    component : ReplaceCustomer,
  },
  {
    path : '/transferchit',
    layout:DefaultLayout,
    component : TransferChit,
  },
  {
    path : '/custprofile',
    layout : DefaultLayout,
    component : ProfileView,
  },
  {
    path :'/introducer',
    layout : DefaultLayout,
    component : Introducer,
  },
  {
    path :'/modifyintroducer',
    layout : DefaultLayout,
    component : ModifyIntroducer,
  },
  {
    path : '/collectionasst',
    layout : DefaultLayout , 
    component : CollectionAsst
  },
  {
    path : '/modifycollectionasst',
    layout : DefaultLayout , 
    component : ModifyCollectionAsst
  },
  {
    path : '/installment',
    layout : DefaultLayout,
    component : Installment,
  },
  {
    path : '/applycharges',
    layout : DefaultLayout,
    component : ApplyCharges,
  },
  {
    path : '/auction',
    layout : DefaultLayout,
    component : Auction,
  },
  {
    path : '/changeauctiontimings',
    layout : DefaultLayout,
    component : ChangeAuctionTimings,
  },
  {
    path : '/approveauction',
    layout : DefaultLayout,
    component : ApproveAuction,
  },  
  {
    path : '/approveauctionsuccess',
    layout : DefaultLayout,
    component : MarkSuccessfulAuction,
  },
  {
    path : '/group',
    layout : DefaultLayout,
    component : Group,
  }, 
  {
    path : '/addnewgroup',
    layout : DefaultLayout,
    component : AddNewGroup,
  },
  {
    path : '/removegroup',
    layout : DefaultLayout,
    component : RemoveGroup,
  }, 
  {
    path : '/modifygroup',
    layout : DefaultLayout,
    component : ModifyGroup,
  },
  {
    path : '/addcustomergroup',
    layout : DefaultLayout,
    component : AddCustomerToGroup,
  },   
  {
    path : '/payinstallment',
    layout : DefaultLayout,
    component : PayInstallment,
  },
  {
    path : '/invalidatereceipt',
    layout : DefaultLayout,
    component: InvalidateReceipt,
  },
  {
    path : '/receipt',
    layout : DefaultLayout,
    component : Receipt
  },
  {
    path : '/dailysummary',
    layout : DefaultLayout,
    component : DailySummary
  },
  {
    path : '/profit',
    layout : DefaultLayout,
    component : ProfitAnalysis
  },
  {
    path : '/addprofit',
    layout : DefaultLayout,
    component : AddProfit,
  },
  {
    path : '/deposit',
    layout : DefaultLayout,
    component : BankDeposit
  },
  {
    path : '/adddeposit',
    layout : DefaultLayout,
    component : AddBankDeposit,
  },
  {
    path : '/modifydeposit',
    layout : DefaultLayout,
    component : ModifyDeposit,
  },
  {
    path : '/removedeposit',
    layout : DefaultLayout,
    component : RemoveDeposit,
  },

  {
    path : '/email',
    layout : DefaultLayout,
    component : Email,
  },
  {
    path : '/files',
    layout : DefaultLayout,
    component :Files
  },
  {
    path : '/sms',
    layout : DefaultLayout,
    component :SMS
  },
  {
    path : '/due',
    layout : DefaultLayout,
    component : DueManagement
  },
  {
    path : '/settings',
    layout : DefaultLayout,
    component : Settings
  },
  {
    path : '/addcustomer',
    layout : DefaultLayout,
    component : AddCustomer
  },
  {
    path : '/addcollectionasst',
    layout : DefaultLayout,
    component : AddCollectionAsst
  },
  {
    path : '/addintroducer',
    layout : DefaultLayout,
    component : AddIntroducer
  },
  {
    path : '/removecustomer',
    layout : DefaultLayout,
    component : RemoveCustomer
  },
  {
    path : '/removeintroducer',
    layout : DefaultLayout,
    component : RemoveIntroducer
  },
  {
    path : '/modifycustomer',
    layout : DefaultLayout,
    component : ModifyCustomer
  },
  {
    path: "/blog-overview",
    layout: DefaultLayout,
    component: Dashboard
  },
  {
    path: "/user-profile-lite",
    layout: DefaultLayout,
    component: UserProfileLite
  },
  {
    path: "/add-new-post",
    layout: DefaultLayout,
    component: AddNewPost
  },
  {
    path: "/errors",
    layout: DefaultLayout,
    component: Errors
  },
  {
    path: "/components-overview",
    layout: DefaultLayout,
    component: ComponentsOverview
  },
  {
    path: "/tables",
    layout: DefaultLayout,
    component: Tables
  },
  {
    path: "/blog-posts",
    layout: DefaultLayout,
    component: BlogPosts
  }
];

import React from 'react';
import { Container, Row, Col,Card , CardHeader,CardBody,FormInput,Button,Form,FormSelect } from "shards-react";
import PageTitle from "../common/PageTitle";
import firebase from '../../firebase';
import { AtomSpinner } from 'react-epic-spinners';

import alert from '../AlertStore';


export default class MarkSuccessfulAuction extends React.Component{
  constructor(props) {
    super(props);
  
    this.state = {	
                loading  :false,
            		
                group_id : null,
                auct_no : null,
            	  auct_timings : null,
            		
                auct_id  : null,
                cust_id  : null,
                ticket_no : null,
                phone  : null,
            		
                amount_paid : null,
                amount_paid_date : null,
                payment_method : null,
                payment_ref : null,
            };
    this.ApproveSuccess = this.ApproveSuccess.bind(this);
  }


  componentDidMount(){
      var url_string = window.location.href;
      var url = new URL(url_string);
      var group_id = url.searchParams.get("group_id");
      var auct_timings =url.searchParams.get('auct_timings');
      var auct_id = url.searchParams.get('auct_id');
      var auct_no = url.searchParams.get('auct_no');
      var cust_id = url.searchParams.get('cust_id');
      var ticket_no = url.searchParams.get('ticket_no');
      var phone = url.searchParams.get('phone');

      this.setState({
            group_id,
            auct_timings,
            auct_id,
            auct_no,
            cust_id,
            ticket_no,
            phone,
      });
  }

  ApproveSuccess = () =>{
  		if(this.state.amount_paid_date===null || this.state.amount_paid_date===undefined || this.state.amount_paid===null || this.state.amount_paid===undefined || this.state.payment_method===null || this.state.payment_method===undefined || this.state.payment_ref===null || this.state.payment_ref===undefined){
  			alert.showMessage("Kindly Enter All Details","warning");
  			return;
  		}
  		if(this.state.auct_id===null || this.state.auct_id===undefined ){
  			alert.showMessage("Error : A Required Parameter is Missing","warning");
  			return;
  		}

  		var data = {
  			auct_id : this.state.auct_id,
  			amount_paid_date : new Date(this.state.amount_paid_date).toUTCString(),
        amount_paid : this.state.amount_paid,
        payment_method : this.state.payment_method,
        payment_ref : this.state.payment_ref,
  		}

  		var that = this;

  		this.setState({loading:true});

		var func_ref = firebase.functions().httpsCallable('markSuccesfullAuction');

		func_ref(data).then(function(result) {
                  // Read result of the Cloud Function.
                  var data = result.data;
                  console.log(data);
                  if(data.status==="success"){
                        console.log("Document succesfully added!");
                        alert.showMessage("Auction Marked Success","success");
                        window.close();
                   }
                  else{
                        console.log(data.message);
                        alert.showMessage(data.message,"danger");
                  }
                  that.setState({loading:false});

        }).catch(function(error) {
                  // Getting the Error details.
                  //var code = error.code;
                  //var message = error.message;
                  //var details = error.details;
                  // ...
                  console.log(error);
                  that.setState({loading:false});
                  alert.showMessage("Unable to Mark Success","danger");
        });



  }

 

  render(){


    return(
       <Container fluid className="main-content-container px-4 pb-4">
          
            {/* Page Header */}
            <Row noGutters className="page-header py-4">
                  <PageTitle sm="4" title="Approve Success" subtitle="Auction" className="text-sm-left" />
            </Row>

            <form onSubmit={()=>{window.close();}} style={{marginBottom: '2%',marginLeft: '2%'}}>
                <Button type="submit" theme="danger">Cancel</Button>
           </form>


          <Col lg="12">
               <Card small className="mb-4">
                    <CardHeader className="border-bottom">
                          <h6 className="m-0">Auction Approve Success</h6>
                    </CardHeader>
                    <CardBody className="p-2 pb-2" >
                      {
                        !this.state.loading &&
                        <Col>
                          <Form /*onSubmit={this.onSubmitHandler}*/>
                            <Row form>
                              <FormTextInput size="4" type="text" field_name="Group ID" disabled value={this.state.group_id} onChange={()=>{}}/>   
                              <FormTextInput size="2" type="text" field_name="Auct No." disabled value={this.state.auct_no} onChange={()=>{}}/>
                              <FormTextInput size="6" type="text" field_name="Auction Timings" disabled value={this.state.auct_timings} onChange={()=>{}} />
                            </Row>
                            <Row form>
                              <FormTextInput size="4" type="text" field_name="Customer ID" disabled value={this.state.cust_id} onChange={()=>{}}/>   
                              <FormTextInput size="4" type="text" field_name="Ticket No." disabled value={this.state.ticket_no} onChange={()=>{}}/>
                              <FormTextInput size="4" type="text" field_name="Phone" disabled value={this.state.phone} onChange={()=>{}} />
                            </Row>
                            <Row form>
                                <FormTextInput size="4" type="number" field_name="Amount Paid" onChange={(evt)=>{this.setState({amount_paid:evt.target.value})}}/> 
                                <FormTextInput size="4" type="datetime-local" field_name="Amount Paid Date" onChange={(evt)=>{this.setState({amount_paid_date:evt.target.value})}}/> 
                                <PaymentMethod onClick={(evt)=>{this.setState({payment_method:evt.target.value})}} />  
                            
                            </Row>
                            <Row form>
                              <FormTextInput size="4" type="text" field_name="Payment Ref" onChange={(evt)=>{this.setState({payment_ref:evt.target.value})}}/> 
                            </Row>
                          

                              <SubmitButton onClick={this.ApproveSuccess} />
                              
                          </Form>
                        </Col>
                      }
                      
                        {
                          (this.state.loading) &&
                          <AtomSpinner color="blue" style={{alignSelf: 'center',marginLeft: '40%',marginTop: '10%',marginBottom: '10%'}}/>
                        }
                      
                    </CardBody>
                </Card>
              </Col>


        </Container>

    )
  }
}


class SubmitButton extends React.Component{
  render(){
    return(
      <Row>
                            <Col lg="4"></Col>
                                <Col lg="4">
                                  <Button onClick={this.props.onClick} style={{maxWidth: '100%',width:'100%',marginTop: '1%'}} size="md">
                                    {"Approve Auction"}
                                  </Button>
                                </Col>
                                <Col lg="4"></Col>
                            </Row>

    )
  }
}

class PaymentMethod extends React.Component{
  render(){
    return(
          <Col md="4" className="form-group">
                    <label htmlFor="feEmailAddress">{"Payment Method"}</label>
                    <br />
                     <FormSelect {...this.props}>
                        <option value={null}>Select</option>
                        <option value={"cash"}>Cash</option>
                        <option value={"cheque"}>Cheque</option>
                        <option value={"NEFT"}>NEFT</option>
                    </FormSelect>
              </Col>

    )
  }
}

//dont use for date
class FormTextInput extends React.Component{
  render(){
    return(
       <Col md={this.props.size} className="form-group">
                  <label>{this.props.field_name}</label>
                  <FormInput
                  {...this.props}
                  type={this.props.type}
                  placeholder={this.props.field_name}
                  onChange = {this.props.onChange}
                />

              </Col>
    )
  }
}


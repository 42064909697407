import {store} from 'react-easy-state';
import firebase from '../../firebase';
import alert from '../AlertStore';

const files_data = store({
    isLoading : false,
    currentFolder : '',
    ref : firebase.storage().ref('root'),
    
    files_list : [],
    folder_list : [],

    getUpdateRef(){
        return files_data.ref.child(files_data.currentFolder);
    },

    deleteItem(path){
        var r = window.confirm("Are you Sure?");
        if (r === false) {
             return;
        } 
        files_data.isLoading = true;
        var Ref = firebase.storage().ref(path);
        // Delete the file
        Ref.delete().then(function() {
                // File deleted successfully
                files_data.isLoading = false;
                alert.showMessage("Succesfully Deleted","success");
                files_data.listAllItems();
        }).catch(function(error) {
                // Uh-oh, an error occurred!
                files_data.isLoading = false;
                console.error(error);
        });
    }, 
    
    addNewFolder(){
        var fname = prompt("Please enter folder name");
        if (fname == null || fname == "") {
            return;
        }
        files_data.changeFolder(fname);
        alert.showMessage('The Directory is valid only if atleat one file exists',"danger");
    },


    getUrl(path){
        console.log(path);
        var ref = firebase.storage().ref(path);
        
        return ref.getDownloadURL().then(function(url) {
            
                return url;
          
          }).catch(function(error) {
            // Handle any errors
            console.error(error);
            alert.showMessage(error.message,"danger");
          });
    },

    goBack(){
        var temp = files_data.currentFolder.split('/');
        temp.pop();
        console.log(temp);
        if(temp.length===0){
            files_data.currentFolder = '';
            files_data.listAllItems();
            return;
        }
        files_data.currentFolder = temp.join('/');
        files_data.listAllItems();
    },

    changeFolder(folderName){
        if(folderName==='..'){
            files_data.goBack();
            return;
        }
        if(files_data.currentFolder===''){
            files_data.currentFolder = folderName;
            files_data.listAllItems();
            return;
        }
        files_data.currentFolder = files_data.currentFolder+"/"+folderName;
        files_data.listAllItems();

    },

    listAllItems(){
        console.log("File=",files_data.currentFolder);
        // Find all the prefixes and items.
        var reference = files_data.ref;
        if(files_data.currentFolder!==''){
            reference = reference.child(files_data.currentFolder);
        }
    
        var files = [];
        var folders = [];
        files_data.isLoading = true;
        files_data.folder_list = [];
        files_data.files_list = [];
        
        reference.listAll().then(function(res) {
            //list folders
            res.prefixes.forEach(function(folderRef) {
                    folders.push(folderRef);
            });
            //list files
            res.items.forEach(function(itemRef) {
                files.push(itemRef);
            });
            files_data.folder_list = folders;
            files_data.files_list = files;
            files_data.isLoading = false;
            console.log(files,folders);
            console.log(files_data.folder_list);
            console.log(files_data.files_list);
        }).catch(function(error) {
            // Uh-oh, an error occurred!
            console.error(error);
            files_data.isLoading = false;
        });
    },
})





export default files_data;
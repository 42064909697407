import { store } from 'react-easy-state';
import firebase from '../firebase';
import is_empty_string from './isEmptyString';
import getArray from './getArray';
import alert from './AlertStore';

const pay_inst = store({
	group_id : null,
	ticket_no: null,

	isInstLoading : false,
	InsthasError : false,
	instData : [],
	no_of_due : 0,



	inst_total_without_interest : 0,

	isChitLoading : false,
	ChitHasError : false,
	hasChitData : false,
	chitDetails : {},
	gotData : false,

	showReceipt : false,
	showReceiptdata : [],


	viewReceipt(data){
		pay_inst.showReceipt = true;
		pay_inst.showReceiptdata = [data];
		console.log(pay_inst.showReceiptdata);
	},

	closeViewReceipt(){
		pay_inst.showReceipt = false;
		pay_inst.showReceiptdata = [];
	},

	setGroupID(group_id){
		pay_inst.group_id = group_id;
	},	

	setTicketNo(ticket_no){
		pay_inst.ticket_no  = ticket_no;
	},

	getData(){
		pay_inst.resetPayInstallment();
		if(is_empty_string(pay_inst.group_id) || is_empty_string(pay_inst.ticket_no) ){
			alert.showMessage("Invalid Input","danger");
			return;
		}
		pay_inst.inst_total_without_interest = 0; // important
		pay_inst.isChitLoading = true;
		let code = pay_inst.group_id + ":" +pay_inst.ticket_no;
		firebase.firestore().collection('GroupCustomer').doc(code).get().then(function(doc){
			if(doc.exists){
				let data = doc.data();

				let paid_inst = getArray(data.installment_payments_detail);
				let due_inst  = [];
				
				paid_inst.forEach(function(inst){
						if(!inst.paid){
							due_inst.push(inst.name);
						}
				})
				

				pay_inst.getDueInstDetails(due_inst);

				pay_inst.isChitLoading = false;
				pay_inst.ChitHasError = false;
				pay_inst.hasChitData = true;
				pay_inst.chitDetails = data;
				pay_inst.gotData 	= true;


			}
			else{
				alert.showMessage("Invalid Ticket No","warning");
				pay_inst.isChitLoading = false;
				pay_inst.ChitHasError  = true;
				pay_inst.hasChitData   = false;
				pay_inst.gotData 	= true;
			}
			pay_inst.calculate_everything();
		})

	},

	async getDueInstDetails(due_inst){
		console.log(due_inst);
		let query = firebase.firestore().collection('Installment');

		pay_inst.isInstLoading = true;
		pay_inst.no_of_due = 0;
		pay_inst.total_interest = 0;
		pay_inst.inst_total_without_interest = 0;

		var inst_ids = [];
		let temp = [];

		console.log("Before inst processing Inst total " ,pay_inst.inst_total_without_interest);

		await due_inst.forEach(async function(inst_id){
			await query.doc(inst_id).get().then(function(doc){
				if(doc.exists){
					let data = doc.data();
					data = pay_inst.calc_insterest_and_total(data);
					temp.push(data);
					inst_ids.push(data.inst_id);
					pay_inst.no_of_due = pay_inst.no_of_due+1;
					pay_inst.inst_total_without_interest = pay_inst.inst_total_without_interest + data.installment_val -data.dividend;
					pay_inst.total_interest = pay_inst.total_interest + data.interest;
				}
				else{
					console.log("Inst "+inst_id+" not found");
				}
				pay_inst.calculate_everything();
			}).catch(function(err){
				console.log(err);
				pay_inst.InsthasError  =  true;
				alert.showMessage(err.message,"danger");
			})
		})

		pay_inst.isInstLoading = false;
		pay_inst.instData 	   = temp;
		pay_inst.selected_inst = [];
		console.log("After inst processing Inst total " ,pay_inst.inst_total_without_interest);
		console.log(pay_inst);
	},

	calc_insterest_and_total(inst_data){

		 var inst_val = inst_data.installment_val;
    	 var dividend = inst_data.dividend;
    	 var other_charges = inst_data.extra_charges;
    	 var interest_total = pay_inst.getInterest(inst_data.due_date,inst_data.installment_val);
    	 var total = (inst_val + other_charges + interest_total) - dividend;
    	 var data = inst_data;
    	 data['interest'] = interest_total;
    	 data['total'] = total;
    	 console.log(data);
    	 return data;

	},
	getInterest(due_date,inst_val){
		let diff_days = pay_inst.GetDiffDays(due_date);
        let interest = diff_days > 0 ? (parseInt(inst_val) * (0.0493150/100) )  * diff_days  :0;
        return Math.floor(interest);
	},
	GetDiffDays(due_date){
          var date1 = new Date(due_date.toDate());
          var date2 = new Date();
          var timeDiff = date2.getTime() - date1.getTime();
          var diffDays = timeDiff / (1000 * 3600 * 24);

          return parseInt(diffDays);
	},

	manual_receipt_id : 0,
	manual_receipt_date : null,
	payment_method   : null,
	comments : null,
	receivedBy:null,
	//calculation section
	previous_carriage : 0,
	estimated_arrear  : 0,
	excess_paid 	  : 0,
	inst_total : 0,
	grand_total	      : 0,

	//interest
	total_interest : 0,

	//selected installments (checked)
	selected_inst : [],
	
	//set by user
	other_charges 	  : 0,
	customer_paid	  : 0,

	//bill details 
	mr_id : null,
	mr_date  : null,
	comments : null,
	receivedBy : null,


	getPreviousCarriage(account_balance,total){
		console.log("AC = ",account_balance," Total  =",total);
		if(account_balance>=0){
			console.log("PC = ",account_balance);
			return account_balance;
		}
		var temp = total - Math.abs(account_balance);
		if(temp>0){
			console.log("PC = ",account_balance);
			return temp;
		}
		console.log("PC = ",0);	
		return 0;
	},
	
	calculate_everything(){
		var inst_data = pay_inst.instData;
		var selected_inst = pay_inst.selected_inst;

		console.log(selected_inst);

		let other_charges = pay_inst.other_charges;
		let customer_paid = pay_inst.customer_paid;
		let cust_det =null;
	
		
		if(pay_inst.hasChitData){
			cust_det  = pay_inst.chitDetails;
		}
		else{
			console.log("Cannot Perform Calculation");
			return;
		}

		let interest = 0;
		let inst_total = 0;
		let estimated_arrear  = (cust_det.account_balance<0?cust_det.account_balance:0);
		let excess_paid = 0;
		let grand_total = 0;


		selected_inst.forEach(function(inst_id){
			inst_data.forEach(function(inst){
				
				if(inst.inst_id===inst_id){
						inst_total = inst_total + inst.total;
				   		interest = interest + inst.interest;
				}
					
			});
		});



		console.log("Before func call Inst total " ,pay_inst.inst_total_without_interest);
		let previous_carriage = pay_inst.getPreviousCarriage(cust_det.account_balance,pay_inst.inst_total_without_interest);
		grand_total = ( inst_total + other_charges - previous_carriage );
		excess_paid = ( customer_paid <= grand_total ? 0 : customer_paid - grand_total );

		//assign to global
		pay_inst.grand_total = grand_total;
		pay_inst.excess_paid = excess_paid;
		pay_inst.inst_total = inst_total;
		pay_inst.total_interest = interest;
		pay_inst.estimated_arrear = estimated_arrear;
		pay_inst.previous_carriage = previous_carriage;

		console.log("Previous Carriage = ",previous_carriage );
	},

	

	addInst(inst_id){
		pay_inst.selected_inst.push(inst_id);
		pay_inst.calculate_everything();
	},

	removeFromArray(index,array){
		if (index > -1) {
  			array.splice(index, 1);
		}
		// array = [2, 9]
		return array;

	},
	removeInst(inst_id){
		var index = null ;
		for(var i = 0 ; i < pay_inst.selected_inst.length ; i++){
			if(pay_inst.selected_inst[i]==inst_id){
				index = i ;
			}
		}
		console.log("Remove index "+index);
		if(index!==null){
			var res = pay_inst.removeFromArray(index,pay_inst.selected_inst);
			pay_inst.selected_inst = res;
			console.log("Selected inst "+res);
			pay_inst.calculate_everything();
		}

	},

	setOtherCharges(charges){
		if(isNaN(charges) || is_empty_string(charges) ){
			pay_inst.other_charges = 0;
			pay_inst.calculate_everything();
			return;
		}
		pay_inst.other_charges = parseInt(charges);
		pay_inst.calculate_everything();
	},

	setCustomerPaid(amount){
		console.log(amount);
		console.log(isNaN(amount));
		if(isNaN(amount) || is_empty_string(amount) ){
			pay_inst.customer_paid = 0;
			pay_inst.calculate_everything();	
			return;
		}
		pay_inst.customer_paid = parseInt(amount);
		console.log(pay_inst.customer_paid);
		pay_inst.calculate_everything();
	},


	setMRID(id){
 	 	pay_inst.mr_id = id;
	},

	setMRDate(date){
  		pay_inst.mr_date = date;
  		console.log(pay_inst.mr_date);
	},

	setComments(comments){
 	 	pay_inst.comments = comments;
	},

	setReceivedBy(person){
  		pay_inst.receivedBy = person;
	},

	setPaymentMethod(method){
		pay_inst.payment_method = method;
	},

	formatSelectedInst(selected_inst){
		var temp = [];


		selected_inst.forEach(function(inst_id){
			
			pay_inst.instData.forEach(function(inst){
				if(inst_id===inst.inst_id){
					temp.push({id:inst_id,value:(inst.installment_val-inst.dividend+inst.extra_charges)});
				}
			})
		
		})
		return temp;

	},

	payInstallment(){

		if(is_empty_string(pay_inst.customer_paid.toString()) || is_empty_string(pay_inst.payment_method))
		{	
			alert.showMessage("Kindly Enter Customer Paid and Payment Method","warning");
			return;
		}

		if(pay_inst.customer_paid < pay_inst.grand_total && (pay_inst.grand_total>0) ){
			alert.showMessage("Customer Paid Less than Grand Total","warning");
			return;
		}

		var selected_inst = pay_inst.formatSelectedInst(pay_inst.selected_inst);

		var interest = 0;

		//kindly caclulate interest only for selected inst

		pay_inst.isChitLoading = true;

		var data = { 
						cust_data : pay_inst.chitDetails,	
						bill_data : {
							mr_id : pay_inst.mr_id,
							mr_date : pay_inst.mr_date===null?null:new Date(pay_inst.mr_date).toUTCString(),
							comments : pay_inst.comments,
							receivedBy : pay_inst.receivedBy,
							payment_method : pay_inst.payment_method,

							customer_paid : pay_inst.customer_paid,
							other_charges : pay_inst.other_charges,
							installment_total : pay_inst.inst_total,
							grand_total : pay_inst.grand_total,
							excess_paid : pay_inst.excess_paid,

							interest : pay_inst.total_interest,

							selected_inst :selected_inst,
						}
				   };
		console.log(data);
	

	var func_ref = firebase.functions().httpsCallable('payInstallment');
	func_ref(data).then(function(result) {
                  // Read result of the Cloud Function.
                  var rdata = result.data;
                  console.log(rdata);                  
				  pay_inst.isChitLoading = false;
				  try{
					if(rdata.status==="success"){
						alert.showMessage("Receipt Added Succesfully","success");	
						pay_inst.resetPayInstallment();
					}
					else{
						alert.showMessage(rdata.message,"danger");
					}	
				  }
				  catch(err){
						alert.showMessage("Unknown Error Occured.Kindly View this Profile","danger");
				  }
				  
        }).catch(function(error) {
                  // Getting the Error details.
                  //var code = error.code;
                  //var message = error.message;
                  //var details = error.details;
                  // ...
                  console.log(error);
				  pay_inst.isChitLoading = false;
				  alert.showMessage(error.message,"danger");
        });

    },



    resetPayInstallment(){

			pay_inst.isInstLoading = false;
			pay_inst.InsthasError = false;
			pay_inst.instData = [];
			pay_inst.no_of_due = 0;
			

			pay_inst.isChitLoading = false;
			pay_inst.ChitHasError = false;
			pay_inst.hasChitData = false;
			pay_inst.chitDetails = {};
			pay_inst.gotData = false;

			pay_inst.inst_total_without_interest = 0;

			//interest
			pay_inst.total_interest = 0;


			pay_inst.manual_receipt_id = 0;
			pay_inst.manual_receipt_date = null;
			pay_inst.payment_method   = null;
			pay_inst.comments = null;
			pay_inst.receivedBy=null;
			//calculation section
			pay_inst.previous_carriage = 0;
			pay_inst.estimated_arrear  = 0;
			pay_inst.excess_paid 	  = 0;
			pay_inst.inst_total 		  = 0;
			pay_inst.grand_total	      = 0;

			//selected installments (checked)
			pay_inst.selected_inst = [];
	
			//set by user
			pay_inst.other_charges 	  = 0;
			pay_inst.customer_paid	  = 0;

			//bill details 
			pay_inst.mr_id = null;
			pay_inst.mr_date  = null;
			pay_inst.comments = null;
			pay_inst.receivedBy = null;
    },


	editReceipt(id,comment){
		var newComment = window.prompt("Please enter", comment);

		if (newComment == null || id==null  ) {
				return;
		}
		alert.showMessage("Comment is Updating","success");
		
		firebase.firestore().collection('Receipt').doc(id.toString()).update({comments:newComment}).then(function(doc){
			alert.showMessage('Succesfully Updated Receipt '+id,"success");
		})
		.catch(function(err){
			alert.showMessage(err.message,"danger");
			console.error(err);
		})
	}


})


export default pay_inst;
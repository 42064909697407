import React from "react";
import { Col, 
		InputGroup,
  		InputGroupAddon,
  		FormSelect ,
  		InputGroupText
	} from "shards-react";
import {  view } from 'react-easy-state';
import collection_assistant_list from './collectionAssistantListStore';
import getArray from './getArray';


export default view((props)=>{     
                                  var data = getArray(collection_assistant_list.list);
                                  var isLoading = collection_assistant_list.isLoading;
                                  var exists = collection_assistant_list.hasData;
                                  

                                  return <SelectCollectionAsst data={data} 
                                                   	  isLoading={isLoading}
                                                   	  exists = {exists}
                                                   	  onChange = {props.onChange}
                                                  
                                          />
                      });

class SelectCollectionAsst extends React.Component{

	componentDidMount(){
			console.log("Running GetGRoupNames");
			collection_assistant_list.getList();
	}


  render(){

      var arr  = this.props.data;
     

    return (
    
      <InputGroup className="mb-3">
                
     <FormSelect onChange ={this.props.onChange}>
        <option value={null}>{this.props.isLoading?"Loading...":(this.props.exists?"Choose Collection Asst":"No Collection Asst")}</option>
        <option value={"None"}>None</option>
        {

               arr.map((item,i)=>{
                         return (<option key={i} value={item.ca_id}>{item.ca_id+" ("+item.name+")"}</option>)
                  })
              
        } 
        

      </FormSelect>
     
      <InputGroupAddon type="prepend">
                    <InputGroupText>CA</InputGroupText>
      </InputGroupAddon>
    </InputGroup>
  )
  }
}


import React from "react";
import { Card,  CardBody,FormCheckbox,} from "shards-react";

import { AtomSpinner } from 'react-epic-spinners';

import '../scroll.css';
import { view } from 'react-easy-state';
import Message from '../Message';
import pay_inst from '../payInstallmentStore';
import format_rupee from '../formatRupee';


function getArray(proxyObj){
    var arr = [];
    var obj = proxyObj;
      for (var key in obj) {
        if (obj.hasOwnProperty(key)) {
                arr.push(obj[key]);
        }
    }
    return arr;
}

function addInst(inst_id){
    pay_inst.addInst(inst_id);
}

function removeInst(inst_id){
  pay_inst.removeInst(inst_id);
}



export default view(()=>{
    var data = {
      isLoading : pay_inst.isInstLoading,
      hasError  : pay_inst.InsthasError,
      data      : getArray(pay_inst.instData),
      no_of_due : pay_inst.no_of_due,
      dividend_total : 0,
      installment_total : 0,
      other_charges : 0,
      interest_total : 0,
      all_total : 0,
    };  

    data.data.forEach(function(inst){
      data.installment_total = data.installment_total + inst.installment_val;
      data.dividend_total = data.dividend_total + inst.dividend;
      data.other_charges =  data.other_charges + inst.extra_charges;
      data.interest_total = data.interest_total + inst.interest;
      data.all_total     = data.all_total + inst.total;
    })

    return <Installments data={data} />
})


class Installments extends React.Component{
	render(){
    console.log(this.props.data);
		return(
			<Card>
        <CardBody>
        { (!this.props.data.isLoading && (this.props.data.data.length>0) ) &&
 			       <table className="table mb-0 scroll_list-group">
              <thead className="bg-light">
                <tr>
                  <th scope="col" className="border-0">
                    Select
                  </th>
                  <th scope="col" className="border-0">
                    Sl No.
                  </th>
                  <th scope="col" className="border-0">
                    Installment No.
                  </th>
                    <th scope="col" className="border-0">
                      Due Date
                  </th>
                  <th scope="col" className="border-0">
                      Installment Value
                  </th>
                  <th scope="col" className="border-0">
                      Dividend
                  </th>
                  <th scope="col" className="border-0">
                      Other Charges
                  </th>
                
                  <th scope="col" className="border-0">
                      Interest
                  </th>
                  <th scope="col" className="border-0">
                      Total
                  </th>
                 
                 

                </tr>
              </thead>
              <tbody>
                  {
                          this.props.data.data.map((item,i)=>{
                                return (<Trow key={i} data={item} index={i+1}/>)
                          })
                  }
                  <tr>
                    <td>Total</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td><b>{format_rupee(this.props.data.installment_total)}</b></td>
                    <td><b>{format_rupee(this.props.data.dividend_total)}</b></td>
                    <td><b>{format_rupee(this.props.data.other_charges)}</b></td>
                    <td><b>{format_rupee(this.props.data.interest_total)}</b></td>
                    <td><b>{format_rupee(this.props.data.all_total)}</b></td>
                  </tr>
                
              </tbody>
            </table>
          }

            {
              (this.props.data.isLoading) &&
               <AtomSpinner color="blue" style={{alignSelf: 'center',marginLeft: '40%',marginTop: '10%',marginBottom: '10%'}}/>
            }
            
            {
              (!this.props.data.isLoading && this.props.data.hasError) &&
              <Message message={"Error Loading Data"} icon_name={"warning"} />
            }
            {
              ( !this.props.data.isLoading && (this.props.data.data.length===0) ) &&
              <Message message={"No Due Installments"} icon_name={"sentiment_very_satisfied"} />
            }

            </CardBody>
           </Card>
		)
	}
}

class Trow extends React.Component{

  constructor(props) {
    super(props);
  
    this.state = {isChecked:false};
    this.handleCheck = this.handleCheck.bind(this);
  }

  handleCheck = () =>{
    if(this.state.isChecked){
        removeInst(this.props.data.inst_id);
        this.setState({isChecked:false});
        console.log("Not Checked");
    }
    else{
      console.log("Removing inst_id "+this.props.data.inst_id);
      addInst(this.props.data.inst_id);
      this.setState({isChecked:true});
      console.log("Checked");
    }
  }

  render(){
    return(
            <tr>
                    <td><FormCheckbox checked={this.state.isChecked} onChange={this.handleCheck}></FormCheckbox></td>
                    <td>{this.props.index}</td>
                    <td>{this.props.data.inst_no}</td>
                    <td>{this.props.data.due_date.toDate().toLocaleDateString()}</td>
                    <td style={{color:'red'}}>{format_rupee(this.props.data.installment_val)}</td>
                    <td style={{color:'green'}}>{format_rupee(this.props.data.dividend)}</td>
                    <td style={{color:'red'}}>{format_rupee(this.props.data.extra_charges)}</td>
                    <td style={{color:'red'}}>{format_rupee(this.props.data.interest)}</td>
                    <td><b>{format_rupee(this.props.data.total)}</b></td>
          </tr>
                  

      )
  }
}
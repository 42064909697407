import React from 'react';
import { Container, Row, Col,Card , CardHeader,CardBody,FormInput,Button,
		Form,FormSelect,DatePicker } from "shards-react";
import PageTitle from "../common/PageTitle";
import FireFileUpload from '../FireFileUpload';
import firebase from '../../firebase';
import { AtomSpinner } from 'react-epic-spinners';
import is_empty_string from '../isEmptyString';
import alert from '../AlertStore';
import AlertBox from '../AlertBox';


export default class AddProfit extends React.Component{
	constructor(props) {
	  super(props);
	
	  this.state = {loading:false,
	  				amount:null,
	  				description :null,
	  				type:'profit', //initial 
	  				payment_method:'cash',//initital
 	  				ref_date : null,
	  				doc1_link:null,
	  				doc1_filename:null,
	  				doc2_link:null,
	  				doc2_filename:null,
	  				};
	  this.addProfit = this.addProfit.bind(this);
	  this.resetState = this.resetState.bind(this);
	}

	resetState = () =>{
			this.setState({
					loading:false,
	  				amount:null,
	  				description :null,
	  				type:'profit',
	  				payment_method:'cash',
	  				ref_date : null,
	  				doc1_link:null,
	  				doc1_filename:null,
	  				doc2_link:null,
	  				doc2_filename:null,
	  		});
	}

	addProfit = () =>{
		if( is_empty_string(this.state.amount) || is_empty_string(this.state.description) || is_empty_string(this.state.type) || is_empty_string(this.state.payment_method) || is_empty_string(this.state.ref_date===null?null:this.state.ref_date.toLocaleDateString()) ){
				alert.showMessage("Kindly Enter All the Details","warning");
				return;
		}

		var data = {
			amount : parseInt(this.state.amount),
			description:this.state.description,
			type:this.state.type,
			payment_method:this.state.payment_method,
			ref_date:this.state.ref_date.toUTCString(),
			doc1_link : this.state.doc1_link,
			doc2_link : this.state.doc2_link,
		};
		console.log(data);
		//return;
		var that = this;
		this.setState({loading:true});
		var func_ref = firebase.functions().httpsCallable('addNewProfit');

		func_ref(data).then(function(result) {
                  // Read result of the Cloud Function.
                  var data = result.data;
                  console.log(data);
                  if(data.status==="success"){
                        console.log("Document succesfully added!");
                        alert.showMessage("Profit Added Successfully","success");
                        console.log(that.state);
                   }
                  else{
                        console.log(data.message);
                        alert.showMessage(data.message,"danger");
                  }
                  that.setState({loading:false});
                  that.resetState();

        }).catch(function(error) {
                  // Getting the Error details.
                  //var code = error.code;
                  //var message = error.message;
                  //var details = error.details;
                  // ...
                  console.log(error);
                  that.setState({loading:false});
                  alert.showMessage("Unable to add Profit","danger");
        });

	}

	render(){
		return(
		<div>
			<AlertBox />
			 <Container fluid className="main-content-container px-4 pb-4">
        	
        		{/* Page Header */}
        		<Row noGutters className="page-header py-4">
              		<PageTitle sm="4" title="New Profit" subtitle="Add" className="text-sm-left" />
        		</Row>

       	 		<form action="/profit" style={{marginBottom: '2%',marginLeft: '2%'}}>
    				<Button type="submit" theme="danger">Cancel</Button>
		    	</form>


		    	<Col lg="12">
       				 <Card small className="mb-4">
          					<CardHeader className="border-bottom">
              						<h6 className="m-0">Add New Profit</h6>
          					</CardHeader>
          					<CardBody className="p-2 pb-2" >
          						{
          							!this.state.loading &&
          							<Col>
          								<Form /*onSubmit={this.onSubmitHandler}*/>
          									<Row form>
          										<FormTextInput size="4" type="number" field_name="Amount" onChange={(evt)=>{this.setState({amount:evt.target.value})}}/>	 
          										<FormTextInput size="8" type="text" field_name="Description" onChange={(evt)=>{this.setState({description:evt.target.value})}}/>
          									</Row>
          									<Row form>
          										<Type onChange={(evt)=>{this.setState({type:evt.target.value})}}/>
          										<PaymentMethod  onChange={(evt)=>{this.setState({payment_method:evt.target.value})}} />
          										<RefDate onChange={(date)=>{this.setState({ref_date:new Date(date)})}}/>
          									</Row>
          									<Row form style={{marginTop: '2%'}}>
                								
                        								<FileUpload path="Profit/Doc1" 
                    													field_name={"Doc1 Link"}
                        												link={this.state.doc1_link}
                        												filename={this.state.doc1_filename}
                          												onChange={(url,name)=>{this.setState({doc1_link:url,doc1_filename:name})}}
                          												remove={()=>{this.setState({doc1_filename:null,doc1_link:null})}} />
                    								

                    									<FileUpload path="Profit/Doc2" 
                    												field_name={"Doc2 Link"}
                    												link={this.state.doc2_link}
                    												filename={this.state.doc2_filename}
                                    								onChange={(url,name)=>{this.setState({doc2_link:url,doc2_filename:name})}} 
                                    								remove={()=>{this.setState({doc2_filename:null,doc2_link:null})}}/>
                    								
                    								
               								</Row>

               								<SubmitButton onClick={this.addProfit} />
               								
          								</Form>
          							</Col>
          						}
          						
          							{
           		 						(this.state.loading) &&
             							<AtomSpinner color="blue" style={{alignSelf: 'center',marginLeft: '40%',marginTop: '10%',marginBottom: '10%'}}/>
          							}
          						
          					</CardBody>
          			</Card>
          		</Col>


		    </Container>
		</div>

		)
	}
}


class SubmitButton extends React.Component{
	render(){
		return(
			<Row>
                						<Col lg="4"></Col>
                								<Col lg="4">
                									<Button onClick={this.props.onClick} style={{maxWidth: '100%',width:'100%',marginTop: '1%'}} size="md">
                										{"Add Profit"}
                									</Button>
                								</Col>
                								<Col lg="4"></Col>
                						</Row>

		)
	}
}

//dont use for date
class FormTextInput extends React.Component{
	render(){
		return(
			 <Col md={this.props.size} className="form-group">
                  <label>{this.props.field_name}</label>
                  <FormInput
                  type={this.props.type}
                  placeholder={this.props.field_name}
                  onChange = {this.props.onChange}
                />

              </Col>
		)
	}
}


class FileUpload extends React.Component{
	render(){
		return(
				<Col md="4">
                   	 								<label htmlFor="fePassword">{this.props.field_name}</label>
                    								
                    								{ this.props.link===null &&
                    									<FireFileUpload path={this.props.path} 
                                    									update_url={(url,name)=>{this.props.onChange(url,name)}} />
                    								}
                    								{
                      									this.props.link!==null &&
                      									<div>
                          									<br />
                           									<Button theme="danger" onClick={()=>{this.props.remove()}}>Remove</Button>
                           									<h6 className="m-0">{this.props.filename}</h6>
                      									</div>
                    								}
               	</Col>
		)
	}
}

class RefDate extends React.Component{
	constructor(props) {
	  super(props);
	
	  this.state = {date:null};
	  this.handleDateChange = this.handleDateChange.bind(this);
	}

	handleDateChange(value){
		this.setState({date:new Date(value)});
		this.props.onChange(new Date(value));
	}

	render(){
		return(
				<Col md={"4"} className="form-group">
                  	<label>{"Ref Date"}</label>
                  	<br />
                  	<DatePicker placeholderText="Ref Date"
                                  selected={this.state.date}
                                  onChange={this.handleDateChange}
                                  dropdownMode="select"
                                  className="text-center"
                                  />
              </Col>
		)
	}
}

class PaymentMethod extends React.Component{



	render(){
		return(
				 <Col md={"4"} className="form-group">
                  		<label htmlFor="feEmailAddress">{"Payment Method"}</label>
                  		<FormSelect onChange ={this.props.onChange}>
                  				<option value="cash">Cash</option>
                  				<option value="online">Online</option>
                  				<option value="NEFT">NEFT</option>
                  				<option value="cheque">Cheque</option>
                  		</FormSelect>
              </Col>	
		)
	}
}

class Type extends React.Component{
	render(){
		return(
				 <Col md={"4"} className="form-group">
                  		<label htmlFor="feEmailAddress">{"Type"}</label>
                  		<FormSelect onChange ={this.props.onChange}>
                  				<option value="profit">Profit</option>
                  				<option value="expenditure">Expenditure</option>
                  		</FormSelect>
             	  </Col>	
		)
	}
}
import React from "react";
import { Container, Row, Col,Card,CardHeader ,CardBody } from "shards-react";
import Message from '../Message';
import { AtomSpinner } from 'react-epic-spinners';
import profile_data from '../customerProfileStore';
import { store, view } from 'react-easy-state';
import getArray from '../getArray';
import formatDate from '../formatDate';
import formatRupee from '../formatRupee';


export default view(()=>{
      var data = {
        data       : getArray(profile_data.viewAuctData),
        isLoading   : profile_data.isAuctionLoading,
        hasError    : profile_data.hasErrorAuctionData,
        no_of_data : profile_data.no_of_auct_data,
      };
      console.log(data);
      console.log(getArray(profile_data.auction_data));

      return <Auction data={data} />
})


class Auction extends React.Component{
	render(){
		return(
			 <Card small className="mb-4 pt-3">
          <CardHeader className="border-bottom">
              <h6 className="m-0">Auction Details</h6>
          </CardHeader>

       
          
          <CardBody className="p-0 pb-3 scroll_list-group" >
          {
            (!this.props.data.isLoading && this.props.data.no_of_data>0) &&
            <table className="table mb-0">
              <thead className="bg-light">
                <tr>
                  <th scope="col" className="border-0">
                    #
                  </th>
                  <th scope="col" className="border-0">
                  	Group
                  </th>
                  <th scope="col" className="border-0">
                    Auct No.
                  </th>
                  <th scope="col" className="border-0">
                   	Auct Date
                  </th>
                  <th scope="col" className="border-0">
                    Bid Amount
                  </th>
                  <th scope="col" className="border-0">
                    Amount Paid
                  </th>
                  <th scope="col" className="border-0">
                    Amount Paid Date
                  </th>
                  <th scope="col" className="border-0">
                    Payment Ref
                  </th>
                  <th scope="col" className="border-0">
                    Payment Method
                  </th>
                </tr>
              </thead>
              <tbody>
                       {
                          this.props.data.data.map((item,i)=>{
                                return (<Trow key={i} data={item} index={i+1}/>)
                          })
                        }
               </tbody>
             </table>

           }

                    {
                        this.props.data.isLoading &&
                        <AtomSpinner color="blue" style={{alignSelf: 'center',marginLeft: '40%',marginTop: '5%',marginBottom: '5%'}}/>
                      }
                      {
                        (!this.props.data.isLoading && this.props.data.hasError) &&
                        <Message message={"Error Loading Data"} icon_name={"warning"} /> 
                      }
                      {
                        ( (!this.props.data.isLoading) && (this.props.data.no_of_data===0) ) &&
                          <Message message={"All Tickets are Non Priced..."} icon_name={"clear_all"} />
                      }
           </CardBody>
          </Card>

		)
	}
}


class Trow extends React.Component{
  render(){
    return(
           <tr>
                  <td>{this.props.index}</td>
                  <td>{this.props.data.group_id+"_"+this.props.data.bidder_ticket_no}</td>
                  <td>{this.props.data.auct_no}</td>
                  <td>{formatDate(this.props.data.auction_date_and_time.toDate())}</td>
                  <td>{formatRupee(this.props.data.bid_amount)}</td>
                  <td>{formatRupee(this.props.data.amount_paid)}</td>
                  <td>{this.props.data.amount_paid_date===null?"NA":formatDate(this.props.data.amount_paid_date.toDate())}</td>
                  <td>{this.props.data.payment_ref}</td>
                  <td>{this.props.data.payment_mode}</td>
          </tr>
      )
  }
}